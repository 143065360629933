import { Button, Input, InputNumber, Popover, Select, Tooltip } from "antd";
import React, { useEffect } from "react";
import {
  RetweetOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import HydraulicSimulatorService from "../../../../domain/HydraulicSimulator/HydraulicSimulatorService";

const hydraulicSimulatorService = new HydraulicSimulatorService();

export default function Popup({
  mmsId,
  object,
  onDelete,
  onRestore,
  onUpdateAttributeValue,
  setAttributesValue,
}) {
  console.log("🚀 ~ object:", object);
  const handleGetAttributes = async () => {
    const response = await hydraulicSimulatorService.getAttributesFromAsset(
      mmsId,
      object.assetId,
      object.type
    );
    setAttributesValue(response);
  };

  useEffect(() => {
    if (!object.attributes) {
      handleGetAttributes();
    }
  }, []);

  return (
    <div
      className="flex column items-center justify-center"
      style={{
        width: "150px",
        backgroundColor: "#29323c",
        color: "white",
        padding: "15px",
        fontSize: "11px",
      }}
    >
      <div className="flex column">
        <div
          className="flex justify-between items-center pb-1"
          style={{ borderBottom: "solid 2px #2186f2" }}
        >
          {object.assetType && (
            <span
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >{`${object.assetType}`}</span>
          )}
          <div className="flex">
            <Tooltip>
              <div
                className="text-center mr-1"
                style={{
                  borderRadius: "100%",
                  borderColor: "white",
                  border: "solid 3px white",
                  backgroundColor: "#29323c",
                  height: 25,
                  width: 25,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleGetAttributes();
                }}
              >
                <RetweetOutlined />
              </div>
            </Tooltip>
            {object.invisible ? (
              <Tooltip title="Restore">
                <div
                  className="text-center"
                  style={{
                    borderRadius: "100%",
                    borderColor: "white",
                    border: "solid 3px white",
                    backgroundColor: "#29323c",
                    height: 25,
                    width: 25,
                    cursor: "pointer",
                  }}
                  onClick={onRestore}
                >
                  <UndoOutlined style={{ cursor: "pointer", color: "white" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Delete">
                <div
                  className="text-center"
                  style={{
                    borderRadius: "100%",
                    borderColor: "white",
                    border: "solid 3px white",
                    backgroundColor: "#29323c",
                    height: 25,
                    width: 25,
                    cursor: "pointer",
                  }}
                  onClick={onDelete}
                >
                  <DeleteOutlined
                    style={{ cursor: "pointer", color: "white" }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {object.attributes && object.attributes.length > 0 && (
          <div className="flex column mt-1">
            <table
              style={{ borderSpacing: "0px 5px", borderCollapse: "separate" }}
            >
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{object.assetId}</td>
                </tr>
                {object.attributes.map((at, index) => {
                  return (
                    <tr
                      key={`${at.name}-${index}`}
                      style={{ marginBottom: "5px" }}
                    >
                      <td style={{ paddingRight: "8px" }}> {at.name}:</td>
                      <td>
                        {!at.editable ? (
                          <span>{at.value}</span>
                        ) : at.options.length > 0 ? (
                          <Select
                            value={at.value}
                            labelInValue
                            defaultActiveFirstOption={false}
                            onChange={(val) => {
                              onUpdateAttributeValue(at.name, val.value);
                            }}
                            style={{ width: "100%" }}
                            options={(at.options || []).map((i) => ({
                              value: i,
                              label: i,
                            }))}
                            size="small"
                          />
                        ) : at.numeric ? (
                          <InputNumber
                            value={at.value}
                            style={{ width: "100%" }}
                            placeholder=""
                            min={at.ranges[0]}
                            max={at.ranges[1]}
                            step={0.1}
                            size="small"
                            onChange={(val) => {
                              onUpdateAttributeValue(at.name, val);
                            }}
                            onBlur={(val) => {
                              if(val.target.value == "") {
                                onUpdateAttributeValue(at.name, at.ranges[0] || 0);
                              }
                              // console.log("🚀 ~ {object.attributes.map ~ val:", val)
                            }}
                          />
                        ) : (
                          <Input
                            value={at.value}
                            style={{ width: "100%" }}
                            placeholder=""
                            size="small"
                            onChange={(ev) => {
                              onUpdateAttributeValue(at.name, ev.target.value);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
