import React from "react";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

export const PageLayout = (props) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
    }

    return (
        <>
            {props.children}
            <br />
            <AuthenticatedTemplate></AuthenticatedTemplate>
        </>
    );
};
