import InteractiveSimulationRepository from "./InteractiveSimulationRepository";

export default class InteractiveSimulationService {
  private interactiveSimulationRepository: InteractiveSimulationRepository =
    new InteractiveSimulationRepository();

  async getInteractiveSimulations({
    organizationId,
    page,
    size,
    name,
    startDate,
    endDate,
    status,
  }: {
    organizationId: string | number;
    page: null | number | string;
    size: null | number | string;
    name: null | string;
    startDate: null | string;
    endDate: null | string;
    status: null | string;
  }): Promise<any> {
    return await this.interactiveSimulationRepository.getInteractiveSimulations(
      {
        organizationId,
        name,
        startDate,
        endDate,
        page,
        size,
        status,
      }
    );
  }

  async postInteractiveSimulation({
    simulationName,
    startDate,
    reportDate,
    endDate,
    stepTime,
    inpId,
    locationId,
  }: {
    simulationName: string;
    startDate: string;
    reportDate: string;
    endDate: string;
    stepTime: string | number;
    inpId: string | number;
    locationId: string | number;
  }) {
    try {
      return await this.interactiveSimulationRepository.postInteractiveSimulation(
        {
          simulationName,
          startDate,
          reportDate: startDate,
          endDate,
          stepTime,
          inpId,
          locationId,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async postFinishInteractiveSimulation(simulationId: any) {
    try {
      return await this.interactiveSimulationRepository.postFinishInteractiveSimulation(
        simulationId
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getSimulationInfo(simulationId: any) {
    try {
      return await this.interactiveSimulationRepository.getSimulationInfo(
        simulationId
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getAllIterationsInteractiveSimulation(simulationId: any) {
    try {
      return await this.interactiveSimulationRepository.getAllIterationsInteractiveSimulation(
        simulationId
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getDataOfAnIteration(simulationId: any, iterationId: any) {
    try {
      return await this.interactiveSimulationRepository.getDataOfAnIteration(
        simulationId,
        iterationId
      );
    } catch (error) {
      console.error(error);
    }
  }

  async deleteInteractiveSimulation(simulationId: any) {
    try {
      return await this.interactiveSimulationRepository.deleteInteractiveSimulation(
        simulationId
      );
    } catch (error) {
      console.error(error);
    }
  }
}
