import { api, apiController, restartApiCotroller } from "./api";
import { apiNl } from "./apiNl";
const getModelFiles = async (
  userId,
  { page= 1, size= 10, name= null, type= null, locationId= -1 },
  loading=true
) => {
  try {
    restartApiCotroller();
    const apiInstance = loading ? api : apiNl;
    const response = await apiInstance.get(`inps/uploads/organizations/${userId}`, {
      params: {
        page: page,
        size: size,
        name: name || null,
        pluviometer: type || null,
        locationId: locationId !== -1 ? locationId : null,
      },
      signal: apiController.signal,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getModelFiles };
