import React, { FC } from "react";

interface IProps {}

/**
 * @author
 * @function @PinOffSvg
 **/

export const PinOffSvg: FC<IProps> = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 20L9.09091 14.9091M13.4545 4L20 10.5455M14.9091 5.45455L10.5455 9.81818C10.5455 9.81818 7.63636 9.09091 5.45455 11.2727L12.7273 18.5455C14.9091 16.3636 14.1818 13.4545 14.1818 13.4545L18.5455 9.09091L14.9091 5.45455Z"
                stroke="#74788D"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <line
                x1="20.1596"
                y1="20.7071"
                x2="4.29293"
                y2="4.84044"
                stroke="white"
                strokeWidth="2"
            />
            <line
                x1="21.2929"
                y1="19.5738"
                x2="5.42623"
                y2="3.70711"
                stroke="#74788D"
                strokeWidth="2"
            />
        </svg>
    );
};
