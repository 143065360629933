import React, {
  useState,
  useEffect,
  Dispatch,
  useCallback,
  useRef,
} from "react";
import { Tabs } from "antd";
import LoadFile from "../../components/LoadFile/Index";
import { apiController } from "../../services/api";
import { getGisFiles } from "../../services/gis";
import { getModelFiles } from "../../services/model";
import GisTable from "./GisTable";
import ModelTable from "./ModelTable";
import HistoricalTabla from "./HistoricalTable";
import type { PaginationProps } from "antd";

import "./index.scss";
import "./index.scss";
import { useSearchParams } from "react-router-dom";
import UploadParametersTable from "./ParameterTable";

const UploadsView: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState<any>(null);
  const [fileName, setFileName] = useState<any>(null);
  const [typeGis, setTypeGis] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });
  const [pagination2, setPagination2] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });
  const [gisFiles, setGisFiles] = useState<any>([]);
  const [modelFiles, setModelFiles] = useState<any>([]);
  const [historicalFiles, setHistoricalFiles] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("GIS");
  const fetchInterval = useRef<any>(null);

  useEffect(() => {
    handleUpdateModelFiles();
  }, [pagination2.page]);

  useEffect(() => {
    const prevPage = pagination2.page;
    setPagination2({ ...pagination2, page: 1 });
    if (prevPage == 1) {
      handleUpdateModelFiles();
    }
  }, [name, type]);

  useEffect(() => {
    handleUpdateGisFiles();
    // return () => {
    //   apiController.abort();
    // };
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({ ...pagination, page: 1 });
    if (prevPage == 1) {
      handleUpdateGisFiles();
    }
    // handleUpdateGisFiles();
    // return () => {
    //   apiController.abort();
    // };
  }, [fileName, typeGis]);

  const handleUpdateGisFiles = useCallback(
    (loading = true) => {
      const userId = localStorage.getItem("userId");
      getGisFiles(
        userId,
        {
          page: pagination.page,
          size: pagination.pageSize,
          fileName,
          typeGis,
        },
        loading
      )
        .then((response) => {
          setPagination((prev) => {
            return {
              ...prev,
              totalRows: response.totalRecords,
            };
          });
          setGisFiles(response.filesUploaded);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [pagination, fileName, typeGis]
  );

  const handleUpdateModelFiles = async (loading = true) => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getModelFiles(
      organizationId,
      {
        page: pagination2.page,
        size: pagination2.pageSize,
        name,
        type,
      },
      loading
    )
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setPagination2({
          ...pagination2,
          totalRows: response.totalRecords,
        });
        setModelFiles(response.getAllResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    setPagination({ ...pagination, page });
  };

  const onChangePagination2: PaginationProps["onChange"] = (page) => {
    setPagination2({ ...pagination2, page });
  };

  const fetchWithInterval = useCallback(() => {
    fetchInterval.current = setInterval(() => {
      if (selectedTab == "GIS") {
        handleUpdateGisFiles(false);
      } else if (selectedTab == "MODEL") {
        handleUpdateModelFiles(false);
      }
    }, 10000);
  }, [pagination, pagination2, selectedTab, fileName, typeGis, name, type]);

  useEffect(() => {
    setSelectedTab(searchParams.get("selectedTab") || "GIS");
  }, [searchParams]);

  useEffect(() => {
    fetchWithInterval();
    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [pagination, pagination2, selectedTab, fileName, typeGis, name, type]);

  return (
    <div>
      <div className="text-title-page">Upload Files</div>
      <Tabs
        className="mt-3 ml-4"
        activeKey={selectedTab}
        onChange={(value) => {
          setSelectedTab(value);
        }}
        items={[
          {
            label: <span className="titulo-tabs">GIS FILES</span>,
            key: "GIS",
            children:
              gisFiles.length <= 0 && !typeGis && !fileName ? (
                <LoadFile url={"gisfiles"} text="Upload GIS Files" />
              ) : (
                <GisTable
                  handleUpdateGisFiles={handleUpdateGisFiles}
                  files={gisFiles}
                  onChangePagination={onChangePagination}
                  pagination={pagination}
                  setInputSearch={setFileName}
                  setTypeGis={setTypeGis}
                />
              ),
          },
          {
            label: <span className="titulo-tabs">MODEL FILES</span>,
            key: "MODEL",
            children:
              modelFiles.length > 0 || type || name ? (
                <ModelTable
                  handleUpdateModelFiles={handleUpdateModelFiles}
                  files={modelFiles}
                  onChangePagination={onChangePagination2}
                  pagination={pagination2}
                  setInputSearch={setName}
                  setType={setType}
                  setModelFiles={setModelFiles}
                />
              ) : (
                <LoadFile url={"modelfiles"} text={"Upload Models Files"} />
              ),
          },
          {
            label: <span className="titulo-tabs">HISTORICAL FILES</span>,
            key: "HISTORICAL",

            children:
              modelFiles.length > 0 || type || name ? (
                <HistoricalTabla isCurrentTab={selectedTab == "HISTORICAL"} />
              ) : (
                <LoadFile
                  url={"historicalfiles"}
                  text={"Upload Historical Files"}
                />
              ),
          },
          {
            label: <span className="titulo-tabs">PARAMETER FILES</span>,
            key: "PARAMETER",

            children:
              modelFiles.length > 0 || type || name ? (
                <UploadParametersTable
                  isCurrentTab={selectedTab == "PARAMETER"}
                />
              ) : (
                <LoadFile url={"parameters"} text={"Upload Files"} />
              ),
          },
        ]}
      />
    </div>
  );
};

export default UploadsView;
