import React, { useEffect, useState } from "react";
import { Input, Pagination, Tree } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import InfrastructureService from "../../../domain/Infrastructure/InfraestructureService";

const infrastructureService = new InfrastructureService();

type Props = {
  locationId: string | number;
  onSelect: Function;
};

export default function ListParametersTree({ locationId, onSelect }: Props) {
  const [name, setName] = useState<any>("");
  const [parameters, setParameters] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    currentPage: 1,
  });

  const handleUpdateParameters = async () => {
    const infra: any = await infrastructureService.getInfraestructureInUse(
      locationId.toString(),
      pagination.page,
      pagination.pageSize,
      name,
      null,
      0
    );
    setPagination({
      ...pagination,
      totalPages: infra.totalPages,
    });
    setParameters(
      infra.data.map((infra: any) => {
        return {
          title: infra.infraestructureName,
          key: infra.infraestructureId,
          disabled: true,
          ...(infra.measurementParameters?.length > 0
            ? {
                children: infra.measurementParameters.map((param: any) => {
                  return {
                    title: param.paremeterName,
                    key: JSON.stringify({
                      ...param,
                      infrastructureId: infra.infraestructureId,
                    }),
                  };
                }),
              }
            : {}),
        };
      })
    );
  };

  const onChangePagination = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    handleUpdateParameters();
  }, [name, pagination.page]);

  return (
    <div style={{ minHeight: 250 }}>
      <div>
        <Input
          className="ml-2"
          style={{ width: 220 }}
          placeholder="Search by Infrastructure"
          prefix={<SearchOutlined style={{ color: "#078CB3" }} rev={undefined} />}
          onPressEnter={(e) => {
            if (e.target) {
              const element = e.currentTarget as HTMLInputElement;
              setName(element.value);
            }
          }}
          allowClear
        />
      </div>
      <div className="mt-3 ml-2 mr-2">
        {parameters && (
          <>
            <Tree
              showLine
              switcherIcon={<DownOutlined rev={undefined} />}
              onSelect={(selected) => {
                onSelect(selected[0]);
              }}
              treeData={parameters}
              defaultExpandAll
            />
            <Pagination
              style={{ marginTop: "2rem" }}
              onChange={onChangePagination}
              defaultCurrent={pagination.currentPage}
              total={pagination.totalPages}
              showSizeChanger={false}
            />
          </>
        )}
      </div>
    </div>
  );
}
