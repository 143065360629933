import DigitalTwinRepository from "./DigitalTwinRepository";

export default class DigitalTwinService {
  private digitalTwinRepository: DigitalTwinRepository =
    new DigitalTwinRepository();

  async postDigitalTwin(
    data: any,
    infrastructureId: any,
    parameterId: any
  ): Promise<any> {
    return await this.digitalTwinRepository.postDigitalTwin(
      data,
      infrastructureId,
      parameterId
    );
  }

  async getDigitalTwins(
    infrastructureId: number | string,
    paramId: number | string,
    loading: boolean = true
  ): Promise<DigitalTwin[]> {
    const response = await this.digitalTwinRepository.getDigitalTwins(
      infrastructureId,
      paramId,
      loading
    );
    return response as DigitalTwin[];
  }

  async deleteDigitalTwin(neuralNetworkId: any): Promise<boolean> {
    return await this.digitalTwinRepository.deleteDigitalTwin(neuralNetworkId);
  }

  async getTestModel(neuralNetworkId: number | string): Promise<any> {
    return this.digitalTwinRepository.getTestModel(neuralNetworkId);
  }

  async setFavoriteModel(neuralNetworkId: number | string): Promise<boolean> {
    return this.digitalTwinRepository.setFavoriteModel(neuralNetworkId);
  }

  async getDefaultConfiguration(): Promise<any> {
    const response = await this.digitalTwinRepository.getDefaultConfiguration();
    return response;
  }

  async postConfiguration(params: {
    parameterId: Number | String;
    data: {
      inpId: Number;
      inpObjectId: Number;
      inpObjectType: Number;
    };
  }) {
    return await this.digitalTwinRepository.postConfiguration(params);
  }

  async getConfiguration({ paramId, type }: { paramId: Number; type: String }) {
    return await this.digitalTwinRepository.getConfiguration({ paramId, type });
  }
}
