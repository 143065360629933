import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_MONITOR = "GET_MONITOR";
let GET_MONITOR_SUCCESS = "GET_MONITOR_SUCCESS";
let GET_MONITOR_ERROR = "GET_MONITOR_ERROR";

// reducer
export default function monitorReducer(state = initialData, action) {
    switch (action.type) {
        case GET_MONITOR:
            return { ...state, fetching: true };
        case GET_MONITOR_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_MONITOR_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export const getMonitorAction = () => (dispatch, getState) => {
    let oui = JSON.parse(localStorage.getItem("security") || "{}").organizationId;
    let start = localStorage.getItem("globalDate").split("***")[0];
    let end = localStorage.getItem("globalDate").split("***")[1];
    return api
        .get(
            `${URL}/measurements/resume/devices/organizations/${oui}?startDate=${start}&endDate=${end}`
        )
        .then((res) => {
            dispatch({
                type: GET_MONITOR_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_MONITOR_ERROR,
                payload: err.response.message,
            });
        });
};
