import React from "react";

type Props = {};

export default function OverloadSvg() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_724_15279)">
        <path
          d="M41.8405 21.68H14.1205C10.7294 21.68 7.98047 24.429 7.98047 27.82V42.54C7.98047 45.931 10.7294 48.68 14.1205 48.68H41.8405C45.2315 48.68 47.9805 45.931 47.9805 42.54V27.82C47.9805 24.429 45.2315 21.68 41.8405 21.68Z"
          fill="#C8D1D9"
        />
        <path
          d="M10.7598 28.15H46.1698"
          stroke="#4D4D4D"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4697 40.49H36.9197"
          stroke="#4D4D4D"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8496 34.53H38.2996"
          stroke="#4D4D4D"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.3896 17.17L41.5996 12.02L46.6396 16.89L52.2596 11.84"
          stroke="#E6BF29"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3799 10.64L15.5899 11L12.4499 15.02L18.8299 18.49"
          stroke="#E6BF29"
          strokeWidth="1.11"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.0404 55.99H37.8604C34.7604 55.99 32.8204 52.64 34.3704 49.95L37.9604 43.73L41.5504 37.51C43.1004 34.83 46.9704 34.83 48.5204 37.51L52.1104 43.73L55.7004 49.95C57.2504 52.63 55.3104 55.99 52.2104 55.99H45.0304H45.0404Z"
          fill="#FF6666"
        />
        <path
          d="M45.7116 53.7C45.7116 53.972 45.6156 54.2 45.4236 54.384C45.2396 54.568 45.0116 54.66 44.7396 54.66C44.4756 54.66 44.2476 54.568 44.0556 54.384C43.8716 54.2 43.7796 53.972 43.7796 53.7C43.7796 53.428 43.8716 53.2 44.0556 53.016C44.2476 52.832 44.4756 52.74 44.7396 52.74C45.0116 52.74 45.2396 52.832 45.4236 53.016C45.6156 53.2 45.7116 53.428 45.7116 53.7ZM43.9956 51.84L43.8036 45.648H45.6036L45.4116 51.84H43.9956Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_724_15279">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
