import BlockRepository from "./BlockRepository";

export default class BlockService {
  private blockRepository: BlockRepository = new BlockRepository();

  async saveBlockDiagram(
    organizationId: any,
    locationId: any,
    block: BlockDiagram
  ): Promise<Boolean> {
    const data = {
      ...(block.id > 0 ? { id: block.id } : {}),
      name: block.name,
      nodes: JSON.stringify(block.nodes),
      edges: JSON.stringify(block.edges),
    };
    return this.blockRepository.saveBlockDiagram(
      organizationId,
      locationId,
      data
    );
  }

  async getBlockDiagram(blockId: number): Promise<BlockDiagram> {
    const res = await this.blockRepository.getBlockDiagram(blockId);
    function esJSONValido(str: string) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
    return {
      id: res.id,
      name: res.name,
      nodes:
        esJSONValido(res.nodes) && res.nodes != null
          ? JSON.parse(res.nodes)
          : [],
      edges:
        esJSONValido(res.edges) && res.edges != null
          ? JSON.parse(res.edges)
          : [],
    } as BlockDiagram;
  }

  async getAllBlockRecordsByOrganization(
    organizationId: any,
    locationId: any,
    page: number,
    size: number,
    name: string | null
  ): Promise<any> {
    return await this.blockRepository.getAllBlockRecordsByOrganization(
      organizationId,
      locationId,
      page,
      size,
      name
    );
  }

  async getBlock(
    organizationId: string,
    page: number,
    size: number,
    name: string | null = null
  ): Promise<Block> {
    return this.blockRepository.getBlock(organizationId, page, size, name);
  }

  async updateBlockDiagram(
    organizationId: string,
    locationId: any,
    block: any
  ): Promise<boolean> {
    const data = {
      ...(block.id > 0 ? { id: block.id } : {}),
      name: block.name,
      location: block.location,
      enabled: block.enabled,
    };
    return this.blockRepository.updateBlockDiagram(
      organizationId,
      locationId,
      data
    );
  }

  async enableInUseBlockDiagram(block: any): Promise<boolean> {
    return this.blockRepository.changeInUseBlockDiagram(block.id, true);
  }

  async disableInuseBlockDiagram(block: any): Promise<boolean> {
    return this.blockRepository.changeInUseBlockDiagram(block.id, false);
  }

  async deleteBlockDiagram(blockId: number): Promise<boolean> {
    return this.blockRepository.deleteBlockDiagram(blockId);
  }
}
