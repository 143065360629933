import { api } from "../../services/api";

export default class KpiRepository {
  async getKpi(organizationId: string, userId: string): Promise<Kpi[]> {
    const kpis = await api.get(
      `/kpis/organizations/${organizationId}/users/${userId}`
    );
    return kpis.data;
  }

  async getKpiByOrganization(
    organizationId: string,
    page: string | number = "1",
    size: string | number = "10",
    name?: string | null,
    enabled?: boolean | null,
    user?: string | null,
    location?: string | null
  ): Promise<KpiPaginated> {
    const kpis = await api.get(`kpis/organizations/${organizationId}`, {
      params: {
        page,
        size,
        name,
        enabled,
        user,
        location,
      },
    });
    return kpis.data;
  }

  async postKpi(
    name: string,
    enabled: boolean,
    parameterId: number,
    kpiId?: number
  ): Promise<boolean> {
    const response = await api.post(`kpis/`, {
      name,
      enabled,
      parameterId,
      kpiId,
    });
    return response.data;
  }

  async changeEnableKpi(
    kpiId: string | number,
    enabled: boolean
  ): Promise<boolean> {
    const response = await api.put(`kpis/${kpiId}/status?enabled=${enabled}`);
    return response.data;
  }

  async getKpiDetails(kpiId: number): Promise<any> {
    const kpi = await api.get(`/kpis/${kpiId}`);
    return kpi.data;
  }

  async postPinKpiInDashboard(kpiId: number, showBoard: boolean) {
    const response = await api.post(
      `users/kpis/${kpiId}?showBoard=${showBoard}`
    );
    return response.data;
  }

  async getKpisByUser(userId: string) {
    const kpis = await api.get(`users/${userId}/kpis`);
    return kpis.data;
  }

  async deleteKpi(kpiId: number) {
    const response = await api.delete(`kpis/${kpiId}`);
    return response.data;
  }
}
