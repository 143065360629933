import AlertRepository from "./AlertRepository";

export default class AlertService {
  private alertRepository: AlertRepository = new AlertRepository();

  async createAlert(alert: Alert): Promise<any> {
    return await this.alertRepository.postAlert(alert);
  }

  async updateAlert(alertId: number, alert: Alert): Promise<any> {
    return await this.alertRepository.postUpadateAlert(alertId, alert);
  }

  async getAlerts({
    name = null,
    enable = null,
    page = 1,
    size = 20,
  }: {
    name?: null | string;
    enable?: null | boolean;
    page: number;
    size?: number;
  }): Promise<AlertsPaginated> {
    const response = await this.alertRepository.getAlerts({
      name,
      enable,
      page,
      size,
    });
    return {
      totalRecords: response.totalRecords,
      alerts: response.data.map(
        (item: {
          name: any;
          createdAt: any;
          createdBy: any;
          enable: any;
          alertId: any;
        }) => {
          return {
            alertId: item.alertId,
            alertName: item.name,
            alertCreatedAt: item.createdAt,
            alertCreatedBy: item.createdBy,
            alertEnable: item.enable,
          } as Alert;
        }
      ),
    } as AlertsPaginated;
  }

  async getAlertById(alertId: number): Promise<Alert> {
    const response = await this.alertRepository.getAlertById(alertId);
    return {
      ...response,
      alertWhenFiring: JSON.parse(response.alertWhenFiring),
      rules: response.rulesDTO.map((rule: Rule) => {
        return rule as Rule;
      }),
      recipients: response.recipientsDTO.map((recipient: any) => {
        return {
          userId: recipient.userId,
          enableSendEmail: recipient.enableSendEmail,
          enableSendSms: recipient.enableSendSms,
          recipientId: recipient.recipientsId,
          name: recipient.userNames,
          email: recipient.userMail,
        } as Recipient;
      }),
    } as Alert;
  }
}
