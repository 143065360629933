import { api } from "../../services/api";

export default class BlockRepository {
  async saveBlockDiagram(organizationId: any, locationId: any, data: any) {
    const res = await api.put(
      `blocks/organizations/${organizationId}/locations/${locationId}`,
      data
    );
    return res.data.success === 1;
  }

  async getBlock(
    organizationId: string,
    page: number,
    size: number,
    name: string | null
  ) {
    const res = await api.get(`blocks/organizations/${organizationId}`, {
      params: { page, size, name },
    });
    return res.data;
  }

  async updateBlockDiagram(organizationId: string, locationId: any, data: any) {
    const res = await api.post(
      `blocks/organizations/${organizationId}/locations/${locationId}`,
      data
    );
    return res.data;
  }

  async changeInUseBlockDiagram(id: number, inUse: boolean): Promise<boolean> {
    const result = await api.put(`/blocks/${id}?enable=${inUse}`);
    return result.data;
  }

  async getBlockDiagram(blockId: number) {
    const res = await api.get(`blocks/${blockId}`);
    return res.data;
  }

  async getAllBlockRecordsByOrganization(
    organizationId: any,
    locationId: any,
    page: number,
    size: number,
    name: string | null
  ) {
    const res = await api.get(`blocks/organizations/${organizationId}`, {
      params: {
        locationId,
        page,
        size,
        name,
      },
    });
    return res.data;
  }

  async deleteBlockDiagram(blockId: number): Promise<boolean> {
    const result = await api.delete(`/blocks/${blockId}`);
    return result.data;
  }
}
