import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/devices/";

let GET_DEVICE_SENSOR = "GET_DEVICE_SENSOR";
let GET_DEVICE_SENSOR_SUCCESS = "GET_DEVICE_SENSOR_SUCCESS";
let GET_DEVICE_SENSOR_ERROR = "GET_DEVICE_SENSOR_ERROR";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_DEVICE_SENSOR:
            return { ...state, fetching: true };
        case GET_DEVICE_SENSOR_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_DEVICE_SENSOR_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getDeviceSensorAction = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_DEVICE_SENSOR,
    });
    return api
        .get(`${URL}${id}/sensors`)
        .then((res) => {
            dispatch({
                type: GET_DEVICE_SENSOR_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_DEVICE_SENSOR_ERROR,
                payload: err.response.message,
            });
        });
};
