import { Button, Modal } from "antd";
import React from "react";

export default function GenerateKeplerAnalysisModal({
  show,
  onCancel,
  onAccept,
}) {
  function handleGeneratekeplerAnalysis(){
    onAccept();
  }
  return (
    <Modal
      title="Generate Analysis"
      open={show}
      footer={[]}
      closable={true}
      onCancel={onCancel}
    >
      <div className="flex column">
        <span>Do you want to generate the Kepler analysis?</span>
        <div className="flex self-center mt-2">
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={() => {
              handleGeneratekeplerAnalysis();
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
}
