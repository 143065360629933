/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Select, Switch, Tabs, TabsProps, Tag } from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { List, Modal, Nav } from "rsuite";
import SourcesDetail from "./sources";
import "./index.scss";
import Forecast from "./forecast";
import InfrastructureService from "../../../../domain/Infrastructure/InfraestructureService";
import InfraestructureContext from "../../../../views/Infrastructures/Parameters/context";

const infrastructureService = new InfrastructureService();

type Props = {
  open: boolean;
  infrastructure: Infrastructure | null;
  onClose: Function;
  handleLoadSources: Function;
};

export default function ModalParameterDetail({
  open,
  infrastructure,
  onClose,
  handleLoadSources,
}: Props) {
  const { value: context, setValue: setContext } = useContext(
    InfraestructureContext
  );
  const [selectNav, setSelectNav] = useState("source");
  const [selectedInfrastructure, setSelectedInfrastructure] =
    useState<any>(null);
  const [selectedParameter, setSelectedParameter] = useState<any>(null);
  const [infraestructures, setInfraestructures] = useState<any[]>([]);
  const [parameters, setParameters] = useState<any[]>([]);

  async function handleSearchInfrastructures(search: any) {
    if (!infrastructure?.locationId) return;
    const response = await infrastructureService.getInfraestructureInUse(
      `${infrastructure.locationId}`,
      1,
      50,
      search,
      null,
      0
    );
    setInfraestructures(response.data);
  }

  async function handleSetParameters(val: null) {
    const selectedParameter = infraestructures.find(
      (p) => p.infraestructureId === val
    );
    if (selectedParameter) {
      setParameters(selectedParameter.measurementParameters);
    }
  }

  useEffect(() => {
    if (context.showAddVariableVirtualParam) {
      handleSearchInfrastructures("");
    }
  }, [context.showAddVariableVirtualParam]);

  useEffect(() => {
    handleSetParameters(selectedInfrastructure);
  }, [selectedInfrastructure]);

  return (
    <Modal className="modal-source-detail" open={open} size='md'>
      <div
        className={context.showAddVariableVirtualParam ? "flex column" : "hide"}
      >
        <ArrowLeftOutlined
          onClick={() => {
            setContext({
              ...context,
              selectedVirtualSource: null,
              showAddVariableVirtualParam: false,
              handleAddVariableVirtualParam: null,
            });
            setSelectedInfrastructure(null);
            setSelectedParameter(null);
          } } rev={undefined}        />
        <Nav appearance="subtle" activeKey={"addv"}>
          <Nav.Item eventKey="addv">Add variables</Nav.Item>
        </Nav>
        <div className="flex column mt-3">
          <span className="mb-2">Select parameter</span>
          <div className="flex mb-3">
            <Select
              id="infrastructure"
              placeholder="Infrastructure"
              value={selectedInfrastructure}
              notFoundContent={null}
              onChange={(val) => {
                setSelectedInfrastructure(val);
                setSelectedParameter(null);
              }}
              style={{ minWidth: "200px" }}
              showSearch
              options={(infraestructures || []).map((d) => ({
                value: d.infraestructureId,
                label: d.infraestructureName,
              }))}
              className="mr-1"
            />
            <Select
              id="parameter"
              placeholder="Parameter"
              value={selectedParameter}
              onChange={(val) => {
                setSelectedParameter(val);
              }}
              style={{ minWidth: "200px" }}
              options={(parameters || []).map((p) => ({
                value: JSON.stringify(p),
                label: p.paremeterName,
              }))}
              className="mr-1"
            />
            <Button
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined rev={undefined} />}
              className="flex items-center ml-auto"
              style={{
                backgroundColor: "#023E65",
                borderColor: "#023E65",
                color: "white",
                width: "100px",
                height: "32px",
              }}
              onClick={() => {
                if (
                  context.handleAddVariableVirtualParam &&
                  selectedParameter
                ) {
                  context.handleAddVariableVirtualParam(
                    JSON.parse(selectedParameter)
                  );
                  setSelectedInfrastructure(null);
                  setSelectedParameter(null);
                }
              }}
            >
              Variable
            </Button>
          </div>
          <div className="flex" style={{ flexWrap: "wrap" }}>
            {context.selectedVirtualSource?.variables.map((va) => {
              return (
                <Tag
                  color="#E8F9FF"
                  key={va.parameterId}
                  style={{
                    color: "#023E65",
                    fontWeight: "bold",
                    borderRadius: "20px",
                  }}
                  className="mr-1 mb-1"
                >
                  {`${va.parameterName} = ${va.name}`}
                </Tag>
              );
            })}
          </div>
        </div>
      </div>

      {/* =========SEPARADOR======= */}

      <div
        className={
          !context.showAddVariableVirtualParam ? "flex column" : "hide"
        }
      >
        <div className="flex justify-between">
          <span
            style={{
              color: "#232B36",
              fontSize: "2rem",
              fontWeight: "600",
            }}
          >
            {context.selectedParam?.name}
          </span>
          <CloseOutlined
            onClick={() => {
              onClose();
            } } rev={undefined}          />
        </div>
        <Nav appearance="subtle" activeKey={selectNav} onSelect={setSelectNav}>
          <Nav.Item eventKey="source">REAL-TIME DATA SOURCES</Nav.Item>
          <Nav.Item eventKey="forecast">FORECAST DATA SOURCE</Nav.Item>
        </Nav>
        {selectNav === "source" ? (
          <SourcesDetail
            sources={context.selectedParam?.sources || []}
            parameter={context.selectedParam}
            infrastructure={infrastructure}
            handleLoadSources={handleLoadSources}
          />
        ) : (
          <div>
            <Forecast infrastructure={infrastructure} onClose={onClose} />
          </div>
        )}
      </div>
    </Modal>
  );
}
