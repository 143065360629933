import React, { FC } from "react";

/**
 * @author
 * @function @ArrowDropdownSvg
 **/
const ArrowDropdownSvg: FC = () => {
    return (
        <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.16667 0.64168C9.01054 0.486471 8.79933 0.399353 8.57917 0.399353C8.35902 0.399353 8.14781 0.486471 7.99167 0.64168L5.00001 3.59168L2.05001 0.64168C1.89387 0.486471 1.68266 0.399353 1.46251 0.399353C1.24235 0.399353 1.03114 0.486471 0.875008 0.64168C0.796901 0.719149 0.734906 0.811317 0.692598 0.912866C0.650291 1.01442 0.62851 1.12334 0.62851 1.23335C0.62851 1.34336 0.650291 1.45228 0.692598 1.55383C0.734906 1.65538 0.796901 1.74754 0.875008 1.82501L4.40834 5.35835C4.48581 5.43645 4.57798 5.49845 4.67953 5.54076C4.78108 5.58306 4.89 5.60485 5.00001 5.60485C5.11002 5.60485 5.21894 5.58306 5.32049 5.54076C5.42204 5.49845 5.51421 5.43645 5.59167 5.35835L9.16667 1.82501C9.24478 1.74754 9.30678 1.65538 9.34908 1.55383C9.39139 1.45228 9.41317 1.34336 9.41317 1.23335C9.41317 1.12334 9.39139 1.01442 9.34908 0.912866C9.30678 0.811317 9.24478 0.719149 9.16667 0.64168Z"
                fill="black"
            />
        </svg>
    );
};

export default ArrowDropdownSvg;
