let SET_DATA = "SET_DATA";

export default function reducer(state = { data: {} }, action) {
  switch (action.type) {
    case SET_DATA:
      return { data: action.payload };
    default:
      return state;
  }
}

export let setSelectedParameterDataToDigitalTwin =
  (payload) => async (dispatch) => {
    console.log(
      "🚀 ~ file: digitalTwinDuck.js:13 ~ setSelectedParameterDataToDigitalTwin ~ payload:",
      payload
    );
    dispatch({
      type: SET_DATA,
      payload,
    });
  };
