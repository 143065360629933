import OrganizationRepository from "./OrganizationRepository";

export default class OrganizationService {
  private organizationRepository: OrganizationRepository =
    new OrganizationRepository();

  async getOrganizations({
    name = null,
  }: {
    name?: null | string;
  }): Promise<Organization> {
    console.log("🚀 ~ OrganizationService ~ name:", name);
    return this.organizationRepository.getOrganizations({ name });
  }
  async postOrganization(data: any) {
    return this.organizationRepository.postOrganization(data);
  }

  async putOrganization(organizationId: string, data: any) {
    return this.organizationRepository.putOrganization(organizationId, data);
  }

  async deleteOrganization(organizationId: string) {
    return this.organizationRepository.deleteOrganization(organizationId);
  }
}
