import IterationSimulationRepository from "./IterationSimulationRepository";

export default class IterationSimulationService {
  private iterationSimulationRepository: IterationSimulationRepository =
    new IterationSimulationRepository();

  async getIterationsBySimulation({
    simulationId,
  }: {
    simulationId: string | number;
  }): Promise<any> {
    return await this.iterationSimulationRepository.getIterationsBySimulation(
      {
        simulationId
      }
    );
  }

  async postIterationSimulation({
    simulationId,
  }: {
    simulationId: string | number;
  }) {
    try {
      return await this.iterationSimulationRepository.postIterationSimulation(
        {
          simulationId,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
