import { api } from "../../services/api";

export default class LocationRepository {
  async getLocationsByCountryOrganization(
    countryId: string | number,
    organizationId: string,
    name?: string,
    status?: string,
  ): Promise<Location[]> {
    const locations = await api.get(
      `locations/countries/${countryId}/organizations/${organizationId}`, { params: { name, status } }
    );
    return locations.data;
  }

  async getLocationsByUser(userId: string): Promise<Location[]> {
    const locations = await api.get(`users/${userId}/locations`);
    return locations.data;
  }

  async postLocationsByCountryOrganization(
    countryId: string,
    organizationId: string,
    newLocations: Location[]
  ): Promise<boolean> {
    const locations = await api.post(
      `locations/countries/${countryId}/organizations/${organizationId}`,
      newLocations
    );
    return locations.data.success === 1;
  }

  async deleteLocation(locationId: number): Promise<boolean> {
    const response = await api.delete(`/locations/${locationId}`);
    return response.data;
  }

  async changeEnableLocation(
    organizationId: string,
    locationId: number,
    visible: boolean
  ): Promise<boolean> {
    const response = await api.put(
      `locations/${locationId}/organizations/${organizationId}/visible/${visible ? "false" : "true"
      }`
    );
    return response.data;
  }
}
