import { api } from "../services/api";
import { getDeviceSensorAction } from "./deviceSensorDuck";
// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/devices/location/";

let GET_SENSOR = "GET_SENSOR";
let GET_SENSOR_SUCCESS = "GET_SENSOR_SUCCESS";
let GET_SENSOR_ERROR = "GET_SENSOR_ERROR";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_SENSOR:
            return { ...state, fetching: true };
        case GET_SENSOR_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_SENSOR_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getSensorAction = (id) => async (dispatch, getState) => {
    dispatch({
        type: GET_SENSOR,
    });
    return api
        .get(`${URL}${id}`)
        .then((res) => {
            getDeviceSensorAction(id)(dispatch, getState);
            dispatch({
                type: GET_SENSOR_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_SENSOR_ERROR,
                payload: err.response.message,
            });
        });
};
