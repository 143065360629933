import { Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useInpFiles from "../../hooks/useInpFiles";

const SelectInpFile = forwardRef(
  (
    {
      organizationId,
      onSelect,
      emitObject,
      defaultValue = null,
      locationId = null,
      requireLocation = false,
      onlyCompleted = null,
    }: {
      organizationId: number;
      onSelect: Function;
      emitObject?: boolean;
      defaultValue?: any;
      locationId?: any;
      requireLocation?: boolean;
      onlyCompleted?: boolean | null;
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue);
    const { inpFiles, setParams } = useInpFiles({
      organizationId,
      locationId,
      size: 20,
      requireLocation,
      onlyCompleted,
    });

    function is_json(str: string) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
    }));

    useEffect(() => {
      if (selectedValue && emitObject && `${selectedValue}`.length < 5) {
        const finded = inpFiles.findIndex((inp: any) => {
          return inp.inpId == selectedValue;
        });
        if (finded >= 0) {
          setSelectedValue(JSON.stringify(inpFiles[finded]));
        }
      }
    }, [inpFiles, selectedValue]);

    useEffect(() => {
      setParams((params: any) => {
        return { ...params, locationId };
      });
    }, [locationId]);

    return (
      <Select
        value={selectedValue}
        placeholder="Select an option"
        defaultValue={defaultValue}
        showSearch
        defaultActiveFirstOption={false}
        onSearch={(newValue: string) => {
          if (newValue.length >= 3) {
            setParams((params: any) => {
              return { ...params, name: newValue };
            });
          } else {
            setParams((params: any) => {
              return { ...params, name: null };
            });
          }
        }}
        onChange={(val: string) => {
          if (val) {
            console.log("🚀 ~ val:", val);
            setSelectedValue(val);
            onSelect(emitObject ? JSON.parse(val || "{}") : val);
          }
        }}
        style={{ width: "100%" }}
        options={(inpFiles || []).map((p) => ({
          value: emitObject ? JSON.stringify(p) : p.inpId,
          label: p.inpName,
        }))}
      />
    );
  }
);

export default SelectInpFile;
