import React from "react";
import { Select, Spin } from "antd";
import Icon from "@ant-design/icons";
import SearchSvg from "../../../assets/svg/SearchSvg";
import "./index.scss";

interface Props {
  options: Array<any>;
  onSelect: Function;
  fetching: boolean;
  placeholder: string;
  styleDiv: any;
}

const SelectSearch: React.FC<Props> = ({
  onSelect,
  fetching,
  options,
  placeholder,
  styleDiv
}) => {
  return (
    <div
      className="flex relative custom-select__container"
      style={{ width: "inherit", ...styleDiv }}
    >
      <Icon
        style={{
          position: "absolute",
          zIndex: "9",
          left: "10px",
          top: "9px",
        }}
        component={SearchSvg} rev={undefined}      />
      <Select
        onSelect={(_, option) => onSelect(option)}
        showSearch={true}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
        placeholder={placeholder}
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        suffixIcon={<div />}
      />
    </div>
  );
};

export default SelectSearch;
