import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import StateMachineContext from "./context";
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  Connection,
  Edge,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  Node,
  Background,
  BackgroundVariant,
  EdgeChange,
  MarkerType,
} from "reactflow";
import { useLocation, useParams } from "react-router-dom";
import { StateMachineSidebar } from "./Sidebar";
import StateMachineService from "../../domain/StateMachine/StateMachineService";
import NodeComponent from "./NodeComponent";
import ActionModal from "./NodeComponentModals/ActionModal";
import TransitionModal from "./NodeComponentModals/TransitionModal";
import { notification } from "antd";

// const nodeTypes = { parameter: ParameterNode };
const nodeTypes = { node: NodeComponent };

const getColor = (type: any) => {
  switch (type) {
    case "default": {
      return "green";
    }
    case "input": {
      return "blue";
    }
    case "output": {
      return "red";
    }
    default: {
      return "gray";
    }
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let id = 0;
const getId = () => `${id++}`;

const stateMachineService = new StateMachineService();

export default function EditStateMachineView() {
  const query = useQuery();
  const isEdit = query.get("isEdit");
  const { stateMachineId } = useParams();

  const [contextValue, setContextValue] = useState<any>({
    inputsList: [],
    outputsList: [],
    constantsList: [],
    strategyList: [],
  });
  const reactFlowWrapper = useRef<any>(null);

  const [nodes, setNodes] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [nodeSelect, setNodeSelect] = useState<any>(null);
  const [stateMachineDetails, setStateMachineDetails] = useState<any>(null);
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [showTransitionModal, setShowTransitionModal] =
    useState<boolean>(false);
  const [transition, setTransition] = useState<any>({
    source: null,
    target: null,
  });
  const [transitionsList, setTransitionsList] = useState<any[]>([]);
  const [selectedTransition, setSelectedTransition] = useState<any>(null);

  const onNodesChange = useCallback(
    (changes: any) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onConnect = useCallback((params: Edge | Connection) => {
    console.log("🚀 ~ onConnect ~ params:", params);
    setNodes((prev) => {
      const s = prev.find((n: any) => n.id == params.source);
      const t = prev.find((n: any) => n.id == params.target);
      setTransition({ source: s, target: t });
      return prev;
    });
    setShowTransitionModal(true);
    setSelectedTransition(null);
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          type: "straight",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        },
        eds
      )
    );
  }, []);

  const openActionModal = (data: any, id: any) => {
    let ns = null;
    setNodes((prev) => {
      ns = prev.find((n: any) => n.id == id);
      return prev;
    });
    setNodeSelect(ns);
    setShowActionModal(true);
  };

  function onChangeNode(newData: any, id: any) {
    console.log("🚀 ~ file: index.tsx:189 ~ onChangeNode ~ event:", newData);
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id != id) {
          return node;
        }

        // const label = data.paremeterName;
        // const value = data;

        return {
          ...node,
          data: {
            ...node.data,
            value: newData,
          },
        };
      })
    );
  }

  function onChangeLabel(label: any, id: any) {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id != id) {
          return node;
        }

        return {
          ...node,
          data: {
            ...node.data,
            label: label,
          },
        };
      })
    );
  }

  function setInitial(id: any) {
    setNodes((nds) => {
      console.log("🚀 ~ setNodes ~ nds:", nds);
      console.log("🚀 ~ setInitial ~ setInitial:", id);

      return nds.map((node) => {
        if (node.id != id) {
          return {
            ...node,
            data: {
              ...node.data,
              initial: false,
            },
          };
        } else {
          return {
            ...node,
            data: {
              ...node.data,
              initial: true,
            },
          };
        }
      });
    });
  }

  const onDrop = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { getData: (arg0: string) => any };
      clientX: number;
      clientY: number;
    }) => {
      event.preventDefault();

      const reactFlowBounds =
        reactFlowWrapper?.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance?.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      let color = "";
      color = getColor(type);
      let newNode: Node;
      const newID = getId();
      console.log("🚀 ~ EditStateMachineView ~ newID:", newID);
      switch (type) {
        case "node":
          newNode = {
            id: newID,
            type,
            position,
            data: {
              label: ``,
              value: {},
              initial: false,
              editable: isEdit,
              onChange: onChangeNode,
              onChangeLabel: onChangeLabel,
              setInitial: setInitial,
              removeNode: removeNode,
              addNode: addNode,
              openActionModal: openActionModal,
            },
            style: {
              background: color,
            },
          } as Node;
          break;

        default:
          newNode = {
            id: newID,
            type,
            position,
            data: { label: `${type} node`, value: {}, onChange: onChange },
            style: {
              background: color,
            },
          } as Node;
          break;
      }
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  function onChange(event: any, id: any) {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id != id) {
          return node;
        }

        const label = event.target.value;

        return {
          ...node,
          data: {
            ...node.data,
            label,
          },
        };
      })
    );
  }

  const removeNode = (id: string) => {
    setNodes((prev) => {
      return prev.filter((e) => {
        return e.id != id;
      });
    });
  };

  const addNode = (id: string) => {
    onAddNode(id);
  };

  function onAddNode(id: string) {
    const newID = getId();
    setNodes((prev) => {
      const curentNode = prev.find((node) => {
        return node.id == id;
      });

      const newNode = {
        id: newID,
        type: "parameter",
        position: {
          x: (curentNode?.position.x || 0) + 250,
          y: curentNode?.position.y,
        },
        data: {
          label: `Parameter node`,
          value: {},
          onChange: onChangeNode,
          removeNode: removeNode,
          addNode: addNode,
        },
        style: {
          background: getColor("parameter"),
        },
      } as unknown as Node;
      return [...prev, newNode];
    });
    const newEdge = {
      id: `reactflow__edge-${id}-${newID}`,
      source: id,
      target: newID,
      type: "smoothstep",
    };
    console.log("🚀 ~ file: index.tsx:179 ~ addNode ~ newEdge:", newEdge);
    setEdges((prev) => {
      return [...prev, newEdge];
    });
  }

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const handleGetDetails = async () => {
    if (stateMachineId) {
      const response = await stateMachineService.getStateMachineDetail(
        stateMachineId
      );
      setStateMachineDetails(response);
      const nodes = JSON.parse(response.nodes);
      if (nodes.length > 0) {
        setNodes(
          JSON.parse(response.nodes).map((node: any) => {
            return {
              ...node,
              data: {
                ...node.data,
                onChange: onChangeNode,
                onChangeLabel: onChangeLabel,
                setInitial: setInitial,
                removeNode: removeNode,
                addNode: addNode,
                openActionModal: openActionModal,
              },
            };
          })
        );
        id = nodes.length > 0 ? parseInt(nodes[nodes.length - 1].id) + 1 : 1;
        console.log(id);
      }
      if (response.edges.length > 0) {
        setEdges(JSON.parse(response.edges));
      }
      if (response.definition.length > 0) {
        const definition = JSON.parse(response.definition);
        const variables = definition.variables;
        console.log("🚀 ~ file: index.tsx:179 ~ response ~ response:", variables);
        const newContext = {
          inputsList: variables.inputs.map((inp: string) => {
            const values = inp.split(".");
            return {
              label: inp,
              assetType: values[1],
              assetId: values[2],
              attribute: values[3],
            };
          }),
          outputsList: variables.outputs.map((out: string) => {
            const values = out.split(".");
            return {
              label: out,
              assetType: values[1],
              assetId: values[2],
              attribute: values[3],
            };
          }),
          constantsList: variables.constants.map((constant: any) => {
            return {
              ...constant,
              label: `Constants.${constant.name}=${constant.value}`, 
            };
          }),
          strategyList: variables.strategies.map((strategy: any) => {
            return {
              ...strategy,
              label: strategy.name, 
            };
          }),
        };

        setContextValue(newContext);
        setTransitionsList(definition.transitions);
      }
    }
  };

  const handleSaveStateMachine = async () => {
    const body = {
      id: stateMachineDetails.id,
      name: stateMachineDetails.name,
      enabled: stateMachineDetails.enabled,
      inpId: stateMachineDetails.inpId,
      locationId: stateMachineDetails.locationId,
      nodes: JSON.stringify(nodes),
      edges: JSON.stringify(edges),
      definition: JSON.stringify({
        state: nodes.map((node: any) => ({
          initial: node.data.initial,
          tag: node.data.label,
          action: { ...node.data.value },
        })),
        variables: {
          inputs: contextValue.inputsList.map((inp: any) => inp.label),
          outputs: contextValue.outputsList.map((out: any) => out.label),
          constants: contextValue.constantsList.map((constant: any) => ({
            name: constant.name,
            value: constant.value,
            strategies: constant.strategies || []
          })),
          strategies: contextValue.strategyList.map((strategy: any) => ({
            name: strategy.name,
          })),
        },
        transitions: transitionsList.map((transition) => {
          const from = nodes.find((node) => node.id == transition.fromId) as any;
          const to = nodes.find((node) => node.id == transition.toId) as any;
          return {
            fromId: transition.fromId,
            toId: transition.toId,
            from: from.data?.label,
            to: to.data?.label,
            condition: transition.condition,
          };
        }),
      }),
    };  
    try {
      const response = await stateMachineService.editStateMachineComplete(body);
      if (response) {
        notification.success({
          message: "Successful",
          description: "State machine edited successfully",
        });
      }
    } catch (error) {
      console.error("Error updating state machine:", error);
      notification.error({
        message: "Error",
        description: "Failed to update state machine",
      });
    }
  };
  

  useEffect(() => {
    handleGetDetails();
  }, []);

  return (
    <StateMachineContext.Provider
      value={{
        value: contextValue,
        setValue: setContextValue,
      }}
    >
      <div className="containerDiagramBlock">
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={(edgeChanges) => {
                  onEdgesChange(edgeChanges);
                }}
                onEdgeClick={(event, edges) => {
                  console.log("🚀 ~ EditStateMachineView ~ edges:", edges);
                  setNodes((prev) => {
                    const s = prev.find((n: any) => n.id == edges.source);
                    const t = prev.find((n: any) => n.id == edges.target);
                    setTransition({ source: s, target: t });
                    return prev;
                  });
                  const find = transitionsList.find(
                    (t) => t.fromId == edges.source && t.toId == edges.target
                  );
                  setSelectedTransition(find);
                  setShowTransitionModal(true);
                }}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                fitView
              >
                {/* <Controls /> */}
                <Background
                  color="#81818a"
                  gap={16}
                  variant={BackgroundVariant.Dots}
                />
              </ReactFlow>
            </div>
            <StateMachineSidebar
              onSave={() => {
                handleSaveStateMachine();
              }}
              isEditMode={isEditMode}
              nodeSelect={nodeSelect}
              onChangeNode={onChangeNode}
              closeEditMode={() => {
                setIsEditMode(false);
              }}
              stateMachineDetails={stateMachineDetails}
            />
          </ReactFlowProvider>
        </div>
      </div>
      {showActionModal && (
        <ActionModal
          selectedNode={nodeSelect}
          onCancel={() => {
            setShowActionModal(false);
          }}
        />
      )}
      {showTransitionModal && (
        <TransitionModal
          selectedTransition={selectedTransition}
          source={transition.source}
          target={transition.target}
          onCancel={() => {
            setShowTransitionModal(false);
          }}
          saveChanges={(data: any) => {
            console.log("🚀 ~ EditStateMachineView ~ data:", data);
            setTransitionsList((prev: any) => {
              let copy = [...prev];
              const finded = prev.findIndex((t: any) => {
                return t.fromId == data.fromId && t.toId == data.toId;
              });
              if (finded >= 0) {
                copy[finded].condition = data.condition;
              } else {
                copy.push(data);
              }
              return copy;
            });
          }}
        />
      )}
    </StateMachineContext.Provider>
  );
}
