import { api } from "../services/api";

// constants
let initialData = {
  array: localStorage.getItem("security")
    ? JSON.parse(localStorage.getItem("security"))
    : {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_SECURITY = "GET_SECURITY";
let GET_SECURITY_SUCCESS = "GET_SECURITY_SUCCESS";
let GET_SECURITY_ERROR = "GET_SECURITY_ERROR";

// reducer
export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_SECURITY:
      return { ...state, fetching: true };
    case GET_SECURITY_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_SECURITY_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export let getSecurityAction = (userId) => (dispatch, getState) => {
  dispatch({
    type: GET_SECURITY,
  });
  return api
    .get(`${URL}/users/${userId}`)
    .then((res) => {
      localStorage.setItem("security", JSON.stringify(res.data));
      dispatch({
        type: GET_SECURITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_SECURITY_ERROR,
        payload: err.response.message,
      });
    });
};
