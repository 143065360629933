import React, { useEffect, useState } from "react";
import LocationService from "../domain/Location/LocationService";

const locationService = new LocationService();

export default function useLocation({
  userId,
  mode = "ByUser",
}: {
  userId: string;
  mode: "ByUser" | "ByCountry";
}) {
  const [locations, setLocations] = useState<Location[]>([]);
  const [params, setParams] = useState<any>({
    userId,
  });

  async function handleGetLocations() {
    let res = null;
    if (mode === "ByUser" && userId) {
      res = await locationService.getLocationsByUser(userId);
    }
    if (mode === "ByCountry") {
    }
    if (res) {
      setLocations(res);
    }
  }

  useEffect(() => {
    handleGetLocations();
  }, [params]);

  return { locations, params, setParams };
}
