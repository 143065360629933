import { api, apiController, restartApiCotroller } from "./api";

const getSimulationHistory = async (
  organizationId,
  options = { page: 1, size: 10, simulationId: null, startDate: null, endDate: null, status: null }
) => {
  try {
    restartApiCotroller();
    const response = await api.get(
      `/simulations/organizations/${organizationId}`,
      {
        params: {
          page: options.page,
          size: options.size,
          simulationId: options.simulationId || null,
          startDate: options.startDate || null,
          endDate: options.endDate || null,
          status: options.status || null,
        },
        signal: apiController.signal,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getLastSimulation = async () => {
  try {
    restartApiCotroller();
    const response = await api.get(`/simulations/last`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getSimulation = async (simulationId) => {
  try {
    restartApiCotroller();
    const response = await api.get(`/simulations/${simulationId}`);
    // console.log("🚀 ~ file: simulations.js:46 ~ getSimulation ~ response", response)
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getSimulationHistory, getLastSimulation, getSimulation };
