import React from "react";

type Props = {};

export default function ContractArrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17.0915 0.908364C17.014 0.830257 16.9218 0.768262 16.8203 0.725954C16.7187 0.683647 16.6098 0.661865 16.4998 0.661865C16.3898 0.661865 16.2809 0.683647 16.1793 0.725954C16.0778 0.768262 15.9856 0.830257 15.9081 0.908364L11.0831 5.7417V4.4167C11.0831 4.19568 10.9953 3.98372 10.839 3.82744C10.6828 3.67116 10.4708 3.58336 10.2498 3.58336C10.0288 3.58336 9.81681 3.67116 9.66053 3.82744C9.50425 3.98372 9.41645 4.19568 9.41645 4.4167V7.75003C9.41777 7.85893 9.44042 7.96651 9.48312 8.0667C9.56768 8.27032 9.7295 8.43213 9.93312 8.5167C10.0333 8.5594 10.1409 8.58205 10.2498 8.58336H13.5831C13.8041 8.58336 14.0161 8.49557 14.1724 8.33929C14.3287 8.18301 14.4165 7.97104 14.4165 7.75003C14.4165 7.52902 14.3287 7.31706 14.1724 7.16077C14.0161 7.00449 13.8041 6.9167 13.5831 6.9167H12.2581L17.0915 2.0917C17.1696 2.01423 17.2316 1.92206 17.2739 1.82051C17.3162 1.71896 17.338 1.61004 17.338 1.50003C17.338 1.39002 17.3162 1.2811 17.2739 1.17955C17.2316 1.078 17.1696 0.985833 17.0915 0.908364ZM8.06645 9.48336C7.96627 9.44066 7.85868 9.41801 7.74979 9.4167H4.41645C4.19544 9.4167 3.98348 9.50449 3.8272 9.66078C3.67092 9.81705 3.58312 10.029 3.58312 10.25C3.58312 10.471 3.67092 10.683 3.8272 10.8393C3.98348 10.9956 4.19544 11.0834 4.41645 11.0834H5.74145L0.90812 15.9084C0.830013 15.9858 0.768017 16.078 0.72571 16.1795C0.683403 16.2811 0.661621 16.39 0.661621 16.5C0.661621 16.61 0.683403 16.719 0.72571 16.8205C0.768017 16.9221 0.830013 17.0142 0.90812 17.0917C0.985589 17.1698 1.07776 17.2318 1.17931 17.2741C1.28086 17.3164 1.38978 17.3382 1.49979 17.3382C1.6098 17.3382 1.71872 17.3164 1.82027 17.2741C1.92182 17.2318 2.01398 17.1698 2.09145 17.0917L6.91645 12.2584V13.5834C6.91645 13.8044 7.00425 14.0163 7.16053 14.1726C7.31681 14.3289 7.52877 14.4167 7.74979 14.4167C7.9708 14.4167 8.18276 14.3289 8.33904 14.1726C8.49532 14.0163 8.58312 13.8044 8.58312 13.5834V10.25C8.5818 10.1411 8.55915 10.0335 8.51645 9.93336C8.43189 9.72974 8.27008 9.56793 8.06645 9.48336Z"
        fill="black"
      />
    </svg>
  );
}
