import { api } from "../../services/api";

export default class ParameterSourceRepository {
  async saveSources(
    infraId: number | string,
    parameterId: number | string,
    sources: Array<any>
  ): Promise<boolean> {
    const result = await api.post(
      `/measurements/infrastructures/${infraId}/parameters/${parameterId}/sources`,
      { sources }
    );
    return result.data.success === 1;
  }

  async deleteParameter(sourceId: number | string): Promise<boolean> {
    const result = await api.delete(`/measurements/sources/${sourceId}`);
    return result.data;
  }
}
