import { api } from "../../services/api";

export default class InteractiveSimulationRepository {
  async getIterationsBySimulation({
    simulationId,
  }: {
    simulationId: string | number;
  }) {
    try {
      const response = await api.get(
        `interactive-simulations/simulations/${simulationId}/iterations`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async postIterationSimulation({
    simulationId,
  }: {
    simulationId: string | number;
  }) {
    try {
      const response = await api.post(
        `interactive-simulations/simulations/${simulationId}/iterations`,
        { simulationId }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
