import MetereologicalRepository from "./MetereologicalRepository";

export default class MetereologicalService {
  private metereologicalRepository: MetereologicalRepository =
    new MetereologicalRepository();

  async getMeteorologicalInfo(): Promise<Metereological[]> {
    const response: Metereological[] =
      await this.metereologicalRepository.getMeteorologicalInfo();
    return response;
  }
}
