import React from "react";

const LocationSvg: React.FC = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9.90002V15C8 15.2652 8.10536 15.5196 8.29289 15.7071C8.48043 15.8947 8.73478 16 9 16C9.26522 16 9.51957 15.8947 9.70711 15.7071C9.89464 15.5196 10 15.2652 10 15V9.90002C11.214 9.6522 12.2928 8.96243 13.0272 7.96443C13.7616 6.96643 14.0992 5.7313 13.9747 4.49849C13.8501 3.26569 13.2723 2.12304 12.3532 1.29208C11.434 0.461118 10.2391 0.0010376 9 0.0010376C7.76092 0.0010376 6.56598 0.461118 5.64685 1.29208C4.72771 2.12304 4.14986 3.26569 4.02532 4.49849C3.90078 5.7313 4.23843 6.96643 4.9728 7.96443C5.70718 8.96243 6.78596 9.6522 8 9.90002ZM9 2.00002C9.59334 2.00002 10.1734 2.17596 10.6667 2.50561C11.1601 2.83525 11.5446 3.30379 11.7716 3.85197C11.9987 4.40015 12.0581 5.00335 11.9424 5.58529C11.8266 6.16723 11.5409 6.70178 11.1213 7.12134C10.7018 7.5409 10.1672 7.82662 9.58527 7.94237C9.00333 8.05813 8.40013 7.99872 7.85195 7.77166C7.30377 7.54459 6.83524 7.16008 6.50559 6.66673C6.17595 6.17338 6 5.59336 6 5.00002C6 4.20437 6.31607 3.44131 6.87868 2.8787C7.44129 2.31609 8.20435 2.00002 9 2.00002ZM13.21 12.42C13.0787 12.3924 12.9432 12.391 12.8113 12.4158C12.6795 12.4406 12.5537 12.4911 12.4414 12.5644C12.329 12.6378 12.2322 12.7325 12.1565 12.8433C12.0808 12.9541 12.0276 13.0787 12 13.21C11.9724 13.3413 11.971 13.4768 11.9958 13.6087C12.0205 13.7406 12.071 13.8663 12.1444 13.9786C12.2178 14.091 12.3125 14.1878 12.4233 14.2635C12.5341 14.3393 12.6587 14.3924 12.79 14.42C15.06 14.87 16 15.68 16 16C16 16.58 13.55 18 9 18C4.45 18 2 16.58 2 16C2 15.68 2.94 14.87 5.21 14.38C5.34132 14.3524 5.46593 14.2993 5.5767 14.2235C5.68747 14.1478 5.78224 14.051 5.8556 13.9386C5.92896 13.8263 5.97947 13.7006 6.00424 13.5687C6.02902 13.4368 6.02758 13.3013 6 13.17C5.97242 13.0387 5.91925 12.9141 5.84352 12.8033C5.76778 12.6925 5.67097 12.5978 5.55861 12.5244C5.44626 12.4511 5.32055 12.4006 5.18867 12.3758C5.05679 12.351 4.92132 12.3524 4.79 12.38C1.75 13.08 0 14.39 0 16C0 18.63 4.53 20 9 20C13.47 20 18 18.63 18 16C18 14.39 16.25 13.08 13.21 12.42Z"
        fill="#74788D"
      />
    </svg>
  );
};

export default LocationSvg;
