import { api } from "../services/api";

// constants
let initialData = {
    array: [],
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
let GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";
let GET_ORGANIZATIONS_ERROR = "GET_ORGANIZATIONS_ERROR";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_ORGANIZATIONS:
            return { ...state, fetching: true };
        case GET_ORGANIZATIONS_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_ORGANIZATIONS_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getOrganizationsAction = (name) => (dispatch, getState) => {
    let organizationName = name ? "?name=" + name : "";

    dispatch({
        type: GET_ORGANIZATIONS,
    });

    return api
        .get(`${URL}/security/organizations${organizationName}`)
        .then((res) => {
            dispatch({
                type: GET_ORGANIZATIONS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ORGANIZATIONS_ERROR,
                payload: err.response.message,
            });
        });
};
