import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_COUNTRY = "GET_COUNTRY";
let GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
let GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

// reducer
export default function countryReducer(state = initialData, action) {
    switch (action.type) {
        case GET_COUNTRY:
            return { ...state, fetching: true };
        case GET_COUNTRY_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_COUNTRY_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export const getAllCountryAction = () => (dispatch, getState) => {
    return api
        .get(`${URL}/locations/countries`)
        .then((res) => {
            dispatch({
                type: GET_COUNTRY_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_COUNTRY_ERROR,
                payload: err.response.message,
            });
        });
};
