import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Input,
  Pagination,
  PaginationProps,
  Radio,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ExpandIconPosition } from "antd/es/collapse/Collapse";
import Icon, {
  PlusCircleOutlined,
  SearchOutlined,
  DownloadOutlined,
  RightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import DashboardContext from "../../layout/DashboardLayout/context";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ParameterIconTableSvg from "../../assets/svg/ParameterIconTableSvg";
import dayjs from "dayjs";
import { getReadingsFromDevice } from "../../services/historical";
import { getResumeFromDevice } from "../../services/measurement";
import { infraTypes } from "../../statics/constants/infraTypes";
import MroService from "../../domain/Mro/MroService";
import NoDataTableSvg from "../../assets/svg/NoDataTableSvg";
import PluviometerIconSvg from "../../assets/svg/PluviometerIconSvg";
import OutfallIconSvg from "../../assets/svg/OutfallIconSvg";
import "./index.scss";
import { AnyAction } from "redux";
import { mroReportsEventsService } from "../../events/mroReports";
const utc = require("dayjs/plugin/utc"); // Importa el complemento UTC de Day.js
dayjs.extend(utc);

const infrastructureService = new InfrastructureService();
const mroService = new MroService();

export default function ReportsMroView() {
  const subscriptionMRO$ = mroReportsEventsService.getSubject();
  const { value: context } = useContext(DashboardContext);
  const chartRef2 = useRef<any>(null);
  const [graphOptions, setGraphOptions] = useState({});
  const [expandIconPosition] = useState<ExpandIconPosition>("start");
  const [parameters, setParameters] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState<any>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [dataModalDetail, setDataModalDetail] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRecords: 0,
    currentPage: 1,
  });
  const [isLoading, setLoading] = useState(false);
  const [queryParams, setQueryParams] = useState("");
  const [expandedPanels, setExpandedPanels] = useState<any[]>([]);
  const [invisibleParams, setInvisibleParams] = useState<any[]>([]);
  const [sourceList, setsourceList] = useState<any[]>([]);

  const [outfallList, setOutfallList] = useState<any[]>([]);
  const [pluviometerList, setPluviometerList] = useState<any[]>([]);
  const [selectedOutfall, setSelectedOutfall] = useState<any>(null);
  const [selectedPluviometer, setSelectedPluviometer] = useState<any>(null);
  const [highchartVmRef, setHighchartVmRef] = useState<any>(null);

  const columnsExpanded = useMemo(
    () => [
      {
        title: () => {
          return <Icon component={ParameterIconTableSvg} />;
        },
        render: (_: any, record: any) => <ParameterIconTableSvg />,
        width: 100,
      },
      {
        title: "Infra.",
        dataIndex: "infrastructureName",
        key: "infrastructureName",
        width: 200,
      },
      {
        title: "Param.",
        dataIndex: "parameter",
        key: "parameter",
        width: 200,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 80,
      },
      {
        title: "Source",
        render: (_: any, record: { sourceName: any }) => (
          <div className="flex column">
            <span>{`${record.sourceName}`}</span>
          </div>
        ),
        width: 160,
      },
      {
        title: "Units",
        dataIndex: "unit",
        key: "unit",
        width: 80,
      },
      {
        title: "Last Reading",
        render: (_: any, record: { currentValue: any }) => (
          <>
            <span>{`${record.currentValue}`}</span>
          </>
        ),
        width: 90,
      },
      {
        title: "Last Collected",
        render: (
          _: any,
          record: {
            lastCollected:
              | string
              | number
              | Date
              | dayjs.Dayjs
              | null
              | undefined;
          }
        ) => (
          <span>
            {record.lastCollected && record.lastCollected != "N/A"
              ? `${dayjs(record.lastCollected).format("YYYY/MM/DD hh:mm A")}`
              : "No data available"}
          </span>
        ),
        width: 180,
      },
      {
        title: "Current Utilization",
        render: (
          _: any,
          record: { usage: any; color: string | (string & {}) | undefined }
        ) => (
          <div className="flex">
            <span className="mr-1">{`${record.usage}`}</span>
            <Badge color={record.color} />
          </div>
        ),
        width: 100,
      },
      {
        title: "Max.",
        render: (_: any, record: { maximun: any }) => (
          <span>{`${record.maximun}`}</span>
        ),
        width: 90,
      },
      {
        title: "Min.",
        render: (_: any, record: { minimum: any }) => (
          <span>{`${record.minimum}`}</span>
        ),
        width: 90,
      },
      {
        title: "Sum",
        render: (
          _: any,
          record: {
            source: any;
            sum:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined;
          }
        ) => <span>{record.source ? record.sum : "-"}</span>,
        width: 100,
      },
      {
        title: "Average",
        render: (_: any, record: { average: any }) => (
          <span>{`${record.average}`}</span>
        ),
        width: 90,
      },
      {
        title: "Critical High High",
        render: (_: any, record: { criticalHhigh: any }) => (
          <span>{`${record.criticalHhigh}`}</span>
        ),
        width: 90,
      },
      {
        title: "Critical High",
        render: (_: any, record: { criticalHigh: any }) => (
          <span>{`${record.criticalHigh}`}</span>
        ),
        width: 90,
      },
      {
        title: "Critical Low",
        render: (_: any, record: { criticalLow: any }) => (
          <span>{`${record.criticalLow}`}</span>
        ),
        width: 90,
      },
      {
        title: "Critical Low Low",
        render: (_: any, record: { criticalLlow: any }) => (
          <span>{`${record.criticalLlow}`}</span>
        ),
        width: 90,
      },
    ],
    []
  );

  function obtenerFechaFormateada() {
    const fechaActual = dayjs();

    const year = fechaActual.format("YYYY");
    const month = fechaActual.format("MM");
    const day = fechaActual.format("DD");
    const hour = fechaActual.format("HH:mm:ss");

    const fechaFormateada = `${year}-${month}-${day} ${hour}`;

    return fechaFormateada;
  }

  const handleGetPluviometers = async () => {
    const locationId =
      context.selectedLocation || localStorage.getItem("Location") || "";
    const infra: any = await infrastructureService.getInfraestructureInUse(
      locationId,
      1,
      10000,
      "",
      "",
      `${infraTypes.PLUVIOMETER}`
    );
    if (infra) {
      setPluviometerList(infra.data);
    }
  };

  const handleGetOutfall = async () => {
    const locationId =
      context.selectedLocation || localStorage.getItem("Location") || "";
    const infra: any = await infrastructureService.getInfraestructureInUse(
      locationId,
      1,
      10000,
      "",
      "",
      `${infraTypes.OUTFALL}`
    );
    if (infra) {
      setOutfallList(infra.data);
    }
  };

  async function fetchDataToGraph() {
    const [dateFrom, dataTo] = context.selectedDate;
    if (!dateFrom || !dataTo) return;

    setLoading(true);
    Promise.all(
      selectedSensors
        .filter((sl: any) => {
          const selectedPluv: any = pluviometerList.find(
            (pluviometer) =>
              pluviometer.infraestructureId == selectedPluviometer
          );
          const selectedOut: any = outfallList.find(
            (outfall) => outfall.infraestructureId == selectedOutfall
          );
          console.log("🚀 ~ .filter ~ selectedPluv:", selectedPluv);
          return (
            (selectedPluv &&
              sl.split("+")[0] == selectedPluv.infraestructureId) ||
            (selectedOut && sl.split("+")[0] == selectedOut.infraestructureId)
          );
        })
        .map(async (sl: any) => {
          const sensor = sl.split("+");
          const rangeDates: any = compareDates(dataTo, dateFrom);
          return Promise.all([
            getReadingsFromDevice(
              sensor[1],
              sensor[2],
              dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
              dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss"),
              rangeDates[0],
              rangeDates[1]
            ),
            getResumeFromDevice(
              sensor[0],
              sensor[1],
              sensor[2],
              dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
              dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
            ),
          ]).then(([readings, resume]) => {
            return {
              ...readings,
              resume,
              serialNumberMeasurementTool: sensor[2],
              paramId: sensor[1],
              source: sensor[3],
            };
          });
        })
    ).then((dataArray) => {
      const data = dataArray.filter((item) => item !== undefined);
      buildDataToGraph(data);
      buildDataToTable(data);
    });
  }

  function compareDates(date1: any, date2: any) {
    const startDate = dayjs(date2);
    const endDate = dayjs(date1);
    const diffInMonths = endDate.diff(startDate, "month", true); // true para obtener fracción de mes

    if (diffInMonths < 1) {
      return ["5", "minute"];
    } else if (diffInMonths >= 1 && diffInMonths <= 6) {
      return ["15", "minute"];
    } else {
      return ["1", "hour"];
    }
  }

  function buildDataToGraph(dataArray: any) {
    let yAxis: {
      valueUnit: any;
      labels: { format: string };
      title: { text: string };
    }[] = [];
    dataArray.forEach(
      (item: { valueUnit: any; sensorType: any } | undefined) => {
        if (item === undefined) return;
        if (!yAxis.find(({ valueUnit }) => valueUnit === item.valueUnit)) {
          yAxis.push({
            valueUnit: item.valueUnit,
            labels: {
              format: `{value}${item.valueUnit}`,
            },
            title: {
              text: `${item.sensorType}`,
            },
          });
        }
      }
    );
    let seriesData: any[] = [];
    dataArray.forEach((data: any, index: string | number) => {
      if (data === undefined) return;
      const measurementHistorialValues = data.measurementToolHistoricalValues;
      if (measurementHistorialValues) {
        const keys = Object.keys(measurementHistorialValues);
        // .map((item) => [
        //   parseFloat(item),
        //   measurementHistorialValues[item].value,
        // ])
        const dataSeries = keys
          .map((item) => {
            // return [parseFloat(item), measurementHistorialValues[item].value];
            return {
              x: parseFloat(item),
              y: measurementHistorialValues[item].value,
              origen: measurementHistorialValues[item].serialNumber,
            };
          })
          .filter((item) => {
            return item.y != null;
          })
          .sort((a, b) => {
            return a.x - b.x;
          });
        const read = {
          name: data.measurementTargetName,
          paramId: data.paramId,
          valueUnit: data.valueUnit,
          sensorType: data.sensorType,
          yAxis: yAxis.findIndex(
            ({ valueUnit }) => valueUnit === data.valueUnit
          ),
          data: dataSeries,
        };
        seriesData.push(read);
      }
      if (data.measurementToolForecastValues) {
        data.measurementToolForecastValues?.forEach(
          (forecast: { [x: string]: any }) => {
            const colors: any = Highcharts.getOptions().colors;
            if (!colors) return;
            const foreData = {
              name: data.measurementTargetName,
              paramId: data.paramId,
              sourceName: data.resume.sourceName,
              valueUnit: data.valueUnit,
              sensorType: data.sensorType,
              yAxis: yAxis.findIndex(
                ({ valueUnit }) => valueUnit === data.valueUnit
              ),
              showInLegend: false,
              color: colors[index],
              dashStyle: "Dash",
              data: forecast
                ? Object.keys(forecast)
                    .map((item) => {
                      // return [parseFloat(item), forecast[item]];
                      return {
                        x: parseFloat(item),
                        y: forecast[item],
                        origen: data.resume.sourceName,
                      };
                    })
                    .filter((item) => {
                      return item.y != null;
                    })
                    .sort((a, b) => {
                      return a.x - b.x;
                    })
                : [],
            };
            seriesData.push(foreData);
          }
        );
      }
    });

    if (seriesData.length <= 0) {
      setIsDataAvailable(false);
    } else {
      setIsDataAvailable(true);
    }

    const [dateFrom, dataTo] = context.selectedDate;
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
    setGraphOptions({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: null,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
        events: {
          load: function () {
            console.log("Chart loaded successfully");
            setHighchartVmRef(this);
          },
          render() {
            console.log("renderrr");
            setHighchartVmRef(this);
            chartRef2.current = this;
          },
        },
      },
      legend: {
        backgroundColor: "#FFFFFF",
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: dayjs().valueOf() - localOffset,
            to: dayjs("2090-01-01 00:00:00"),
            color: "#EFFFFF",
          },
        ],
        min: new Date(dateFrom).getTime() - localOffset,
        max: new Date(dataTo).getTime() - localOffset,
        ordinal: false,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          turboThreshold: 0,
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: false,
      },
      tooltip: {
        formatter: function (): any {
          const vm: any = this;
          let text = `<span>${dayjs(vm.points[0].x + localOffset).format(
            "YYYY-MM-DD hh:mm:ss A"
          )}</span><br/><br/>`;
          vm.points.forEach((point: any) => {
            const series = point.series;
            const symbol = "●";
            text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Source: <b>${point.point.origen}</b></span><br/>`;
          });
          return [text];
        },
        valueDecimals: 2,
        shared: true,
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: false,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    });
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }

  function buildDataToTable(dataArray: any[]) {
    const seriesData = dataArray.map(
      (
        data:
          | { measurementTargetId?: any; source?: any; resume?: any }
          | undefined,
        index: string | number
      ) => {
        if (data === undefined) return {};
        const colors: any = Highcharts.getOptions().colors;
        if (!colors) return {};
        const { resume } = data;
        if (resume) {
          return {
            parameter: resume.parameterName,
            type: resume.sourceType,
            unit: resume.unit,
            criticalHigh: resume.criticalHigh,
            criticalHhigh: resume.criticalHhigh,
            criticalLow: resume.criticalLow,
            criticalLlow: resume.criticalLlow,
            currentValue: resume.currentValue,
            lastCollected: resume.lastCollected,
            usage: resume.usage,
            sourceName: resume.sourceName,
            maximun: resume.max,
            minimum: resume.min,
            average: resume.average,
            color: getColor(
              resume.currentValue,
              resume.criticalHigh,
              resume.criticalHhigh,
              resume.criticalLow,
              resume.criticalLlow
            ),
            colorLine: colors[index],
            measurementTargetId: data.measurementTargetId,
            sum: resume.sum,
            source: data.source,
            infrastructureName: resume.infrastructureName,
          };
        }
      }
    );
    setDataModalDetail(seriesData.filter((series) => series));
  }

  function getColor(
    currentValue: number,
    criticalHigh: number,
    criticalHhigh: number,
    criticalLow: number,
    criticalLlow: number
  ) {
    if (criticalHigh >= currentValue && currentValue <= criticalHhigh) {
      return "orange";
    }
    if (criticalHhigh <= currentValue) {
      return "red";
    }
    if (criticalLow >= currentValue && currentValue <= criticalLlow) {
      return "orange";
    }
    if (currentValue <= criticalLlow) {
      return "red";
    }
    if (criticalLow <= currentValue && currentValue <= criticalHigh) {
      return "green";
    }
    return "green";
  }

  async function handleGetMroReport() {
    const [startDate, endDate] = context.selectedDate;
    const outfall = selectedSensors.map((sl: any) => {
      const sensor = sl.split("+");
      return {
        infrastructureId: sensor[0],
        paramId: sensor[1],
        sourceId: sensor[3],
      };
    });
    // const outfall = selectedSensors.map((sensorString) => {
    //   const sensor = JSON.parse(sensorString);
    //   return {
    //     infrastructureId: sensor.infrastructureId,
    //     paramId: sensor.measurementTargetId,
    //     sourceId: sensor.sourceId,
    //   };
    // });
    const response = await mroService.getMROReport({
      startDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
      outfall,
    });
    base64ToFile(response, "MRO-Report.csv");
  }

  function base64ToFile(base64: string, fileName: string) {
    // Decodificar el texto Base64
    var binaryData = atob(base64);

    // Convertir el texto decodificado a un arreglo de bytes
    var byteArray = new Uint8Array(binaryData.length);
    for (var i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Crear un Blob a partir de los datos binarios
    var blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Crear un enlace de descarga
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Simular un clic en el enlace para descargar el archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const paramsToShowInTable = useCallback(() => {
    if (queryParams != "") {
      return dataModalDetail.filter((md) => {
        return md.parameter.includes(queryParams);
      });
    } else {
      return dataModalDetail;
    }
  }, [queryParams, dataModalDetail]);

  const renderPluviometers = useCallback(() => {
    const selected: any = pluviometerList.find(
      (pluviometer) => pluviometer.infraestructureId == selectedPluviometer
    );
    if (selectedPluviometer) {
      return (
        <div className="flex column pt-2">
          {selected.measurementParameters.map((param: any) => {
            return (
              <div className="flex column" key={param.paremeterId}>
                <div className="flex column mb-2">
                  <span className="font-bold">{param.paremeterName}</span>
                  <Checkbox
                    checked={
                      selectedSensors.indexOf(
                        `${selected.infraestructureId}+${param.paremeterId}+null+null`
                      ) >= 0
                    }
                    onChange={(val) => {
                      if (val.target.checked) {
                        setSelectedSensors((sl: any) => {
                          return [
                            ...sl,
                            `${selected.infraestructureId}+${param.paremeterId}+null+null`,
                          ];
                        });
                      } else {
                        setSelectedSensors((sl: any) => {
                          return sl.filter(
                            (item: any) =>
                              item !=
                              `${selected.infraestructureId}+${param.paremeterId}+null+null`
                          );
                        });
                      }
                    }}
                  >
                    Recommended value
                  </Checkbox>
                  {param.sources.map((source: any) => {
                    return (
                      <Checkbox
                        checked={
                          selectedSensors.indexOf(
                            `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`
                          ) >= 0
                        }
                        onChange={(val) => {
                          console.log("🚀 ~ {param.sources.map ~ val:", val);
                          if (val.target.checked) {
                            setSelectedSensors((sl: any) => {
                              return [
                                ...sl,
                                `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`,
                              ];
                            });
                          } else {
                            setSelectedSensors((sl: any) => {
                              return sl.filter(
                                (item: any) =>
                                  item !=
                                  `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`
                              );
                            });
                          }
                        }}
                      >
                        {source.sourceName}
                      </Checkbox>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }, [selectedPluviometer, selectedSensors]);

  const renderOutfalls = useCallback(() => {
    const selected: any = outfallList.find(
      (outfall) => outfall.infraestructureId == selectedOutfall
    );
    if (selected) {
      return (
        <div className="flex column pt-2">
          {selected.measurementParameters.map((param: any) => {
            return (
              <div className="flex column" key={param.paremeterId}>
                <div className="flex column mb-2">
                  <span className="font-bold">{param.paremeterName}</span>
                  <Checkbox
                    checked={
                      selectedSensors.indexOf(
                        `${selected.infraestructureId}+${param.paremeterId}+null+null`
                      ) >= 0
                    }
                    onChange={(val) => {
                      if (val.target.checked) {
                        setSelectedSensors((sl: any) => {
                          return [
                            ...sl,
                            `${selected.infraestructureId}+${param.paremeterId}+null+null`,
                          ];
                        });
                      } else {
                        setSelectedSensors((sl: any) => {
                          return sl.filter(
                            (item: any) =>
                              item !=
                              `${selected.infraestructureId}+${param.paremeterId}+null+null`
                          );
                        });
                      }
                    }}
                  >
                    Recommended value
                  </Checkbox>
                  {param.sources.map((source: any) => {
                    return (
                      <Checkbox
                        checked={
                          selectedSensors.indexOf(
                            `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`
                          ) >= 0
                        }
                        onChange={(val) => {
                          if (val.target.checked) {
                            setSelectedSensors((sl: any) => {
                              return [
                                ...sl,
                                `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`,
                              ];
                            });
                          } else {
                            setSelectedSensors((sl: any) => {
                              return sl.filter(
                                (item: any) =>
                                  item !=
                                  `${selected.infraestructureId}+${param.paremeterId}+${source.serialNumber}+${source.sourceId}`
                              );
                            });
                          }
                        }}
                      >
                        {source.sourceName}
                      </Checkbox>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }, [selectedOutfall, selectedSensors]);

  useEffect(() => {
    handleGetPluviometers();
    handleGetOutfall();
    // subscriptionMRO$.subscribe((event) => {
    //   console.log("Eventt", event);
    //   if (event.type == "setInvisiblesParams") {
    //     console.log("chartEvent", chartRef2.current);
    //     console.log("InvisibleParams", invisibleParams);
    //     const invisibleSeries = chartRef2.current.series.filter((serie: any) =>
    //       invisibleParams.includes(serie.userOptions.paramId)
    //     );
    //     const visibleSeries = chartRef2.current.series.filter(
    //       (serie: any) => !invisibleParams.includes(serie.userOptions.paramId)
    //     );
    //     invisibleSeries.forEach((is: any) => {
    //       is.setVisible(false);
    //     });
    //     visibleSeries.forEach((vs: any) => {
    //       vs.setVisible(true);
    //     });
    //   }
    // });
  }, []);

  useEffect(() => {
    setParameters([]);
    setSelectedSensors([]);
    // setGraphOptions({});
    setDataModalDetail([]);
    setIsDataAvailable(false);
    handleGetOutfall();
    handleGetPluviometers();
    setSelectedOutfall(null);
    setSelectedPluviometer(null);
  }, [context.selectedLocation]);

  useEffect(() => {
    if (selectedSensors.length > 0) {
      setLoading(true);
      fetchDataToGraph();
    } else {
      setIsDataAvailable(false);
      setGraphOptions({});
      setDataModalDetail([]);
    }
  }, [
    selectedSensors,
    context.selectedDate,
    selectedOutfall,
    selectedPluviometer,
  ]);

  useEffect(() => {
    if (chartRef2.current) {
      const invisibleSeries = chartRef2.current.series.filter((serie: any) =>
        invisibleParams.includes(parseInt(serie.userOptions.paramId))
      );
      const visibleSeries = chartRef2.current.series.filter(
        (serie: any) =>
          !invisibleParams.includes(parseInt(serie.userOptions.paramId))
      );
      invisibleSeries.forEach((is: any) => {
        is.setVisible(false);
      });
      visibleSeries.forEach((vs: any) => {
        vs.setVisible(true);
      });
    }
  }, [invisibleParams, chartRef2.current]);

  useEffect(() => {
    //TODO list
    setsourceList(
      parameters.reduce((acum: any, infra: any) => {
        return [
          ...acum,
          ...infra.measurementParameters.reduce((acum2: any, param: any) => {
            return [
              ...acum2,
              ...param.sources.map((source: any) => {
                return {
                  ...source,
                  paramId: param.paremeterId,
                  infraId: infra.infraestructureId,
                };
              }),
            ];
          }, []),
        ];
      }, [])
    );
  }, [parameters]);

  return (
    <div
      style={{
        paddingTop: "80px",
        height: "100vh",
        backgroundColor: "#F7F9FC",
      }}
      className="flex mro-collapse"
    >
      <div style={{ width: "30%" }} className="px-4">
        <span style={{ fontSize: "20px", color: "#023E65", fontWeight: 600 }}>
          MRO Reports
        </span>
        <div
          className="mt-3 ml-2 mr-2"
          style={{ maxHeight: "calc(100vh - 190px)", overflow: "auto" }}
        >
          <Collapse defaultActiveKey={["outfall", "pluviometer"]}>
            <Collapse.Panel
              header={
                <Tooltip placement="topLeft" title="Outfall">
                  <div className="flex">
                    <div
                      className="flex"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <OutfallIconSvg />
                      <span className="ml-2" style={{ color: "#04628C" }}>
                        Outfalls
                      </span>
                    </div>
                    <div style={{ width: "10px" }}></div>
                  </div>
                </Tooltip>
              }
              key="outfall"
            >
              <div className="flex column">
                <Select
                  value={selectedOutfall}
                  options={outfallList.map((of) => {
                    return {
                      label: of.infraestructureName,
                      value: of.infraestructureId,
                    };
                  })}
                  placeholder="Select a Infrastructure"
                  onChange={(val): any => {
                    setSelectedOutfall(val);
                  }}
                />
                {renderOutfalls()}
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <Tooltip placement="topLeft" title="Rain Gauges">
                  <div className="flex">
                    <div
                      className="flex"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <PluviometerIconSvg />
                      <span className="ml-2" style={{ color: "#04628C" }}>
                        Rain Gauges
                      </span>
                    </div>
                    <div style={{ width: "10px" }}></div>
                  </div>
                </Tooltip>
              }
              key="pluviometer"
            >
              <div className="flex column">
                <Select
                  value={selectedPluviometer}
                  options={pluviometerList.map((pv) => {
                    return {
                      label: pv.infraestructureName,
                      value: pv.infraestructureId,
                    };
                  })}
                  placeholder="Select a Infrastructure"
                  onChange={(val): any => {
                    setSelectedPluviometer(val);
                  }}
                />
                {renderPluviometers()}
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
      <div style={{ width: "70%" }} className="px-4">
        <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: "9",
          }}
        >
          <div className="flex column">
            <div
              className="grid p-2"
              style={{
                gridTemplateColumns: "1fr",
                backgroundColor: "white",
                borderRadius: "8px",
              }}
            >
              <div
                className="self-center"
                style={{ height: "calc(50vh - 55px)" }}
              >
                <div
                  style={{
                    display: isDataAvailable ? "flex" : "none",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="flex items-center"
                    style={{
                      height: "30px",
                    }}
                  >
                    <span className="mr-2">Dates selected</span>
                    <div
                      className="flex p-1"
                      style={{
                        backgroundColor: "#F7F9FC",
                      }}
                    >
                      <span>{`${dayjs(context.selectedDate[0]).format(
                        "YYYY/MM/DD - hh:mm a"
                      )}`}</span>
                      <div
                        className="mx-2"
                        style={{
                          width: "1px",
                          height: "100%",
                          backgroundColor: "#E6E6E6",
                        }}
                      />
                      <span>{`${dayjs(context.selectedDate[1]).format(
                        "YYYY/MM/DD - hh:mm a"
                      )}`}</span>
                    </div>
                  </div>
                  <div style={{ height: "calc(50vh - 90px)" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={graphOptions}
                      containerProps={{ style: { height: "100%" } }}
                    />
                  </div>
                </div>
                <div style={{ display: !isDataAvailable ? "flex" : "none" }}>
                  <NoDataTableSvg />
                </div>
              </div>
            </div>
            {/* <Divider /> */}
            <div
              className="flex column mt-2 p-2"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "calc(50vh - 90px)",
              }}
            >
              <div
                className="flex items-center"
                style={{ height: "52px", marginBottom: "1rem" }}
              >
                <span
                  className="mr-2"
                  style={{
                    color: "#023E65",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Current readings
                </span>
                <Input
                  placeholder="Search Parameters"
                  prefix={<SearchOutlined />}
                  style={{ width: "205px" }}
                  onChange={(e) => {
                    setQueryParams(e.target.value);
                  }}
                />
                <Button
                  type="primary"
                  shape="round"
                  className="flex items-center self-center ml-2"
                  style={{
                    backgroundColor: "#023E65",
                    color: "white",
                    width: "120px",
                    height: "40px",
                    position: "fixed",
                    right: "40px",
                  }}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    handleGetMroReport();
                  }}
                  disabled={selectedSensors.length <= 0}
                >
                  Download
                </Button>
              </div>
              <div
                className="flex items-center content-center"
                style={{
                  width: "100%",
                  alignItems: "center",
                  placeContent: "center",
                  height: "calc(50vh - 142px)",
                  overflow: "auto",
                }}
              >
                {selectedSensors.length <= 0 ? (
                  <div>
                    <span style={{ color: "#22A2CA", fontSize: "16px" }}>
                      Select one flow parameter to start
                    </span>
                  </div>
                ) : (
                  <Table
                    columns={columnsExpanded}
                    dataSource={paramsToShowInTable()}
                    scroll={{ x: true }}
                    pagination={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
