import { api } from "../../services/api";

export default class AnnotationsRepository {
  async postAnnotations(data: Annotation) {
    try {
      console.log("🚀 ~ file: AnnotationsRepository.tsx:5 ~ AnnotationsRepository ~ postAnnotations ~ data:", data)
      const response = await api.post(`readings/annotations`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAnnotationsBySourceId(
    sourceId: string,
    startDate: string,
    endDate: string
  ) {
    try {
      const response = await api.get(
        `readings/sources/${sourceId}/annotations`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
