import React, {
  RefObject,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import ListParametersTree from "../../listParametersTree";
import VirtualSourceService from "../../../../../domain/VirutalSources/VitualSourceService";
import { DatePicker, Tag } from "antd";
import dayjs from "dayjs";
import InfraestructureContext from "../../../../../views/Infrastructures/Parameters/context";

const virtualSourceService = new VirtualSourceService();

type Props = {
  infrastructure: Infrastructure;
  source: ParameterSource;
  sources: ParameterSource[];
};

type VirtualSourceRefType = {
  getVirtualSourceData: Function;
  getDateStamp: Function;
};

const VirtualSources = React.forwardRef<VirtualSourceRefType, Props>(
  ({ infrastructure, source, sources }, ref) => {
    const { value: context, setValue: setContext } = useContext(
      InfraestructureContext
    );
    const [showAddParam, setShowAddParam] = useState(false);
    const [virtualSource, setVirtualSource] = useState<VirtualSource>({
      sourceId: source.id,
      code: "",
      variables: [],
    });
    const [virtualCurrentTs, setVirtualCurrentTs] = useState<string>(
      source.virtualCurrentTs || dayjs().format("YYYY-MM-DD HH:mm:ss")
    );

    function handleAddVariableParam(param: any) {
      if (
        virtualSource.variables.findIndex((va: SourceVariable) => {
          return va?.parameterId === param?.paremeterId;
        }) < 0
      ) {
        setVirtualSource((vs: VirtualSource) => {
          const vsource = {
            ...vs,
            variables: [
              ...vs.variables,
              {
                parameterId: param.paremeterId,
                parameterName: param.paremeterName,
                name: `$V${vs.variables.length + 1}`,
              } as SourceVariable,
            ] as SourceVariable[],
          };
          setContext({ ...context, selectedVirtualSource: vsource });
          return vsource;
        });
      }
    }

    async function handleGetVirtualSource() {
      setVirtualSource(await virtualSourceService.getVirtualSource(source));
    }

    async function handleSaveVirtualSource() {
      const response = await virtualSourceService.saveVirtualSource(
        virtualSource
      );
    }

    React.useImperativeHandle(ref, () => ({
      getVirtualSourceData() {
        return virtualSource;
      },
      getDateStamp() {
        return virtualCurrentTs;
      },
    }));

    useEffect(() => {
      if (source.id) {
        handleGetVirtualSource();
      }
    }, [source]);

    return (
      <div className="flex column">
        <div>
          {showAddParam ? (
            <ListParametersTree
              locationId={infrastructure?.locationId || 0}
              onSelect={(param: any) => {
                handleAddVariableParam(JSON.parse(param || "{}"));
              }}
            />
          ) : (
            <div className="flex column">
              <div className="flex justify-between mb-2">
                <div className="flex column">
                  <DatePicker
                    showTime
                    onChange={(date, datestring) => {
                      setVirtualCurrentTs(
                        dayjs(datestring).format("YYYY-MM-DD HH:mm:ss")
                      );
                    }}
                    defaultValue={
                      source.virtualCurrentTs
                        ? dayjs(source.virtualCurrentTs)
                        : dayjs()
                    }
                  />
                  <span style={{ color: "#74788D", fontWeight: "500" }}>
                    Code
                  </span>
                </div>
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#023E65",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setContext({
                      ...context,
                      selectedVirtualSource: virtualSource,
                      showAddVariableVirtualParam: true,
                      handleAddVariableVirtualParam: handleAddVariableParam,
                    });
                  }}
                >
                  Add new variable
                </span>
              </div>
              <div className="flex mb-1" style={{ flexWrap: "wrap" }}>
                {virtualSource?.variables.slice(0, 4).map((va) => {
                  return (
                    <Tag
                      color="#E8F9FF"
                      key={va.parameterId}
                      style={{
                        color: "#023E65",
                        fontWeight: "bold",
                        borderRadius: "20px",
                      }}
                      className="mr-1 mb-1"
                    >
                      {`${va.parameterName} = ${va.name}`}
                    </Tag>
                  );
                })}
              </div>
              {virtualSource?.variables.length > 4 && (
                <div className="flex mb-1">
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "#44B6DC",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setContext({
                        ...context,
                        selectedVirtualSource: virtualSource,
                        showAddVariableVirtualParam: true,
                        handleAddVariableVirtualParam: handleAddVariableParam,
                      });
                    }}
                  >
                    See all
                  </span>
                </div>
              )}
              {/* <li>
                {virtualSource?.variables.map((va) => {
                  return (
                    <ul
                      key={va.parameterId}
                    >{`${va.parameterName}->${va.name}`}</ul>
                  );
                })}
                <ul
                  className="cursor-pointer"
                  style={{ textDecoration: "underline", color: "#1675e0" }}
                  onClick={() => {
                    setShowAddParam(true);
                  }}
                >
                  Add new variable
                </ul>
              </li> */}
            </div>
          )}
        </div>
        <AceEditor
          className="mt-1"
          mode="python"
          theme="github"
          name="editor"
          onChange={(val) => {
            setVirtualSource((vs) => {
              return { ...vs, code: val };
            });
          }}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={virtualSource.code}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          placeholder="Type your code here"
          style={{ maxHeight: "200px" }}
        />
      </div>
    );
  }
);

export default VirtualSources;
