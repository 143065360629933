import { api } from "../../services/api";
import { apiNl } from "../../services/apiNl";
export default class HistoricalRepository {
  async postHistoricalFiles(file: any): Promise<boolean> {
    const reponse = await api.post(`/bulks/data/`, file);
    return reponse.data.success === 1;
  }
  
  async getHistoricalFiles({
    name = null,
    status = null,
    page = 1,
    size = 10,
    loading = true,
  }: {
    name?: null | string;
    status?: null | string;
    page: number;
    size: number;
    loading?: boolean;
  }): Promise<any> {
    const apiInstance = loading ? api : apiNl;
    const files = await apiInstance.get(`/bulks/data`, {
      params: { name, status, page, size },
    });
    return files.data;
  }

  async deleteHistoricalFile(
    historicalFileId: string | number
  ): Promise<Parameter[]> {
    const result = await api.delete(
      `/bulks/data/${historicalFileId}`
    );
    return result.data;
  }
}
