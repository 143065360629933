import AnnotationsRepository from "./AnnotationsRepository";

export default class AnnotationsService {
  private annotationsRepository: AnnotationsRepository =
    new AnnotationsRepository();

  async postAnnotations(data: Annotation) {
    try {
      return await this.annotationsRepository.postAnnotations(data);
    } catch (error) {
      console.error(error);
    }
  }

  async getAnnotationsBySourceId(
    sourceId: string,
    startDate: string,
    endDate: string
  ) {
    try {
      return await this.annotationsRepository.getAnnotationsBySourceId(
        sourceId,
        startDate,
        endDate
      );
    } catch (error) {
      console.error(error);
    }
  }
}
