let SET_DATA = "SET_DATA";

export default function reducer(state = { data: {} }, action) {
  switch (action.type) {
    case SET_DATA:
      return { data: action.payload };
    default:
      return state;
  }
}

export let setInteractiveSimulationData = (payload) => async (dispatch) => {
  dispatch({
    type: SET_DATA,
    payload,
  });
};
