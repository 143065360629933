import React, { FC } from "react";



/**
 * @author
 * @function @PencilSvg
 **/

const PencilSvg: FC = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.12461 18H5.89288C6.04088 18.0008 6.1876 17.9725 6.32461 17.9165C6.46163 17.8605 6.58625 17.7781 6.69134 17.6738L14.4735 9.88043L17.6673 6.75407C17.7728 6.64952 17.8564 6.52514 17.9135 6.3881C17.9706 6.25106 18 6.10407 18 5.95561C18 5.80715 17.9706 5.66016 17.9135 5.52312C17.8564 5.38608 17.7728 5.26169 17.6673 5.15715L12.8991 0.332652C12.7945 0.227246 12.6702 0.143583 12.5331 0.0864889C12.3961 0.0293948 12.2491 0 12.1006 0C11.9522 0 11.8052 0.0293948 11.6681 0.0864889C11.5311 0.143583 11.4067 0.227246 11.3022 0.332652L8.13081 3.51525L0.32615 11.3087C0.221922 11.4137 0.139461 11.5384 0.0834958 11.6754C0.027531 11.8124 -0.000836897 11.9591 1.87967e-05 12.1071V16.8754C1.87967e-05 17.1736 0.118502 17.4597 0.329404 17.6706C0.540306 17.8815 0.82635 18 1.12461 18ZM12.1006 2.71679L15.2832 5.89938L13.6863 7.4963L10.5037 4.3137L12.1006 2.71679ZM2.2492 12.5682L8.91803 5.89938L12.1006 9.08197L5.43179 15.7508H2.2492V12.5682Z"
                fill="#74788D"
            />
        </svg>
    );
};

export default PencilSvg