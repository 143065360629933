import { api, apiController } from "../services/api";

// constants
let initialData = {
    array: [],
};

let URL = process.env.REACT_APP_BASE_URL;

let CREATE_DATA = "CREATE_DATA";
let READ_ALL_DATA = "READ_ALL_DATA";
let UPDATE_DATA = "UPDATE_DATA";
let DELETE_DATA = "DELETE_DATA";
let ERROR_DATA = "ERROR_DATA";
let NO_DATA = "NO_DATA";

let GET_USER_SECURITY = "GET_USER_SECURITY";
let GET_USER_SECURITY_SUCCESS = "GET_USER_SECURITY_SUCCESS";
let GET_USER_SECURITY_ERROR = "GET_USER_SECURITY_ERROR";

// reducer
export default function userReducer(state = initialData, action) {
    switch (action.type) {
        case READ_ALL_DATA: {
            return { ...state, array: action.payload };
        }
        case CREATE_DATA: {
            return { ...state, array: [...state.db, action.payload] };
        }
        case UPDATE_DATA: {
            let newData = state.db.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );

            return {
                ...state,
                array: newData,
            };
        }
        case DELETE_DATA: {
            let newData = state.db.filter((el) => el.id !== action.payload);

            return {
                ...state,
                array: newData,
            };
        }
        case ERROR_DATA:
            return { ...state, fetching: false, error: action.payload };
        case NO_DATA:
            return initialData;
        default:
            return state;
    }
}
// actions (tunks)

export const createAction = (data) => ({ type: CREATE_DATA, payload: data });

export const getAllUserAction =
    (
        userRole,
        options = {
            page: 1,
            size: 10,
            userNameFilter: null,
            roleFilter: null,
        }
    ) =>
    (dispatch, getState) => {
        let oui = JSON.parse(localStorage.getItem("security") || "{}").organizationId;
        console.log("userRole", userRole);
        let url =
            userRole == "SuperAdmin"
                ? `${URL}/users/organizations/all`
                : `${URL}/users/organizations/${oui}`;
        let filter =
            options.userNameFilter || options.roleFilter
                ? `${URL}/users/organizations/${oui}`
                : url;

        return api
            .get(url, {
                params: {
                    page: options.page,
                    size: options.size,
                    username: options.userNameFilter || null,
                    roleId: options.roleFilter || null,
                },
                // signal: apiController.signal,
            })
            .then((res) => {
                dispatch({
                    type: READ_ALL_DATA,
                    payload: res.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: NO_DATA,
                    payload: err.response.message,
                });
            });
    };

export const updateAction = (data) => ({ type: UPDATE_DATA, payload: data });

export const deleteAction = (id) => (dispatch, getState) => {
    dispatch({ type: DELETE_DATA, payload: id });
};

export const noAction = () => ({ type: NO_DATA });

export const userSecurity = (userId) => (dispatch, getState) => {
    dispatch({
        type: GET_USER_SECURITY,
    });
    return api
        .get(`${URL}/users/${userId}`)
        .then((res) => {
            localStorage.setItem("security", JSON.stringify(res.data));
            dispatch({
                type: GET_USER_SECURITY_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_USER_SECURITY_ERROR,
                payload: err.response.message,
            });
        });
};
