import { api } from "../../services/api";
// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

// let idLocation = localStorage.getItem("Location");

let GET_INFRASTRUCTURE = "GET_INFRASTRUCTURE";
let GET_INFRASTRUCTURE_SUCCESS = "GET_INFRASTRUCTURE_SUCCESS";
let GET_INFRASTRUCTURE_ERROR = "GET_INFRASTRUCTURE_ERROR";
let GET_INFRASTRUCTURE_TYPES = "GET_INFRASTRUCTURE_TYPES";
let GET_INFRASTRUCTURE_TYPES_SUCCESS = "GET_INFRASTRUCTURE_TYPES_SUCCESS";
let GET_INFRASTRUCTURE_TYPES_ERROR = "GET_INFRASTRUCTURE_TYPES_ERROR";

// reducer
export default function infrastructureReducer(state = initialData, action) {
    switch (action.type) {
        case GET_INFRASTRUCTURE:
            return { ...state, fetching: true };
        case GET_INFRASTRUCTURE_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_INFRASTRUCTURE_TYPES_SUCCESS:
            return {
                ...state,
                array: action.payload.map((types) => {
                    return {
                        value: types.infrastructureTypeId,
                        label: types.infrastructureTypeName,
                    };
                }),
                fetching: false,
            };
        case GET_INFRASTRUCTURE_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}

// actions (tunks)

export let getInfrastructureErrorAction =
    (idOrganization, idLocation) => (dispatch, getState) => {
        dispatch({
            type: GET_INFRASTRUCTURE,
        });
        return api
            .get(
                `${URL}/measurements/organizations/${idOrganization}/locations/${idLocation}/validate`
            )
            .then((res) => {
                dispatch({
                    type: GET_INFRASTRUCTURE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_INFRASTRUCTURE_ERROR,
                    payload: err.response.message,
                });
            });
    };

export let getInfrastructureTypes = () => (dispatch, getState) => {
    dispatch({
        type: GET_INFRASTRUCTURE_TYPES,
    });
    return api
        .get(URL + "/measurements/infrastructure/types/")
        .then((res) => {
            dispatch({
                type: GET_INFRASTRUCTURE_TYPES_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_INFRASTRUCTURE_TYPES_ERROR,
                payload: err.response.message,
            });
        });
};
