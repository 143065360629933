import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/measurements/parameters/";

let GET_MEASUREMENT_TYPES = "GET_MEASUREMENT_TYPES";
let GET_MEASUREMENT_TYPES_SUCCESS = "GET_MEASUREMENT_TYPES_SUCCESS";
let GET_MEASUREMENT_TYPES_ERROR = "GET_MEASUREMENT_TYPES_ERROR";

// reducer
export default function typesReducer(state = initialData, action) {
    switch (action.type) {
        case GET_MEASUREMENT_TYPES:
            return { ...state, fetching: true };
        case GET_MEASUREMENT_TYPES_SUCCESS:
            return {
                ...state,
                array: action.payload.map((type) => {
                    return {
                        name: type.name,
                        id: type.id,
                    };
                }),
                fetching: false,
            };
        case GET_MEASUREMENT_TYPES_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}

export let getTypesAction = () => (dispatch, getState) => {
    dispatch({
        type: GET_MEASUREMENT_TYPES,
    });
    return api
        .get(`${URL}types`)
        .then((res) => {
            dispatch({
                type: GET_MEASUREMENT_TYPES_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_MEASUREMENT_TYPES_ERROR,
                payload: err.response.message,
            });
        });
};
