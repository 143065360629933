import ParameterRepository from "./ParameterRepository";

export default class ParameterService {
  private parameterRepository: ParameterRepository = new ParameterRepository();

  async getParametersByInfraestructure(
    infreaestructureId: string
  ): Promise<Parameter[]> {
    return this.parameterRepository.getParametersByInfraestructure(
      infreaestructureId
    );
  }

  async deleteParameter(parameter: Parameter): Promise<boolean> {
    return this.parameterRepository.deleteParameter(parameter.id);
  }

  async enableParameter(parameter: Parameter): Promise<boolean> {
    return this.parameterRepository.changeVisibleParameter(parameter.id, true);
  }

  async disableParameter(parameter: Parameter): Promise<boolean> {
    return this.parameterRepository.changeVisibleParameter(parameter.id, false);
  }

  async enableInUseParameter(parameter: any): Promise<boolean> {
    return this.parameterRepository.changeInUseParameter(
      parameter.infraId,
      true
    );
  }

  async disableInuseParameter(parameter: any): Promise<boolean> {
    return this.parameterRepository.changeInUseParameter(
      parameter.infraId,
      false
    );
  }

  async postParameterForecast(
    parameterId: string | number,
    forecast: ParameterForecast
  ): Promise<boolean> {
    return this.parameterRepository.postParameterForecast(
      parameterId,
      forecast
    );
  }

  async putFavoriteParameter(parameterId: string | number) {
    return this.parameterRepository.putFavoriteParameter(parameterId);
  }

  async getParameter(parameterId: string | number) {
    return this.parameterRepository.getParameter(parameterId);
  }

  // Only Id an Name
  async getParametersListByInfrastructure(
    infraestructureId: number
  ): Promise<Parameter[]> {
    return this.parameterRepository.getParametersListByInfrastructure(
      infraestructureId
    );
  }
}
