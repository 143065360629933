import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import {
  Alert,
  Modal,
  Button,
  Input,
  Select,
  Table,
  Pagination,
  Tooltip,
  notification,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import dayjs from "dayjs";
import HistoricalService from "../../domain/Historical/HistoricalService";

const historicalService = new HistoricalService();

const HistoricalTabla: FC<{
  isCurrentTab: boolean;
}> = ({ isCurrentTab = false }) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [historicalname, setHistoricalName] = useState<any>(null);
  const [fileSelect, setFileSelect] = useState<any | null>(null);
  const [historicalFiles, setHistoricalFiles] = useState<any>([]);
  const [pagination3, setPagination3] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });
  const intervalRef = useRef<any>(null);

  const handleUpdateHistoricalFiles = useCallback(
    async (loading = true) => {
      const res = await historicalService.getHistoricalFiles({
        name: historicalname,
        status: null,
        page: pagination3.page,
        size: pagination3.pageSize,
        loading,
      });
      setHistoricalFiles(res.data);
      setPagination3((p3) => {
        return { ...p3, totalRows: res.totalRecords };
      });
    },
    [pagination3, historicalname]
  );

  const columns: ColumnsType<any> = [
    {
      title: "File Name",
      dataIndex: "uploadName",
      key: "uploadName",
      render: (_, { name }) => (
        <>
          <span className="table-data-gis">{name}</span>
        </>
      ),
    },
    // {
    //   title: "Uploaded by",
    //   dataIndex: ["createdAt", "createdBy"],
    //   key: "gisFileUploadedDTOS",
    //   render: (_, { uploadAt, uploadBy }) => (
    //     <>
    //       <div>
    //         <span className="table-data-gis">{uploadBy}</span>
    //         <br></br>
    //         <span className="date-gis">
    //           {dayjs(uploadAt).format("YYYY/MM/DD [at] hh:mm a")}
    //         </span>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Upload Status",
      dataIndex: "uploadStatus",
      key: "uploadStatus",
      render: (_, { status }) => {
        const fontColor = "#232B36";
        let color = "#FFEECB";
        if (status === "COMPLETED") {
          color = "#C0EFDE";
        }
        if (status === "IN PROGRESS") {
          color = "#FFEECB";
        }
        if (status === "FAILED") {
          color = "#FFD3D3";
        }
        return (
          status && (
            <Tag color={color} style={{ color: fontColor }}>
              {status}
            </Tag>
          )
        );
      },
    },
    {
      title: "Upload Status Message",
      dataIndex: "uploadStatusMessage",
      key: "uploadStatusMessage",
      render: (_, { statusMessage }) => (
        <span className="table-data-gis">{statusMessage}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "uploadId",
      key: "uploadId",
      render: (_, { name, id }) => (
        <>
          <Tooltip title="Delete">
            <DeleteOutlined
              className="mr-1"
              style={{ fontSize: "1.5rem" }}
              onClick={() => {
                setFileSelect({
                  label: name,
                  id: id,
                });
                setShowDeleteModal(true);
              }}
              rev={undefined}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    handleUpdateHistoricalFiles();
  }, [pagination3.page]);

  useEffect(() => {
    const prevPage = pagination3.page;
    if (prevPage !== 1) {
      setPagination3({ ...pagination3, page: 1 });
    } else {
      handleUpdateHistoricalFiles();
    }
  }, [historicalname]);

  useEffect(() => {
    if (isCurrentTab) {
      intervalRef.current = setInterval(() => {
        handleUpdateHistoricalFiles(false);
      }, 5000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isCurrentTab, historicalname]);

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <ClockCircleOutlined
                style={{ color: "#078CB3" }}
                rev={undefined}
              />
            }
            onChange={(e) => {
              if (e.target.value == "") {
                setHistoricalName(null);
              }
            }}
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                setHistoricalName(element.value);
              }
            }}
            allowClear
          />
        </div>

        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="mr-5 boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`/upload/historicalfiles`);
            }}
          >
            Upload HistoricalFile
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={historicalFiles}
          rowKey={(record) => {
            return record.uploadId;
          }}
          pagination={false}
          size="small"
          style={{
            maxHeight: "calc(100vh - 320px)",
            overflowX: "auto",
          }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={(page) => {
            setPagination3({ ...pagination3, page });
          }}
          current={pagination3.page}
          total={pagination3.totalRows}
          showSizeChanger={false}
        />
      </div>
      <Modal
        open={showDeleteModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
      >
        <div className="flex column items-center">
          <span
            className="mb-2"
            style={{
              color: "#232B36",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Hitorical File delete?
          </span>
          <span
            className="mb-3"
            style={{
              color: "#74788D",
              fontSize: "1rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure to delete {fileSelect?.label} ?
          </span>
          <div className="separator mb-2 mt-2" />
          <div className="flex">
            <Button
              className="mr-3"
              style={{ width: "85px", height: "40px" }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={async () => {
                console.log("paramSelect", fileSelect);
                if (fileSelect && fileSelect.id) {
                  await historicalService.deleteHistoricalFile(
                    fileSelect?.id?.toString()
                  );
                  notification.success({
                    message: "Successful deletion",
                    description: "File deleted successfully",
                    placement: "topRight",
                  });
                  setShowDeleteModal(false);
                  handleUpdateHistoricalFiles();
                }
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HistoricalTabla;
