import { api } from "../services/api";

// constants
let initialData = {
  fetching: false,
  array: [],
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/kpis/all/today/";

let GET_KPI = "GET_KPI";
let GET_KPI_SUCCESS = "GET_KPI_SUCCESS";
let GET_KPI_ERROR = "GET_KPI_ERROR";

// reducer
export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_KPI:
      return { ...state, fetching: true };
    case GET_KPI_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_KPI_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export let getKpisAction = (id) => (dispatch, getState) => {
  if (!id) return;
  dispatch({
    type: GET_KPI,
  });
  return api
    .get(`${URL}${id}`)
    .then((res) => {
      dispatch({
        type: GET_KPI_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_KPI_ERROR,
        payload: err.response.message,
      });
    });
};
