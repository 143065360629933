import { api, apiController as _apiController, restartApiCotroller as _restartApiCotroller} from "./api";
import { apiNl, apiController, restartApiCotroller } from "./apiNl";

const getResumeFromDevice = async (infraId, parameterId,source, dateFrom, dateTo) => {
  try {
    restartApiCotroller();
    const response = await apiNl.get(
      `measurements/infraestructure/${infraId}/parameters/${parameterId}/resume`,
      {
        params: {
          dateFrom,
          dateTo,
          source,
        },
        signal: apiController.signal,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getInfraestructuresByLocation = async (locationId) => {
  if(locationId) {
    try {
      _restartApiCotroller();
      const response = await api.get(
        `measurements/location/${locationId}/infraestructure/all/measurementTarget/principal`,
        {
          signal: _apiController.signal,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  } else {
    return []
  }
};

const getInfreaestructureById = async (infraId) => {
  try {
    _restartApiCotroller();
    const response = await api.get(
      `/measurements/infraestructure/${infraId}/measurementTarget/principal`,
      {
        signal: _apiController.signal,
      }
    );
    console.log("🚀 ~ file: measurement.js:46 ~ getInfreaestructureById ~ response", response)
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getResumeFromDevice,
  getInfraestructuresByLocation,
  getInfreaestructureById,
};
