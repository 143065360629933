import React, { FC } from "react";

/**
 * @author
 * @function @BellSvg
 **/

const BellSvg: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
        >
            <path
                d="M14.6248 11.18V8C14.6234 6.58312 14.1206 5.21247 13.2054 4.13077C12.2903 3.04908 11.0219 2.32615 9.62482 2.09V1C9.62482 0.734784 9.51946 0.48043 9.33192 0.292893C9.14439 0.105357 8.89003 0 8.62482 0C8.3596 0 8.10525 0.105357 7.91771 0.292893C7.73017 0.48043 7.62482 0.734784 7.62482 1V2.09C6.22776 2.32615 4.95934 3.04908 4.0442 4.13077C3.12906 5.21247 2.62625 6.58312 2.62482 8V11.18C2.04127 11.3863 1.53582 11.7681 1.17776 12.2729C0.819695 12.7778 0.626556 13.3811 0.624817 14V16C0.624817 16.2652 0.730174 16.5196 0.91771 16.7071C1.10525 16.8946 1.3596 17 1.62482 17H4.76482C4.9951 17.8474 5.49782 18.5954 6.19541 19.1287C6.89301 19.6621 7.74671 19.951 8.62482 19.951C9.50292 19.951 10.3566 19.6621 11.0542 19.1287C11.7518 18.5954 12.2545 17.8474 12.4848 17H15.6248C15.89 17 16.1444 16.8946 16.3319 16.7071C16.5195 16.5196 16.6248 16.2652 16.6248 16V14C16.6231 13.3811 16.4299 12.7778 16.0719 12.2729C15.7138 11.7681 15.2084 11.3863 14.6248 11.18ZM4.62482 8C4.62482 6.93913 5.04624 5.92172 5.79639 5.17157C6.54654 4.42143 7.56395 4 8.62482 4C9.68568 4 10.7031 4.42143 11.4532 5.17157C12.2034 5.92172 12.6248 6.93913 12.6248 8V11H4.62482V8ZM8.62482 18C8.27579 17.9979 7.93339 17.9045 7.63165 17.7291C7.32991 17.5536 7.07933 17.3023 6.90482 17H10.3448C10.1703 17.3023 9.91973 17.5536 9.61799 17.7291C9.31624 17.9045 8.97384 17.9979 8.62482 18ZM14.6248 15H2.62482V14C2.62482 13.7348 2.73017 13.4804 2.91771 13.2929C3.10525 13.1054 3.3596 13 3.62482 13H13.6248C13.89 13 14.1444 13.1054 14.3319 13.2929C14.5195 13.4804 14.6248 13.7348 14.6248 14V15Z"
                fill="#74788D"
            />
        </svg>
    );
};

export default BellSvg;
