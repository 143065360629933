import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { TreePicker } from "rsuite";
import LocationService from "../../domain/Location/LocationService";
import { useDispatch } from "react-redux";

const locationService = new LocationService();

const SelectLocations = forwardRef(
  ({ defaultValue, aditionalStyle, setSelected }: any, ref) => {
    const dispatch = useDispatch();
    const [locations, setLocations] = useState<any>([]);
    const [dataSelect, setDataSelect] = useState<any>(null);
    const [itemsDisabled, setItemsDisabled] = useState<any>([]);
    const [dataPicker, setDataPicker] = useState<any>([]);

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setDataSelect(value);
      },
    }));

    function childChild(childrenLocation: any) {
      let childrenArray: any = [];
      childrenLocation.map((child: any) => {
        if (!child.canShowLocation) {
          setItemsDisabled([...itemsDisabled, child.locationId]);
        }
        childrenArray.push({
          label: child.locationName,
          value: child.locationId,
          disabled: !child.canShowLocation,
          ...(child.hasChild
            ? { children: childChild(child.childLocations) }
            : {}),
        });
      });

      return childrenArray;
    }

    function handleGetLocations() {
      const userId = localStorage.getItem("userId") || "";
      const resLocationByUser = locationService.getLocationsByUser(userId);
      resLocationByUser.then((value) => {
        setLocations(value);
      });
    }

    useEffect(() => {
      handleGetLocations();
    }, []);

    useEffect(() => {
      if (defaultValue) {
        setDataSelect(defaultValue);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (locations.length > 0) {
        const datas = locations.map((location: any) => {
          if (!location.canShowLocation) {
            setItemsDisabled([...itemsDisabled, location.locationId]);
          }
          return {
            label: location.locationName,
            value: location.locationId,
            disabled: !location.canShowLocation,
            ...(location.hasChild
              ? { children: childChild(location.childLocations) }
              : {}),
          };
        });
        setDataPicker(datas);
      }
    }, [locations]);

    return (
      <>
        {locations.length > 0 ? (
          <TreePicker
            data={dataPicker}
            showIndentLine
            onChange={(val) => {
              setDataSelect(val);
              setSelected(val);
            }}
            disabledItemValues={itemsDisabled}
            value={dataSelect}
            cleanable={true}
            className="location-picker"
            style={{ ...aditionalStyle }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
);

// export default const  SelectLocations = ({
//   defaultValue,
//   aditionalStyle,
//   setSelected,
// }) {
//   const dispatch = useDispatch();
//   const [locations, setLocations] = useState([]);
//   const [dataSelect, setDataSelect] = useState(null);
//   const [itemsDisabled, setItemsDisabled] = useState([]);
//   const [dataPicker, setDataPicker] = useState([]);

//   function childChild(childrenLocation) {
//     let childrenArray = [];
//     childrenLocation.map((child) => {
//       if (!child.canShowLocation) {
//         setItemsDisabled([...itemsDisabled, child.locationId]);
//       }
//       childrenArray.push({
//         label: child.locationName,
//         value: child.locationId,
//         disabled: !child.canShowLocation,
//         ...(child.hasChild
//           ? { children: childChild(child.childLocations) }
//           : {}),
//       });
//     });

//     return childrenArray;
//   }

//   function handleGetLocations() {
//     const userId = localStorage.getItem("userId") || "";
//     const resLocationByUser = locationService.getLocationsByUser(userId);
//     resLocationByUser.then((value) => {
//       setLocations(value);
//     });
//   }

//   useEffect(() => {
//     handleGetLocations();
//   }, []);

//   useEffect(() => {
//     if (defaultValue) {
//       setDataSelect(defaultValue);
//     }
//   }, [defaultValue]);

//   useEffect(() => {
//     if (locations.length > 0) {
//       const datas = locations.map((location) => {
//         if (!location.canShowLocation) {
//           setItemsDisabled([...itemsDisabled, location.locationId]);
//         }
//         return {
//           label: location.locationName,
//           value: location.locationId,
//           disabled: !location.canShowLocation,
//           ...(location.hasChild
//             ? { children: childChild(location.childLocations) }
//             : {}),
//         };
//       });
//       setDataPicker(datas);
//     }
//   }, [locations]);

//   return (
//     <>
//       {locations.length > 0 ? (
//         <TreePicker
//           data={dataPicker}
//           showIndentLine
//           onChange={(val) => {
//             setDataSelect(val);
//             setSelected(val);
//           }}
//           disabledItemValues={itemsDisabled}
//           value={dataSelect}
//           cleanable={true}
//           className="location-picker"
//           style={{ ...aditionalStyle }}
//         />
//       ) : (
//         ""
//       )}
//     </>
//   );
// }

export default SelectLocations;
