import { api } from "../../services/api";

export default class CountryRepository {
  async getCountriesByOrganization(
    organizationId: string
  ): Promise<CountryOrganization[]> {
    const countries = await api.get(
      `/locations/countries/organizations/${organizationId}`
    );
    return countries.data.map(
      (country: {
        countryId: any;
        active: any;
        inactive: any;
        locations: any;
        sublocations: any;
        countryName: string;
      }) => {
        return {
          id: country.countryId,
          name: country.countryName,
          locationsActive: country.active,
          locationsInactive: country.inactive,
          locationsQuantity: country.locations,
          subLocationsQuantity: country.sublocations,
          status: true,
        };
      }
    );
  }
  async getCountries() {
    const countries = await api.get(`locations/countries`);
    return countries.data.map((country: { code: any; name: any }) => {
      return {
        id: country.code,
        name: country.name,
      };
    });
  }
}
