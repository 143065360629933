import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import generateStore from "./redux/store";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/css/main.scss";
import { StyleProvider } from "@ant-design/cssinjs";
import { msalConfig } from "./authConfig";

// import 'antd/dist/antd.css';

export let store = generateStore();

// import Router from './routes/Router'
export const msalInstance = new PublicClientApplication(msalConfig);

let WhitStore = () => (
  <Provider store={store}>
    {/* <Router /> */}
    <StyleProvider hashPriority="high">
      <App msalInstance={msalInstance} />
    </StyleProvider>
  </Provider>
);

ReactDOM.render(<WhitStore />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
