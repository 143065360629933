import { Spin } from "antd";
import { connect } from "react-redux";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"

function Loader({ show, loadingText }) {
  return show ? (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: "0",
        left: "0",
        background: "rgb(0 0 0 / 83%)",
        zIndex: "9999",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Spin indicator={<LoadingOutlined spin />} size="large"/> 
    </div>
  ) : (
    <></>
  );
}

function mapState(state) {
  return {
    loadingText: state.loader.text,
  };
}

export default connect(mapState)(Loader);
