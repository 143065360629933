import { api } from "../../services/api";

export default class ParameterRepository {
  //Get Params with Sources
  async getParametersByInfraestructure(
    infraestructureId: string
  ): Promise<Parameter[]> {
    const parameters = await api.get(
      `/measurements/infrastructures/${infraestructureId}/parameters/all/sources/all`
    );
    return parameters.data;
  }

  // Only Id an Name
  async getParametersListByInfrastructure(
    infraestructureId: number
  ): Promise<Parameter[]> {
    const parameters = await api.get(
      `/measurements/infrastructure/${infraestructureId}/params/all`
    );
    return parameters.data;
  }

  async deleteParameter(parameterId: any): Promise<boolean> {
    const result = await api.delete(`/measurements/parameters/${parameterId}`);
    return result.data;
  }

  async changeVisibleParameter(
    parameterId: any,
    visible: boolean
  ): Promise<boolean> {
    const result = await api.put(
      `/measurements/parameters/${parameterId}/visible?visible=${visible}`
    );
    return result.data;
  }

  async postParameterForecast(
    parameterId: string | number,
    forecast: ParameterForecast
  ): Promise<boolean> {
    const locations = await api.post(
      `measurements/parameters/${parameterId}/forecast/configuration`,
      forecast
    );
    return locations.data.success === 1;
  }

  async changeInUseParameter(
    infrastructureId: any,
    inUse: boolean
  ): Promise<boolean> {
    const result = await api.put(
      `/measurements/infrastructures/${infrastructureId}?inUse=${inUse}`
    );
    return result.data;
  }

  async getInfraestructureInUse(
    location: string,
    page: string,
    size: string
  ): Promise<Parameter[]> {
    console.log("🚀 ~ ParameterRepository ~ location:", location);
    debugger;
    if (location) {
      const parameters = await api.get(
        `/measurements/location/${location}/inuse?page=${page}&size=${size}`
      );
      return parameters.data;
    } else {
      return [];
    }
  }

  async putFavoriteParameter(parameterId: string | number) {
    const parameters = await api.put(
      `/measurements/parameters/${parameterId}/principal`
    );
    return parameters.data;
  }

  async getParameter(parameterId: string | number): Promise<Parameter[]> {
    const parameters = await api.get(`/measurements/parameters/${parameterId}`);
    return parameters.data;
  }
}
