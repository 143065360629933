import { Dropdown, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import ListParametersTree from "../../Parameters/Infrastructure/listParametersTree";
import DiagramBlockContext from "../../../views/DiagramBlock/context";
import { getResumeFromDevice } from "../../../services/measurement";
import "./ParameterNode.scss";
import WaveSvg from "../../../assets/svg/WaveSvg";
import Icon, { PlusOutlined } from "@ant-design/icons";
import ThreeDotsSVG from "../../../assets/svg/ThreeDotsSVG";
import type { MenuProps } from "antd";
import HistorySvg from "../../../assets/svg/HistorySvg";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const ParameterNode: React.FC = ({ data, id }: any) => {
  const { value: context, setValue: setContext } =
    useContext(DiagramBlockContext);
  const [showModal, setShowModal] = useState(false);
  const [resumeInfo, setResumeInfo] = useState<any>(null);
  const globalDate = localStorage.getItem("globalDate");
  const [dateFrom, dataTo] = globalDate ? globalDate.split("***") : [];

  const items: MenuProps["items"] = [
    {
      label: (
        <div
          className="flex"
          onClick={() => {
            data.addNode(id);
          }}
        >
          <PlusOutlined className="mr-2" rev={undefined} />
          <span>Create new node</span>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <span
          style={{ color: "#F46A6A", cursor: "pointer" }}
          onClick={() => {
            data.removeNode(id);
          }}
        >
          Delete
        </span>
      ),
      key: "1",
    },
  ];

  async function handleGetReading() {
    const resume = await getResumeFromDevice(
      data.value.infrastructureId,
      data.value.paremeterId,
      null,
      dateFrom,
      dataTo
    );
    setResumeInfo(resume);
  }

  useEffect(() => {
    if (data.value.infrastructureId && data.value.serialNumberSource) {
      handleGetReading();
    }
  }, [data.value]);

  return (
    <>
      <div className="parameter-node py-2">
        <div className="flex items-center content-container justify-center">
          <Icon
            component={WaveSvg}
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
            className="mr-2"
          />
          <span className="mr-2" style={{ fontSize: "12px" }}>
            {data.label}
          </span>
          {data.editable && (
            <Dropdown menu={{ items }}>
              <Icon
                component={ThreeDotsSVG}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                rev={undefined}
              />
            </Dropdown>
          )}
        </div>
        <div className="container-details" style={{ fontSize: "12px" }}>
          {data.value.paremeterTypeName && (
            <>
              <div className="bold">
                <span>Parameter Type:</span>
              </div>
              <div>{data.value.paremeterTypeName}</div>
            </>
          )}
          {data.value.infrastructure && (
            <>
              <div className="bold">Infrastructure name:</div>
              <div>{data.value.infrastructure.name}</div>
              <div className="bold">Infrastructure type:</div>
              <div>{data.value.infrastructure.type}</div>
            </>
          )}
        </div>
        {resumeInfo && (
          <div className="flex column" style={{ fontSize: "12px" }}>
            <div className="separator my-2" style={{ height: "1px" }} />
            <div className="flex pl-2 mb-1 items-center">
              <div
                style={{
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    backgroundColor: "#88D8F6",
                    borderRadius: "100%",
                  }}
                  className="mr-2"
                />
              </div>
              <span style={{ fontSize: "10px" }}>
                Current Value: {resumeInfo.currentValue} {resumeInfo.unit}
              </span>
            </div>
            <div className="flex pl-2 items-center">
              <Icon component={HistorySvg} className="mr-2" rev={undefined} />
              <span style={{ fontSize: "10px" }}>
                Updated {`${dayjs(resumeInfo.lastCollected).fromNow()}`}
              </span>
            </div>
          </div>
        )}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
      </div>
      <Modal
        title="Select a parameter"
        open={showModal}
        footer={[]}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <ListParametersTree
          locationId={context.locationId}
          onSelect={(val: string) => {
            data.onChange(JSON.parse(val), id);
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ParameterNode;
