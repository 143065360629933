import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  EnvironmentOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
  StarOutlined,
  EditOutlined,
  DeleteOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import { Button, Input, Switch, Table, Tooltip, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import { setSelectedParameterDataToDigitalTwin } from "../../../redux/digitalTwinDuck";
import InfrastructureService from "../../../domain/Infrastructure/InfraestructureService";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import ParameterService from "../../../domain/Parameter/ParameterService";
import ModalParameterDetail from "../../../components/Parameters/Infrastructure/ParameterDetail";
import AddInfrastructureModal from "../../../components/Infrastructure";
import InfraestructureContext from "./context";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import {
  infraTypes,
  infraTypesById,
} from "../../../statics/constants/infraTypes";

const infrastructureService = new InfrastructureService();
const parameterService = new ParameterService();

interface InfraestructureValueInterface {
  selectedParam: Parameter | null;
  selectedVirtualSource: VirtualSource | null;
  showAddVariableVirtualParam: boolean;
  handleAddVariableVirtualParam: Function | null;
  locationName: string;
}

const ParameterView: React.FC = () => {
  const { infraId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contextValue, setContextValue] =
    useState<InfraestructureValueInterface>({
      selectedParam: null,
      selectedVirtualSource: null,
      showAddVariableVirtualParam: false,
      handleAddVariableVirtualParam: null,
      locationName: "",
    });
  const [infrastructure, setInfrastructure] = useState<Infrastructure | null>(
    null
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showModalParameterDetail, setShowModalParameterDetail] =
    useState(false);

  const [isNewParameter, setIsNewParameter] = useState<boolean>(true);
  const [editParameter, setEditParamater] = useState<any>({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState(
    infrastructure?.description || ""
  );
  const locationId = useRef<any>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function handleUpdateMainParameter(parameterId: string | number) {
    await parameterService.putFavoriteParameter(parameterId);
    await handleGetInfrastructure();
  }

  async function handleUpdateDescription() {
    if (infraId) {
      await infrastructureService.putInfraestructureDescription(
        infraId,
        description
      );
    }
  }

  const columnsTable: ColumnsType<Parameter> = [
    {
      title: "Parameter Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Main Parameter",
      dataIndex: "isMainParameter",
      key: "isMainParameter",
      render: (_, record) => (
        <StarOutlined
          style={{
            color: record.principal ? "#078CB3" : "#C4CCD5",
            cursor: "pointer",
          }}
          onClick={() => {
            handleUpdateMainParameter(record.id);
          }}
          rev={undefined}
        />
      ),
    },
    {
      title: "Critical High",
      dataIndex: "highCritical",
      key: "highCritical",
    },
    {
      title: "Critical High High",
      dataIndex: "hHighCritical",
      key: "hHighCritical",
    },
    {
      title: "Critical Low",
      dataIndex: "lowCritical",
      key: "lowCritical",
    },
    {
      title: "Critical Low Low",
      dataIndex: "lLowCritical",
      key: "lLowCritical",
    },
    {
      title: "Sample rate",
      dataIndex: "sampleRate",
      key: "sampleRate",
    },
    {
      title: "Transfer rate",
      dataIndex: "transferRate",
      key: "transferRate",
    },
    {
      title: "Expiration time",
      dataIndex: "expirationTime",
      key: "expirationTime",
    },
    {
      title: "Retention policy",
      dataIndex: "retentionPolicy",
      key: "retentionPolicy",
    },
    {
      title: "Historical policy",
      dataIndex: "historicalPolicy",
      key: "historicalPolicy",
    },
    {
      title: "Forecasted retention time",
      dataIndex: "forecastedRetentionTime",
      key: "forecastedRetentionTime",
    },
    {
      title: "Parameter type",
      dataIndex: "parameterType",
      key: "parameterType",
    },
    {
      title: "Parameter Unit Type",
      dataIndex: "parameterUnit",
      key: "parameterUnit",
    },
    {
      title: (
        <div>
          <Tooltip title="For an active infrastructure, it must have an active source and parameter">
            <span>Visible</span>
          </Tooltip>
        </div>
      ),
      key: "isVisible",
      render: (_, record) => (
        <Switch
          checked={record.isVisible}
          onClick={async (checked) => {
            try {
              if (checked) {
                await parameterService.enableParameter(record);
              } else {
                await parameterService.disableParameter(record);
              }
              if (infrastructure) {
                const newData: any = [...infrastructure.parameters];
                const index = newData.findIndex(
                  (item: any) => record.id === item.id
                );
                newData[index].isVisible = checked;
                console.log("🚀 ~ onClick={ ~ newData:", newData);
                setInfrastructure({ ...infrastructure, parameters: newData });
              }
            } catch (error) {
              console.error(error);
            }
          }}
        />
      ),
    },
    {
      title: "Sources",
      key: "viewResource",
      render: (_, record) => (
        <span
          onClick={() => {
            setContextValue({ ...contextValue, selectedParam: record });
            console.log("🚀 ~ file: index.tsx:142 ~ record:", record);
            setShowModalParameterDetail(true);
          }}
          style={{
            cursor: "pointer",
            textAlign: "center",
            textDecorationLine: "underline",
            color: "#22A2CA",
          }}
        >
          View Sources
        </span>
      ),
    },
    {
      title: "Actions",
      key: "edit",
      render: (_, record) => (
        <div className="flex">
          <Tooltip title="Edit" className="mr-2">
            <EditOutlined
              style={{ cursor: "pointer", fontSize: "1.5rem" }}
              onClick={() => {
                setContextValue({ ...contextValue, selectedParam: record });
                setIsNewParameter(false);
                showModal();
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip title="Delete" className="mr-2">
            <DeleteOutlined
              style={{ cursor: "pointer", fontSize: "1.5rem" }}
              onClick={() => {
                setContextValue({ ...contextValue, selectedParam: record });
                setShowConfirmDelete(true);
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip title="HydroTwin">
            <NodeIndexOutlined
              style={{ cursor: "pointer", fontSize: "1.5rem" }}
              onClick={() => {
                dispatch(
                  setSelectedParameterDataToDigitalTwin({
                    parameterId: record.id,
                    infrastructureId: infraId,
                    locationId: infrastructure?.locationId,
                    parameterName: record.name,
                    locationName: infrastructure?.locationName,
                  }) as unknown as AnyAction
                );
                navigate(`/infrastructures/parameter/hydroTwin`);
              }}
              rev={undefined}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleGetInfrastructure = async () => {
    if (infraId) {
      const infra = await infrastructureService.getInfrastructureWithParams(
        infraId
      );
      setInfrastructure(infra);
      locationId.current = infra.locationId;
      if (contextValue.selectedParam) {
        setContextValue({
          ...contextValue,
          locationName: infra.locationName,
          selectedParam:
            infra.parameters.find(
              (p) => p.id == contextValue.selectedParam?.id
            ) || null,
        });
      }
    } else {
      navigate(`parameters`);
    }
  };

  useEffect(() => {
    console.log("Infraaaaaaaaaaaaaaaa");
    handleGetInfrastructure();
  }, []);

  useEffect(() => {
    setDescription(infrastructure?.description || "");
  }, [infrastructure]);

  return (
    <InfraestructureContext.Provider
      value={{ value: contextValue, setValue: setContextValue }}
    >
      <>
        <div style={{ padding: "0px 3rem" }}>
          <Button
            type="text"
            shape="round"
            className="flex items-center"
            style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowLeftOutlined rev={undefined} /> Back to Infrastructure
          </Button>
          <div className="flex column pt-2">
            <span className="text-title-page">{infrastructure?.name}</span>
            {infrastructure && (
              <span style={{ fontSize: "1.1rem" }}>
                {infrastructure.infraTypeId
                  ? infraTypesById[
                      infrastructure.infraTypeId as keyof typeof infraTypesById
                    ]
                  : ""}
              </span>
            )}
            <div style={{ color: "#023E65" }} className="pt-2">
              <EnvironmentOutlined
                style={{ fontSize: "1.3rem" }}
                rev={undefined}
              />
              <span style={{ fontSize: "1.1rem" }} className="ml-1">
                {infrastructure?.locationName}
              </span>
            </div>
            <div className="flex column pt-2">
              <div style={{ width: "600px" }}>
                <Input.TextArea
                  value={description}
                  style={{ height: 80, resize: "none" }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="mt-1">
                <Button
                  style={{
                    backgroundColor: "#00203C",
                    color: "white",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleUpdateDescription();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            <div
              className="flex items-center pt-2"
              style={{ justifyContent: "end" }}
            >
              <div>
                {infrastructure?.parameters &&
                  infrastructure?.parameters.length > 0 && (
                    <Button
                      type="primary"
                      shape="round"
                      icon={<PlusCircleOutlined rev={undefined} />}
                      className="flex items-center ml-auto"
                      style={{
                        backgroundColor: "#023E65",
                        color: "white",
                        width: "150px",
                        height: "40px",
                      }}
                      onClick={() => {
                        setIsNewParameter(true);
                        setEditParamater({});
                        showModal();
                      }}
                    >
                      Add parameters
                    </Button>
                  )}
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                height: "calc(100vh - 430px)",
                display: "flex",
              }}
            >
              {infrastructure?.parameters &&
              infrastructure?.parameters.length > 0 ? (
                <Table
                  columns={columnsTable}
                  dataSource={infrastructure?.parameters}
                  rowKey={(item) => item.id}
                  scroll={{ x: true }}
                />
              ) : (
                <div
                  className="flex flex-column self-center justify-center text-center"
                  style={{ width: "100%" }}
                >
                  <span
                    className="text-title-page"
                    style={{ marginBottom: "10px" }}
                  >
                    No parameters yet!
                  </span>
                  <span
                    className="text-subtitle-page"
                    style={{ marginBottom: "10px" }}
                  >
                    Star by adding a few
                  </span>
                  <div className="self-center">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<PlusCircleOutlined rev={undefined} />}
                      className="flex items-center ml-auto"
                      style={{
                        backgroundColor: "#023E65",
                        color: "white",
                        width: "150px",
                        height: "40px",
                      }}
                      onClick={() => {
                        setIsNewParameter(true);
                        setEditParamater({});
                        showModal();
                      }}
                    >
                      Add parameter
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmationModal
          title="Delete Parameter"
          message="Are you sure to delete it?"
          showModal={showConfirmDelete}
          onAccept={async () => {
            if (contextValue.selectedParam) {
              await parameterService.deleteParameter(
                contextValue.selectedParam
              );
              await handleGetInfrastructure();
              notification.success({
                message: "Deleted successfully",
                description: "Parameter deleted successfully",
                placement: "topRight",
              });
              setShowConfirmDelete(false);
            }
          }}
          oncancel={() => {
            setShowConfirmDelete(false);
          }}
          showWarning
        />
        <ModalParameterDetail
          open={showModalParameterDetail}
          onClose={() => {
            setContextValue({ ...contextValue, selectedParam: null });
            handleGetInfrastructure();
            setShowModalParameterDetail(false);
          }}
          infrastructure={infrastructure}
          handleLoadSources={handleGetInfrastructure}
        />
        <AddInfrastructureModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleGetInfrastructure={handleGetInfrastructure}
          isNewParameter={isNewParameter}
          editParameter={contextValue.selectedParam}
          setIsModalOpen={setIsModalOpen}
          infraId={infraId}
          parameters={infrastructure?.parameters}
        />
      </>
    </InfraestructureContext.Provider>
  );
};

export default ParameterView;
