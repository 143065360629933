import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Select,
  Table,
  Tag,
  UploadFile,
  UploadProps,
  message,
  notification,
} from "antd";
import { useNavigate } from "react-router";
import Icon, {
  ArrowLeftOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import DevicesService from "../../domain/Devices/DevicesService";
import { iotEventsService } from "../../events/iot-events.subject";
import { recordExpression } from "@babel/types";

const statusLabel: { [key: string]: string } = {
  success: "Success",
  errror: "Error",
  inProgress: "In Progress",
};

const statusColor: { [key: string]: string } = {
  success: "green",
  errror: "red",
  inProgress: "orange",
};

const devicesService = new DevicesService();

function UploadBulk() {
  const navigate = useNavigate();
  const [bulkFiles, setBulkFiles] = useState<BulkUploaded[]>([]);
  const [showErrorInfoModal, setShowErrorInfoModal] = useState(false);
  const [selectedIot, setSelectedIot] = useState<number | null>(null);
  const [messageError, setMessageError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const subscriptionIot$ = iotEventsService.getSubject();

  const deleteFile = (fileId: any) => {
    const newArray = bulkFiles.filter((file) => file.uid !== fileId);
    setBulkFiles(newArray);
  };

  const showErrorFile = () => {
    messageApi.open({
      type: "error",
      content: "One of the files selected is not valid",
    });
  };

  const blobToBase64 = async (blob: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result as string;
          resolve(result.split(",")[1]);
        } else {
          resolve("");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadProps: UploadProps = {
    name: "file",
    accept: ".txt",
    showUploadList: false,
    multiple: true,
    beforeUpload: (file, fileList) => {
      console.log(
        "🚀 ~ file: uploadBulk.tsx:62 ~ UploadBulk ~ fileList:",
        fileList
      );
      console.log("🚀 ~ file: uploadBulk.tsx:70 ~ UploadBulk ~ file:", file);
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      handleUpload(file);

      return false;
    },
  };

  const handleUpload = async (file: UploadFile) => {
    const oui = JSON.parse(localStorage.getItem("security") || "").organizationId;

    const data = {
      userId: localStorage.getItem("userId") || "",
      organizationId: oui,
      file: await blobToBase64(file),
      fileName: file.name.split(".")[0],
    } as Iot;

    const uploadFile = await devicesService.uploadIotBulk(data);
    const iot = JSON.parse(uploadFile.data);

    console.log(
      "🚀 ~ file: uploadBulk.tsx:83 ~ handleUpload ~ uploadFile:",
      uploadFile
    );
    if (uploadFile.success) {
      setBulkFiles([
        ...bulkFiles,
        {
          fileName: data.fileName,
          size: file.size || 0,
          status: "IN PROGRESS",
          uid: file.uid,
          id: iot.id,
        },
      ]);
      const iotUploads = JSON.parse(localStorage.getItem("iotUploads") || "[]");
      iotUploads.push(iot);
      localStorage.setItem("iotUploads", JSON.stringify(iotUploads));
    } else {
      openNotification(uploadFile.message);
    }
  };

  const handleDeleteIot = async (iotId: string | number) => {
    const response = await devicesService.deleteIot(iotId);
    console.log(
      "🚀 ~ file: index.tsx:143 ~ handleDeleteIot ~ response:",
      response
    );
  };

  const openNotification = (message: any) => {
    notification.open({
      message: "Error",
      description: <div dangerouslySetInnerHTML={{ __html: message }} />,
      type: "error",
      duration: 0,
    });
  };

  useEffect(() => {
    subscriptionIot$.subscribe((event) => {
      if (event.type == "iotUploaded") {
        console.log(event);
        setBulkFiles((prev) =>
          prev.map((item) =>
            item.id === event.data.id
              ? { ...item, status: event.data.status }
              : item
          )
        );
      }
    });
  }, []);

  return (
    <div className="flex column">
      <div>
        <Button
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(`/bulk`);
          }}
        >
          <ArrowLeftOutlined rev={undefined} /> Back to Model Files
        </Button>
      </div>
      <span className="text-title-page mb-4">Upload IOT Files</span>
      <div
        className="mt-2 flex items-center"
        style={{
          background: "rgba(255, 238, 203, 0.5)",
          height: "40px",
          borderLeft: "solid 8px #FBC261",
        }}
      >
        <span className="ml-2" style={{ color: "#023E65" }}>
          We'll let you know when the upload is finished! You can go out and
          continue with your other tasks in the meantime, just remember to not
          refresh the page
        </span>
      </div>
      <div className="tableContainer mt-3 flex column w-full">
        <table
          style={{
            border: "2px solid #E6E6E6",
            height: "calc(100vh - 380px)",
            borderRadius: "10px",
            borderCollapse: "separate",
          }}
        >
          <tr style={{ height: "50px" }}>
            <th
              style={{
                paddingLeft: "20px",
                borderBottom: "2px solid #E6E6E6",
              }}
            >
              File name
            </th>
            <th
              style={{
                borderBottom: "2px solid #E6E6E6",
              }}
            >
              Size
            </th>
            <th
              style={{
                borderBottom: "2px solid #E6E6E6",
              }}
            >
              Status
            </th>
            <th
              style={{
                borderBottom: "2px solid #E6E6E6",
              }}
            ></th>
          </tr>
          {bulkFiles.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <Dragger {...uploadProps} style={{ backgroundColor: "white" }}>
                  <div className="flex column" style={{}}>
                    <span className="mb-2" style={{ color: "#74788D" }}>
                      Drag and drop your file here or clicking at
                    </span>
                    <Button
                      icon={<PlusCircleOutlined rev={undefined} />}
                      shape="round"
                      className="flex items-center self-center"
                      style={{
                        color: "#023E65",
                        borderColor: "#023E65",
                        backgroundColor: "transparent",
                      }}
                    >
                      Add Files
                    </Button>
                  </div>
                </Dragger>
              </td>
            </tr>
          ) : (
            <>
              {bulkFiles.map((bf) => {
                return (
                  <tr
                    style={{
                      height: "40px",
                      backgroundColor: "#F7F9FC",
                    }}
                    key={bf.uid}
                  >
                    <td style={{ paddingLeft: "20px" }}>{bf.fileName}</td>
                    <td>{bf.size}</td>
                    <td>
                      <Tag
                        color={
                          bf.status == "COMPLETED"
                            ? "green"
                            : bf.status == "FAILED"
                            ? "red"
                            : "orange"
                        }
                        style={{ height: "22px", borderRadius: "20px" }}
                      >
                        {bf.status}
                      </Tag>
                    </td>
                    <td>
                      {/* <DeleteOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "1.3rem",
                        }}
                        onClick={() => {}}
                      /> */}
                      {bf.status === "FAILED" && (
                        <WarningOutlined
                          className="mr-1 cursor-pointer"
                          style={{ fontSize: "1.5rem", color: "red" }}
                          onClick={() => {
                            if (bf.id) {
                              setSelectedIot(bf.id);
                              setMessageError(
                                "There was an error processing the upload"
                              );
                              setShowErrorInfoModal(true);
                            }
                          } } rev={undefined}                        />
                      )}
                      {bf.status !== "IN PROGRESS" && (
                        <DeleteOutlined
                          className="mr-1"
                          style={{ fontSize: "1.5rem" }}
                          onClick={async () => {
                            if (bf.id) {
                              await handleDeleteIot(bf.id);
                              setBulkFiles((prev) => {
                                return prev.filter((f) => f.id !== bf.id);
                              });
                            }
                          } } rev={undefined}                        />
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={4}></td>
              </tr>
            </>
          )}
        </table>
      </div>
      <Modal
        open={showErrorInfoModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
      >
        <div className="flex column items-center">
          <span
            className="mb-2"
            style={{ color: "#F46A6A", fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Error
          </span>
          <div
            className="flex items-center justify-center mb-3"
            style={{
              width: "150px",
              height: "56px",
              backgroundColor: "#FFD3D380",
              borderRadius: "5px",
            }}
          >
            <span
              className=""
              style={{
                color: "#F46A6A",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              {messageError}
            </span>
          </div>

          <div className="separator mb-2" />
          <div className="flex justify-center">
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#F46A6A",
                color: "white",
              }}
              onClick={async () => {
                if (selectedIot) {
                  const response = await devicesService.postCheckedIot(
                    selectedIot
                  );
                  if (response.success === 1) {
                    window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/bulk/upload`;
                  }
                }
              }}
            >
              Try again
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UploadBulk;
