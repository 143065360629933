import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import BulkDevicesView from "../BulkDevices";
import StatusDevice from "./StatusDevice";


const DevicesView: React.FC = () => {
  return (
    <div>
      <div className="text-title-page">Devices</div>
      <Tabs
        className="mt-3 ml-4"
        defaultActiveKey="1"
        items={[
          {
            label: <span className="titulo-tabs">UPLOAD DEVICE</span>,
            key: "1",
            children: <BulkDevicesView />,
          },
          {
            label: <span className="titulo-tabs">STATUS DEVICE</span>,
            key: "2",
            children: <StatusDevice />,
          },
        ]}
      />
    </div>
  );
};

export default DevicesView;
