import React, { FC, useState, useEffect, useRef } from "react";
import {
  Switch,
  Badge,
  Input,
  Select,
  Table,
  Tag,
  Pagination,
  Tooltip,
  Button,
  Modal,
  Divider,
  Form,
  notification,
} from "antd";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { apiController } from "../../services/api";
import { useParams, useNavigate } from "react-router-dom";
import LocationService from "../../domain/Location/LocationService";
import Locations from "../../components/Locations/Locations/Locations";
import ParameterService from "../../domain/Parameter/ParameterService";
import "./index.scss";
import SelectLocation from "../../basicComponents/Selects/SelectLocation";
import { useSearchParams } from "react-router-dom";
import StateMachineService from "../../domain/StateMachine/StateMachineService";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import SelectInpFile from "../../basicComponents/Selects/SelectInpFile";
import dayjs from "dayjs";

const locationService = new LocationService();

const parameterService = new ParameterService();
const stateMachineService = new StateMachineService();

interface DataType {
  id: number;
  name: string;
  inpName: string;
  location: string;
  createdBy: number;
  createdAt: number;
  enabled: boolean;
}

/**
 * @author
 * @function @ParametersView
 **/

const StateMachineView: FC = () => {
  const userId = localStorage.getItem("userId") || "";
  const security = localStorage.getItem("security");
  const organizationId = JSON.parse(security || "{}").organizationId;
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState<any>(null);
  const [inUse, setInUse] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [locations, setLocations] = useState<any>(null);
  const [stateMachines, setStateMachines] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedStateMachine, setSelectedStateMachine] = useState<any>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
  });
  const selectLocationRef = useRef<any>(null);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  //NewState
  const [selectedLocation, setSelectedLocation] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetStateMachines();
    return () => {
      apiController.abort();
    };
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({...pagination, page: 1});
    if(prevPage == 1) {
      handleGetStateMachines();
    }
    return () => {
      apiController.abort();
    };
  }, [ name, inUse, searchParams.get("locationId"), searchParams.get("name")]);

  // useEffect(() => {
  //   handleGetStateMachines();
  // }, [searchParams.get("locationId"), searchParams.get("name")]);

  useEffect(() => {
    if (searchParams.get("locationId") && selectLocationRef.current) {
      const locationParam = searchParams.get("locationId");
      selectLocationRef.current.setValue(locationParam);
      // setLocation(locationParam);
    }
  }, [searchParams]);

  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }, []);

  const handleGetStateMachines = async () => {
    const response = await stateMachineService.getAllStateMachines({
      name: searchParams.get("name"),
      locationId: searchParams.get("locationId"),
      page: pagination.page,
      size: pagination.pageSize,
    });
    if (response) {
      setPagination({
        ...pagination,
        totalPages: response.totalRecords,
      });
      setStateMachines(response.data);
    }
  };

  const deleteStatemachine = async () => {
    // const response = await stateMachineService.
  };

  const handleSaveStateMachine = async (values: {
    name: string | null;
    locationId: string | number | null;
    inpId: string | null;
    enabled: boolean;
  }) => {
    const response = await stateMachineService.createStateMachine(values);
    if (response) {
      setShowNewModal(false);
      notification.success({
        message: "Successful",
        description: "State machine created successfully",
      });
      handleGetStateMachines();
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "State Machine",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "locationName",
      key: "locationName",
      width: 200,
    },
    {
      title: "Inp File",
      dataIndex: "inpName",
      key: "inpName",
      width: 200,
    },
    {
      title: "Upload by",
      key: "uploadFor",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span
            style={{
              color: "#232B36",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {record.createdBy}
          </span>
          <span
            style={{
              color: "#74788D",
            }}
          >
            {dayjs(record.createdAt).format("YYYY/MM/DD [at] hh:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div>
          {/* <Tooltip title="For an active infrastructure, it must have an active source and parameter"> */}
          <span>Enable</span>
          {/* </Tooltip> */}
        </div>
      ),
      key: "enable",
      width: 60,
      render: (record) => (
        <>
          <Switch
            checked={record.enabled}
            onClick={async (checked) => {
              // try {
              //   if (checked) {
              //     await parameterService.enableInUseParameter(record);
              //   } else {
              //     await parameterService.disableInuseParameter(record);
              //   }
              //   const newData: any = [...stateMachines];
              //   const index = newData.findIndex(
              //     (item: any) => record.infraId === item.infraId
              //   );
              //   newData[index].inUse = checked;
              //   setStateMachines(newData);
              // } catch (error) {
              //   console.error(error);
              // }
            }}
          />
        </>
      ),
    },
    {
      title: "Actions",
      width: 200,
      key: "options",
      render: (_, record) => (
        <>
          <div>
            <Tooltip title="Edit">
              <EditOutlined
                className="mr-1"
                style={{ cursor: "pointer", fontSize: "1.5rem" }}
                onClick={() => {
                  navigate(`/stateMachines/edit/${record.id}`);
                }}
                rev={undefined}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                style={{ fontSize: "1.5rem" }}
                onClick={() => {
                  setSelectedStateMachine(record);
                  setShowConfirmDelete(true);
                }}
                rev={undefined}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    // console.log(page);
    setPagination({ ...pagination, page: page });
  };

  const handleClickSelect = (value: any) => {
    // record.location = value;
    // form.setFieldsValue({ location: value });
    // console.log("value", value);
    setLocation(value);
  };



  return (
    <div>
      <div className="text-title-page">State Machines</div>
      {/* <div className="text-subtitle-page">
        Configure the stateMachines for available infrastructure
      </div> */}
      <div className="mt-5 flex justify-between">
        <div className="flex">
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <ClockCircleOutlined
                style={{ color: "#078CB3" }}
                rev={undefined}
              />
            }
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                // setName(element.value);
                setSearchParams({ ...searchParams, name: element.value });
              }
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                searchParams.delete("name");
                setSearchParams(searchParams);
              }
            }}
            allowClear
          />
          <div style={{ marginLeft: 30 }}>
            {/* <Locations
            locations={locations}
            handleClickSelect={handleClickSelect}
            defaultValue={false}
          /> */}
            <SelectLocation
              ref={selectLocationRef}
              userId={userId}
              onSelect={(value: any) => {
                setLocation(value);
                if (value) {
                  setSearchParams({ locationId: value });
                } else {
                  searchParams.delete("locationId");
                  setSearchParams(searchParams);
                }
              }}
            />
          </div>

          {/* <Select
          style={{ marginLeft: 30, width: 140 }}
          placeholder="In use"
          optionFilterProp="children"
          options={[
            {
              value: "true",
              label: "True",
            },
            {
              value: "false",
              label: "False",
            },
          ]}
          onChange={(value) => {
            setInUse(value);
          }}
          allowClear
        /> */}
        </div>
        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              // navigate(`/stateMachine/new`);
              setShowNewModal(true);
            }}
          >
            Add State Machine
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={stateMachines}
          pagination={false}
          style={{
            maxHeight: "calc(100vh - 320px)",
            overflowX: "auto",
          }}
          // scroll={{ x: 1000, y: 400 }}
          rowKey="infraId"
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalPages}
          showSizeChanger={false}
        />
      </div>
      <ConfirmationModal
        title="Delete State Machine?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={async () => {
          if (selectedStateMachine) {
            // deleteStatemachine(selectedStateMachine?.id);
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      />
      <Modal
        title={<div className="title-modal">ADD STATE MACHINE</div>}
        open={showNewModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
        destroyOnClose={true}
        afterClose={() => form.resetFields()}
      >
        <Divider style={{ border: "1px solid #E6E6E6" }} />
        {/* <div className="flex column items-center"> */}
        <Form
          layout={"vertical"}
          form={form}
          onFinish={(val) => {
            handleSaveStateMachine(val);
          }}
          onFinishFailed={() => {}}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            label="Select location"
            name="locationId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Locations
              locations={locations}
              handleClickSelect={(val: any) => {
                form.setFieldValue("locationId", val);
                setSelectedLocation(val);
              }}
              defaultValue={false}
            />
          </Form.Item>
          <Form.Item
            label="Model INP"
            name="inpId"
            rules={[
              { required: true, message: "Please select an option" },
              {
                validator: (_, value) =>
                  value !== 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("Please select an option")),
              },
            ]}
          >
            <SelectInpFile
              emitObject
              onSelect={(val: any) => {
                form.setFieldValue("inpId", val.inpId);
              }}
              organizationId={organizationId}
              locationId={form.getFieldValue("locationId")}
              requireLocation
              onlyCompleted
            />
          </Form.Item>
          {/* <div className="separator mb-2 mt-2" /> */}
          <div className="flex justify-between mt-2">
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              htmlType="submit"
            >
              Accept
            </Button>
            <Button
              className="mr-3"
              style={{ width: "85px", height: "40px" }}
              onClick={() => setShowNewModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>

        {/* </div> */}
      </Modal>
    </div>
  );
};

export default StateMachineView;
