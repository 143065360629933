import { api } from "../../services/api";

export default class KeplerRepository {
  async postGenerateKeplerAnimation(simulationInteractiveId: any, iterationId: any) {
    try {
      const response = await api.post(
        `kepler/animation/simulationInteractive/${simulationInteractiveId}/iteration/${iterationId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getKeplerAnimation(simulationInteractiveId: any){
    try {
      const response = await api.get(
        `kepler/animation/simulationInteractive/${simulationInteractiveId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
