import { Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useInpFiles from "../../hooks/useInpFiles";
import useMetereological from "../../hooks/useMetereological";

const SelectMetereological = forwardRef(
  (
    {
      onSelect,
      emitObject,
      defaultValue = null,
      mode,
      otherSettings = {},
    }: {
      onSelect: Function;
      emitObject?: boolean;
      defaultValue?: any;
      mode: "forecast" | "historical" | "all";
      otherSettings?: any;
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue);
    const { meterelogicals } = useMetereological();

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
    }));

    return (
      <Select
        {...otherSettings}
        value={selectedValue}
        placeholder="Select an option"
        defaultValue={defaultValue}
        showSearch
        defaultActiveFirstOption={false}
        onChange={(val: string) => {
          if (val) {
            setSelectedValue(val);
            onSelect(emitObject ? JSON.parse(val || "{}") : val);
          }
        }}
        style={{ width: "100%" }}
        options={(meterelogicals || [])
          .filter((m) => {
            switch (mode) {
              case "forecast":
                return m.apply_forecast;
              case "historical":
                return m.apply_historical;
              case "all":
                return true;
              default:
                return true;
            }
          })
          .map((m) => ({
            value: emitObject ? JSON.stringify(m) : m.meterologicalId,
            label: m.name,
          }))}
      />
    );
  }
);

export default SelectMetereological;
