import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import { useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import { infraTypesById } from "../../statics/constants/infraTypes";

const infrastructureService = new InfrastructureService();

interface SideBarProps {
  onSave: Function;
  isEditMode: boolean;
  nodeSelect: any;
  locationId: any;
  onChangeParamNode: Function;
  closeEditMode: Function;
}

export const Sidebar: React.FC<SideBarProps> = ({
  onSave,
  isEditMode,
  nodeSelect,
  locationId,
  onChangeParamNode,
  closeEditMode,
}: SideBarProps) => {
  const navigate = useNavigate();
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string
  ) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  const [infraestructures, setInfraestructures] = useState<any[]>([]);
  const [parameters, setParameters] = useState<any[]>([]);
  const [selectedInfraestructure, setSelectedInfraestructure] =
    useState<any>(null);
  const [selectedParameter, setSelectedParameter] = useState<any>(null);

  async function handleGetInfrastructures(search: any) {
    if (!locationId) return;
    const response = await infrastructureService.getInfraestructureInUse(
      locationId,
      1,
      100,
      "",
      null,
      0
    );
    setInfraestructures(response.data);
  }

  async function handleSetParameters(val: null) {
    const selectedParameter = infraestructures.find(
      (p) => p.infraestructureId === val
    );
    if (selectedParameter) {
      setParameters(selectedParameter.measurementParameters);
    }
  }

  useEffect(() => {
    handleGetInfrastructures("");
  }, []);

  useEffect(() => {
    if (selectedInfraestructure) {
      const selectedInfraId = JSON.parse(selectedInfraestructure).id;
      handleSetParameters(selectedInfraId);
    }
  }, [selectedInfraestructure]);

  return (
    <aside className="flex column">
      {isEditMode ? (
        <>
          <span
            style={{
              color: "#023E65",
              fontSize: "16px",
              fontWeight: "500",
            }}
            className="mb-3"
          >
            Nodes settings
          </span>
          <span style={{ color: "#232B36" }} className="mb-1">
            Select infrastructure
          </span>
          <Select
            id="infrastructure"
            value={selectedInfraestructure}
            notFoundContent={null}
            onChange={(val) => {
              setSelectedInfraestructure(val);
            }}
            style={{ minWidth: "200px" }}
            showSearch
            options={(infraestructures || []).map((d) => {
              const infratypeId: number = d.infraTypeId || 1;
              return {
                value: JSON.stringify({
                  id: d.infraestructureId,
                  name: d.infraestructureName,
                  type: infraTypesById[
                    infratypeId as keyof typeof infraTypesById
                  ],
                }),
                label: d.infraestructureName,
              };
            })}
            className="mb-2"
          />
          <span style={{ color: "#232B36" }} className="mb-1">
            Select parameter
          </span>
          <Select
            id="parameter"
            value={selectedParameter}
            onChange={(val) => {
              setSelectedParameter(val);
            }}
            style={{ minWidth: "200px" }}
            options={(parameters || []).map((p) => {
              const selectedInfra = JSON.parse(selectedInfraestructure);
              return {
                value: JSON.stringify({
                  ...p,
                  infrastructureId: selectedInfra.id,
                  infrastructure: selectedInfra,
                }),
                label: p.paremeterName,
              };
            })}
          />
          <div className="flex justify-content-around mt-auto">
            <Button
              style={{
                backgroundColor: "#00203C",
                color: "white",
                height: "40px",
              }}
              onClick={() => {
                if (selectedParameter) {
                  // data.onChange(JSON.parse(val), id);
                  onChangeParamNode(
                    JSON.parse(selectedParameter),
                    nodeSelect.id
                  );
                  closeEditMode();
                  setSelectedInfraestructure(null);
                  setSelectedParameter(null);
                  setParameters([]);
                }
              }}
            >
              Accept
            </Button>
            <Button
              style={{
                height: "40px",
              }}
              onClick={() => {
                closeEditMode();
                setSelectedInfraestructure(null);
                setSelectedParameter(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <span>Flow nodes</span>
          <div className="description">
            You can drag these nodes to the pane on the left.
          </div>
          <div
            className="dndnode parameter"
            onDragStart={(event) => onDragStart(event, "parameter")}
            draggable
          >
            Parameter Node
          </div>
          <div className="flex justify-content-around mt-auto">
            <Button
              style={{
                backgroundColor: "#00203C",
                color: "white",
                height: "40px",
              }}
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
            <Button
              style={{
                height: "40px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </aside>
  );
};
