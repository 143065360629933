import { api } from "../../services/api";

export default class InteractiveSimulationRepository {
  async getInteractiveSimulations({
    organizationId,
    page,
    size,
    name,
    startDate,
    endDate,
    status,
  }: {
    organizationId: string | number;
    page: null | number | string;
    size: null | number | string;
    name: null | string;
    startDate: null | string;
    endDate: null | string;
    status: null | string;
  }) {
    try {
      const response = await api.get(
        `interactive-simulations/simulations/interactives/organizations/${organizationId}`,
        {
          params: {
            page,
            size,
            name,
            startDate,
            endDate,
            status,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async postInteractiveSimulation({
    simulationName,
    startDate,
    reportDate,
    endDate,
    stepTime,
    inpId,
    locationId,
  }: {
    simulationName: string;
    startDate: string;
    reportDate: string;
    endDate: string;
    stepTime: string | number;
    inpId: string | number;
    locationId: string | number;
  }) {
    try {
      const response = await api.post(`interactive-simulations/simulations`, {
        simulationName,
        startDate,
        reportDate,
        endDate,
        stepTime,
        inpId,
        locationid: locationId,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async postFinishInteractiveSimulation(simulationId: any) {
    try {
      const response = await api.post(
        `interactive-simulations/simulations/${simulationId}/end/COMPLETED`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getSimulationInfo(simulationId: any) {
    try {
      const response = await api.get(
        `interactive-simulations/simulations/${simulationId}/info`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllIterationsInteractiveSimulation(simulationId: any) {
    try {
      const response = await api.get(
        `interactive-simulations/simulations/${simulationId}/iterations`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getDataOfAnIteration(simulationId: any, iterationId: any) {
    try {
      const response = await api.get(
        `interactive-simulations/simulations/${simulationId}/iterations/${iterationId}/results`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteInteractiveSimulation(simulationId: any) {
    try {
      const response = await api.delete(
        `interactive-simulations/simulations/interactives/${simulationId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
