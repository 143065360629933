let colorAssignments = {}; // Objeto para almacenar las asignaciones de colores
const availableColors = [
    "#688ae8", "#c33d69", "#2ea597", "#8456ce", "#e07941", "#3759ce",
    "#962249", "#096f64", "#6237a7", "#a84401", "#273ea5", "#780d35",
    "#03524a", "#4a238b", "#7e3103", "#1b2b88", "#ce567c", "#003e38",
    "#9469d6", "#602400", "#4066df", "#a32952", "#0d7d70", "#6b40b2",
    "#bc4d01", "#2c46b1", "#81143b", "#045b52", "#512994", "#8a3603"
];

export function generateColor(paramId, sourceId = '') {
    // Combinar los parámetros en un solo string
    const key = `${paramId}_${sourceId}`;

    // Verificar si la combinación ya tiene un color asignado
    if (colorAssignments[key]) {
        return colorAssignments[key];
    }

    // Si no se ha asignado un color aún, selecciona el primer color disponible
    if (availableColors.length > 0) {
        const assignedColor = availableColors.shift(); // Elimina el color de la lista
        colorAssignments[key] = assignedColor; // Asigna el color a la combinación
        return assignedColor;
    } else {
        throw new Error('No more unique colors available');
    }
}
