import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL =
    process.env.REACT_APP_BASE_URL +
    "/measurements/infrastructures/pluviometers/locations/";

let GET_PLUVIOMETER = "GET_PLUVIOMETER";
let GET_PLUVIOMETER_SUCCESS = "GET_PLUVIOMETER_SUCCESS";
let GET_PLUVIOMETER_ERROR = "GET_PLUVIOMETER_ERROR";

// reducer
export default function pluviometerReducer(state = initialData, action) {
    switch (action.type) {
        case GET_PLUVIOMETER:
            return { ...state, fetching: true };
        case GET_PLUVIOMETER_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_PLUVIOMETER_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getPluviometerAction = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_PLUVIOMETER,
    });
    return api
        .get(`${URL}${id}`)
        .then((res) => {
            dispatch({
                type: GET_PLUVIOMETER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_PLUVIOMETER_ERROR,
                payload: err.response.message,
            });
        });
};
