import React, { useEffect, useState } from "react";
import {
  EnvironmentOutlined,
  ArrowLeftOutlined,
  LineChartOutlined,
  StarOutlined,
  StarFilled,
  DeleteOutlined,
  PlusCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import InfrastructureService from "../../../../domain/Infrastructure/InfraestructureService";
import DigitalTwinService from "../../../../domain/DigitalTwin/DigitalTwinService";
import { Button, Collapse, DatePicker, Input, Select, Switch, Tag } from "antd";
import { Dayjs } from "dayjs";

const infrastructureService = new InfrastructureService();
const digitalTwinService = new DigitalTwinService();

export default function Step1({
  digitalTwinData,
  onSaved,
  onBack,
  onCancel,
}: any) {
  const [selectedParameter, setSelectedParameter] = useState<any>(null);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [variables, setVariables] = useState<any[]>([]);
  const [newNeuralName, setNewNeuralName] = useState("");
  const [selectedInfraestructure, setSelectedInfraestructure] =
    useState<any>(null);
  const [infraestructures, setInfraestructures] = useState<any[]>([]);
  const [parameters, setParameters] = useState<any[]>([]);
  const [skipDwfCompression, setSkipDwfCompression] = useState<any>(false);
  const [numberOfNNUnits, setNumberOfNNUnits] = useState<any>(128);
  const [numberOfLayers, setNumberOfLayers] = useState<any>(2);
  const [inputTimeSteps, setInputTimeSteps] = useState<any>(288);
  const [numberOfEpochs, setNumberOfEpochs] = useState<any>(150);
  const [batchSize, setBatchSize] = useState<any>(128);

  async function handleSaveDigitalTwin() {
    const data = {
      name: newNeuralName,
      startDate: dateFrom,
      endDate: dateTo,
      variables,
      output: `${digitalTwinData.infrastructureId}-${digitalTwinData.parameterId}`,
      skipDWF: skipDwfCompression,
      nnUnits: numberOfNNUnits,
      layers: numberOfLayers,
      inputTimeSteps: inputTimeSteps,
      epochs: numberOfEpochs,
      batchSize: batchSize,
    };
    onSaved(data);

    // const res = await digitalTwinService.postDigitalTwin(
    //   data,
    //   digitalTwinData.infrastructureId,
    //   digitalTwinData.parameterId
    // );
    // if (res) {
    //   setNewNeuralName("");
    //   setSelectedParameter(null);
    //   setDateFrom(null);
    //   setDateTo(null);
    //   setVariables([]);
    // }
  }

  async function handleSearchInfrastructures() {
    const response = await infrastructureService.getInfraestructureInUse(
      `${digitalTwinData.locationId}`,
      1,
      1000,
      "",
      null,
      0
    );
    setInfraestructures(response.data);
  }

  async function handleSetParameters(val: null) {
    const selectedParameter = infraestructures.find(
      (p) => p.infraestructureId === val
    );
    if (selectedParameter) {
      setParameters(selectedParameter.measurementParameters);
    }
  }

  async function handleGetDefaultConfiguration() {
    const res = await digitalTwinService.getDefaultConfiguration();
    setBatchSize(res.batchSize);
    setInputTimeSteps(res.inputTimeSteps);
    setNumberOfEpochs(res.numberEpochs);
    setNumberOfLayers(res.numberLayers);
    setNumberOfNNUnits(res.numberNNUnits);
    setSkipDwfCompression(res.skipDWF);
    console.log("🚀 ~ handleGetDefaultConfiguration ~ res:", res);
  }

  useEffect(() => {
    if (selectedInfraestructure) {
      handleSetParameters(
        JSON.parse(selectedInfraestructure).infraestructureId
      );
    } else {
    }
  }, [selectedInfraestructure]);

  useEffect(() => {
    handleSearchInfrastructures();
    handleGetDefaultConfiguration();
  }, []);

  return (
    <div className="flex column" style={{ height: "100%" }}>
      <div className="flex column">
        <ArrowLeftOutlined
          onClick={() => {
            setNewNeuralName("");
            setSelectedParameter(null);
            setDateFrom(null);
            setDateTo(null);
            setVariables([]);
            onBack();
          }}
          rev={undefined}
        />
      </div>
      <div className="flex column">
        <span className="mt-2">Neural Network name</span>
        <Input
          value={newNeuralName}
          onChange={(e) => {
            setNewNeuralName(e.target.value);
          }}
        />
        <span className="mt-2">Date Range</span>
        <DatePicker.RangePicker
          onChange={(dates: null | (Dayjs | null)[], dateStrings: string[]) => {
            setDateFrom(dateStrings[0]);
            setDateTo(dateStrings[1]);
          }}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{ format: "HH:mm:ss" }}
        />
        <Tag
          icon={<InfoCircleOutlined style={{ fontWeight: 800 }} />}
          color="processing"
          className="mt-2"
        >
          <span style={{ fontWeight: 700 }}>
            To add the input params press the button: "Add Input"
          </span>
        </Tag>
        <span className="mt-1">Input Parameters</span>
        <div className="flex mb-3">
          <Select
            id="infrastructure"
            placeholder="Infrastructure"
            value={selectedInfraestructure}
            notFoundContent={null}
            onChange={(val) => {
              setSelectedInfraestructure(val);
              setSelectedParameter(null);
            }}
            style={{ minWidth: "200px" }}
            showSearch
            options={(infraestructures || []).map((d) => ({
              value: JSON.stringify(d),
              label: d.infraestructureName,
            }))}
            className="mr-1"
          />
          <Select
            id="parameter"
            placeholder="Parameter"
            value={selectedParameter}
            onChange={(val) => {
              setSelectedParameter(val);
            }}
            style={{ minWidth: "200px" }}
            options={(parameters || [])
              .filter((item) => {
                return (
                  variables.findIndex(
                    (va) => va.parameterId == item.paremeterId
                  ) < 0
                );
              })
              .map((p) => ({
                value: JSON.stringify(p),
                label: p.paremeterName,
              }))}
            className="mr-1"
          />
          <Button
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined rev={undefined} />}
            className="flex items-center ml-auto"
            style={{
              backgroundColor: "#023E65",
              borderColor: "#023E65",
              color: "white",
              width: "120px",
              height: "32px",
            }}
            onClick={() => {
              setVariables((prev: any) => {
                const param = JSON.parse(selectedParameter);
                const infra = JSON.parse(selectedInfraestructure);
                return [
                  ...prev,
                  {
                    parameterName: param.paremeterName,
                    parameterId: param.paremeterId,
                    infraName: infra.infraestructureName,
                    infraId: infra.infraestructureId,
                  },
                ];
              });
              setSelectedParameter(null);
            }}
            disabled={!selectedParameter}
          >
            Add input
          </Button>
        </div>
        <Collapse>
          <Collapse.Panel
            header={<span>Advanced Parameters</span>}
            key="advanced"
          >
            <div className="flex column">
              <div className="flex column mb-2">
                <span className="font-bold">Skip DWF Compression</span>
                <Switch
                  defaultChecked={skipDwfCompression}
                  onChange={(checked) => {
                    setSkipDwfCompression(checked);
                  }}
                  style={{ width: "50px" }}
                />
              </div>
              <div className="mb-2">
                <span className="font-bold">Number of NN units</span>
                <Input
                  value={numberOfNNUnits}
                  type="number"
                  onChange={(val) => {
                    if (parseInt(val.target.value) < 0) return;
                    setNumberOfNNUnits(parseInt(val.target.value));
                  }}
                  onBlur={(val) => {
                    if (val.target.value == "") {
                      setNumberOfNNUnits(0);
                    }
                  }}
                  min={0}
                />
              </div>
              <div className="mb-2">
                <span className="font-bold">Number of layers</span>
                <Input
                  value={numberOfLayers}
                  type="number"
                  onChange={(val) => {
                    if (parseInt(val.target.value) < 0) return;
                    setNumberOfLayers(parseInt(val.target.value));
                  }}
                  onBlur={(val) => {
                    if (val.target.value == "") {
                      setNumberOfLayers(0);
                    }
                  }}
                  min={0}
                />
              </div>
              <div className="mb-2">
                <span className="font-bold">Input time steps</span>
                <Input
                  value={inputTimeSteps}
                  type="number"
                  onChange={(val) => {
                    if (parseInt(val.target.value) < 0) return;
                    setInputTimeSteps(parseInt(val.target.value));
                  }}
                  onBlur={(val) => {
                    if (val.target.value == "") {
                      setInputTimeSteps(0);
                    }
                  }}
                  min={0}
                />
              </div>
              <div className="mb-2">
                <span className="font-bold">Number of epochs</span>
                <Input
                  value={numberOfEpochs}
                  type="number"
                  onChange={(val) => {
                    if (parseInt(val.target.value) < 0) return;
                    setNumberOfEpochs(parseInt(val.target.value));
                  }}
                  onBlur={(val) => {
                    if (val.target.value == "") {
                      setNumberOfEpochs(0);
                    }
                  }}
                  min={0}
                />
              </div>
              <div className="mb-2">
                <span className="font-bold">Batch size</span>
                <Input
                  value={batchSize}
                  type="number"
                  onChange={(val) => {
                    if (parseInt(val.target.value) < 0) return;
                    setBatchSize(parseInt(val.target.value));
                  }}
                  onBlur={(val) => {
                    if (val.target.value == "") {
                      setBatchSize(0);
                    }
                  }}
                  min={0}
                />
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
        <div
          className="flex mt-2"
          style={{
            flexWrap: "wrap",
            border: "solid 1px",
            borderRadius: "5px",
            minHeight: "80px",
            padding: "10px",
          }}
        >
          {variables.map((va) => {
            return (
              <Tag
                color="#E8F9FF"
                key={va.parameterId}
                style={{
                  color: "#023E65",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  height: "25px",
                }}
                className="mr-1 mb-1"
              >
                {`${va.infraName} - ${va.parameterName}`}
              </Tag>
            );
          })}
        </div>
      </div>
      <div className="flex mt-auto" style={{ justifyContent: "flex-end" }}>
        <Button
          style={{
            backgroundColor: "#00203C",
            color: "white",
            height: "40px",
          }}
          onClick={() => {
            handleSaveDigitalTwin();
          }}
          disabled={variables.length < 1 || !dateFrom || !dateTo || newNeuralName == ""}
        >
          Next
        </Button>
        <Button
          className="ml-3"
          style={{
            height: "40px",
          }}
          onClick={() => {
            setNewNeuralName("");
            setSelectedInfraestructure(null);
            setSelectedParameter(null);
            setDateFrom(null);
            setDateTo(null);
            setVariables([]);
            onCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
