import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Locations from "../Locations/Locations/Locations";
import LocationService from "../../domain/Location/LocationService";
import InteractiveSimulationService from "../../domain/InteractiveSimulations/InteractiveSimulationService";
import { getModelFiles } from "../../services/model";
import dayjs from "dayjs";
import { setInteractiveSimulationData } from "../../redux/interactiveSimulationDuck";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import type { FormInstance } from "antd/es/form";
import SelectInpFile from "../../basicComponents/Selects/SelectInpFile";

export interface IProps {
  showCreationModal: boolean;
  onCancel: Function;
  onSuccess: Function;
}

const locationService = new LocationService();
const interactiveSimulationService = new InteractiveSimulationService();

export default function ModalCreationInteractiveSimulation({
  showCreationModal,
  onCancel,
  onSuccess,
}: IProps) {
  const security = localStorage.getItem("security") || "{}";
  const organizationId = JSON.parse(security).organizationId;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [locations, setLocations] = useState<Location[]>([]);
  const [name, setName] = useState("");
  const [selectLocation, setSelectLocation] = useState(0);
  const [selectedInp, setSelectedInp] = useState(0);
  const [inpList, setInpList] = useState([]);
  const [startDate, setStartdate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [stepTime, setStepTime] = useState(0);

  const handleClickSelectLocation = (value: any) => {
    setSelectLocation(value);
  };

  const handleGetModelFiles = async () => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getModelFiles(organizationId, {
      page: 1,
      size: 500,
      name: null,
      type: null,
      locationId: form.getFieldValue("locationId"),
    })
      .then((response) => {
        setInpList(response.getAllResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSaveSimulation = async (values: any) => {
    const res = await interactiveSimulationService.postInteractiveSimulation({
      ...values,
    });
    if (res.success) {
      notification.success({
        message: "Successful",
        description: "Interactive Simulation created successfully",
      });
      localStorage.setItem(
        "simulationTime",
        JSON.stringify([
          dayjs(values.startDate).format("YYYY-MM-DD HH:mm:ss"),
          dayjs(values.endDate).format("YYYY-MM-DD HH:mm:ss"),
        ])
      );
      localStorage.setItem("stepTime", values.stepTime);
      dispatch(
        setInteractiveSimulationData({ ...values }) as unknown as AnyAction
      );
      onSuccess({
        ...values,
        simulationId:
          JSON.parse(res.data.replaceAll("\\", "")).simulationId || 0,
      });
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }, []);

  // useEffect(() => {
  //   if (form.getFieldValue("locationId")) {
  //   }
  // }, [form]);

  return (
    <Modal open={showCreationModal} footer={[]} closable={false} width={350}>
      <div className="flex column pl-3 pr-3">
        <span
          className="text-title-page pt-2 pb-2"
          style={{ fontSize: "1rem" }}
        >
          CREATE SIMULATIONS
        </span>
        <div className="separator mb-2" />
        <div className="mb-1">
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              handleSaveSimulation(values);
            }}
            onFinishFailed={(errorInfo) => {
              console.log("Failed:", errorInfo);
            }}
            autoComplete="off"
            size="small"
            style={{ maxWidth: 700 }}
          >
            <Form.Item
              name="simulationName"
              label="Simulation name:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
              ]}
            >
              <Input maxLength={32} />
            </Form.Item>
            <Form.Item
              name="locationId"
              label="Select Location:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
              ]}
            >
              <Locations
                locations={locations}
                handleClickSelect={(value: any) => {
                  form.setFieldsValue({
                    locationId: value,
                  });
                  if (value) {
                    form.setFieldsValue({
                      inpId: null,
                    });
                    handleGetModelFiles();
                  }
                }}
                aditionalStyle={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="inpId"
              label="Select Inp:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
                {
                  validator: (_, value) =>
                    value !== 0
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should select an option")),
                },
              ]}
            >
              {/* <Select
                className="mt-1"
                style={{ width: "100%", borderRadius: "20px" }}
                placeholder="Select an option"
                // onChange={(value) => {
                //   setSelectedInp(value);
                // }}
                options={inpList?.map((modeli: any) => {
                  return {
                    value: parseInt(modeli.inpId),
                    label: modeli.inpName,
                  };
                })}
              /> */}
              <SelectInpFile
                emitObject={false}
                onSelect={(val: any) => {
                  // form.setFieldValue("inpObjectId", null);
                  form.setFieldValue("inpId", val);
                  setSelectedInp(val);
                }}
                organizationId={organizationId}
                locationId={form.getFieldValue("locationId")}
                onlyCompleted
              />
            </Form.Item>
            <Form.Item
              name="startDate"
              label="Select Start date:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
              ]}
            >
              <DatePicker
                showTime
                // onChange={(date, dateString) => {
                //   setStartdate(
                //     dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
                //   );
                // }}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </Form.Item>
            {/* <Form.Item
              name="reportDate"
              label="Select Report date:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
                {
                  validator: (_, value) =>
                    value > form.getFieldValue("startDate")
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Should be greater than the start date")
                        ),
                },
              ]}
            >
              <DatePicker
                showTime
                // onChange={(date, dateString) => {
                //   setStartdate(
                //     dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
                //   );
                // }}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </Form.Item> */}
            <Form.Item
              name="endDate"
              label="Select End date:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
                {
                  validator: (_, value) =>
                    value > form.getFieldValue("startDate")
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Should be greater than the start date")
                        ),
                },
              ]}
            >
              <DatePicker
                showTime
                // onChange={(date, dateString) => {
                //   setStartdate(
                //     dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
                //   );
                // }}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </Form.Item>
            <Form.Item
              name="stepTime"
              label="Time interval:"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ display: "inline-block" }}>Required</span>
                  ),
                },
              ]}
            >
              <InputNumber
                min={1}
                max={60}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </Form.Item>
            <div className="separator" />
            <div className="flex pt-3 justify-between">
              <Button
                style={{
                  width: "85px",
                  height: "40px",
                  backgroundColor: "#00203C",
                  color: "white",
                }}
                // onClick={() => {
                //   handleSaveSimulation();
                // }}
                htmlType="submit"
              >
                Accept
              </Button>
              <Button
                style={{ width: "85px", height: "40px" }}
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
          {/* <label htmlFor="nameKpi" className="mr-2">
            Simulation name:
          </label>
          <br />
          <Input
            id="simulationName"
            value={name}
            onChange={(val) => {
              setName(val.target.value);
            }}
            style={{ width: "100%", borderRadius: "20px" }}
          /> */}
        </div>
        {/* <div className="mb-1">
          <label htmlFor="location" className="mr-2">
            Select Location:
          </label>
          <br />
          {locations.length > 0 && (
            <Locations
              locations={locations}
              handleClickSelect={handleClickSelectLocation}
              aditionalStyle={{ width: "100%" }}
            />
          )}
        </div>
        <div className="mb-1">
          <label htmlFor="location" className="mr-2">
            Select Inp:
          </label>
          <br />
          <Select
            className="mt-1"
            style={{ width: "100%", borderRadius: "20px" }}
            placeholder="Select"
            onChange={(value) => {
              setSelectedInp(value);
            }}
            options={inpList
              ?.map((modeli: any) => {
                return {
                  value: parseInt(modeli.inpId),
                  label: modeli.inpName,
                };
              })
              .concat([{ value: 0, label: "Select an option" }])}
          />
        </div>
        <div className="mb-1">
          <label htmlFor="infrastructure" className="mr-2">
            Select Start date:
          </label>
          <br />
          <DatePicker
            showTime
            onChange={(date, dateString) => {
              setStartdate(
                dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
              );
            }}
            style={{ width: "100%", borderRadius: "20px" }}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="parameter" className="mr-2">
            Select Finish date:
          </label>
          <br />
          <DatePicker
            showTime
            onChange={(date, dateString) => {
              setFinishDate(
                dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
              );
            }}
            style={{ width: "100%", borderRadius: "20px" }}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="parameter" className="mr-2">
            Intervalos de tiempo:
          </label>
          <br />
          <Input
            value={stepTime}
            onChange={(val) => {
              setStepTime(parseInt(val.target.value));
            }}
            type="number"
            style={{ width: "100%", borderRadius: "20px" }}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="parameter" className="mr-2">
            Select Report date :
          </label>
          <br />
          <DatePicker
            showTime
            onChange={(date, dateString) => {
              setReportDate(
                dayjs(dateString).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
              );
            }}
            style={{ width: "100%", borderRadius: "20px" }}
          />
        </div> */}
      </div>
    </Modal>
  );
}
