import React, { useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { Modal } from "rsuite";
import "./../css/modal.css";

export default function ModalExit({ closeModal }) {
  const { accounts, instance } = useMsal();
  function logout() {
    localStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Modal open={closeModal} size="xs">
          <Modal.Body>
            <div className="">
              <div className="modalContainer">
                <div className="title">
                  <img src="img/exit.png" />
                </div>
                <div className="body">Are you sure want to logout?</div>
                <div className="footer">
                  <button className="no" onClick={() => closeModal(false)}>
                    No, Just kidding
                  </button>
                  <button className="yes" onClick={() => logout()}>
                    Yes, Log me out
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="modalBackground">
          <div className="modalContainer">
            <div className="body">You are not Authenticated</div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}
