import { api } from "../../services/api";

export default class VirtualSourceRepository {
  async getVirtualSource(measurementSourceId: string | number): Promise<any> {
    const virtualSource = await api.get(
      `/measurements/virtualMetadata/${measurementSourceId}`
    );
    return virtualSource.data;
  }

  async saveVirutalSource(
    measurementSourceId: number | string,
    variablesDTOS: any[],
    statement: string
  ): Promise<boolean> {
    const result = await api.post(`/measurements/virtualMetadata`, {
      measurementSourceId,
      variablesDTOS,
      statement,
    });
    return result.data.success === 1;
  }
}
