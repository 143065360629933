import React, { useEffect, useState } from "react";
import {
  EnvironmentOutlined,
  ArrowLeftOutlined,
  LineChartOutlined,
  StarOutlined,
  StarFilled,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import InfrastructureService from "../../../../domain/Infrastructure/InfraestructureService";
import DigitalTwinService from "../../../../domain/DigitalTwin/DigitalTwinService";
import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Input,
  Select,
  Tag,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { getReadingsFromDevice } from "../../../../services/historical";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const infrastructureService = new InfrastructureService();
const digitalTwinService = new DigitalTwinService();

export default function Step2({
  digitalTwinData,
  newDigitalTwinData,
  onSaved,
  onBack,
}: any) {
  const [dateFrom, setDateFrom] = useState<any>(newDigitalTwinData.startDate);
  const [dateTo, setDateTo] = useState<any>(newDigitalTwinData.endDate);
  const [variables, setVariables] = useState(newDigitalTwinData.variables);
  const [collapseItems, setCollapseItems] = useState<any[]>([]);
  const [collapseItems2, setCollapseItems2] = useState<any[]>([]);

  async function handleSaveDigitalTwin() {
    const data = {
      name: newDigitalTwinData.name,
      startDate: dateFrom,
      endDate: dateTo,
      input: variables.reduce((previus: any, variable: any) => {
        return (
          previus +
          `${previus.length > 0 ? "," : ""}${variable.infraId}-${
            variable.parameterId
          }`
        );
      }, ""),
      output: newDigitalTwinData.output,
      skipDWF: newDigitalTwinData.skipDWF,
      nnUnits: newDigitalTwinData.nnUnits,
      layers: newDigitalTwinData.layers,
      inputTimeSteps: newDigitalTwinData.inputTimeSteps,
      epochs: newDigitalTwinData.epochs,
      batchSize: newDigitalTwinData.batchSize,
    };
    const res = await digitalTwinService.postDigitalTwin(
      data,
      digitalTwinData.infrastructureId,
      digitalTwinData.parameterId
    );
    if (res) {
      setDateFrom(null);
      setDateTo(null);
      setVariables([]);
      onSaved();
    }
  }

  async function handleGetParamData() {
    let yAxis: any = [];
    let seriesData: any = [];
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const data = await getReadingsFromDevice(
      digitalTwinData.parameterId,
      null,
      dayjs(dateFrom).format("YYYY-MM-DD HH:mm:ss"),
      dayjs(dateTo).format("YYYY-MM-DD HH:mm:ss")
    );
    yAxis.push({
      valueUnit: data.valueUnit,
      labels: {
        format: `{value}${data.valueUnit}`,
      },
      title: {
        text: `${data.sensorType}`,
      },
    });
    const measurementHistorialValues = data.measurementToolHistoricalValues;
    if (measurementHistorialValues) {
      const sources = Object.keys(measurementHistorialValues).map((item) => {
        return measurementHistorialValues[item].serialNumber;
      });
      const uniqueSources = [...new Set(sources)];
      if (uniqueSources.length > 0) {
        uniqueSources.forEach((source) => {
          if (!source) return;
          const keys = Object.keys(measurementHistorialValues);
          const dataSeries = keys
            .filter(
              (item) => measurementHistorialValues[item].serialNumber == source
            )
            .map((item) => [
              parseFloat(item),
              measurementHistorialValues[item].value,
            ])
            .sort((a, b) => {
              return a[0] - b[0];
            });
          const read = {
            name: data.measurementTargetName,
            sourceName: source,
            valueUnit: data.valueUnit,
            sensorType: data.sensorType,
            yAxis: yAxis.findIndex(
              ({ valueUnit }: any) => valueUnit === data.valueUnit
            ),
            data: [
              ...dataSeries,
              [new Date(dateFrom).getTime() - localOffset, null],
              [new Date(dateTo).getTime() - localOffset, null],
            ],
          };
          seriesData.push(read);
        });
      } else {
        const read = {
          name: data.measurementTargetName,
          sourceName: "",
          valueUnit: data.valueUnit,
          sensorType: data.sensorType,
          yAxis: yAxis.findIndex(
            ({ valueUnit }: any) => valueUnit === data.valueUnit
          ),
          data: [
            [new Date(dateFrom).getTime() - localOffset, null],
            [new Date(dateTo).getTime() - localOffset, null],
          ],
        };
        seriesData.push(read);
      }
    }
    if (data.measurementToolForecastValues) {
      data.measurementToolForecastValues?.forEach((forecast: any) => {
        const colors = Highcharts.getOptions().colors;
        if (!colors) return;
        const foreData = {
          name: data.measurementTargetName,
          sourceName: data.resume.sourceName,
          valueUnit: data.valueUnit,
          sensorType: data.sensorType,
          yAxis: yAxis.findIndex(
            ({ valueUnit }: any) => valueUnit === data.valueUnit
          ),
          showInLegend: false,
          dashStyle: "Dash",
          data: forecast
            ? Object.keys(forecast)
                .map((item) => [parseFloat(item), forecast[item]])
                .sort((a, b) => {
                  return a[0] - b[0];
                })
            : [],
        };
        seriesData.push(foreData);
      });
    }
    const graphOptions = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: null,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
      },
      legend: {
        backgroundColor: "#FFFFFF",
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: new Date(),
            to: Date.UTC(2024, 1, 1),
            color: "#EFFFFF",
          },
        ],
        min: new Date(dateFrom).getTime() - localOffset,
        max: new Date(dateTo).getTime() - localOffset,
        ordinal: false,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: true,
        height: 30,
      },
      tooltip: {
        formatter: function (): any {
          let text = ``;
          const self: any = this;
          self.points.forEach((point: any) => {
            const series = point.series;
            const symbol = "●";
            text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b style="color: black">${point.y} ${series.userOptions.valueUnit}</b><br/><span style="color: black"> Source: <b>${series.userOptions.sourceName}</b></span><br/>`;
          });
          return [
            `<span style="color: black">${dayjs(
              self.points[0].x + localOffset
            ).format("YYYY-MM-DD hh:mm:ss A")}</span><br/><br/>`,
            text,
          ];
        },
        valueDecimals: 2,
        shared: true,
        xDateFormat: "%Y-%m-%d %H:%M:%S",
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: true,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    };
    setCollapseItems2([
      {
        key: "h-1",
        label: digitalTwinData.parameterName,
        children: (
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={graphOptions}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
        ),
      },
    ]);
  }

  useEffect(() => {}, []);

  useEffect(() => {
    setCollapseItems([]);
    if (dateFrom && dateTo) {
      handleGetParamData();
      Promise.all(
        newDigitalTwinData.variables.map(async (variable: any, index: any) => {
          let yAxis: any = [];
          let seriesData: any = [];
          const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
          const data = await getReadingsFromDevice(
            variable.parameterId,
            null,
            dayjs(dateFrom).format("YYYY-MM-DD HH:mm:ss"),
            dayjs(dateTo).format("YYYY-MM-DD HH:mm:ss")
          );
          yAxis.push({
            valueUnit: data.valueUnit,
            labels: {
              format: `{value}${data.valueUnit}`,
            },
            title: {
              text: `${data.sensorType}`,
            },
          });
          console.log("🚀 ~ file: Step2.tsx:66 ~ items ~ readings:", data);
          const measurementHistorialValues =
            data.measurementToolHistoricalValues;
          if (measurementHistorialValues) {
            const sources = Object.keys(measurementHistorialValues).map(
              (item) => {
                return measurementHistorialValues[item].serialNumber;
              }
            );
            const uniqueSources = [...new Set(sources)];
            if (uniqueSources.length > 0) {
              uniqueSources.forEach((source) => {
                if (!source) return;
                const keys = Object.keys(measurementHistorialValues);
                const dataSeries = keys
                  .filter(
                    (item) =>
                      measurementHistorialValues[item].serialNumber == source
                  )
                  .map((item) => [
                    parseFloat(item),
                    measurementHistorialValues[item].value,
                  ])
                  .sort((a, b) => {
                    return a[0] - b[0];
                  });
                const read = {
                  name: data.measurementTargetName,
                  sourceName: source,
                  valueUnit: data.valueUnit,
                  sensorType: data.sensorType,
                  yAxis: yAxis.findIndex(
                    ({ valueUnit }: any) => valueUnit === data.valueUnit
                  ),
                  data: [
                    ...dataSeries,
                    [new Date(dateFrom).getTime() - localOffset, null],
                    [new Date(dateTo).getTime() - localOffset, null],
                  ],
                };
                seriesData.push(read);
              });
            } else {
              const read = {
                name: data.measurementTargetName,
                sourceName: "",
                valueUnit: data.valueUnit,
                sensorType: data.sensorType,
                yAxis: yAxis.findIndex(
                  ({ valueUnit }: any) => valueUnit === data.valueUnit
                ),
                data: [
                  [new Date(dateFrom).getTime() - localOffset, null],
                  [new Date(dateTo).getTime() - localOffset, null],
                ],
              };
              seriesData.push(read);
            }
          }
          if (data.measurementToolForecastValues) {
            data.measurementToolForecastValues?.forEach((forecast: any) => {
              const colors = Highcharts.getOptions().colors;
              if (!colors) return;
              const foreData = {
                name: data.measurementTargetName,
                sourceName: data.resume.sourceName,
                valueUnit: data.valueUnit,
                sensorType: data.sensorType,
                yAxis: yAxis.findIndex(
                  ({ valueUnit }: any) => valueUnit === data.valueUnit
                ),
                showInLegend: false,
                color: colors[index],
                dashStyle: "Dash",
                data: forecast
                  ? Object.keys(forecast)
                      .map((item) => [parseFloat(item), forecast[item]])
                      .sort((a, b) => {
                        return a[0] - b[0];
                      })
                  : [],
              };
              seriesData.push(foreData);
            });
          }
          const graphOptions = {
            title: {
              text: "",
            },
            credits: {
              enabled: false,
            },
            chart: {
              type: "line",
              zoomType: "x",
              panKey: "shift",
              height: null,
              plotBorderWidth: 1,
              backgroundColor: "#FFFFFF",
            },
            legend: {
              backgroundColor: "#FFFFFF",
            },
            xAxis: {
              type: "datetime",
              plotBands: [
                {
                  from: new Date(),
                  to: Date.UTC(2024, 1, 1),
                  color: "#EFFFFF",
                },
              ],
              min: new Date(dateFrom).getTime() - localOffset,
              max: new Date(dateTo).getTime() - localOffset,
              ordinal: false,
            },
            yAxis: yAxis,
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              },
            },
            navigator: {
              enabled: true,
              adaptToUpdatedData: true,
              stickToMax: true,
              height: 30,
            },
            tooltip: {
              formatter: function (): any {
                let text = ``;
                const self: any = this;
                self.points.forEach((point: any) => {
                  const series = point.series;
                  const symbol = "●";
                  text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b style="color: black">${point.y} ${series.userOptions.valueUnit}</b><br/><span style="color: black"> Source: <b>${series.userOptions.sourceName}</b></span><br/>`;
                });
                return [
                  `<span style="color: black">${dayjs(
                    self.points[0].x + localOffset
                  ).format("YYYY-MM-DD hh:mm:ss A")}</span><br/><br/>`,
                  text,
                ];
              },
              valueDecimals: 2,
              shared: true,
              xDateFormat: "%Y-%m-%d %H:%M:%S",
            },
            rangeSelector: {
              buttonTheme: {
                style: {
                  display: "none",
                },
              },
              enabled: true,
              dropdown: "always",
              inputDateFormat: "%Y/%m/%d %I:%M %p",
              selected: 1,
            },
            series: seriesData,
          };
          return {
            key: index,
            label: variable.infraName,
            children: (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphOptions}
                  containerProps={{ style: { height: "100%" } }}
                />
              </div>
            ),
          };
        })
      ).then((dataArray: any) => {
        console.log(
          "🚀 ~ file: Step2.tsx:268 ~ )).then ~ dataArray:",
          dataArray
        );
        setCollapseItems(dataArray);
      });
    }
  }, [newDigitalTwinData, dateFrom, dateTo]);

  return (
    <div className="flex column" style={{ height: "100%" }}>
      <div className="flex column">
        <span className="mt-2">Date Range</span>
        <DatePicker.RangePicker
          defaultValue={[dayjs(dateFrom), dayjs(dateTo)]}
          onChange={(dates: null | (Dayjs | null)[], dateStrings: string[]) => {
            setDateFrom(dateStrings[0]);
            setDateTo(dateStrings[1]);
          }}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{ format: "HH:mm:ss" }}
        />
        <Divider orientation="left"></Divider>
        <span className="mb-2" style={{ fontWeight: 700 }}>
          Input Params
        </span>
        <Collapse accordion items={collapseItems} />
        <Divider orientation="left"></Divider>
        <span className="mb-2" style={{ fontWeight: 700 }}>
          Output Params
        </span>
        <Collapse accordion items={collapseItems2} />
      </div>
      <div className="flex mt-auto" style={{ justifyContent: "flex-end" }}>
        <Button
          style={{
            backgroundColor: "#00203C",
            color: "white",
            height: "40px",
          }}
          onClick={() => {
            handleSaveDigitalTwin();
          }}
        >
          Next
        </Button>
        <Button
          className="ml-3"
          style={{
            height: "40px",
          }}
          onClick={() => {
            onBack();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
