import React, { useCallback, useEffect, useRef, useState } from "react";
import InteractiveSimulationMap from "./InteractiveSimulationMap";
import { Tooltip, Popover, Button } from "antd";
import Icon from "@ant-design/icons";

import "./InteractiveSimulation.style.scss";
import dayjs from "dayjs";
import TimePlayer from "../../components/General/TimePlayer";
import { useLocation, useParams } from "react-router-dom";
import InteractiveSimulationService from "../../domain/InteractiveSimulations/InteractiveSimulationService";
import { connect } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { interactiveSimulationsEventsService } from "../../events/interactiveSimulations";
import IterationCompletedModal from "../../components/InteractiveSimulation/IterationCompletedModal";
import GenerateKeplerAnalysisModal from "../../components/InteractiveSimulation/GenerateKeplerAnalysisModal";
import LateralMenu from "../../components/InteractiveSimulation/LateralMenu";
import useWebSocket from "../../hooks/useWebSocket";
import PlayCircleBlueSvg from "../../assets/svg/PlayCircleBlueIconSvg";

Highcharts.setOptions({
  lang: {
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
});

const interactiveSimulationService = new InteractiveSimulationService();

function InteractiveSimulationReplay({ interactiveSimulationData }) {
  //Constants
  const { simulationId } = useParams();
  const [simulationTime, setSimulationTime] = useState([]);
  const [stepTime, setStepTime] = useState(0);
  const [simulationInfo, setSimulationInfo] = useState(null);
  const intervalSimulation = useRef(null);

  //Serie de tiempo
  const chartRef = useRef(null);
  const [graphOptions, setGraphOptions] = useState(null);
  const [highchartVmRef, setHighchartVmRef] = useState(null);

  //Iteration Data
  const [iterationList, setIterationList] = useState([]);
  const [currentIteration, setCurrentIteration] = useState(null);

  //TimePlayer/Socket/Simulation
  const [isRunningIteration, setIsRunningIteration] = useState(false);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [eventsToSend, setEventsToSend] = useState([]);
  const currentTime = useRef(dayjs(simulationTime[0]));
  const [playing, setPlaying] = useState(false);
  const [markers, setMarkers] = useState([]);

  //Replay Data
  const [replayData, setReplayData] = useState([]);

  //Modals
  const [showIterationCompletedModal, setShowIterationCompletedModal] =
    useState(false);

  //Infrastructures
  const [infrasEdited, setInfrasEdited] = useState([]);
  const [infraestructures, setInfraestructures] = useState([]);
  const [selectedIteration, setSelectedIteration] = useState(null);

  //Functions
  const addMarker = (data) => {
    const newMarker = {
      time: dayjs(currentTime.current),
      iterations: [],
      data,
    };
    setMarkers((prevMarkers) => {
      const updatedMarkers = [...prevMarkers, newMarker];
      updatedMarkers.sort((a, b) => a.time - b.time);
      return updatedMarkers;
    });
  };

  const playPause = () => {
    const iterationIndex = iterationList.findIndex((it) => {
      return it.iterationId == currentIteration?.iterationId;
    });

    if (iterationList[iterationIndex].ended) {
      setPlaying(() => {
        return !playing;
      });
    } else {
      setPlaying(() => {
        if (playing) {
          if (isRunningIteration) {
            const userId = localStorage.getItem("userId");
            // Logica para cuando se pause la simulacion
            const body = {
              timeEvent: dayjs(currentTime.current).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              status: "PAUSE",
              events: [],
            };
            console.log("Send pause", body);

            // clientSocket.publish({
            //   destination: `/users/${userId}/simulations/${simulationId}/iterations/${currentIteration?.iterationId}/events`,
            //   body: JSON.stringify(body),
            // });
          }
        }
        return !playing;
      });
    }
  };

  const initGraphOptions = useCallback(() => {
    setGraphOptions({});
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

    const yAxisUnits = new Set();
    let yAxis = [];
    let seriesData = [];

    const from = dayjs(simulationTime[0]).subtract(localOffset, "ms").valueOf();
    const to = dayjs(simulationTime[1]).subtract(localOffset, "ms").valueOf();

    infraestructures.forEach((data) => {
      if (!data) return;

      const { unit } = data.mainMeasurementTarget;
      if (!yAxisUnits.has(unit)) {
        yAxisUnits.add(unit);
        yAxis.push({
          valueUnit: unit,
          labels: {
            format: `{value}${unit}`,
          },
        });
      }

      seriesData.push({
        paramId: data.mainMeasurementTarget.measurementTargetId,
        measurementeInfraestructureId: data.measurementeInfraestructureId,
        name: data.measurementInfraestructureName,
        sourceName: data.mainMeasurementTarget.measurementTargetName,
        valueUnit: unit,
        yAxis: Array.from(yAxisUnits).indexOf(unit),
        data: [
          [from, null],
          [to, null],
        ],
      });
    });

    setGraphOptions({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: null,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
        events: {
          load: function () {
            const vm = this;
            setHighchartVmRef(this);
          },
        },
      },
      legend: {
        backgroundColor: "#FFFFFF",
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: new Date(),
            to: Date.UTC(2025, 1, 1),
            color: "#EFFFFF",
          },
        ],
        // min: dayjs(simulationTime[0])
        //   // .subtract(new Date().getTimezoneOffset(), "minute")
        //   .valueOf(),
        // max: dayjs(simulationTime[1])
        //   .subtract(new Date().getTimezoneOffset(), "minute")
        //   .valueOf(),
        ordinal: false,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: true,
        margin: 5,
        height: 30,
      },
      tooltip: {
        formatter: function () {
          const vm = this;
          let text = `<span>${dayjs(vm.points[0].x).format(
            "YYYY-MM-DD hh:mm:ss A"
          )}</span><br/><br/>`;
          vm.points.forEach((point) => {
            const series = point.series;
            const symbol = "●";
            text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Infrastructure: <b>${series.userOptions.name}</b></span><br/>`;
          });
          return [text];
        },
        valueDecimals: 2,
        shared: true,
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: false,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    });
  }, [infraestructures, simulationTime]);

  function resetGraph() {
    if (highchartVmRef && highchartVmRef.series) {
      let series = highchartVmRef.series;
      series.forEach((serie) => {
        serie.setData(
          [
            [dayjs(simulationTime[0]).valueOf(), null],
            [dayjs(simulationTime[1]).valueOf(), null],
          ],
          true,
          false,
          true
        );
      });
    }
  }

  async function getIterations() {
    const iterationList =
      await interactiveSimulationService.getAllIterationsInteractiveSimulation(
        simulationId
      );
    console.log(
      "🚀 ~ file: InteractiveSimulationReplay.view.jsx:311 ~ getIterations ~ res:",
      iterationList
    );
    setIterationList(
      iterationList.map((iteration) => {
        return {
          iterationId: iteration.id,
          ended: true,
          markers: iteration.infraEvents.reduce((accumulator, currentValue) => {
            return [...accumulator, ...currentValue.events];
          }, []),
        };
      })
    );
  }

  async function getSimulationInfo() {
    const res = await interactiveSimulationService.getSimulationInfo(
      simulationId
    );
    console.log(
      "🚀 ~ file: InteractiveSimulationReplay.view.jsx:281 ~ getSimulationInfo ~ res:",
      [dayjs(res.start_date), dayjs(res.end_date)]
    );
    setSimulationInfo(res);
    setSimulationTime([
      dayjs(res.start_date.substring(0, res.start_date.length - 2)),
      dayjs(res.end_date.substring(0, res.start_date.length - 2)),
    ]);
    setStepTime(res.step);
  }

  async function getDataOfAnIteration() {
    const res = await interactiveSimulationService.getDataOfAnIteration(
      simulationId,
      currentIteration.iterationId
    );
    const data = res
      .filter((it) => {
        return it.partial_request.status == "RUNNING";
      })
      .map((iterationData) => {
        return {
          data: iterationData.partial_response,
          requestData: iterationData.partial_request.events,
          time: dayjs(iterationData.time_response),
        };
      });
    setReplayData(data);

    console.log("getDataOfAnIteration", res);
  }

  function replaySimulation() {
    let index = 0;
    if (intervalSimulation.current) {
      clearInterval(intervalSimulation.current);
    }
    setMarkers([]);
    intervalSimulation.current = setInterval(() => {
      if (index >= replayData.length) {
        currentTime.current = simulationTime[1];
        interactiveSimulationsEventsService.setSubject({
          type: "setCurrentTime",
          data: simulationTime[1],
        });
        clearInterval(intervalSimulation.current);
      } else {
        currentTime.current = dayjs(replayData[index].time);
        interactiveSimulationsEventsService.setSubject({
          type: "setCurrentTime",
          data: dayjs(replayData[index].time),
        });
        interactiveSimulationsEventsService.setSubject({
          type: "dataSocketRecived",
          data: replayData[index].data,
        });
        setHighchartVmRef((currentRef) => {
          if (currentRef && currentRef.series) {
            let dataAdded = [];
            let series = currentRef.series;
            const dataToGraph = replayData[index].data;
            series.forEach((serie) => {
              console.log(
                "🚀 ~ findedIndex ~ serie.userOptions:",
                serie.userOptions
              );
              const findedIndex = dataToGraph.findIndex((item) => {
                return serie.userOptions.paramId == item.paramId;
              });
              if (findedIndex >= 0) {
                let x = dayjs(currentTime.current).valueOf(), // current time
                  y = dataToGraph[findedIndex].value;
                serie.addPoint([x, y], true, false, true);
              } else {
                let x = dayjs(currentTime.current).valueOf(), // current time
                  y = serie.yData[serie.yData.lenght - 2];
                serie.addPoint([x, y], true, false, true);
              }
              if (highchartVmRef && highchartVmRef.xAxis) {
                highchartVmRef.xAxis[0] &&
                  highchartVmRef.xAxis[0].setExtremes(null, null);
                highchartVmRef.xAxis[1] &&
                  highchartVmRef.xAxis[1].setExtremes(null, null);
              }
              const requestData = replayData[index].requestData;
              const indexRequest = requestData.findIndex((item) => {
                return (
                  serie.userOptions.measurementeInfraestructureId ==
                  item.infrastructureId
                );
              });
              if (
                indexRequest >= 0 &&
                requestData[indexRequest].mod_value &&
                !serie.userOptions.id
              ) {
                dataAdded.push({
                  name: serie.userOptions.name,
                  value: requestData[indexRequest].mod_value,
                });
              }
            });
            dataAdded.length > 0 && addMarker(dataAdded);
          }
          return highchartVmRef;
        });
        index++;
        if (highchartVmRef && highchartVmRef.xAxis) {
          highchartVmRef.xAxis[0] &&
            highchartVmRef.xAxis[0].setExtremes(null, null);
          highchartVmRef.xAxis[1] &&
            highchartVmRef.xAxis[1].setExtremes(null, null);
        }
      }
    }, 1000);
  }

  useEffect(() => {
    getSimulationInfo();
    getIterations();
  }, []);

  useEffect(() => {
    if (replayData && replayData.length > 0) {
      replaySimulation();
      resetGraph();
    }
  }, [replayData]);

  useEffect(() => {
    console.log("Is Runnign Iteration", isRunningIteration);
  }, [isRunningIteration]);

  useEffect(() => {
    if (isWaitingResponse) {
      console.log("waiting reponse");
    }
  }, [isWaitingResponse]);

  useEffect(() => {
    interactiveSimulationsEventsService.setSubject({
      type: "setCurrentTime",
      data: currentTime.current,
    });
  }, [currentTime.current]);

  useEffect(() => {
    if (infraestructures.length > 0) {
      initGraphOptions();
    }
  }, [infraestructures]);

  useEffect(() => {
    if (currentIteration) {
      setReplayData(null);
      getDataOfAnIteration();
    }
  }, [currentIteration]);

  return (
    <>
      <InteractiveSimulationMap
        infrasEdited={infrasEdited}
        setInfraestructures={setInfraestructures}
        editValueInfra={(selectedInfra) => {
          setIterationList(() => {
            let iterationsCopy = [...iterationList];
            const iterationIndex = iterationsCopy.findIndex((it) => {
              return it.iterationId == currentIteration?.iterationId;
            });
            let iterationCopy = iterationsCopy[iterationIndex];
            let temporalMarkers = iterationCopy.markers;
            const findedIndex = temporalMarkers.findIndex((marker) => {
              return marker.time == currentTime.current;
            });
            if (findedIndex >= 0) {
              temporalMarkers[findedIndex].sensors.push(selectedInfra);
            } else {
              temporalMarkers.push({
                time: currentTime.current,
                sensors: [selectedInfra],
              });
            }
            iterationCopy.markers = temporalMarkers;
            iterationsCopy[iterationIndex] = iterationCopy;
            return iterationsCopy;
          });
        }}
        iterationId={currentIteration?.iterationId}
      />
      {simulationInfo && (
        <>
          <div
            style={{
              position: "absolute",
              top: "85px",
              left: "10px",
              width: "280px",
              minHeight: "300px",
              flexDirection: "column",
              backgroundColor: "white",
              padding: "15px",
            }}
            className="items-center justify-center"
          >
            <div>
              <span style={{ color: "#023E65", fontWeight: "bold" }}>
                View simulation
              </span>
            </div>
            <div
              className="mb-2"
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#E6E6E6",
              }}
            />
            <span style={{ color: "#023E65" }}>Simulation name</span>
            <div
              className="flex justify-between"
              style={{ color: "#74788D", textAlign: "right" }}
            >
              <span>Location:</span>
              <span
                style={{
                  textOverflow: "ellipsis",
                  width: "140px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {simulationInfo.location}
              </span>
            </div>
            <div
              className="flex justify-between"
              style={{ color: "#74788D", textAlign: "right" }}
            >
              <span>Date:</span>
              <span>{`${dayjs(simulationInfo.start_date).format(
                "YYYY/MM/DD"
              )} - ${dayjs(simulationInfo.end_date).format(
                "YYYY/MM/DD"
              )}`}</span>
            </div>
            <div
              className="flex justify-between"
              style={{ color: "#74788D", textAlign: "right" }}
            >
              <span>INP:</span>
              <Tooltip title={simulationInfo.inp}>
                <span
                  style={{
                    textOverflow: "ellipsis",
                    width: "140px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    cursor: "default",
                  }}
                >
                  {simulationInfo.inp}
                </span>
              </Tooltip>
            </div>
            <div
              className="flex justify-between"
              style={{ color: "#74788D", textAlign: "right" }}
            >
              <span>Execution time:</span> <span></span>
            </div>
            <span style={{ color: "#023E65", fontWeight: "500" }}>
              Iterations:
            </span>
            {iterationList.map((iteration) => {
              return (
                <div className="flex justify-between">
                  <span>Iteration {iteration.iterationId}</span>
                  <Tooltip title="Replay iteration">
                    <Icon
                      component={PlayCircleBlueSvg}
                      className="cursor-pointer"
                      onClick={() => {
                        setCurrentIteration(iteration);
                      }}
                    />
                  </Tooltip>
                </div>
              );
            })}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "5px",
              width: "100vw",
              height: "300px",
              display: currentIteration ? "flex" : "none",
              flexDirection: "column",
            }}
            className="items-center justify-center pa-4"
          >
            <div
              style={{
                width: "600px",
                height: "190px",
                position: "absolute",
                bottom: "75px",
                left: "5px",
              }}
            >
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={graphOptions}
                containerProps={{ style: { height: "100%" } }}
              />
            </div>
            <div
              style={{
                width: "600px",
                height: "65px",
                backgroundColor: "white",
                position: "absolute",
                bottom: "0px",
                left: "5px",
              }}
            >
              <TimePlayer
                startDate={simulationTime[0]}
                endDate={simulationTime[1]}
                stepTime={stepTime}
                markers={markers}
                playPause={playPause}
                restart={() => {
                  replaySimulation();
                  resetGraph();
                }}
                endedSimulation={
                  iterationList.find((it) => {
                    return it.iterationId == currentIteration?.iterationId;
                  })?.ended
                }
                playing={playing}
                iterationNumber={currentIteration?.iterationId || 0}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

function mapState(state) {
  return {
    interactiveSimulationData: state.interactiveSimulation.data,
  };
}

export default connect(mapState)(InteractiveSimulationReplay);
