import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { apiController } from "../../../services/api";
import { Table, Badge, Switch, Card, Button } from "antd";
import Icon, { EnvironmentOutlined } from "@ant-design/icons";
import CountryService from "../../../domain/Country/CountryService";
import LocationsContext from "../../../views/Locations/context";
import TrashIconSvg from "../../../assets/svg/TrashIconSvg";
import type { ColumnsType } from "antd/es/table";
import "./index.scss";
import SelectSearch from "../../Library/SelectSearch";

const countryService = new CountryService();

type Props = {
  displayMode: string;
};

const CountriesByOrganization = ({ displayMode }: Props) => {
  const columnsTable: ColumnsType<CountryOrganization> = [
    {
      title: "Region name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Locations",
      dataIndex: "locationsQuantity",
      key: "locationsQuantity",
    },
    {
      title: "Sub-locations",
      dataIndex: "subLocationsQuantity",
      key: "subLocationsQuantity",
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <div className="flex column">
          <div className="flex">
            <Badge status="success" style={{ marginRight: "5px" }} />
            {record.locationsActive} active
          </div>
          <div>
            <Badge status="default" style={{ marginRight: "5px" }} />
            {record.locationsInactive} inactive
          </div>
        </div>
      ),
    },
    // {
    //   title: "",
    //   key: "active",
    //   render: (_, record) => <Switch defaultChecked onChange={() => {}} />,
    // },
    {
      title: "",
      key: "viewLocations",
      render: (_, record) => (
        <span
          style={{
            color: "#22A2CA",
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
          onClick={() => toDetails(record.id, record.name)}
        >
          View Locations
        </span>
      ),
    },
    // {
    //   title: "",
    //   key: "delete",
    //   render: (_, record) => (
    //     <Icon component={TrashIconSvg} style={{ cursor: "pointer" }} />
    //   ),
    // },
  ];
  const navigate = useNavigate();
  const locationsContext = useContext(LocationsContext);
  const [countries, setCountries] = useState<Country[]>([]);

  async function getCountries() {
    const res = await countryService.getCountries();
    setCountries(res);
  }

  function toDetails(id: string, name: string) {
    navigate({
      pathname: `/locations/${id}`,
      search: `?name=${name}`,
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  return locationsContext.countriesByOrganization.length > 0 ? (
    <div className="w-full flex column">
      <div style={{ width: "200px", marginBottom: "2rem" }}>
        <SelectSearch
          styleDiv={{}}
          placeholder="Search"
          options={countries.map((c) => {
            return { value: c.id, label: c.name };
          })}
          onSelect={(value: any) => {
            console.log(value);
            toDetails(value.value, value.label);
          }}
          fetching={false}
        />
      </div>
      {displayMode === "table" ? (
        <div className="w-full">
          <Table
            columns={columnsTable}
            dataSource={locationsContext.countriesByOrganization}
            rowKey={(item) => item.id}
          />
        </div>
      ) : (
        <div className="w-full card-display__container">
          {locationsContext.countriesByOrganization.map((item) => (
            <Card
              key={item.id}
              cover={
                <div
                  className="flex items-center"
                  style={{
                    padding: "1.5rem",
                    borderBottom: "1px solid #C4CCD5",
                  }}
                >
                  <EnvironmentOutlined
                    style={{
                      marginRight: "10px",
                      fontSize: "1.5rem",
                    }}
                    rev={undefined}
                  />
                  <span style={{ marginRight: "10px" }}>{item.name}</span>
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    style={{ marginLeft: "auto" }}
                    onClick={() => toDetails(item.id, item.name)}
                  >
                    View locations
                  </Button>
                </div>
              }
              style={{ maxWidth: "400px" }}
            >
              <div className="card-content__container">
                <div>Locations</div>
                <div>{item.locationsQuantity}</div>
                <div>
                  <Badge status="success" style={{ marginRight: "5px" }} />{" "}
                  Active
                </div>
                <div>{item.locationsActive}</div>
                <div>Sub-locations</div>
                <div>{item.subLocationsQuantity}</div>
                <div>
                  <Badge status="default" style={{ marginRight: "5px" }} />{" "}
                  Inactive
                </div>
                <div>{item.locationsInactive}</div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div
      className="flex flex-column self-center text-center"
      style={{ maxWidth: "300px" }}
    >
      <span className="text-title-page" style={{ marginBottom: "10px" }}>
        No Regions - yet!
      </span>
      <span className="text-subtitle-page" style={{ marginBottom: "10px" }}>
        Looks that you haven’t select any Region, no worries. Search to start
      </span>
      <div className="self-center" style={{ width: "200px" }}>
        <SelectSearch
          styleDiv={{}}
          placeholder="Search"
          options={countries.map((c) => {
            return { value: c.id, label: c.name };
          })}
          onSelect={(value: any) => {
            console.log(value);
            toDetails(value.value, value.label);
          }}
          fetching={false}
        />
      </div>
    </div>
  );
};

export default CountriesByOrganization;
