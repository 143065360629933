import React, { ComponentType, SVGProps } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import Icon, {
  DesktopOutlined,
  AlertOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import GeneralLayout from "../GeneralLayout";
import { MenuProps } from "rc-menu";
import LocationSvg from "../../assets/svg/LocationSvg";
import ParametersMenuSvg from "../../assets/svg/ParametersMenuSvg";
import SimulationMenuSvg from "../../assets/svg/SimulationMenuSvg";
import UploadFilesSvg from "../../assets/svg/UploadFilesSvg";
import BlockDiagramIconSvg from "../../assets/svg/BlockDiagramIconSvg";
import UserViewSvg from "../../assets/svg/UsersViewSvg";
import "./index.scss";
import KpiSvg from "../../assets/svg/KpiSvg";

const ConfigurationLayout: React.FC = () => {
  const navigate = useNavigate();
  const security = JSON.parse(localStorage.getItem("security") || "{}");
  const showUserManager =
    security.role === "Administrator" || security.role === "SuperAdmin";
  const LocationIcon = () => <Icon component={LocationSvg} rev={undefined} />;
  const UserViewIcon = () => <Icon component={UserViewSvg} rev={undefined} />;
  const ParametersIcon = () => (
    <Icon component={ParametersMenuSvg} rev={undefined} />
  );
  const SimulationsIcon = () => (
    <Icon component={SimulationMenuSvg} rev={undefined} />
  );
  const UploadFilesIcon = () => (
    <Icon component={UploadFilesSvg} rev={undefined} />
  );
  const BlockDiagramIcon = () => (
    <Icon component={BlockDiagramIconSvg} rev={undefined} />
  );
  const KpiIcon = () => <Icon component={KpiSvg} rev={undefined} />;
  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Locations", "1", <LocationIcon />),
    getItem("Upload Files", "2", <UploadFilesIcon />),
    getItem("Infrastructure", "3", <ParametersIcon />),
    getItem("KPIs", "4", <KpiIcon />),
    getItem("Block Diagram", "5", <BlockDiagramIcon />),
    getItem("Simulations", "6", <SimulationsIcon />),
    getItem("Devices", "7", <UploadFilesIcon />),
    getItem("Alerts", "8", <AlertOutlined />),
    getItem("State Machines", "9", <ClusterOutlined />),
  ];

  const itemsBotton: MenuItem[] = [
    getItem("UserManager", "50", <UserViewIcon />),
  ];

  function redirectTo(key: string) {
    switch (key) {
      case "1":
        return navigate("/locations");
      case "2":
        return navigate("/upload");
      case "3":
        return navigate("/infrastructures");
      case "4":
        return navigate("/kpi");
      case "5":
        return navigate("/block/diagram");
      case "6":
        return navigate("simulations");
      case "7":
        return navigate("/devices");
      case "8":
        return navigate("/alerts");
      case "9":
        return navigate("/stateMachines");
      case "50":
        return navigate("/users");
      default:
        break;
    }
  }
  return (
    <GeneralLayout>
      <div style={{ marginBottom: "1rem" }}>
        <h5>CONFIGURATION</h5>
      </div>
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
        style={{ backgroundColor: "#f7f9fc", border: "none" }}
        onClick={({ key }) => redirectTo(key)}
      />
      {showUserManager && (
        <div style={{ marginTop: "auto" }}>
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={itemsBotton}
            style={{ backgroundColor: "#f7f9fc", border: "none" }}
            onClick={({ key }) => redirectTo(key)}
          />
        </div>
      )}
    </GeneralLayout>
  );
};

export default ConfigurationLayout;
