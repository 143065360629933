import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Pagination,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import Icon from "@ant-design/icons";
import {
  ExperimentOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import ModalCreationInteractiveSimulation from "./ModalCreationInteractiveSimulation";
import InteractiveSimulationService from "../../domain/InteractiveSimulations/InteractiveSimulationService";
import PlayCircleSvg from "../../assets/svg/PlayCircleIconSvg";
import { setInteractiveSimulationData } from "../../redux/interactiveSimulationDuck";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import ModalKeplerAnalysis from "./ModalKeplerAnalysis";
import ConfirmationModal from "../Modal/ConfirmationModal";

const interactiveSimulationService = new InteractiveSimulationService();

export default function InteractiveSimulations() {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalRows: 0,
  });
  const [query, setQuery] = useState<null | string>(null);
  const [simulations, setSimulations] = useState<any[]>([]);
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [showKeplerAnalysisModal, setShowKeplerAnalysismodal] = useState(false);
  const [selectedSimulation, setSelectedSimulation] = useState<any>(null);
  const [showConfirmDelete, setshowConfirmDelete] = useState(false);

  const navigate = useNavigate();

  const columns: ColumnsType<InteractiveSimulation> = [
    {
      title: "Simulation Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "INP File",
      dataIndex: "inp",
      key: "inp",
      width: 200,
    },
    {
      title: "Number of iterations",
      dataIndex: "numit",
      key: "numit",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span>{`${record.createdBy}`}</span>
          <span style={{ color: "#74788D", fontSize: "10px" }}>{`${dayjs(
            record.createdAt
          ).format("YYYY/MM/DD [at] hh:mm a")}`}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="flex">
          <Tooltip title="Replay Simulation">
            <Icon
              component={PlayCircleSvg}
              className="cursor-pointer mr-1"
              onClick={() => {
                dispatch(
                  setInteractiveSimulationData(record) as unknown as AnyAction
                );
                navigate(
                  `/interactive-simulation-view/${record.locationId}/${record.id}`
                );
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip title="Kepler analysis">
            <ExperimentOutlined
              style={{ fontSize: "18px" }}
              onClick={() => {
                setSelectedSimulation(record);
                setShowKeplerAnalysismodal(true);
              }}
            />
          </Tooltip>
          {/* <Icon
            component={DeleteOutlined}
            onClick={() => {
              handleDeleteSimulations(record.id);
            }}
          /> */}
          <Tooltip title="Delete">
            <DeleteOutlined
              className="mr-1"
              style={{ fontSize: "18px" }}
              onClick={() => {
                setSelectedSimulation(record);
                setshowConfirmDelete(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleGetSimulations = useCallback(async () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const response =
      await interactiveSimulationService.getInteractiveSimulations({
        organizationId: oui,
        name: query,
        endDate: null,
        startDate: null,
        status: null,
        page: pagination.page,
        size: pagination.pageSize,
      });
    setPagination({
      ...pagination,
      totalRows: response.totalRecords,
    });
    setSimulations(response.data);
  }, [query, pagination.page]);

  const handleDeleteSimulations = async (simulationId: any) => {
    const response =
      await interactiveSimulationService.deleteInteractiveSimulation(
        simulationId
      );
    if (response.success) {
      notification.success({
        message: "Successful",
        description: "Interactive Simulation deleted successfully",
      });
      setshowConfirmDelete(false);
      handleGetSimulations();
    }
  };

  const onChangePagination = (page: any) => {
    console.log("🚀 ~ onChangePagination ~ page:", page);
    setPagination({ ...pagination, page: page });
  };

  const cancelCreation = () => {
    setShowCreationModal(false);
  };

  useEffect(() => {
    console.log(
      "🚀 ~ InteractiveSimulations ~ pagination.page:",
      pagination.page
    );
    handleGetSimulations();
  }, [pagination.page]);

  useEffect(() => {
    if (query == null) {
      const prevPage = pagination.page;
      setPagination({ ...pagination, page: 1 });
      if (prevPage == 1) {
        handleGetSimulations();
      }
    }
  }, [query]);

  return (
    <>
      <div className="flex column">
        {simulations.length > 0 || query != null ? (
          <>
            <div className="flex justify-between mb-3">
              <div className="flex">
                <Input
                  style={{ width: 200 }}
                  placeholder="Search ID"
                  prefix={
                    <SearchOutlined
                      style={{ color: "#078CB3" }}
                      rev={undefined}
                    />
                  }
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setQuery(null);
                    } else {
                      setQuery(e.target.value);
                    }
                  }}
                  onPressEnter={() => {
                    const prevPage = pagination.page;
                    if (prevPage == 1) {
                      handleGetSimulations();
                    } else {
                      setPagination({ ...pagination, page: 1 });
                    }
                    // handleGetSimulations();
                  }}
                  allowClear
                  className="mr-2"
                />
              </div>
              <Button
                type="primary"
                shape="round"
                className="flex items-center self-center"
                style={{
                  backgroundColor: "#023E65",
                  color: "white",
                  width: "108px",
                  height: "40px",
                }}
                icon={<PlusCircleOutlined rev={undefined} />}
                onClick={() => {
                  setShowCreationModal(true);
                }}
              >
                Add new
              </Button>
            </div>
            <Table
              columns={columns}
              dataSource={simulations}
              pagination={false}
              style={{
                maxHeight: "calc(100vh - 420px)",
                overflowX: "auto",
              }}
              bordered
            />
            <Pagination
              style={{ marginTop: "2rem" }}
              onChange={onChangePagination}
              total={pagination.totalRows}
              showSizeChanger={false}
              current={pagination.page}
            />
          </>
        ) : (
          <div
            className="flex column items-center justify-center"
            style={{ height: "calc(100vh - 200px)" }}
          >
            <span
              style={{ fontSize: "20px", fontWeight: "600" }}
              className="text-center"
            >
              No interactive simulations yet
            </span>
            <span style={{ fontSize: "14px" }} className="text-center">
              Start by creating one
            </span>
            <Button
              type="primary"
              shape="round"
              className="flex items-center self-center mt-3"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "108px",
                height: "40px",
              }}
              icon={<PlusCircleOutlined rev={undefined} />}
              onClick={() => {
                setShowCreationModal(true);
              }}
            >
              Add new
            </Button>
          </div>
        )}
      </div>
      <ModalCreationInteractiveSimulation
        showCreationModal={showCreationModal}
        onSuccess={(data: any) => {
          navigate(
            `/interactive-simulation-edit/${data.locationId}/${data.simulationId}`
          );
          setShowCreationModal(false);
          handleGetSimulations();
        }}
        onCancel={cancelCreation}
      />
      <ModalKeplerAnalysis
        showKeplerAnalysisModal={showKeplerAnalysisModal}
        onCancel={() => {
          setShowKeplerAnalysismodal(false);
          setSelectedSimulation(null);
        }}
        simulation={selectedSimulation}
        onSuccess={() => {
          handleGetSimulations();
          setSelectedSimulation((current: any) => {
            return simulations.find(
              (simulation) => simulation.id === current.id
            );
          });
        }}
      />
      <ConfirmationModal
        title="Delete Simulation?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={() => {
          if (selectedSimulation) {
            handleDeleteSimulations(selectedSimulation.id);
          }
        }}
        oncancel={() => {
          setshowConfirmDelete(false);
        }}
      />
    </>
  );
}
