const { Client } = require("@stomp/stompjs");
const { useEffect } = require("react");
const { useState } = require("react");

// Nuevo hook personalizado para manejar WebSocket
const useWebSocket = (url, shouldConnect) => {
  const [clientSocket, setClientSocket] = useState(null);

  useEffect(() => {
    console.log("🚀 ~ file: useWebSocket.jsx:11 ~ useEffect ~ shouldConnect:", shouldConnect)
    if (!shouldConnect) {
      if (clientSocket) {
        clientSocket.deactivate();
        setClientSocket(null);
      }
      return;
    }
    const client = new Client();
    client.configure({
      brokerURL: url,
      debug: (str) => {
        console.log(
          "🚀 ~ file: InteractiveSimulation.view.jsx:200 ~ createClient ~ str:",
          str
        );
      },
      onConnect: () => {
        console.log("onConnect - Setting clientSocket");
        setClientSocket(client);
      },
      onDisconnect: (data) => {
        console.log("onDisconnect - clientSocket");
      },
      onWebSocketError: (error) => {
        console.log("WebSocket ~ error:", error);
      },
    });

    client.activate();

    return () => client.deactivate();
  }, [url]);

  return clientSocket;
};

export default useWebSocket;
