import HydraulicSimulatorRepository from "./HydraulicSimulatorRespository";

export default class HydraulicSimulatorService {
  private hydraulicSimulatorRepository: HydraulicSimulatorRepository =
    new HydraulicSimulatorRepository();

  async postInpAssets(file: string, name: string, others: any): Promise<any> {
    const response = await this.hydraulicSimulatorRepository.postInpAssets(
      file,
      name,
      others
    );
    return response;
  }

  async getAttributesFromAsset(mmsId: string, assetId: string, type: string) {
    const response =
      await this.hydraulicSimulatorRepository.getAttributesFromAsset(
        mmsId,
        assetId,
        type
      );
    return response;
  }

  async getAssetsFromMmsid(mmsId: string) {
    return await this.hydraulicSimulatorRepository.getAssetsFromMmsid(mmsId);
  }

  async postSaveChangesModelFile(mmsId: string, data: any) {
    const response =
      await this.hydraulicSimulatorRepository.postSaveChangesModelFile(
        mmsId,
        data
      );
    return response;
  }
}
