import React, { FC, useState } from "react";
import { CheckCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, message, Upload, Modal, notification } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import DevicesService from "../../domain/Devices/DevicesService";

const devicesService = new DevicesService();

/**
 * @author
 * @function @BulkView
 **/
const { Dragger } = Upload;

const BulkView: FC = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [showSuccesModal, setShowSuccessModal] = useState(false);
    const [uploading, setUploading] = useState(false);

    const props: UploadProps = {
        name: "file",
        accept: ".txt",
        maxCount: 1,
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
            setFileList([file]);

            return false;
        },
        fileList,
    };

    const timerControl = () => {
        setShowSuccessModal(true);
        const timer = setTimeout(() => {
            setShowSuccessModal(false);
        }, 1000);
        return () => clearTimeout(timer);
    };

    const blobToBase64 = async (blob: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                if (reader.result) {
                    const result = reader.result as string;
                    resolve(result.split(",")[1]);
                } else {
                    resolve("");
                }
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleUpload = async () => {
        const oui = JSON.parse(
            localStorage.getItem("security") || ""
        ).organizationId;

        console.log("fileList", fileList.length);

        const data = {
            userId: localStorage.getItem("userId") || "",
            organizationId: oui,
            file: "",
            // fileName: fileList[0].name.split(".")[0],
            fileName: "",
        } as Iot;

        await fileList.map(async (files, index) => {
            console.log("index", index);
            console.log("files.preview", files.preview);
            data.file = await blobToBase64(files);
            data.fileName = files.name.split(".")[0];

            if (fileList.length - 1 == index) {
                const uploadFile = await devicesService.uploadIotBulk(data);
                if(uploadFile.success){
                    console.log('uploadFile', uploadFile)
                    setFileList([])
                    timerControl()
                }
                else{
                    openNotification(uploadFile.message)
                }
            } else {
                console.log("No upload");
            }
        });
    };

    const openNotification = (message:any) => {
        notification.open({
            message: "Error",
            description: <div dangerouslySetInnerHTML={{__html: message}} />,
            type: 'error',
        });
    };

    return (
        <div>
            <div className="text-title-page">Bulk Devices</div>
            <div className="mt-3">
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined rev={undefined} />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                    {/* <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                    </p> */}
                </Dragger>
                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                >
                    {uploading ? "Uploading" : "Start Upload"}
                </Button>
            </div>
            <Modal
                open={showSuccesModal}
                footer={[]}
                closable={false}
                style={{ maxWidth: "180px" }}
            >
                <div className="flex column items-center">
                    <span
                        className="mt-2"
                        style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: 15,
                            lineHeight: "14px",
                            display: "flex",
                            color: "#34C38F",
                        }}
                    >
                        <CheckCircleOutlined
                            className="mr-1"
                            style={{ fontSize: 25 }} rev={undefined}                        />
                        File have <br></br> been saved
                    </span>
                </div>
            </Modal>
        </div>
    );
};

export default BulkView;
