import UserRepository from "./UserRepository";

export default class UserService {
  private userRepository: UserRepository = new UserRepository();

  async getAllUsers(
    organizationId: any,
    page: number,
    size: number,
    username: string | null,
    roleId: string | number | null
  ): Promise<Users> {
    return this.userRepository.getAllUsers(
      organizationId,
      page,
      size,
      username,
      roleId
    );
  }

  async createUser(data: any): Promise<boolean> {
    return this.userRepository.createUser(data);
  }

  async deleteUser(user: AllUser): Promise<boolean> {
    return this.userRepository.deleteUser(user.userId);
  }

  async getRole(): Promise<Role> {
    return this.userRepository.getRole();
  }

  async getModules(): Promise<Module> {
    return this.userRepository.getModules();
  }

  async getOrganizations(): Promise<Organization> {
    return this.userRepository.getOrganizations();
  }

  async getLocationsIdsByUser(userId: any): Promise<any> {
    return this.userRepository.getLocationsIdsByUser(userId);
  }

  async postLocationsUser(userId: any, data: any): Promise<any> {
    return this.userRepository.postLocationsUser(userId, data);
  }
}
