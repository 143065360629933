import { api, apiController, restartApiCotroller } from "./api";

const getParameters = async (
  organizationId: any,
  options: any = {
    page: 1,
    size: 10,
    name: null,
    inUse: null,
    location: null,
    infraTypeId: null,
  }
) => {
  try {
    restartApiCotroller();
    const response = await api.get(
      `/measurements/infrastructures/organizations/${organizationId}`,
      {
        params: {
          page: options.page,
          size: options.size,
          name: options.name || null,
          inUse: options.inUse || null,
          locationId: options.location || null,
          infraTypeId: options.infraTypeId || null,
        },
        signal: apiController.signal,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getParameters };
