import React, { useState, useEffect, useRef, useContext } from "react";
import Icon from "@ant-design/icons";
import PauseCircleSvg from "../../assets/svg/PauseCircleIconSvg";
import PlayCircleSvg from "../../assets/svg/PlayCircleIconSvg";
import RestartCircleSvg from "../../assets/svg/RestartCircleIconSvg";
import dayjs, { utc } from "dayjs";
import { interactiveSimulationsEventsService } from "../../events/interactiveSimulations";
import { Button, Popover, Tooltip } from "antd";
import TourInteractiveSimulationContext from "../../views/Simulations/Context";

const TimePlayer = ({
  playing,
  playPause,
  restart,
  startDate,
  endDate,
  stepTime,
  markers,
  endedSimulation = false,
  setPlayButtonRef,
  iterationNumber,
  isReplaying,
  isWaitingResponse,
}) => {
  const playButtonRef = useRef(null);
  const subscriptionIS$ = interactiveSimulationsEventsService.getSubject();
  const [progress, setProgress] = useState(0);
  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const [currentTime, setCurrentTime] = useState(new Date());
  const { value: context, setValue: setContext } = useContext(
    TourInteractiveSimulationContext
  );

  useEffect(() => {
    const endTime = dayjs(endDate);
    const startTime = dayjs(startDate);
    const currentTimeObj = dayjs(currentTime);
    const step = stepTime;

    const timeDifference = endTime.valueOf() - startTime.valueOf();
    const totalSteps = timeDifference / (step * 60 * 1000);
    const stepProgress = 100 / totalSteps;

    const currentTimeDifference =
      currentTimeObj.valueOf() - startTime.valueOf();
    const currentSteps = currentTimeDifference / (step * 60 * 1000);
    const currentProgress = currentSteps * stepProgress;

    setProgress(Math.min(currentProgress, 100));
  }, [currentTime, endDate, startDate, stepTime]);

  useEffect(() => {
    subscriptionIS$.subscribe((event) => {
      if (event.type == "setCurrentTime") {
        setCurrentTime(event.data);
      }
    });
  }, []);

  return (
    <div className="flex column p-1" style={{ width: "100%" }}>
      <div className="flex justify-between mb-1">
        <div className="flex items-center">
          <span
            className="mr-3"
            style={{ color: "#023E65", fontSize: "16px", fontWeight: "600" }}
          >
            Iteration {iterationNumber}
          </span>

          {endedSimulation ? (
            <Tooltip title="Restart">
              <Icon
                component={RestartCircleSvg}
                className="cursor-pointer"
                disabled={isReplaying}
                onClick={() => {
                  restart();
                }}
              />
            </Tooltip>
          ) : playing ? (
            <div className="flex">
              <Tooltip title="Pause">
                <Icon
                  component={PauseCircleSvg}
                  className="cursor-pointer mr-2"
                  onClick={() => {
                    playPause();
                  }}
                />
              </Tooltip>
              {isWaitingResponse && (
                <span style={{ color: "#078CB3" }}>
                  waiting for response...
                </span>
              )}
            </div>
          ) : (
            <Tooltip title="Play">
              <Popover
                placement="bottom"
                color="#023E65"
                overlayStyle={{ maxWidth: "190px" }}
                open={context.showTour && context.activeStep == 3}
                content={
                  <div className="flex column">
                    <div className="flex column text-white">
                      <span>
                        if you do not want to edit any infrastructure at startup
                        you can press play to launch the simulation with the
                        default settings
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <Button
                        type="text"
                        style={{ color: "white" }}
                        onClick={() => {
                          setContext({ ...context, activeStep: 2 });
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                }
              >
                <Icon
                  component={PlayCircleSvg}
                  className="cursor-pointer mr-2"
                  onClick={() => {
                    playPause();
                    setContext({ ...context, activeStep: 7 });
                  }}
                  ref={setPlayButtonRef}
                />
              </Popover>
            </Tooltip>
          )}

          {/* <span
            onClick={() => {
              addMarker();
            }}
          >
            Add marker
          </span> */}
        </div>
        <div className="ml-2">
          {/* {formatDateTime(currentTime)} / {TOTAL_TIME} */}
          Date: {`${dayjs(currentTime).format("DD/MM/YYYY HH:mm:ss")}`}
        </div>
      </div>
      <div className="flex items-center">
        <span className="mr-2">{`${dayjs(startDate).format("HH:mm:ss")}`}</span>
        <div
          style={{
            position: "relative",
            height: "5px",
            backgroundColor: "#E6E6E6",
            width: "500px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              height: "5px",
              width: `${progress}%`,
              backgroundColor: "#88D8F6",
            }}
          />
          {markers.map((marker, index) => (
            <Tooltip
              placement="top"
              title={
                <div className="flex column">
                  <span>
                    {dayjs(marker.time).format("YYYY/MM/DD [at] hh:mm a")}
                  </span>
                  {marker.data &&
                    marker.data.map((data) => {
                      return (
                        <span>
                          {data.name}: {data.value}
                        </span>
                      );
                    })}
                </div>
              }
            >
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${Math.min(
                    ((marker.time.valueOf() - dayjs(startDate).valueOf()) /
                      (dayjs(endDate).valueOf() - dayjs(startDate).valueOf())) *
                      100,
                    100
                  )}%`,
                  height: "7px",
                  width: "7px",
                  borderRadius: "50%",
                  backgroundColor: "#023E65",
                }}
              />
            </Tooltip>
          ))}
        </div>
        <span className="ml-2">{`${dayjs(endDate).format("HH:mm:ss")}`}</span>
      </div>

      <div className="flex ml-4"></div>
      {/* <button onClick={addMarker}>Add Marker</button> */}
      {/* <button onClick={playPause}>{playing ? "Pause" : "Play"}</button>
      <button onClick={restart}>Restart</button> */}
    </div>
  );
};

export default TimePlayer;
