import { api } from "../services/api";
// constants
let initialData = {
  fetching: false,
  array: null,
  selectedLocation: null,
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL;
// let access_token = localStorage.getItem("accessToken");
// let userId = localStorage.getItem("userId");

let GET_LOCATIONS = "GET_LOCATIONS";
let GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
let GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";
let SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";

// reducer
export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_LOCATIONS:
      return { ...state, fetching: true };
    case GET_LOCATIONS_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_LOCATIONS_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export let getLocationsAction = (userId) => (dispatch, getState) => {
  dispatch({
    type: GET_LOCATIONS,
  });
  return api
    .get(`${URL}/users/${userId}/locations`)
    .then((res) => {
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_LOCATIONS_ERROR,
        payload: err.response.message,
      });
    });
};

export let setSelectedLocation = (locationId) => (dispatch) => {
  if (locationId) {
    dispatch({
      type: SET_SELECTED_LOCATION,
      payload: locationId.toString(),
    });
  }
};
