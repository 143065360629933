import React from "react";
import Icon from "@ant-design/icons";
import RestartCircleSvg from "../../assets/svg/RestartCircleIconSvg";
import { Button, Modal } from "antd";

export default function IterationCompletedModal({ show, onAccept }) {
  return (
    <Modal title="Iteration completed" open={show} footer={[]} closable={false}>
      <div className="flex column">
        <span>
          The iteration is finished, if you wish to preview it, click on the
          following icon
          <Icon component={RestartCircleSvg} /> in the timeline section.
        </span>
        <div className="flex self-center mt-2">
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={() => {
              onAccept();
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
}
