import React, { useState } from "react";

const initialContext = {
  value: {
    activeStep: 1,
    showTour: false,
  },
  setValue: () => {},
};

const TourInteractiveSimulationContext = React.createContext(initialContext);

export default TourInteractiveSimulationContext;
