import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  DatePickerProps,
  Divider,
  Dropdown,
  Layout,
  Popover,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import type { MenuProps } from "antd";
import Locations from "../../components/Locations/Locations/Locations";
import SelectLocationsAntd from "../../components/General/SelectLocationsAntd";
import LocationService from "../../domain/Location/LocationService";
import Icon, {
  CalendarOutlined,
  SettingOutlined,
  UserOutlined,
  SyncOutlined,
  AreaChartOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";

import HomeSvg from "../../assets/svg/HomeSvg";
import BellSvg from "../../assets/svg/BellSvg";
import ArrowDropdownSvg from "../../assets/svg/ArrowDropdownSvg";
import DashboardContext from "./context";
import "./index.scss";
import { connect, useDispatch } from "react-redux";
import { getActivitiesAction } from "../../redux/activitiesDuck";
import { AnyAction } from "redux";
import ParamsCard from "../../components/Layouts/DashboardLayout/ParamsCard";
import Notifications from "./Notifications";
import { setSelectedLocation } from "../../redux/locationsDuck";
import LogoutIconSvg from "../../assets/svg/LogoutIconSvg";
import ModalExit from "../../components/Modal";
import BlockDiagramIconSvg from "../../assets/svg/BlockDiagramIconSvg";
import BlockDiagramCard from "../../components/Layouts/DashboardLayout/BlockDiagramCard";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useLocation } from "react-router-dom";
import OrganizationIconSvg from "../../assets/svg/OrganizationIconSvg";
import { getAllList } from "../../redux/readingsResumesDuck";
import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import ChatBotRepository from "../../domain/ChatBot/ChatBotReposiory";
import ChatbotSvg from "../../assets/svg/ChatbotSvg";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const HydrodigitalLogo = require("../../assets/img/hydrodigital.svg").default;

const { Header, Content } = Layout;
const chatbotRepository = new ChatBotRepository();
const parsedSensors: any = [];
const seen = new Set();
const sensores = parsedSensors
  .map((item: string) => JSON.parse(item))
  .filter((item: any) => {
    const tuple = JSON.stringify([
      item.originalParameterId,
      item.originalSourceSerialNumber,
    ]);
    // Si el set ya contiene la tupla, este objeto ya fue visto
    if (seen.has(tuple)) {
      return false;
    }
    // Si no, agrega la tupla al set y devuelve verdadero para mantener este objeto
    seen.add(tuple);
    return true;
  });

function roundToNearest5Minutes(date: any) {
  const dt = dayjs(date);
  const minutes = dt.minute();
  const roundedMinutes = Math.round(minutes / 5) * 5;
  return dt.minute(roundedMinutes).second(0);
}

const hoy = new Date();
const semana = 1000 * 60 * 60 * 24 * 1;
const fec_init = hoy.getTime() - semana;
const fec_end = hoy.getTime() + semana;

const calendarFormat = "YYYY-MM-DD h:mm a";

const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

const globalDate = localStorage.getItem("globalDate");
const initialContext = {
  showNotifications: false,
  showParamsCard: false,
  isExpandedParamsCard: false,
  selectedSensors: sensores,
  selectedLocation: null,
  infraToShow: "",
  expandLeftMenu: false,
  selectedDate: globalDate
    ? [dayjs(globalDate.split("***")[0]), dayjs(globalDate.split("***")[1])]
    : [roundToNearest5Minutes(fec_init), roundToNearest5Minutes(fec_end)],
};

const locationService = new LocationService();
type Props = {
  children: React.ReactNode;
  activities: any[];
  security: any;
  // onChange?: (dates: [dayjs.Dayjs, dayjs.Dayjs]) => void;
};

const DashboardLayout = ({
  children,
  activities,
  security,
}: // onChange,
Props) => {
  const companyName = security.organizationName;
  const name = `${security.firstname} ${security.lastname}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [contextValue, setContextValue] = useState<any>(initialContext);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [showInputCalendar, setShowInputCalendar] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState<any>(null);

  const [locations, setLocations] = useState<Location[]>([]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const [startDate, setStartDate] = useState<any>(
    dayjs(contextValue.selectedDate[0])
  );

  const [searchValues, setSearchValues] = useState<any>();
  const [endDate, setEndDate] = useState<any>(
    dayjs(contextValue.selectedDate[1])
  );
  const [selectedTime1, setSelectedTime1] = useState<dayjs.Dayjs>(
    dayjs(contextValue.selectedDate[0])
  );
  const [selectedTime2, setSelectedTime2] = useState<dayjs.Dayjs>(
    dayjs(contextValue.selectedDate[1])
  );

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "years") >= 2;
    const tooEarly = dates[1] && dates[1].diff(current, "years") >= 2;
    return !!tooEarly || !!tooLate;
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex cursor-pointer"
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          <Icon component={LogoutIconSvg} className="mr-2" rev={undefined} />
          <span style={{ color: "#F46A6A" }}>Logout</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/organizations");
          }}
          style={{ display: security.role == "SuperAdmin" ? "flex" : "none" }}
        >
          <Icon
            component={OrganizationIconSvg}
            className="mr-2"
            rev={undefined}
          />
          <span style={{ color: "#00203C" }}>Organization Manager</span>
        </div>
      ),
    },
  ];

  const handleClickSelect = (value: any) => {
    const selectedValue = value;
    setContextValue({ ...contextValue, selectedLocation: selectedValue });
  };

  const handleOpenBlockDialogChange = (newOpen: boolean) => {
    setOpenBlockDialog(newOpen);
  };
  useEffect(() => {
    addResponseMessage(
      "Hello, my name is Hydrobot and I am your virtual assistant. How can I help you?"
    );
  }, []);

  const handleNewUserMessage = async (query: string) => {
    try {
      const locationIdString = localStorage.getItem("Location");
      if (locationIdString === null) {
        throw new Error("Location ID is not available in localStorage");
      }
      const locationId = parseInt(locationIdString, 10);
      const response = await chatbotRepository.postChatbot(query, locationId);
      addResponseMessage(response.response);
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      addResponseMessage("Sorry, there was an error processing your message.");
    }
  };
  function handleCambioFecha(item: any) {
    setContextValue({
      ...contextValue,
      selectedDate: [
        dayjs(item[0]).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(item[1]).format("YYYY-MM-DD HH:mm:ss"),
      ],
    });
    setShowCalendar(false);
  }

  function formatDate(date: any) {
    let dateFormat;
    let timeFormat;

    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      seconds = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (seconds.length < 2) seconds = "0" + seconds;

    dateFormat = [year, month, day].join("-");
    timeFormat = [hour, minute, seconds].join(":");

    return dateFormat + " " + timeFormat;
  }

  const handleCalendarClick = () => {
    setShowInputCalendar(!showInputCalendar);
  };

  const handleSearch = () => {
    setStartDate(
      startDate
        .set("hour", selectedTime1.hour())
        .set("minute", selectedTime1.minute())
    );

    setEndDate(
      endDate
        .set("hour", selectedTime2.hour())
        .set("minute", selectedTime2.minute())
    );

    setSearchValues([startDate, endDate]);

    handleCambioFecha([startDate, endDate]);
  };
  const getCustomLauncher = (
    handleToggle: React.MouseEventHandler<HTMLButtonElement> | undefined
  ) => (
    <button
      style={{
        backgroundColor: "transparent",
      }}
      onClick={handleToggle}
    >
      <Tooltip placement="left" title="Chatbot">
        <Badge count={activities.length}>
          <Icon
            component={ChatbotSvg}
            className="cursor-pointer"
            style={{
              fontSize: "16px",
              margin: "23px 0px 0px 6px",
            }}
            rev={undefined}
          />
        </Badge>
      </Tooltip>
    </button>
  );
  const handleRangePickerChange = (
    dates: any,
    dateStrings: [string, string]
  ) => {
    console.log("🚀 ~ dates:", dates);
    setEndDate(
      dates
        ? dayjs(dates[1])
            .set("hour", selectedTime2.hour())
            .set("minute", selectedTime2.minute())
        : null
    );
    setStartDate(
      dates
        ? dayjs(dates[0])
            .set("hour", selectedTime1.hour())
            .set("minute", selectedTime1.minute())
        : null
    );
    setSearchValues(dateStrings);
  };

  const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).format(calendarFormat)} `;

  const handleTime1Change = (time: dayjs.Dayjs | null) => {
    if (time) {
      const parsedTime = time.set("minute", Math.round(time.minute() / 5) * 5);
      setSelectedTime1(parsedTime);
      // console.log("starDate", startDate);
      // Actualizar la hora seleccionada en el startDate y endDate
      setStartDate(
        startDate
          .set("hour", parsedTime.hour())
          .set("minute", parsedTime.minute())
      );

      // setSearchValues(startDate, endDate);
      // console.log("time", time);
      // Si se proporciona la prop onChange, se llama a la función para informar sobre el cambio
      // if (onChange) {
      //     onChange([startDate, endDate]);
      // }
    }
  };

  // Función que se ejecuta cuando se selecciona una hora en el segundo TimePicker
  const handleTime2Change = (time: dayjs.Dayjs | null) => {
    if (time) {
      const parsedTime = time.set("minute", Math.round(time.minute() / 5) * 5);
      setSelectedTime2(parsedTime);
      // Actualizar la hora seleccionada en el startDate y endDate
      setEndDate(
        endDate
          .set("hour", parsedTime.hour())
          .set("minute", parsedTime.minute())
      );
      // Si se proporciona la prop onChange, se llama a la función para informar sobre el cambio
      // if (onChange) {
      //     onChange([startDate, endDate]);
      // }
    }
  };

  const handleReset = async () => {
    await (() => {
      setStartDate(dayjs().subtract(1, "day"));
      setEndDate(dayjs().add(1, "day"));
      setSelectedTime1(dayjs());
      setSelectedTime2(dayjs());
    })();
    setSearchValues([dayjs().subtract(1, "day"), dayjs().add(1, "day")]);

    handleCambioFecha([dayjs().subtract(1, "day"), dayjs().add(1, "day")]);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
    if (contextValue.selectedSensors.length > 0) {
      dispatch(
        getAllList(
          contextValue.selectedSensors,
          contextValue.selectedDate[0],
          contextValue.selectedDate[1]
        ) as unknown as AnyAction
      );
    }
    if (contextValue.selectedDate) {
      setDates([
        dayjs(contextValue.selectedDate[0])
          .set("hour", selectedTime1.hour())
          .set("minute", selectedTime1.minute()),
        dayjs(contextValue.selectedDate[1])
          .set("hour", selectedTime2.hour())
          .set("minute", selectedTime2.minute()),
      ]);
    }
  }, []);

  useEffect(() => {
    const find: any = locations.find(
      (loc: any) => loc.locationId == contextValue.selectedLocation
    );
    if (find && find.defaultSelected) {
      localStorage.setItem(
        "selectedSensors",
        JSON.stringify(
          contextValue.selectedSensors.map((sensor: any) => {
            return JSON.stringify(sensor);
          })
        )
      );
    }

    // localStorage.setItem(
    //   "selectedSensors",
    //   JSON.stringify(
    //     contextValue.selectedSensors.map((sensor: any) => {
    //       return JSON.stringify(sensor);
    //     })
    //   )
    // );

    if (contextValue.selectedSensors.length > 0) {
      setContextValue({ ...contextValue, showParamsCard: true });
    } else {
      setContextValue({ ...contextValue, showParamsCard: false });
    }
  }, [contextValue.selectedSensors]);

  useEffect(() => {
    if (contextValue.selectedLocation) {
      localStorage.setItem("Location", contextValue.selectedLocation);
      dispatch(
        getActivitiesAction(
          contextValue.selectedLocation
        ) as unknown as AnyAction
      );
      dispatch(
        setSelectedLocation(
          contextValue.selectedLocation
        ) as unknown as AnyAction
      );
    }
  }, [contextValue.selectedLocation]);

  useEffect(() => {
    try {
      localStorage.setItem(
        "globalDate",
        formatDate(contextValue.selectedDate[0]) +
          "***" +
          formatDate(contextValue.selectedDate[1])
      );
    } catch (error) {
      console.error(error);
    }
  }, [contextValue.selectedDate]);
  return (
    <DashboardContext.Provider
      value={{
        value: contextValue,
        setValue: setContextValue,
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Layout
          className="site-layout"
          style={{ background: "white", overflowY: "auto" }}
        >
          <Content style={{ position: "relative", overflow: "hidden" }}>
            <Header
              style={{
                padding: 0,
                background: "transparent",
                height: "76px",
                zIndex: "9",
                position: "absolute",
                top: "0px",
                width: "100%",
              }}
              className="flex justify-between"
            >
              <div
                className="flex items-center"
                style={{
                  backgroundColor: "white",
                  margin: "10px",
                  borderRadius: "8px",
                  padding: "0px 10px",
                }}
              >
                <img
                  src={HydrodigitalLogo}
                  alt="logo"
                  style={{ width: "143px", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <div
                  style={{
                    width: "1px",
                    height: "23px",
                    backgroundColor: "#E6E6E6",
                  }}
                  className="ml-2 mr-2"
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#434B56",
                    // width: "-webkit-fill-available",
                    textAlign: "center",
                    lineHeight: "20px",
                  }}
                >
                  {companyName}
                </span>
                <div
                  style={{
                    width: "1px",
                    height: "23px",
                    backgroundColor: "#E6E6E6",
                  }}
                  className="ml-2 mr-2"
                />
                <SelectLocationsAntd
                  aditionalStyle={{}}
                  handleClickSelect={handleClickSelect}
                  size="large"
                />
                {/* <Locations
                  locations={locations}
                  handleClickSelect={handleClickSelect}
                  defaultValue={true}
                /> */}
              </div>
              <div
                className="flex items-center"
                style={{
                  margin: "10px 0px 10px 10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "0px 10px",
                    height: "100%",
                  }}
                  className="mr-1"
                >
                  <Space>
                    <Tooltip placement="left" title="Calendar">
                      <CalendarOutlined
                        onClick={handleCalendarClick}
                        style={{
                          color: "#74788D",
                          fontSize: "20px",
                        }}
                        rev={undefined}
                      />
                    </Tooltip>
                    {showInputCalendar ? (
                      <RangePicker
                        onClick={() => {
                          setShowCalendar(!showCalendar);
                        }}
                        style={{ width: 350 }}
                        separator={"|"}
                        value={[startDate, endDate]}
                        open={showCalendar}
                        renderExtraFooter={() => (
                          <div>
                            <div
                              style={{
                                marginLeft: -118,
                                marginTop: -70,
                                width: 115,
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: -15,
                                  borderTop: "1px solid #c5c6c7",
                                  height: 2,
                                  maxWidth: 135,
                                  padding: 0,
                                }}
                              ></div>
                              <div className="flex column mb-1">
                                <span
                                  className="font-bold"
                                  style={{
                                    color: "#232B36",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Start Hour
                                </span>
                                <div>
                                  <TimePicker
                                    value={selectedTime1}
                                    placeholder="Start hour"
                                    format={"h:mm a"}
                                    style={{
                                      width: 100,
                                    }}
                                    use12Hours
                                    onChange={handleTime1Change}
                                    minuteStep={5}
                                  />
                                </div>
                              </div>
                              <div className="flex column mb-1 font-bold">
                                <span
                                  className="font-bold"
                                  style={{
                                    color: "#232B36",
                                    fontWeight: "bold",
                                  }}
                                >
                                  End Hour
                                </span>
                                <div>
                                  <TimePicker
                                    value={selectedTime2}
                                    placeholder="End hour"
                                    format={"h:mm a"}
                                    style={{
                                      width: 100,
                                    }}
                                    use12Hours
                                    onChange={handleTime2Change}
                                    minuteStep={5}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex pb-2"
                              style={{
                                float: "right",
                                marginTop: -45,
                                marginBottom: 5,
                                marginRight: 20,
                              }}
                            >
                              <Button
                                onClick={() => setShowCalendar(false)}
                                style={{
                                  width: "85px",
                                  height: "40px",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleSearch}
                                style={{
                                  marginLeft: "5px",
                                  width: "85px",
                                  height: "40px",
                                  backgroundColor: "#00203C",
                                  color: "white",
                                }}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                        bordered={false}
                        format={customWeekStartEndFormat}
                        presets={rangePresets}
                        onChange={handleRangePickerChange}
                        disabledDate={disabledDate}
                        // onBlur={(blured) => {
                        //   console.log("🚀 ~ blured:", blured);
                        // }}
                        // onFocus={(focused) => {
                        //   console.log("🚀 ~ focused:", focused);
                        // }}
                        onCalendarChange={(val: any) => {
                          // console.log("🚀 ~ val:", val);
                          if (val) {
                            setDates((prev: any) => {
                              const init = dayjs(val[0])
                                .set("hour", selectedTime1.hour())
                                .set("minute", selectedTime1.minute());
                              const end = dayjs(val[1])
                                .set("hour", selectedTime2.hour())
                                .set("minute", selectedTime2.minute());
                              // if (prev[1] && prev[1] != end) {
                              //   handleSearch();
                              // }
                              return [init, end];
                            });
                          } else {
                            setDates([null, null]);
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <Tooltip placement="left" title="Reset calendar">
                      <SyncOutlined
                        style={{
                          color: "#74788D",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleReset();
                        }}
                        rev={undefined}
                      />
                    </Tooltip>
                  </Space>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    marginRight: "5px",
                    paddingRight: "10px",
                    height: "100%",
                  }}
                  className="flex items-center"
                >
                  <Tooltip placement="left" title="Home">
                    <Icon
                      component={HomeSvg}
                      className="cursor-pointer"
                      style={{
                        fontSize: "20px",
                        marginRight: "1px",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                      rev={undefined}
                    />
                  </Tooltip>
                  <Popover
                    content={
                      <div
                        style={{
                          width: "300px",
                          maxHeight: "300px",
                          overflowX: "auto",
                        }}
                      >
                        <BlockDiagramCard
                          onClickRow={() => {
                            setOpenBlockDialog(false);
                          }}
                        />
                      </div>
                    }
                    title="Select a Diagram Block"
                    open={openBlockDialog}
                    onOpenChange={handleOpenBlockDialogChange}
                    trigger="click"
                  >
                    <Tooltip placement="left" title="Blocks diagram">
                      <Icon
                        component={BlockDiagramIconSvg}
                        style={{
                          fontSize: "20px",
                          marginRight: "16px",
                          cursor: "pointer",
                        }}
                        rev={undefined}
                      />
                    </Tooltip>
                  </Popover>
                  {/* <Icon
                    component={DashboardSvg}
                    style={{ fontSize: "20px" }}
                    rev={undefined}
                  /> */}
                  <Tooltip placement="left" title="MRO reports">
                    <AreaChartOutlined
                      style={{ fontSize: "20px", marginLeft: "10px" }}
                      onClick={() => {
                        navigate("/mro-reports");
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="left" title="Nrw Dashboard">
                    <FundProjectionScreenOutlined
                      style={{ fontSize: "20px", marginLeft: "10px" }}
                      onClick={() => {
                        navigate("/nrw");
                      }}
                    />
                  </Tooltip>
                  <div
                    style={{
                      width: "1px",
                      height: "23px",
                      backgroundColor: "#E6E6E6",
                    }}
                    className="ml-2 mr-2"
                  />
                  <Tooltip placement="left" title="Notifications">
                    <Badge count={activities.length}>
                      <Icon
                        component={BellSvg}
                        className="cursor-pointer"
                        style={{
                          fontSize: "20px",
                          marginRight: "5px",
                        }}
                        onClick={() => {
                          setContextValue({
                            ...contextValue,
                            showNotifications: true,
                          });
                        }}
                        rev={undefined}
                      />
                    </Badge>
                  </Tooltip>
                  <Widget
                    emojis={true}
                    title="HydroBot Assistant"
                    launcher={(handleToggle: any) =>
                      getCustomLauncher(handleToggle)
                    }
                    handleNewUserMessage={handleNewUserMessage}
                  />
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "180px",
                        padding: "0px 10px",
                        height: "40px",
                        borderRadius: "52px",
                        border: "solid 1px #C4CCD5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      <Avatar
                        size={24}
                        icon={<UserOutlined rev={undefined} />}
                        className="mr-1"
                      />
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "120px",
                        }}
                      >
                        {name}
                      </span>
                      <ArrowDropdownSvg />
                    </div>
                  </Dropdown>
                  <Tooltip placement="left" title="Settings">
                    <SettingOutlined
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/locations");
                      }}
                      rev={undefined}
                    />
                  </Tooltip>
                </div>
              </div>
            </Header>
            <Outlet />
          </Content>
          {location.pathname == "/" && <ParamsCard />}
        </Layout>
        {contextValue.showNotifications && (
          <Notifications activities={activities} />
        )}
      </Layout>
      {showLogoutModal && <ModalExit closeModal={setShowLogoutModal} />}
    </DashboardContext.Provider>
  );
};

function mapState(state: any) {
  return {
    activities: state.activities.array,
    security: state.security.array,
  };
}

export default connect(mapState)(DashboardLayout);
