import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Pagination, Table, Tag } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import DevicesService from "../../domain/Devices/DevicesService";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { iotEventsService } from "../../events/iot-events.subject";

const devicesService = new DevicesService();

export default function StatusDevice() {
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  });
  const [query, setQuery] = useState<any>(null);
  const [messageError, setMessageError] = useState("");
  const [showErrorInfoModal, setShowErrorInfoModal] = useState(false);
  const [selectedIot, setSelectedIot] = useState<IotDevice | null>(null);
  const [messagesIot, setmessagesIot] = useState<null | any[]>(null);
  const [showIotDetails, setShowIotDetails] = useState(false);
  const subscriptionIot$ = iotEventsService.getSubject();
  const navigate = useNavigate();

  const columns: ColumnsType<IotDevice> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
      width: 200,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
      width: 200,
    },
    {
      title: "Upload for",
      key: "uploadFor",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span
            style={{
              color: "#232B36",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {record.createdBy}
          </span>
          <span
            style={{
              color: "#74788D",
            }}
          >
            {dayjs(record.createdAt).format("YYYY/MM/DD [at] hh:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      render: (_, record) => (
        <span>
          <Tag
            color={
              record.status == "COMPLETED"
                ? "green"
                : record.status == "FAILED"
                ? "red"
                : "orange"
            }
            style={{ borderRadius: "20px" }}
          >
            {record.status}
          </Tag>
        </span>
      ),
    },
    {
      title: "Send status",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="flex">
          {record.statusMessage === "SENDING" && (
            <Tag color={"orange"} style={{ borderRadius: "20px" }}>
              {record.statusMessage}
            </Tag>
          )}
          {record.statusMessage === "ERROR" && (
            <Tag color={"red"} style={{ borderRadius: "20px" }}>
              {record.statusMessage}
            </Tag>
          )}
          {record.statusMessage !== "SENDING" &&
            record.statusMessage !== "ERROR" && (
              <span
                style={{
                  color: "#22A2CA",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  getMessages(record.iotUploadId);
                }}
              >
                View sends
              </span>
            )}
        </div>
      ),
    },
  ];
  const columnsDetail: ColumnsType<any> = [
    {
      title: "Message",
      dataIndex: "iotMessage",
      key: "iotMessage",
      width: 300,
    },
    {
      title: "Date",
      dataIndex: "sensor",
      key: "sensor",
      width: 300,
      render: (_, record) => (
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
            }}
          >
            {dayjs(record.createdAt).format("YYYY/MM/DD [at] hh:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: "Sending by",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 300,
    },
  ];

  const getDevices = async () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const response = await devicesService.getIotByOrganization(
      oui,
      pagination.page,
      pagination.pageSize,
      query
    );
    console.log("🚀 ~ file: index.tsx:126 ~ getDevices ~ response:", response);
    setBulkList(response.data);
    setPagination({
      ...pagination,
      totalPages: response.totalPages,
      totalRecords: response.totalRecords,
    });
  };

  const getMessages = async (iotId: string | number) => {
    const response = await devicesService.getMessagesByIot(iotId);
    console.log(
      "🚀 ~ file: StatusDevice.tsx:175 ~ getMessages ~ response:",
      response
    );
  };

  const handleDeleteIot = async (iotId: string | number) => {
    const response = await devicesService.deleteIot(iotId);
    console.log(
      "🚀 ~ file: index.tsx:143 ~ handleDeleteIot ~ response:",
      response
    );
  };

  const onChangePagination = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    subscriptionIot$.subscribe((event) => {
      if (event.type == "iotUploaded") {
        console.log(event);
        setBulkList((prev) =>
          prev.map((item) =>
            item.id === event.data.id
              ? { ...item, status: event.data.status }
              : item
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    getDevices();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    if (prevPage == 1) {
      getDevices();
    } else {
      setPagination({ ...pagination, page: 1 });
    }
  }, [query]);

  return (
    <div className="flex column">
      {bulkList.length > 0 ? (
        <>
          <div className="flex justify-between mb-3">
            <div className="flex">
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                prefix={
                  <SearchOutlined
                    style={{ color: "#078CB3" }}
                    rev={undefined}
                  />
                }
                onPressEnter={(e: any) => {
                  if (e.target) {
                    setQuery(e.target.value);
                  }
                }}
                onChange={(e) => {
                  if (!e.target.value || e.target.value == "") {
                    setQuery(null);
                  }
                }}
                allowClear
                className="mr-2"
              />
              {/* <div style={{ width: "200px" }}>
                <Select
                  id="userId"
                  value={selectedUserId}
                  notFoundContent={null}
                  onChange={(val) => {
                    setSelectedUserId(val);
                  }}
                  style={{ minWidth: "200px" }}
                  showSearch
                  options={(users || []).map((u) => ({
                    value: u.userId,
                    label: `${u.firstName} ${u.lastName}`,
                  }))}
                />
              </div> */}
            </div>
            {/* <Button
              type="primary"
              shape="round"
              className="flex items-center self-center"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "150px",
                height: "40px",
              }}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate(`/bulk/upload`);
              }}
            >
              Upload IOT Files
            </Button> */}
          </div>
          <Table
            columns={columns}
            dataSource={bulkList}
            pagination={false}
            style={{
              maxHeight: "calc(100vh - 330px)",
              overflowX: "auto",
            }}
            bordered
          />
          <Pagination
            style={{ marginTop: "2rem" }}
            onChange={onChangePagination}
            total={pagination.totalRecords}
            current={pagination.page}
            showSizeChanger={false}
          />
        </>
      ) : (
        <div
          className="flex column items-center justify-center"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <span style={{ fontSize: "16px" }} className="text-center">
            Woops looks like you have nothing here. <br />
            Start by clicking here
          </span>
          <Button
            type="primary"
            shape="round"
            className="flex items-center self-center mt-3"
            style={{
              backgroundColor: "#023E65",
              color: "white",
              width: "150px",
              height: "40px",
            }}
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`/bulk/upload`);
            }}
          >
            Upload IOT Files
          </Button>
        </div>
      )}
      <Modal open={showIotDetails} footer={[]} closable={false} width={800}>
        <div className="flex column">
          <div className="flex justify-between">
            <span
              className="mb-2"
              style={{
                color: "#023E65",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {`${selectedIot?.name} DEVICES`}
            </span>
            <CloseOutlined
              className="mb-auto"
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setShowIotDetails(false);
              }}
              rev={undefined}
            />
          </div>
          <div className="separator mb-2" />
          <Table
            columns={columnsDetail}
            dataSource={messagesIot || []}
            pagination={false}
            style={{
              maxHeight: "calc(100vh - 300px)",
              overflowX: "auto",
            }}
            bordered
          />
        </div>
      </Modal>
    </div>
  );
}
