import { api, apiController } from "../services/api";
// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/measurements/parameters/";

let GET_MEASUREMENT_UNITS = "GET_MEASUREMENT_UNITS";
let GET_MEASUREMENT_UNITS_SUCCESS = "GET_MEASUREMENT_UNITS_SUCCESS";
let GET_MEASUREMENT_UNITS_ERROR = "GET_MEASUREMENT_UNITS_ERROR";

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_MEASUREMENT_UNITS:
            return { ...state, fetching: true };
        case GET_MEASUREMENT_UNITS_SUCCESS:
            return {
                ...state,
                array: action.payload.map((unit) => {
                    return {
                        name: unit.name,
                        id: unit.id,
                    };
                }),
                fetching: false,
            };
        case GET_MEASUREMENT_UNITS_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getUnitsAction = () => (dispatch, getState) => {
    dispatch({
        type: GET_MEASUREMENT_UNITS,
    });
    return api
        .get(`${URL}units`)
        .then((res) => {
            dispatch({
                type: GET_MEASUREMENT_UNITS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_MEASUREMENT_UNITS_ERROR,
                payload: err.response.message,
            });
        });
};
