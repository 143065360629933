import axios from "axios";
import { store } from "../index";
// import { useDispatch } from "react-redux";
import { setShowLoader } from "../redux/loaderDuck";
import { showErrorModal } from "../redux/modalAlertDuck";

let apiController = new AbortController();
const apiNl = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let access_token = localStorage.getItem("accessToken");
if (access_token) {
  apiNl.defaults.headers.common = {
    Authorization: `${access_token}`,
    'Content-Encoding': 'gzip',
  };
}

apiNl.interceptors.request.use((config) => {
  //Start the request
  return config;
});

apiNl.interceptors.response.use(
  (response) => {
    //End the response
    return response;
  },
  (error) => {
    if (error.code !== "ERR_CANCELED") {
      store.dispatch(
        showErrorModal(error.message, error.response?.data?.error)
      );
    }
    //Error managment
    return Promise.reject(error);
  }
);

const restartApiCotroller = () => {
  apiController = new AbortController();
};

export { apiNl, apiController, restartApiCotroller };
