import { api } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_GIS = "GET_GIS";
let GET_GIS_SUCCESS = "GET_GIS_SUCCESS";
let GET_GIS_ERROR = "GET_GIS_ERROR";

// reducer
export default function gisReducer(state = initialData, action) {
    switch (action.type) {
        case GET_GIS:
            return { ...state, fetching: true };
        case GET_GIS_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_GIS_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export const getGisAction = (userId) => (dispatch, getState) => {
    return api
        .get(`${URL}/gis/uploads/users/${userId}`)
        .then((res) => {
            dispatch({
                type: GET_GIS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_GIS_ERROR,
                payload: err.response.message,
            });
        });
};
