import React from "react";

const OutfallIconSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2097_4665)">
        <path
          d="M13.3333 14.1667L13.3333 1.66666"
          stroke="#023E65"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 3.33334H12.5"
          stroke="#023E65"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.66669 14.1667L1.66669 1.66666"
          stroke="#023E65"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 11.6667H12.5"
          stroke="#023E65"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6019 16.3083L13.7636 13.3333L11.9254 16.3083C11.7756 16.5767 11.6667 16.8917 11.6667 17.2183C11.6667 18.2917 12.6062 19.1667 13.75 19.1667C14.8938 19.1667 15.8334 18.2917 15.8334 17.2183C15.8334 16.8917 15.738 16.5767 15.5746 16.3083H15.6019Z"
          stroke="#023E65"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2097_4665">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutfallIconSvg;
