import React, { FC, useEffect, useState } from "react";
import {
  Alert,
  Modal,
  Button,
  Input,
  Select,
  Table,
  Pagination,
  Form,
  Divider,
  Switch,
  Tooltip,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import BlockService from "../../domain/BlockDiagram/BlockService";
import LocationService from "../../domain/Location/LocationService";
import Locations from "../../components/Locations/Locations/Locations";
import type { PaginationProps } from "antd";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import "./index.scss";

const blockService = new BlockService();
const locationService = new LocationService();

type LayoutType = Parameters<typeof Form>[0]["layout"];

/**
 * @author
 * @function @BlockDiagramView
 **/

const BlockDiagramView: FC = () => {
  const [block, setBlock] = useState<any | null>(null);
  const security = localStorage.getItem("security");
  const organizationId = security ? JSON.parse(security).organizationId : "";
  const locationId = localStorage.getItem("Location");
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [showNewModal, setShowNewModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedDiagram, setSelectedDiagram] = useState<AllBlocks | null>(
    null
  );
  const [locations, setLocations] = useState<Location[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRecords: 0,
  });
  const [query, setQuery] = useState<any>(null);
  const navigate = useNavigate();

  const handleGetLocations = () => {
    const userId = localStorage.getItem("userId") || "";
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resLocationByUser.then((value) => {
      setLocations(value);
    });
  }

  const handleGetBlock = () => {
    const res = blockService.getBlock(
      organizationId,
      pagination.page,
      pagination.pageSize,
      query
    );
    res
      .then((response) => {
        setPagination({
          ...pagination,
          totalRecords: response.totalRecords,
        });
        setBlock(response.allBlocksResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleGetBlock();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({ ...pagination, page: 1 });
    if (prevPage == 1) {
      handleGetBlock();
    }
  }, [query]);
  
  useEffect(() => {
    handleGetLocations()
  }, [])
  

  const columns: ColumnsType<AllBlocks> = [
    {
      title: "Diagram name",
      key: "name",
      render: (_, { name }) => (
        <>
          <span className="table-data-gis">{name}</span>
        </>
      ),
    },
    {
      title: "Location",
      key: "location",
      render: (_, { location }) => <>{location}</>,
    },
    {
      title: "Upload for",
      key: "createdBy",
      render: (_, { createdBy, createdAt }) => (
        <>
          <div className="flex column">
            <span
              style={{
                color: "#232B36",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {createdBy}
            </span>
            <span
              style={{
                color: "#74788D",
              }}
            >
              {dayjs(createdAt).format("YYYY/MM/DD [at] hh:mm a")}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "In use",
      key: "enabled",
      render: (_, record) => (
        <>
          <Switch
            checked={record.enabled}
            onChange={(checked) => {
              const newData: any = [...block];
              const index = newData.findIndex(
                (item: any) => record.id === item.id
              );
              newData[index].enabled = checked;
              setBlock(newData);
              if (checked) {
                blockService.enableInUseBlockDiagram(record);
              } else {
                blockService.disableInuseBlockDiagram(record);
              }
            }}
          />
        </>
      ),
    },
    {
      title: "Actions",
      key: "id",
      render: (_, record) => (
        <>
          <div>
            <Tooltip title="Edit">
              <EditOutlined
                className="mr-1"
                style={{ cursor: "pointer", fontSize: "1.5rem" }}
                onClick={() => {
                  navigate(
                    `/diagram-block/${record.locationId}/${record.id}?isEdit=true`
                  );
                }}
                rev={undefined}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                style={{ fontSize: "1.5rem" }}
                onClick={() => {
                  setSelectedDiagram(record);
                  setShowConfirmDelete(true);
                }}
                rev={undefined}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const handleClickSelect = (value: any) => {
    // record.location = value;
    form.setFieldsValue({ location: value });
    console.log("value", value);
  };

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    // console.log(page);
    setPagination({ ...pagination, page: page });
  };

  const onFinish = async (values: AllBlocks) => {
    // console.log("values", values);
    // handleAddInfrastructureParemeters(values);

    const respUpdate: any = await blockService.updateBlockDiagram(
      organizationId,
      values.location,
      values
    );
    if (respUpdate.success) {
      notification.success({
        message: "Successful registration",
        description: "Block diagram created successfully",
      });
      const blockId = JSON.parse(respUpdate.data).blockId;
      navigate(`/diagram-block/${values.location}/${blockId}?isEdit=true`);
      // form.resetFields();
      // setShowNewModal(false);
      // handleGetBlock();
    }

    // isModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const deleteBlockDiagram = async (id: any) => {
    await blockService.deleteBlockDiagram(id);
    notification.success({
      message: "Successful deletion",
      description: "Block diagram deleted successfully",
      placement: "topRight",
    });
    await handleGetBlock();
    setShowConfirmDelete(false);
  };

  return (
    <div className="px-5">
      <div className="text-title-page mb-2">Block Diagram</div>
      <div className="flex justify-between">
        <div>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined rev={undefined} />}
            style={{ width: "205px" }}
            onPressEnter={(e: any) => {
              if (e.target) {
                setQuery(e.target.value);
              }
            }}
            onChange={(e) => {
              console.log("🚀 ~ OrganizationView ~ e.target.:", e.target.value);
              if (!e.target.value || e.target.value == "") {
                setQuery(null);
              }
            }}
            allowClear
          />
        </div>
        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              setShowNewModal(true);
            }}
          >
            {"Add diagram"}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={block}
          pagination={false}
          size="small"
          bordered
          style={{
            maxHeight: "calc(100vh - 280px)",
            overflow: "auto",
          }}
          // scroll={{ x: 1100, y: 370 }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalRecords}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={<div className="title-modal">CREATE DIAGRAM</div>}
        open={showNewModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
        destroyOnClose={true}
        afterClose={() => form.resetFields()}
      >
        <Divider style={{ border: "1px solid #E6E6E6" }} />
        {/* <div className="flex column items-center"> */}
        <Form
          layout={formLayout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="Diagram name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            label="Select location"
            name="location"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Locations
              locations={locations}
              handleClickSelect={handleClickSelect}
              defaultValue={false}
            />
          </Form.Item>
          <Form.Item
            label="Enabled"
            name="enabled"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {/* <Input placeholder="enabled" /> */}
            <Switch />
          </Form.Item>
          {/* <div className="separator mb-2 mt-2" /> */}
          <div className="flex justify-between mt-2">
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              htmlType="submit"
            >
              Accept
            </Button>
            <Button
              className="mr-3"
              style={{ width: "85px", height: "40px" }}
              onClick={() => setShowNewModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>

        {/* </div> */}
      </Modal>
      <ConfirmationModal
        title="Delete Block Diagram?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={async () => {
          if (selectedDiagram) {
            deleteBlockDiagram(selectedDiagram?.id);
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
};
export default BlockDiagramView;
