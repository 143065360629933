import React from "react";

const SearchSvg: React.FC = ({...props}) => {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8315 10.9884L9.60683 8.77999C10.4704 7.70246 10.8886 6.33476 10.7754 4.95814C10.6623 3.58151 10.0264 2.30059 8.9985 1.37875C7.97062 0.456919 6.62888 -0.0357596 5.24917 0.00202291C3.86946 0.0398054 2.55665 0.605177 1.58069 1.58189C0.604718 2.5586 0.0397752 3.8724 0.00202138 5.25316C-0.0357324 6.63392 0.456573 7.97668 1.37771 9.00534C2.29884 10.034 3.57879 10.6704 4.95437 10.7836C6.32996 10.8968 7.69661 10.4783 8.77332 9.61413L10.98 11.8225C11.0358 11.8787 11.1021 11.9234 11.1752 11.9538C11.2482 11.9843 11.3266 12 11.4058 12C11.4849 12 11.5633 11.9843 11.6364 11.9538C11.7094 11.9234 11.7758 11.8787 11.8315 11.8225C11.9396 11.7106 12 11.5611 12 11.4054C12 11.2498 11.9396 11.1003 11.8315 10.9884ZM5.40932 9.61413C4.57914 9.61413 3.76759 9.36776 3.07732 8.90618C2.38704 8.44461 1.84904 7.78855 1.53134 7.02097C1.21364 6.25339 1.13051 5.40877 1.29247 4.59392C1.45444 3.77906 1.85421 3.03057 2.44124 2.4431C3.02827 1.85562 3.7762 1.45554 4.59043 1.29346C5.40467 1.13137 6.24865 1.21456 7.01564 1.5325C7.78263 1.85044 8.4382 2.38885 8.89942 3.07965C9.36065 3.77045 9.60683 4.58262 9.60683 5.41343C9.60683 6.52753 9.16459 7.59599 8.37741 8.38377C7.59022 9.17156 6.52257 9.61413 5.40932 9.61413Z"
        fill="#078CB3"
      />
    </svg>
  );
};

export default SearchSvg;
