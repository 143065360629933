import React from "react";

type Props = {};

export default function BlockDiagramIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M21.6248 14H19.6248V11C19.6248 10.7348 19.5194 10.4804 19.3319 10.2929C19.1443 10.1054 18.89 10 18.6248 10H12.6248V8H14.6248C14.89 8 15.1443 7.89464 15.3319 7.70711C15.5194 7.51957 15.6248 7.26522 15.6248 7V1C15.6248 0.734784 15.5194 0.48043 15.3319 0.292893C15.1443 0.105357 14.89 0 14.6248 0H8.62476C8.35954 0 8.10519 0.105357 7.91765 0.292893C7.73011 0.48043 7.62476 0.734784 7.62476 1V7C7.62476 7.26522 7.73011 7.51957 7.91765 7.70711C8.10519 7.89464 8.35954 8 8.62476 8H10.6248V10H4.62476C4.35954 10 4.10519 10.1054 3.91765 10.2929C3.73011 10.4804 3.62476 10.7348 3.62476 11V14H1.62476C1.35954 14 1.10519 14.1054 0.917649 14.2929C0.730113 14.4804 0.624756 14.7348 0.624756 15V21C0.624756 21.2652 0.730113 21.5196 0.917649 21.7071C1.10519 21.8946 1.35954 22 1.62476 22H7.62476C7.88997 22 8.14433 21.8946 8.33186 21.7071C8.5194 21.5196 8.62476 21.2652 8.62476 21V15C8.62476 14.7348 8.5194 14.4804 8.33186 14.2929C8.14433 14.1054 7.88997 14 7.62476 14H5.62476V12H17.6248V14H15.6248C15.3595 14 15.1052 14.1054 14.9176 14.2929C14.7301 14.4804 14.6248 14.7348 14.6248 15V21C14.6248 21.2652 14.7301 21.5196 14.9176 21.7071C15.1052 21.8946 15.3595 22 15.6248 22H21.6248C21.89 22 22.1443 21.8946 22.3319 21.7071C22.5194 21.5196 22.6248 21.2652 22.6248 21V15C22.6248 14.7348 22.5194 14.4804 22.3319 14.2929C22.1443 14.1054 21.89 14 21.6248 14ZM6.62476 16V20H2.62476V16H6.62476ZM9.62476 6V2H13.6248V6H9.62476ZM20.6248 20H16.6248V16H20.6248V20Z"
        fill="#74788D"
      />
    </svg>
  );
}
