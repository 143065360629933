import React, { useContext, useState } from "react";
import { Handle, Position } from "reactflow";
import "./NodeComponent.scss";
import {
  StarFilled,
  StarOutlined,
  SyncOutlined,
  EditOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import StateMachineContext from "./context";
import { Modal, Select, Tooltip } from "antd";
import ActionModal from "./NodeComponentModals/ActionModal";
dayjs.extend(relativeTime);

const NodeComponent: React.FC = ({ data, id }: any) => {
  const { value: context, setValue: setContext } =
    useContext(StateMachineContext);
  const [showActionModal, setShowActionModal] = useState(false);

  return (
    <>
      <div
        className="parameter-node py-2"
        style={{ border: "solid 1px black" }}
      >
        <Handle
          type="target"
          position={Position.Left}
          id="t1"
          style={{
            top: "30px",
            left: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderLeft: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="source"
          position={Position.Left}
          id="s1"
          style={{
            top: "110px",
            left: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderRight: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="target"
          position={Position.Top}
          id="t2"
          style={{
            left: "70px",
            top: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderTop: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="source"
          position={Position.Top}
          id="s2"
          style={{
            left: "215px",
            top: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <div className="flex flex-column">
          <div
            className="flex items-center content-container justify-center"
            style={{ padding: "0px 15px", width: "275px", height: "53px" }}
          >
            <SyncOutlined
              style={{ fontSize: "20px", color: "black" }}
              className="mr-2"
            />
            <div className="flex flex-column" style={{ color: "black" }}>
              <input
                type="text"
                placeholder="Name"
                value={data.label}
                onChange={(event) => {
                  data.onChangeLabel(event.target.value, id);
                }}
              />
              {data.initial && (
                <span
                  className="mt-1"
                  style={{ backgroundColor: "#c9c9c9", width: "fit-content", fontSize: "9px" }}
                >
                  INITIAL STATE
                </span>
              )}
            </div>
            <span className="" style={{ fontSize: "12px" }}>
              {/* {data.label} */}
            </span>
            {data.initial ? (
              <StarFilled
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "#078cb3",
                }}
                className="ml-1"
                onClick={() => {
                  data.setInitial(id);
                  //   console.log("🚀 ~ data:", data)
                }}
              />
            ) : (
              <StarOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "#078cb3",
                }}
                className="ml-1"
                onClick={() => {
                  data.setInitial(id);
                  //   console.log("🚀 ~ data:", data)
                }}
              />
            )}
          </div>
          <div
            className="mt-1 mb-1"
            style={{ width: "100%", height: "1px", backgroundColor: "black" }}
          ></div>
          <div
            className="flex"
            style={{ color: "black", padding: "0px 15px", height: "50px" }}
          >
            <div
              style={{
                width: 15,
                height: 15,
                borderRadius: "100%",
                backgroundColor: "lightblue",
                marginTop: '4px',
              }}
            />
            {data.value && data.value.name ? (
              <>
                <div className="flex flex-column ml-2">
                  <span>{data.value.name}</span>
                  <span style={{ backgroundColor: "#c9c9c9", fontSize: "9px" }}>
                    {data.value.type === "CONSTANT" ? "ASSIGNMENT" : "FUNCTION"}
                  </span>
                </div>
              </>
            ) : (
              <div className="flex flex-column ml-2">
                <span
                  className=""
                  style={{ fontStyle: "italic", fontWeight: "bold" }}
                >
                  --Not linked--
                </span>
                <span style={{ fontStyle: "italic" }}>Press the pencil to edit</span>
              </div>
            )}
            <Tooltip title="Edit State">
              <EditOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "black",
                }}
                className="ml-auto"
                onClick={() => {
                  data.openActionModal(data, id);
                  //   console.log("🚀 ~ data:", data)
                }}
              />
            </Tooltip>
          </div>
        </div>

        <Handle
          type="source"
          position={Position.Right}
          id="s3"
          style={{
            top: "30px",
            right: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderLeft: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="target"
          position={Position.Right}
          id="t3"
          style={{
            top: "110px",
            right: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderRight: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="target"
          position={Position.Bottom}
          id="t4"
          style={{
            left: "70px",
            bottom: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="s4"
          style={{
            left: "215px",
            bottom: "-10px",
            backgroundColor: "transparent",
            width: "10px",
            height: "10px",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderTop: "10px solid #020202",
            borderRadius: "0px",
          }}
        />
      </div>
    </>
  );
};

export default NodeComponent;
