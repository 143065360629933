import { api } from "../../services/api";

export default class StateMachineRepository {
  // async postInpAssets(file: string, name: string): Promise<any> {
  //   const reponse = await api.post(`/mms/upload`, { file, name });
  //   return reponse.data;
  // }

  async getAllStateMachines({
    name,
    locationId = null,
    inpId,
    page = 1,
    size = 20,
  }: {
    name: string | null;
    locationId?: string | number | null;
    inpId: string | null;
    page: number;
    size: number;
  }) {
    // const response = await api.get(`mms/${mmsId}/assets/${assetId}`)
    const response = await api.get(`/statemachine/`, {
      params: { name, locationId, inpId, page, size },
    });
    return response.data;
  }

  async createStateMachine({
    name,
    locationId,
    inpId,
    enabled = true,
  }: {
    name: string | null;
    locationId?: string | number | null;
    inpId: string | null;
    enabled: boolean;
  }) {
    // const response = await api.get(`mms/${mmsId}/assets/${assetId}`)
    const response = await api.post(`/statemachine/`, {
      name,
      locationId,
      inpId,
      enabled,
      nodes: "[]",
      edges: "[]",
      definition: "",
    });
    return response.data;
  }

  async editStateMachineComplete(data: any) {
    // const response = await api.get(`mms/${mmsId}/assets/${assetId}`)
    const response = await api.post(`/statemachine/`, data);
    return response.data;
  }

  async getStateMachineDetail(machineId: string | number) {
    // const response = await api.get(`mms/${mmsId}/assets/${assetId}`)
    const response = await api.get(`/statemachine/${machineId}`);
    return response.data;
  }
}
