import { api } from "../../services/api";

export default class UserRepository {
  async getAllUsers(
    organizationId: any,
    page: number,
    size: number,
    username: string | null,
    roleId: string | number | null
  ) {
    const userRole = JSON.parse(localStorage.getItem("security") || "{}").role;
    const url =
      userRole == "SuperAdmin"
        ? `users/organizations/all`
        : `users/organizations/${organizationId}`;

    const res = await api.get(`${url}`, {
      params: {
        page,
        size,
        username,
        roleId,
      },
    });
    return res.data;
  }

  async createUser(data: any): Promise<boolean> {
    let result;
    if (data.userId) {
      result = await api.post(`users/${data.userId}`, data);
    } else {
      result = await api.post(`users/`, data);
    }

    return result.data;
  }

  async deleteUser(userId: any): Promise<boolean> {
    const result = await api.delete(`users/${userId}`);
    return result.data;
  }

  async getRole() {
    const res = await api.get(`security/roles`);
    return res.data.map((rol: Role) => {
      return {
        value: rol.id,
        label: rol.name,
      };
    });
  }

  async getModules() {
    const res = await api.get(`security/modules`);
    return res.data.map((module: Module) => {
      return {
        value: module.id,
        label: module.name,
      };
    });
  }

  async getOrganizations() {
    const res = await api.get(`security/organizations`);
    return res.data.map((organization: Organization) => {
      return {
        value: organization.id,
        label: organization.name,
      };
    });
  }

  async getLocationsIdsByUser(userId: any) {
    const result = await api.get(`users/${userId}/locations/ids`);
    return result.data;
  }

  async postLocationsUser(userId: any, data: any) {
    const result = await api.post(`users/${userId}/locations`, data);
    return result.data;
  }
}
