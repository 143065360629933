import { Modal, Tag, notification } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Icon, { PlusCircleOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import PlayCircleSvg from "../../assets/svg/PlayCircleIconSvg";
import KeplerService from "../../domain/Kepler/KeplerService";

const keplerService = new KeplerService();

export default function ModalKeplerAnalysis({
  showKeplerAnalysisModal,
  onCancel,
  onSuccess,
  simulation,
}: any) {
  // const [api, contextHolder] = notification.useNotification();
  const [iterations, setIterations] = useState<any>([]);
  const [showSuccessNotify, setShowSuccessNotify] = useState(false);
  // async function handleGetLinks() {
  //   const res = await keplerService.getKeplerAnimation(simulationId);
  //   console.log(
  //     "🚀 ~ file: ModalKeplerAnalysis.tsx:18 ~ handleGetLinks ~ res:",
  //     res
  //   );
  // }
  const columns: ColumnsType<any> = [
    {
      title: "Iteration",
      key: "name",
      width: 200,
      render: (_, record) => <span>Iteration {record.iterationId}</span>,
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="flex justify-center">
          {record.status == "IN PROGRESS" ? (
            <Tag color="warning">IN PROGRESS</Tag>
          ) : !record.link ? (
            <PlusCircleOutlined
              className="cursor-pointer mr-1"
              onClick={async () => {
                const response =
                  await keplerService.postGenerateKeplerAnimation(
                    simulation.id,
                    record.iterationId
                  );
                if (response.success == 1) {
                  onSuccess();
                  openNotification();
                }
              }}
            />
          ) : (
            <EyeOutlined
              className="cursor-pointer mr-1"
              onClick={() => {
                window.open(record.link, "_blank");
              }}
              style={{ fontSize: "18px" }}
            />
          )}
        </div>
      ),
    },
  ];

  const openNotification = () => {
    notification.info({
      message: `Request sent`,
      description:
        "Your analysis is being performed, you will be able to get it in about 10 minutes.",
      placement: "topRight",
      duration:0,
    });
  };

  useEffect(() => {
    if (simulation) {
      setIterations(() => {
        let iterationList = [];
        const links = simulation.links || [];
        for (let index = 1; index <= simulation.numit; index++) {
          const linkFinded = links.find(
            (link: any) => link.iterationId == index
          );
          if (linkFinded) {
            iterationList.push({ ...linkFinded });
          } else {
            iterationList.push({ iterationId: index, link: null });
          }
        }
        return iterationList;
      });
    } else {
      setIterations([]);
    }
  }, [simulation]);

  return (
    <>
      <Modal
        open={showKeplerAnalysisModal}
        footer={[]}
        closable={true}
        width={350}
        onCancel={() => {
          onCancel();
        }}
      >
        <div className="flex column">
          <span className="mb-2" style={{ fontWeight: 700, fontSize: "16px" }}>
            Analysis
          </span>
          <Table
            columns={columns}
            dataSource={iterations}
            pagination={false}
            style={{
              maxHeight: "calc(100vh - 440px)",
              overflowX: "auto",
            }}
            bordered
          />
        </div>
      </Modal>
      {/* <Modal
        open={showSuccessModal}
        footer={[]}
        closable={true}
        width={350}
        onCancel={() => {
          setShowSuccessModal(false);
        }}
      >
        <div className="flex column">
          <span>
            Your kepler analysis is being generated, you will be able to consult
            it in about 10 minutes.
          </span>
        </div>
      </Modal> */}
    </>
  );
}
