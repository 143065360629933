import { api } from "../../services/api";

export default class GisRepository {
  async getGisFiles(
    countryId: string,
    organizationId: string
  ): Promise<Location[]> {
    const locations = await api.get(
      `locations/countries/${countryId}/organizations/${organizationId}`
    );
    return locations.data;
  }

  async uploadGis(data: Gis): Promise<boolean> {
    const locations = await api.post(
      `/gis/upload`,
      data
    );
    return locations.data;
  }

  async deleteGis(gisId: number): Promise<boolean> {
    const response = await api.delete(`/gis/uploads/${gisId}`);
    return response.data;
  }
}