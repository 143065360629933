let SET_DATA = "SET_DATA";

export default function reducer(state = { data: null }, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export let setDataModelFile = (data) => (dispatch, getState) => {
  console.log("🚀 ~ setData ~ data:", data);
  dispatch({
    type: SET_DATA,
    payload: data,
  });
};
