import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Footer } from "antd/es/layout/layout";
import ExpandArrowSvg from "../../../assets/svg/ExpandArrowSvg";
import CloseIconSvg from "../../../assets/svg/CloseIconSvg";
import Icon, { SearchOutlined } from "@ant-design/icons";
import DashboardContext from "../../../layout/DashboardLayout/context";
import {
  Tooltip,
  Button,
  Divider,
  Input,
  InputNumber,
  Modal,
  Table,
  Spin,
} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ParameterIconTableSvg from "../../../assets/svg/ParameterIconTableSvg";
import dayjs from "dayjs";
import "./ParamsCard.scss";
import DeleteRoundedSvg from "../../../assets/svg/DeleteRoundedSvg";
import ExpandedParamsCard from "./ExpandedParamsCard";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAllList,
  restartResumeAndReadingList,
} from "../../../redux/readingsResumesDuck";
import { InboxOutlined } from "@ant-design/icons";
import { paramsCardEventsService } from "../../../events/paramsCard.subject";
import { generateColor } from "../../utils/DashboardGraphs";

let coloresGenerados = [];

export function ParamsCard({ readingsList, resumeList }) {
  const dispatch = useDispatch();
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const chartRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [graphOptions, setGraphOptions] = useState({});
  const [dataModalDetail, setDataModalDetail] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [legendItems, setLegendItems] = useState([]);
  const [queryParams, setQueryParams] = useState("");
  const [highchartVmRef, setHighchartVmRef] = useState(null);
  const [isSpinnerResumeVisible, setIsSpinnerResumeVisible] = useState(false);
  const [isSpinnerReadingVisible, setIsSpinnerReadingVisible] = useState(false);
  const subscriptionPC$ = paramsCardEventsService.getSubject();

  const toggleSeriesVisibility = (record, event) => {
    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      chart.series.forEach((series) => {
        if (
          series.name === record.key &&
          series.userOptions.sourceSerialNumber == null
        ) {
          const isVisible = !series.visible;
          // const color = series.color;
          series.setVisible(isVisible, false);
          const buttonElement = event.currentTarget;
          if (buttonElement) {
            if (!isVisible) {
              buttonElement.style.backgroundColor = "";
            } else {
              buttonElement.style.backgroundColor = generateColor(
                record.parameterId,
                null
              );
            }
          }
        }
      });
      chart.redraw();
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Action",
        render: (_, record) => {
          return (
            <button
              style={{
                backgroundColor: generateColor(record.parameterId, null),
                borderRadius: "13px",
                paddingBottom: "5px",
              }}
              onClick={(event) => toggleSeriesVisibility(record, event)}
            >
              <Tooltip title="Toggle">
                <Icon component={ParameterIconTableSvg} />
              </Tooltip>
            </button>
          );
        },
        key: "key",
      },
      {
        title: "Infrastructure",
        dataIndex: "infrastructure",
        key: "infrastructure",
        render: (_, record) => (
          <div className="flex column">
            <span>{`${record.infrastructureName}`}</span>
            <span style={{ color: "#74788D", fontSize: "10px" }}>
              {`${record.infrastructureType}`}
            </span>
          </div>
        ),
      },
      {
        title: "Parameter",
        dataIndex: "parameter",
        key: "parameter",
      },
      {
        title: "Reading",
        key: "currentValue",
        render: (_, record) => (
          <span>
            {record.currentValue > -1
              ? `${record.currentValue} ${record.unit}`
              : "No data available"}
          </span>
        ),
      },
      {
        title: "Usage",
        key: "usage",
        render: (_, record) => (
          <span>
            {record.usage > -1 ? `${record.usage}%` : "No data available"}
          </span>
        ),
      },
      {
        title: "Last Collected",
        key: "lastCollected",
        render: (_, record) => (
          <span>
            {record.lastCollected && record.lastCollected != "N/A"
              ? `${dayjs(record.lastCollected).format("YYYY/MM/DD hh:mm A")}`
              : "No data available"}
          </span>
        ),
      },
    ],
    []
  );

  async function buildDataToGraph(dataArray) {
    let yAxis = [];
    dataArray.forEach((item) => {
      if (item === undefined) return;
      const key = `${item.valueUnit}-${item.sensorType}`;
      if (!yAxis.find(({ uniqueKey }) => uniqueKey === key)) {
        yAxis.push({
          uniqueKey: key,
          valueUnit: item.valueUnit,
          sensorType: item.sensorType,
          labels: {
            format: `{value}${item.valueUnit}`,
          },
          title: {
            text: `${item.sensorType}`,
          },
        });
      }
    });

    yAxis.sort((a, b) => {
      if (a.valueUnit < b.valueUnit) return -1;
      if (a.valueUnit > b.valueUnit) return 1;
      return 0;
    });
    const checkGrouping = () => {
      const positions = {};

      yAxis.forEach((entry, index) => {
        if (!positions[entry.valueUnit]) {
          positions[entry.valueUnit] = [];
        }
        positions[entry.valueUnit].push(index);
      });

      let allGrouped = true;

      for (const valueUnit in positions) {
        const posArray = positions[valueUnit];
        for (let i = 1; i < posArray.length; i++) {
          if (posArray[i] !== posArray[i - 1] + 1) {
            allGrouped = false;
            break;
          }
        }
      }

      if (allGrouped) {
        console.log("All entries are grouped correctly.");
      }
    };

    checkGrouping();

    let seriesData = [];
    dataArray.forEach((data, index) => {
      if (data === undefined) return;
      const measurementHistorialValues = data.measurementToolHistoricalValues;
      if (measurementHistorialValues) {
        const keys = Object.keys(measurementHistorialValues);
        const dataSeries = keys
          .map((item) => {
            return {
              x: parseFloat(item),
              y: measurementHistorialValues[item].value,
              origen: measurementHistorialValues[item].serialNumber,
            };
          })
          .filter((item) => {
            return item.y != null;
          })
          .sort((a, b) => {
            return a.x - b.x;
          });
        const read = {
          keyname: data.measurementTargetName,
          name: data.measurementTargetId,
          paramId: data.paramId,
          valueUnit: data.valueUnit,
          sensorType: data.sensorType,
          isForecast: false,
          color: generateColor(data.measurementTargetId, null),
          color: generateColor(data.measurementTargetId, null),
          yAxis: yAxis.findIndex(
            ({ valueUnit }) => valueUnit === data.valueUnit
          ),
          data: dataSeries,
        };
        seriesData.push(read);
      }
      if (data.measurementToolForecastValues) {
        data.measurementToolForecastValues?.forEach((forecast, indexData) => {
          const colors = Highcharts.getOptions().colors;
          if (!colors) return;
          const foreData = {
            keyname: data.measurementTargetName,
            name: data.measurementTargetId,
            sourceName: data.serialNumberTool,
            valueUnit: data.valueUnit,
            sensorType: data.sensorType,
            yAxis: yAxis.findIndex(
              ({ valueUnit }) => valueUnit === data.valueUnit
            ),
            showInLegend: false,
            color: generateColor(data.measurementTargetId, null),
            color: generateColor(data.measurementTargetId, null),
            dashStyle: "Dash",
            isForecast: true,
            isPluviometer: data.measurementToolForecastValues.length > 1,
            forecastIndex: indexData,
            data: forecast
              ? Object.keys(forecast)
                  .map((item) => {
                    return {
                      x: parseFloat(item),
                      y: forecast[item],
                      origen: data.serialNumberTool,
                    };
                  })
                  .filter((item) => {
                    return item.y != null;
                  })
                  .sort((a, b) => {
                    return a.x - b.x;
                  })
              : [],
          };
          seriesData.push(foreData);
        });
      }
    });

    if (seriesData.length <= 0) {
      setIsDataAvailable(false);
    } else {
      setIsDataAvailable(true);
    }

    const [dateFrom, dataTo] = context.selectedDate;
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
    setGraphOptions({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: 220,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
        events: {
          load: function () {
            const vm = this;
            setHighchartVmRef(this);
          },
        },
      },
      legend: {
        // backgroundColor: "#FFFFFF",
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: new Date() - localOffset,
            to: new Date(dataTo),
            color: "#EFFFFF",
          },
        ],
        min: new Date(dateFrom).getTime() - localOffset,
        max: new Date(dataTo).getTime() - localOffset,
        ordinal: false,
        events: {
          click: (event) => {},
          click: (event) => {},
        },
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          turboThreshold: 0,
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: false,
        height: 30,
      },
      tooltip: {
        formatter: function () {
          const vm = this;
          let text = `<span>${dayjs(vm.points[0].x + localOffset).format(
            "YYYY-MM-DD hh:mm:ss A"
          )}</span><br/><br/>`;
          vm.points.forEach((point) => {
            const series = point.series;
            const userOptions = series.userOptions;
            const symbol =
              userOptions.isForecast && userOptions.isPluviometer
                ? userOptions.forecastIndex == 1
                  ? "◆"
                  : userOptions.forecastIndex == 2
                  ? "■"
                  : "▲"
                  ? "■"
                  : "▲"
                : "●";
            if (userOptions.isForecast) {
              text += `<span style="color:${series.color}">${symbol} Iteration ${userOptions.index}</span> - ${userOptions.name}: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Source: <b>${point.point.origen}</b></span><br/>`;
            } else {
              text += `<span style="color:${series.color}">${symbol} ${series.userOptions.keyname}</span>: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Source: <b>${point.point.origen}</b></span><br/>`;
            }
          });
          return [text];
        },
        valueDecimals: 2,
        shared: true,
        xDateFormat: "%Y-%m-%d %H:%M:%S",
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: false,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    });
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }

  function buildDataToTable(dataArray) {
    const seriesData = dataArray
      .map((data, index) => {
        if (data === undefined) return {};

        const colors = Highcharts.getOptions().colors;
        if (!colors) return;
        const resume = data;
        console.log("🚀 ~ .map ~ resume:", resume);
        if (resume) {
          return {
            infrastructureName: resume.infrastructureName,
            infrastructureType: resume.infrastructureType,
            parameter: resume.parameterName,
            type: resume.sourceType,
            unit: resume.unit,
            criticalHigh: resume.criticalHigh,
            criticalHhigh: resume.criticalHhigh,
            criticalLow: resume.criticalLow,
            criticalLlow: resume.criticalLlow,
            currentValue: resume.currentValue,
            lastCollected: resume.lastCollected,
            usage: resume.usage,
            sourceName: resume.sourceName,
            maximun: resume.max,
            minimum: resume.min,
            average: resume.average,
            color: getColor(
              resume.currentValue,
              resume.criticalHigh,
              resume.criticalHhigh,
              resume.criticalLow,
              resume.criticalLlow
            ),
            colorLine: colors[index],
            parameterId: data.parameterId,
            parameterId: data.parameterId,
            sum: resume.sum,
            source: data.source,
            infrastructureId: resume.infrastructureId,
            key: resume.parameterId,
          };
        } else {
          return null;
        }
      })
      .filter((item) => {
        return item !== null;
      });
    setDataModalDetail(seriesData);
    // setSpinnerVisible(false);
    // setSpinnerVisible(false);
  }

  function getColor(
    currentValue,
    criticalHigh,
    criticalHhigh,
    criticalLow,
    criticalLlow
  ) {
    if (criticalHigh >= currentValue && currentValue <= criticalHhigh) {
      return "orange";
    }
    if (criticalHhigh <= currentValue) {
      return "red";
    }
    if (criticalLow >= currentValue && currentValue <= criticalLlow) {
      return "orange";
    }
    if (currentValue <= criticalLlow) {
      return "red";
    }
    if (criticalLow <= currentValue && currentValue <= criticalHigh) {
      return "green";
    }
    return "green";
  }

  function deleteSelectedSensor(measurementTargetId) {
    setContext((currentValue) => {
      const newSensors = currentValue.selectedSensors.filter((sensor) => {
        return sensor.measurementTargetId !== measurementTargetId;
      });
      return { ...currentValue, selectedSensors: newSensors };
    });
  }

  const paramsToShowInTable = useCallback(() => {
    if (queryParams != "") {
      return dataModalDetail.filter((md) => {
        return md.parameter.includes(queryParams);
      });
    } else {
      return dataModalDetail;
    }
  }, [queryParams, dataModalDetail]);

  useEffect(() => {
    buildDataToGraph([]);
    subscriptionPC$.subscribe((event) => {
      if (event.type == "showReadingLoader") {
        setIsSpinnerReadingVisible(event.data);
      }
      if (event.type == "showResumeLoader") {
        setIsSpinnerResumeVisible(event.data);
      }
    });
    subscriptionPC$.subscribe((event) => {
      if (event.type == "showReadingLoader") {
        setIsSpinnerReadingVisible(event.data);
      }
      if (event.type == "showResumeLoader") {
        setIsSpinnerResumeVisible(event.data);
      }
    });
  }, []);

  useEffect(() => {
    setContext({ ...context, selectedSensors: [] });
  }, [context.selectedLocation]);

  useEffect(() => {
    setContext({ ...context, selectedSensors: [] });
  }, [context.selectedSensors]);

  useEffect(() => {
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }, [context.selectedDate]);

  useEffect(() => {
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }, [highchartVmRef]);

  useEffect(() => {
    if (readingsList && readingsList.length > 0) {
      const seen = new Set();
      const data = readingsList.filter((item) => {
        if (item) {
          const tuple = JSON.stringify([
            item.originalParameterId,
            item.originalSourceSerialNumber,
          ]);
          // Si el set ya contiene la tupla, este objeto ya fue visto
          if (seen.has(tuple)) {
            return false;
          }
          // Si no, agrega la tupla al set y devuelve verdadero para mantener este objeto
          seen.add(tuple);
        }
        return (
          item !== undefined &&
          item.originalSourceSerialNumber == null &&
          context.selectedSensors.find(
            (sensor) =>
              sensor.serialNumberTool == item.serialNumberMeasurementTool
          )
        );
      });
      buildDataToGraph(data);
    } else {
      buildDataToGraph([]);
    }
  }, [readingsList]);

  useEffect(() => {
    if (resumeList && resumeList.length > 0) {
      const seen = new Set();
      const data = resumeList.filter((item) => {
        if (item) {
          const tuple = JSON.stringify([item.parameterId]);
          // Si el set ya contiene la tupla, este objeto ya fue visto
          if (seen.has(tuple)) {
            return false;
          }
          // Si no, agrega la tupla al set y devuelve verdadero para mantener este objeto
          seen.add(tuple);
        }
        return (
          item !== undefined &&
          context.selectedSensors.find(
            (sensor) => sensor.measurementTargetId == item.parameterId
          )
        );
      });
      buildDataToTable(data);
    } else {
      buildDataToTable([]);
    }
  }, [resumeList]);

  useEffect(() => {
    return () => {
      dispatch(restartResumeAndReadingList());
      setContext((previus) => {
        return { ...previus, selectedSensors: [] };
      });
    };
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          <ExpandedParamsCard
            readingsList={readingsList}
            resumeList={resumeList}
          />
          <Footer
            style={{
              height: "300px",
              padding: "0px",
              backgroundColor: "#eff2f0",
            }}
            className={isLoading || !context.showParamsCard ? "hidden" : ""}
          >
            <div className="flex cols-gap-2" style={{ height: "100%" }}>
              <div
                className="flex column p-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px 8px 8px 0px",
                  position: "relative",
                  width: "40%",
                }}
              >
                {isSpinnerResumeVisible && (
                  <div className="spinner-container">
                    <Spin tip="Processing data..." />
                  </div>
                )}
                <div
                  className="flex justify-center items-center"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                ></div>
                <div
                  className="flex items-center"
                  style={{ marginBottom: "1rem" }}
                >
                  <span
                    className="mr-2"
                    style={{
                      color: "#023E65",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Current readings
                  </span>
                  <Input
                    placeholder="Search Parameters"
                    prefix={<SearchOutlined />}
                    style={{ width: "205px" }}
                    onChange={(e) => {
                      setQueryParams(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Table
                    columns={columns}
                    dataSource={paramsToShowInTable()}
                    style={{ height: "230px", overflowX: "auto" }}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <div className="flex column">
                          <span>
                            No data: select some data to turn on the light
                          </span>
                        </div>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                className="flex column p-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px 0px 0px 8px",
                  position: "relative",
                  width: "60%",
                }}
              >
                {isSpinnerReadingVisible && (
                  <div className="spinner-container">
                    <Spin tip="Processing data..." />
                  </div>
                )}
                {isSpinnerReadingVisible && (
                  <div className="spinner-container">
                    <Spin tip="Processing data..." />
                  </div>
                )}
                <div className="flex items-center" style={{ height: "30px" }}>
                  <span className="mr-2">Dates selected</span>
                  <div
                    className="flex p-1"
                    style={{
                      backgroundColor: "#F7F9FC",
                    }}
                  >
                    <span>{`${dayjs(context.selectedDate[0]).format(
                      "YYYY/MM/DD - hh:mm a"
                    )}`}</span>
                    <div
                      className="mx-2"
                      style={{
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#E6E6E6",
                      }}
                    />
                    <span>{`${dayjs(context.selectedDate[1]).format(
                      "YYYY/MM/DD - hh:mm a"
                    )}`}</span>
                  </div>
                </div>
                <div
                  className="flex justify-center items-center"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <Icon
                    component={ExpandArrowSvg}
                    className="mr-2 cursor-pointer"
                    onClick={() => {
                      setContext({ ...context, isExpandedParamsCard: true });
                    }}
                  />
                  <Icon
                    component={CloseIconSvg}
                    className="cursor-pointer"
                    onClick={() => {
                      setContext({ ...context, showParamsCard: false });
                    }}
                  />
                </div>
                <div
                  className="mt-2 self-center"
                  style={{ height: "220px", width: "100%" }}
                >
                  {isDataAvailable ? (
                    <>
                      <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={graphOptions}
                        containerProps={{ style: { height: "100%" } }}
                      />
                    </>
                  ) : (
                    <span>
                      <b>No data:</b> select some data to turn on the light
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Footer>{" "}
        </>
      )}
    </>
  );
}

function mapState(state) {
  return {
    readingsList: state.readingsResumes.readings,
    resumeList: state.readingsResumes.resumes,
  };
}

export default connect(mapState)(ParamsCard);
