import GisRepository from "./GisRepository";

export default class GisService {
    private gisRespository: GisRepository = new GisRepository();

    async uploadGis(data: Gis): Promise<boolean> {
        return this.gisRespository.uploadGis(data);
    }

    async deleteGis(gisId: number): Promise<boolean> {
        return this.gisRespository.deleteGis(gisId);
    }
}
