import KeplerRepository from "./KeplerRepository";

export default class KeplerService {
  private keplerRepository: KeplerRepository = new KeplerRepository();

  async postGenerateKeplerAnimation(
    simulationInteractiveId: any,
    iterationId: any
  ) {
    try {
      return await this.keplerRepository.postGenerateKeplerAnimation(
        simulationInteractiveId,
        iterationId
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getKeplerAnimation(simulationInteractiveId: any) {
    try {
      return await this.keplerRepository.getKeplerAnimation(
        simulationInteractiveId
      );
    } catch (error) {
      console.error(error);
    }
  }
}
