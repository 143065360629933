import { api, apiController, restartApiCotroller } from "./api";
import { apiNl } from "./apiNl";

const getGisFiles = async (
  userId,
  options = { page: 1, size: 10, fileName: null, typeGis: null },
  loading = true,
) => {
  try {
    restartApiCotroller();
    const apiInstance = loading ? api : apiNl;
    const response = await apiInstance.get(
      `gis/uploads/users/${userId}`,
      {
        params: {
          page: options.page,
          size: options.size,
          name: options.fileName || null,
          infraTypeId: options.typeGis || null
        },
        signal: apiController.signal,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getGisFiles };
