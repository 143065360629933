import ParameterSourceRepository from "./ParameterSourceRepository";

export default class ParameterSourceService {
  private parameterSourceRepository: ParameterSourceRepository =
    new ParameterSourceRepository();

  async saveSourcesByInfrastructure(
    infrastructure: Infrastructure,
    parameter: Parameter,
    sources: ParameterSource[]
  ): Promise<boolean> {
    const sourcesParsed = sources
      .map((source) => {
        return {
          ...(source.id >= 0 ? { id: source.id } : {}),
          name: source.name,
          enabled: source.isEnabled,
          sourceSerialNumber: source.sourceSerialNumber,
          virtual: source.isVirtual,
          longitude: infrastructure.longitude,
          latitude: infrastructure.latitude,
          statement: source.statement,
          variablesDTOS: source.variablesDTOS,
          virtualCurrentTs: source.virtualCurrentTs,
          virtualType: source.virtualType,
          inpId: source.inpId,
          objectTypeId: source.objectTypeId,
          objectModelId: source.objectModelId,
          historicalMetService: source.historicalMetService,
          forecastMetService: source.forecastMetService,
        };
      })
      .filter((source) => {
        return (
          source.name !== "" &&
          source.sourceSerialNumber !== "" &&
          source.sourceSerialNumber?.split(":").length >= 2
        );
      });
    return this.parameterSourceRepository.saveSources(
      infrastructure.id,
      parameter.id,
      sourcesParsed
    );
  }

  async deleteSource(parameter: ParameterSource) {
    return this.parameterSourceRepository.deleteParameter(parameter.id);
  }
}
