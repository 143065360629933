import { Button, InputNumber, Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const LateralMenu = forwardRef(
  ({ selectedAsset, updateAssetValue, oncancel, onSaveChanges }, ref) => {
    const lateralMenuRef = useRef(null);
    const [value, setValue] = useState(0);

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
      selectedAsset && setValue(selectedAsset.attributes[0].value);
    }, [selectedAsset]);

    return (
      <Layout
        className="site-layout"
        style={{ background: "white", overflowY: "auto" }}
      >
        <Sider
          style={{
            background: "white",
            position: "absolute",
            zIndex: 9,
            top: 0,
            right: -15,
            borderRadius: 0,
            height: "100%",
            width: 200,
            minWidth: 200,
          }}
          width={200}
          ref={lateralMenuRef}
          data-tour="step-1"
          className="tour-step-1"
        >
          <div className="flex column p-2 h-100">
            {selectedAsset ? (
              <>
                <span>Value</span>
                <InputNumber
                  value={value}
                  style={{ width: "100%" }}
                  placeholder=""
                  min={0}
                  step={0.1}
                  onChange={(val) => {
                    setValue(val);
                  }}
                />
                <div className="flex text-center justify-center mt-auto">
                  <Button
                    className=""
                    style={{
                      backgroundColor: "#00203C",
                      color: "white",
                      width: "200px",
                    }}
                    onClick={() => {
                      updateAssetValue(value);
                    }}
                  >
                    Save Value
                  </Button>
                  {/* <Button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      width: "200px",
                    }}
                    onClick={() => {
                      oncancel();
                    }}
                  >
                    Cancel
                  </Button> */}
                </div>
              </>
            ) : (
              <div className="flex mt-auto">
                <Button
                  className="mr-2"
                  style={{
                    backgroundColor: "#00203C",
                    color: "white",
                    width: "200px",
                  }}
                  onClick={() => {
                    onSaveChanges();
                  }}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </Sider>
      </Layout>
    );
  }
);

export default LateralMenu;
