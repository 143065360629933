import KpiRepository from "./KpiRepository";

export default class KpiService {
  private kpiRepository: KpiRepository = new KpiRepository();

  async getKpi(organizationId: string, userId: string): Promise<Kpi[]> {
    return this.kpiRepository.getKpi(organizationId, userId);
  }

  async getKpiByOrganization(
    organizationId: string,
    page: string | number = "1",
    size: string | number = "10",
    name?: string | null,
    enabled?: boolean | null,
    user?: string | null,
    location?: string | null
  ): Promise<KpiPaginated> {
    return this.kpiRepository.getKpiByOrganization(
      organizationId,
      page,
      size,
      name,
      enabled,
      user,
      location
    );
  }

  async postKpi(
    name: string,
    enabled: boolean,
    parameterId: number,
    kpiId?: number
  ): Promise<boolean> {
    return this.kpiRepository.postKpi(name, enabled, parameterId, kpiId);
  }

  async deleteKpi(kpiId: number) {
    return await this.kpiRepository.deleteKpi(kpiId);
  }

  async getKpiDetails(kpiId: number): Promise<any> {
    return this.kpiRepository.getKpiDetails(kpiId);
  }

  async changeEnableKpi(
    kpiId: string | number,
    enabled: boolean
  ): Promise<boolean> {
    return this.kpiRepository.changeEnableKpi(kpiId, enabled);
  }

  async postPinKpiInDashboard(kpiId: number, showBoard: boolean) {
    return this.kpiRepository.postPinKpiInDashboard(kpiId, showBoard);
  }

  async getKpisByUser(userId: string) {
    return this.kpiRepository.getKpisByUser(userId);
  }
}
