import React, { useState } from "react";

interface DashboardContextValueInterface {
    showNotifications: boolean;
    showParamsCard: boolean;
    isExpandedParamsCard: boolean;
    selectedSensors: any[];
    selectedLocation: string;
    infraToShow: any;
    expandLeftMenu: boolean;
    selectedDate: [any, any];
    kpi: any[];
}

interface DashboardContextInterface {
    value: DashboardContextValueInterface;
    setValue: React.Dispatch<
        React.SetStateAction<DashboardContextValueInterface>
    >;
}

const initialContext: DashboardContextInterface = {
    value: {
        showNotifications: false,
        showParamsCard: false,
        isExpandedParamsCard: true,
        selectedSensors: [],
        selectedLocation: "",
        infraToShow: null,
        expandLeftMenu: false,
        selectedDate: [new Date(), new Date()],
        kpi: [],
    },
    setValue: () => {},
};

const DashboardContext = React.createContext(initialContext);

export default DashboardContext;
