import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import NrwService from "../../domain/Nrw/NrwService";
import DashboardContext from "../../layout/DashboardLayout/context";

const nrwService = new NrwService();

export default function DashobardNrwView() {
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const [dashboardData, setdashboardData] = useState<any>(null);

  console.log("Context data:", context);

  async function handleGetNrwData() {
    if (!context.selectedLocation) return;
    const response = await nrwService.getMROReport(context.selectedLocation);
    setdashboardData(response);
    console.log(
      "🚀 ~ file: index.tsx:13 ~ handleGetNrwData ~ response:",
      response
    );
  }

  useEffect(() => {
    handleGetNrwData();
  }, [context.selectedLocation]);

  return (
    <div
      className="dashboard"
      style={{ marginTop: "80px", height: "calc(100vh - 80px)" }}
    >
      <div className="column-dashboard column-other">
        <div className="section-dashboard full-height t-b">
          <span className="title">System Input Volume</span>
          <span className="value">220,000 m3</span>
          {/* <span className="unit">{dashboardData?.totalWaterConsume.unit}</span> */}
        </div>
      </div>
      <div className="column-dashboard column-other">
        <div className="section-dashboard half-height t-b">
          <span className="title">Authorized Consumption</span>
          <span className="value">201,800 m3</span>
          {/* <span className="unit">{dashboardData?.authConsume.unit}</span> */}
        </div>
        <div className="section-dashboard half-height t-l">
          <span className="title">Water Losses</span>
          <span className="value">18,200 m3</span>
          {/* <span className="unit">{dashboardData?.waterLoss.unit}</span> */}
        </div>
      </div>
      <div className="column-dashboard column-real-losses">
        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left t-b">
            <span className="title">Billed Authorized Consumption</span>
            <span className="value">190,000 m3</span>
            {/* <span className="unit">{dashboardData?.authPaidConsume.unit}</span> */}
          </div>
          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top t-b">
              <div className="column-body">
                <span className="title">Billed Metered Consumption</span>
                <span className="value">180,000 m3</span>
                {/* <span className="unit">{dashboardData?.authNoPaidConsume.unit}</span> */}
              </div>

            </div>
            <div className="section-dashboard-child half-height bottom t-b">
              <div className="column-body">
                <span className="title">Billed Unmetered Consumption</span>
                <span className="value">10,000 m3</span>
                {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left  t-l">
            <span className="title">Unbilled Authorized Consumption</span>
            <span className="value">11,800 m3</span>
            {/* <span className="unit">{dashboardData?.authNoPaidConsume.unit}</span> */}
          </div>
          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top  t-l">
              <div className="column-body">
                <span className="title">Unbilled Metered Consumption</span>
                <span className="value">10,000 m3</span>
                {/* <span className="unit">{dashboardData?.authNoPaidConsume.unit}</span> */}
              </div>

            </div>
            <div className="section-dashboard-child half-height bottom  t-l">
              <div className="column-body">
                <span className="title">Unbilled Unmetered Consumption</span>
                <span className="value">1,800 m3</span>
                {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="section-dashboard quarter-height double-section">
          <div className="quarter-height half-width column-body-left t-l">
            <span className="title">Apparent Losses</span>
            <span className="value">7,444 m3</span>
            {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
          </div>
          <div className="quarter-height half-width column-body">
            <div className="section-dashboard-child half-height top t-l">
              <div className="column-body">
                <span className="title">Unauthorized Consumption</span>
                <span className="value">3,180 m3</span>
                {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
              </div>
            </div>
            <div className="section-dashboard-child half-height bottom t-l">
              <div className="column-body">
                <span className="title">Customer Meter Inaccuracies and Data Handling Errors</span>
                <span className="value">4,264 m3</span>
                {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="section-dashboard quarter-height t-l">
          <span className="title">Real Losses</span>
          <span className="value">10,756 m3</span>
          {/* <span className="unit">{dashboardData?.comercialLosses.unit}</span> */}
        </div>
      </div>
      <div className="column-dashboard column-other">
        <div className="section-dashboard quarter-height t-b">
          <span className="title">Revenue Water</span>
          <span className="value low">190,000 m3</span>
          <span className="value">86,35%</span>
          {/* <span className="unit">{dashboardData?.authConsume.unit}</span> */}
        </div>
        <div className="section-dashboard three-quarters-height t-l">
          <span className="title">Non-revenue Water</span>
          <span className="value low">30,000 m3</span>
          <span className="value">13,64%</span>
          {/* <span className="unit">{dashboardData?.waterLoss.unit}</span> */}
        </div>
      </div>
    </div>
  );
}