import Infrastructure from "../../components/Infrastructure";
import InfrastructureRepository from "./InfrastructureRepository";

export default class InfrastructureService {
  private infrastructureRepository: InfrastructureRepository =
    new InfrastructureRepository();

  async getInfrastructureWithParams(
    infreaestructureId: string
  ): Promise<Infrastructure> {
    return this.infrastructureRepository.getInfrastructureWithParams(
      infreaestructureId
    );
  }

  async getInfrastructureTypes(): Promise<Types[]> {
    return this.infrastructureRepository.getInfrastructureTypes();
  }

  async postAddParameter(
    infrastructureId: string,
    parameters: Parameter
  ): Promise<any> {
    return this.infrastructureRepository.postAddParameter(
      infrastructureId,
      parameters
    );
  }

  async getInfraestructureInUse(
    location: string | number,
    page: number,
    size: number,
    name: string | null,
    infraId: number | string | null,
    infraTypeId: number | string | null
  ): Promise<any> {
    return this.infrastructureRepository.getInfraestructureInUse(
      location,
      page,
      size,
      name,
      infraId,
      infraTypeId
    );
  }
  async getInfraestructureInUseV2(
    location: string | number,
    page: number,
    size: number,
    name: string | null,
    infraId: number | string | null,
    infraTypeId: number | string | null
  ): Promise<PaginatedInfrastructures> {
    const response =
      await this.infrastructureRepository.getInfraestructureInUse(
        location,
        page,
        size,
        name,
        infraId,
        infraTypeId
      );
    console.log("🚀 ~ InfrastructureService ~ response:", response);

    const parsedData = response.data.map((item: any) => {
      return {
        id: item.infraestructureId,
        name: item.infraestructureName,
        infraTypeId: item.infraTypeId,
        latitude: item.infraestructureLatitude,
        longitude: item.infraestructureLongitude,
        inUse: item.inUse,
        parameters: item.measurementParameters.map((param: any) => {
          return {
            id: param.paremeterId,
            name: param.paremeterName,
            serialNumberSource: param.serialNumberSource,
            parameterUnit: param.unit,
            principal: param.parameterPrincipal,
            inpId: param.inpId,
            inpObjectId: param.inpObjectId,
            inpObjectType: param.inpObjectType,
            sources: param.sources.map((source: any) => {
              return {
                id: source.sourceId,
                name: source.sourceName,
                sourceSerialNumber: source.serialNumber,
              } as ParameterSource;
            }),
          } as Parameter;
        }),
      } as Infrastructure;
    });

    return {
      infrastructures: parsedData,
      totalRecords: response.totalRecords,
    };
  }

  async getMeasurementTarget(infrastructureId: string): Promise<any> {
    return this.infrastructureRepository.getMeasurementTarget(infrastructureId);
  }

  async putInfraestructureDescription(
    infrastructureId: string | number,
    description: string
  ): Promise<any> {
    return this.infrastructureRepository.putInfraestructureDescription(
      infrastructureId,
      description
    );
  }

  async getAllInfrastructuresByOrganization({
    organizationId,
    page = 1,
    size = 20,
  }: getAllInfrastructuresByOrganizationRequest): Promise<PaginatedInfrastructures> {
    const response =
      await this.infrastructureRepository.getAllInfrastructuresByOrganization({
        organizationId,
        page,
        size,
      });
    const parsedData = response.data.map((infra: InfrastructureDTO) => {
      return {
        id: infra.infraId,
        name: infra.infraName,
        infraType: infra.infraType,
        infraTypeId: infra.infraTypeId,
        locationName: infra.location,
        activeParams: infra.activeParams,
        inactiveParams: infra.inactiveParams,
      } as Infrastructure;
    });
    return {
      infrastructures: parsedData,
      totalRecords: response.totalRecords,
    };
  }
}
