import { api } from "../../services/api";
// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

// let idLocation = localStorage.getItem("Location");

let GET_INFRASTRUCTURE_PARAMETER = "GET_INFRASTRUCTURE_PARAMETER";
let GET_INFRASTRUCTURE_PARAMETER_SUCCESS =
    "GET_INFRASTRUCTURE_PARAMETER_SUCCESS";
let GET_INFRASTRUCTURE_PARAMETER_ERROR = "GET_INFRASTRUCTURE_PARAMETER_ERROR";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_INFRASTRUCTURE_PARAMETER:
            return { ...state, fetching: true };
        case GET_INFRASTRUCTURE_PARAMETER_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_INFRASTRUCTURE_PARAMETER_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}

// actions (tunks)

export let getInfrastructureParameterAction =
    (idInfrastructure) => (dispatch, getState) => {
        dispatch({
            type: GET_INFRASTRUCTURE_PARAMETER,
        });
        return api
            .get(
                `${URL}/measurements/infrastructures/${idInfrastructure}/parameters/all/sources/all`
            )
            .then((res) => {
                dispatch({
                    type: GET_INFRASTRUCTURE_PARAMETER_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_INFRASTRUCTURE_PARAMETER_ERROR,
                    payload: err.response.message,
                });
            });
    };
