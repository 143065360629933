import dayjs from "dayjs";
import { getReadingsFromDevice } from "../services/historical";
import { getResumeFromDevice } from "../services/measurement";
import {paramsCardEventsService} from "../events/paramsCard.subject.js";

let ADD_READING_TO_LIST = "ADD_READING_TO_LIST";
let ADD_RESUME_TO_LIST = "ADD_RESUME_TO_LIST";
let ADD_RESUME_AND_READING_TO_LIST = "ADD_RESUME_AND_READING_TO_LIST";
let SET_ALL_LIST = "SET_ALL_LIST";
let SET_ALL_READINGS_LIST = "SET_ALL_READINGS_LIST";
let SET_ALL_RESUMES_LIST = "SET_ALL_RESUMES_LIST";

export default function reducer(state = { readings: [], resumes: [] }, action) {
  switch (action.type) {
    case ADD_READING_TO_LIST:
      return { ...state, readings: [...state.readings, action.payload] };
    case ADD_RESUME_TO_LIST:
      return { ...state, resumes: [...state.resumes, action.payload] };
    case ADD_RESUME_AND_READING_TO_LIST:
      return {
        ...state,
        resumes: [...state.resumes, action.payload.resume],
        readings: [...state.readings, action.payload.reading],
      };
    case SET_ALL_READINGS_LIST:
    return {
      ...state,
      readings: action.payload.readings,
    };
    case SET_ALL_RESUMES_LIST:
    return {
      ...state,
      resumes: action.payload.resumes,
    };
    case SET_ALL_LIST:
      return {
        ...state,
        readings: action.payload.readings,
        resumes: action.payload.resumes,
      };
    default:
      return state;
  }
}

export let addReadingToList =
  (paremeterId, serialNumberSource, dateFrom, dataTo) =>
  async (dispatch, getState) => {
    paramsCardEventsService.setSubject({
      type: "showReadingLoader",
      data: true,
    });
    const rangeDates = compareDates(dataTo, dateFrom);
    const response = await getReadingsFromDevice(
      paremeterId,
      serialNumberSource,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss"),
      rangeDates[0],
      rangeDates[1],
    );
    dispatch({
      type: ADD_READING_TO_LIST,
      payload: response,
    });
    paramsCardEventsService.setSubject({
      type: "showReadingLoader",
      data: false,
    });
  };

export let addResumeToList =
  (infrastructureId, paremeterId, serialNumberSource, dateFrom, dataTo) =>
  async (dispatch, getState) => {
    paramsCardEventsService.setSubject({
      type: "showResumeLoader",
      data: true,
    });
    const response = await getResumeFromDevice(
      infrastructureId,
      paremeterId,
      serialNumberSource,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
    );
    dispatch({
      type: ADD_RESUME_TO_LIST,
      payload: response,
    });
    paramsCardEventsService.setSubject({
      type: "showResumeLoader",
      data: false,
    });
  };

export let addResumeAndReaginToList =
  (infrastructureId, paremeterId, serialNumberSource, dateFrom, dataTo) =>
  async (dispatch, getState) => {
    const rangeDates = compareDates(dataTo, dateFrom);
    paramsCardEventsService.setSubject({
      type: "showReadingLoader",
      data: true,
    });
    paramsCardEventsService.setSubject({
      type: "showResumeLoader",
      data: true,
    });
    getReadingsFromDevice(
      paremeterId,
      null,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss"),
      rangeDates[0],
      rangeDates[1],
    ).then((reading) => {
      dispatch({
        type: ADD_READING_TO_LIST,
        payload: reading,
      });
      paramsCardEventsService.setSubject({
        type: "showReadingLoader",
        data: false,
      });
    });
    getResumeFromDevice(
      infrastructureId,
      paremeterId,
      null,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
    ).then((resume) => {
      dispatch({
        type: ADD_RESUME_TO_LIST,
        payload: resume,
      });
      paramsCardEventsService.setSubject({
        type: "showResumeLoader",
        data: false,
      });
    })
  };

  export let getAllList =
  (selectedSensors, dateFrom, dataTo) => async (dispatch, getState) => {
    const rangeDates = compareDates(dataTo, dateFrom);

    paramsCardEventsService.setSubject({
      type: "showReadingLoader",
      data: true,
    });
    paramsCardEventsService.setSubject({
      type: "showResumeLoader",
      data: true,
    });

    // Primero, obtenemos todas los readings
    const readingsPromises = selectedSensors.map((sensor) => {
      if (sensor.measurementTargetId) {
        return getReadingsFromDevice(
          sensor.measurementTargetId,
          null,
          dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
          dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss"),
          rangeDates[0],
          rangeDates[1]
        );
      }
    });

    // Luego, obtenemos todos los resúmenes
    const resumesPromises = selectedSensors.map((sensor) => {
      if (sensor.measurementTargetId) {
        return getResumeFromDevice(
          sensor.infrastructureId,
          sensor.measurementTargetId,
          sensor.serialNumberMeasurementTool,
          dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
          dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
        );
      }
    });



    // Esperamos que todas las promesas se resuelvan
    Promise.all(readingsPromises).then((readings) => {
      dispatch({
        type: SET_ALL_READINGS_LIST,
        payload: {
          readings,
        },
      });
      paramsCardEventsService.setSubject({
        type: "showReadingLoader",
        data: false,
      });
    });
    Promise.all(resumesPromises).then((resumes) => {
      dispatch({
        type: SET_ALL_RESUMES_LIST,
        payload: {
          resumes,
        },
      });
      paramsCardEventsService.setSubject({
        type: "showResumeLoader",
        data: false,
      });
    });
  };


export let restartResumeAndReadingList = () => async (dispatch, getState) => {
  dispatch({
    type: SET_ALL_LIST,
    payload: {
      readings: [],
      resumes: [],
    },
  });
};

export let deleteResumeAndReadingToList =
  (paremeterId, serialNumberSource) => (dispatch, getState) => {
    const currentState = getState().readingsResumes;
    const resumes = currentState.resumes.filter((resume) => {
      return resume && resume.parameterId != paremeterId;
    });
    console.log("🚀 ~ readings ~ currentState.readings:", currentState.readings)
    console.log("🚀 ~ readings ~ serialNumberSource:", serialNumberSource)
    console.log("🚀 ~ readings ~ paremeterId:", paremeterId)

    const readings = currentState.readings.filter((reading) => {
      return reading && !(
        reading.originalSourceSerialNumber == serialNumberSource &&
        reading.originalParameterId == paremeterId
      );
    });
    dispatch({
      type: SET_ALL_LIST,
      payload: { resumes, readings },
    });
  };

export let deleteReadingFromList =
  (paramId, serialNumberSource) => (dispatch, getState) => {
    const currentState = getState().readingsResumes;
    const resumes = currentState.resumes;
    const readings = currentState.readings.filter((reading) => {
      console.log("🚀 ~ readings ~ reading:", reading);
      return (
        reading &&
        !(
          reading.originalSourceSerialNumber == serialNumberSource &&
          reading.originalParameterId == paramId
        )
      );
    });
    dispatch({
      type: SET_ALL_LIST,
      payload: { resumes, readings },
    });
  };


  function compareDates(date1, date2) {
    const startDate = dayjs(date2);
    const endDate = dayjs(date1);
    const diffInMonths = endDate.diff(startDate, 'month', true); // true para obtener fracción de mes

    if (diffInMonths < 1) {
        return ['5', 'minute'];
    } else if (diffInMonths >= 1 && diffInMonths <= 6) {
        return ['15', 'minute'];
    } else {
        return ['1', 'hour'];
    }
  }