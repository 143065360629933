import React from "react";

type Props = {
  color: string;
};

export default function ParameterIconTableSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="6"
      viewBox="0 0 20 6"
      fill="none"
    >
      <path
        d="M19 1.99997H15.816C15.6124 1.41542 15.2318 0.908741 14.7273 0.550198C14.2227 0.191654 13.619 -0.000976562 13 -0.000976562C12.381 -0.000976562 11.7773 0.191654 11.2727 0.550198C10.7682 0.908741 10.3876 1.41542 10.184 1.99997H1C0.734784 1.99997 0.48043 2.10533 0.292893 2.29286C0.105357 2.4804 0 2.73475 0 2.99997C0 3.26519 0.105357 3.51954 0.292893 3.70708C0.48043 3.89461 0.734784 3.99997 1 3.99997H10.184C10.3876 4.58451 10.7682 5.0912 11.2727 5.44974C11.7773 5.80828 12.381 6.00091 13 6.00091C13.619 6.00091 14.2227 5.80828 14.7273 5.44974C15.2318 5.0912 15.6124 4.58451 15.816 3.99997H19C19.2652 3.99997 19.5196 3.89461 19.7071 3.70708C19.8946 3.51954 20 3.26519 20 2.99997C20 2.73475 19.8946 2.4804 19.7071 2.29286C19.5196 2.10533 19.2652 1.99997 19 1.99997ZM13 3.99997C12.8022 3.99997 12.6089 3.94132 12.4444 3.83144C12.28 3.72156 12.1518 3.56538 12.0761 3.38265C12.0004 3.19993 11.9806 2.99886 12.0192 2.80488C12.0578 2.6109 12.153 2.43271 12.2929 2.29286C12.4327 2.15301 12.6109 2.05777 12.8049 2.01918C12.9989 1.9806 13.2 2.0004 13.3827 2.07609C13.5654 2.15178 13.7216 2.27995 13.8315 2.4444C13.9414 2.60885 14 2.80219 14 2.99997C13.9997 3.2651 13.8943 3.5193 13.7068 3.70678C13.5193 3.89426 13.2651 3.9997 13 3.99997Z"
        fill={"#74788D"}
      />
    </svg>
  );
}
