import { api } from "../services/api";

// constants
let initialData = {
    array: [],
};

let URL = process.env.REACT_APP_BASE_URL;

let READ_MODULE_DATA = "READ_MODULE_DATA";
let NO_DATA = "NO_DATA";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case READ_MODULE_DATA: {
            return { ...state, array: action.payload.map((data) => data) };
        }
        default:
            return state;
    }
}
// actions (tunks)

export const getModulesAction = () => (dispatch, getState) => {
    return api
        .get(`${URL}/security/modules`)
        .then((res) => {
            dispatch({
                type: READ_MODULE_DATA,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: NO_DATA,
                payload: err.response.message,
            });
        });
};

export const noAction = () => ({ type: NO_DATA });
