import { Select } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import useUser from "../../hooks/useUser";
const SelectUser = forwardRef(
  (
    {
      organizationId,
      userName,
      roleId,
      onSelect,
      emitObject = false,
    }: {
      organizationId: string;
      userName?: string | null;
      roleId?: number | null;
      onSelect: Function;
      emitObject?: boolean;
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const { users, setParams } = useUser({
      organizationId,
      userName,
      roleId,
    });

    useImperativeHandle(ref, () => ({
      cleanValue: () => {
        setSelectedValue(null);
      },
    }));

    return (
      <Select
        id="userId"
        value={selectedValue}
        notFoundContent={null}
        onChange={(val) => {
          setSelectedValue(val);
          onSelect(emitObject ? JSON.parse(val || "{}") : val);
        }}
        onSearch={(val) => {
          if (val.length >= 3) {
            setParams((params: any) => {
              return {
                ...params,
                userName: val,
              };
            });
          }
        }}
        onClear={() => {
          setParams((params: any) => {
            return {
              ...params,
              userName: null,
            };
          });
        }}
        style={{ width: "200px" }}
        options={(users || []).map((u) => ({
          value: emitObject ? JSON.stringify(u) : u.userId,
          label: `${u.firstName} ${u.lastName}`,
        }))}
        placeholder="User"
        showSearch
        allowClear
      />
    );
  }
);

export default SelectUser;
