import { Button, Modal } from "antd";
import * as React from "react";

export interface IConfirmationModalProps {
  showModal: boolean;
  title: string;
  message?: string;
  oncancel: Function;
  onAccept: Function;
  showWarning?: boolean;
}

export default function ConfirmationModal({
  showModal,
  title,
  message,
  oncancel,
  onAccept,
  showWarning = false,
}: IConfirmationModalProps) {
  return (
    <Modal
      open={showModal}
      footer={[]}
      closable={false}
      style={{ maxWidth: "300px" }}
    >
      <div className="flex column items-center">
        <span
          className="mb-2"
          style={{ color: "#232B36", fontSize: "1.2rem", fontWeight: "bold" }}
        >
          {title}
        </span>
        <span
          className="mb-3"
          style={{ color: "#74788D", fontSize: "1rem", fontWeight: "600" }}
        >
          {message}
        </span>
        {showWarning && (
          <div
            className="flex column mb-2"
            style={{
              background: "rgba(255, 211, 211, 0.5)",
              borderRadius: "1rem",
              padding: "1rem",
              color: "#F46A6A",
            }}
          >
            <b>Warning</b>
            <span>The deleted record cannot be recovered</span>
          </div>
        )}
        <div className="separator mb-2" />
        <div className="flex">
          <Button
            className="mr-3"
            style={{ width: "85px", height: "40px" }}
            onClick={() => oncancel()}
          >
            No
          </Button>
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={async () => onAccept()}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}
