/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { renderToString } from "react-dom/server";
import DeckGL, { IconLayer } from "deck.gl";
// import { TripsLayer } from "@deck.gl/geo-layers";
import { PathLayer } from "@deck.gl/layers";
import { _MapContext as MapContext, StaticMap, Popup } from "react-map-gl";
// import PopupComponent from "./Popup";
import { getInfraestructuresByLocation } from "../../services/measurement";
import { getMapsAction } from "../../redux/mapDuck";
import "../../css/content.css";
import "./InteractiveSimulationMap.scss";
import { useParams } from "react-router-dom";
import { interactiveSimulationsEventsService } from "../../events/interactiveSimulations";
import { getLinksByLocation, getLinksMinify } from "../../services/links";
import TourInteractiveSimulationContext from "./Context";
import { Button, Popover } from "antd";

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";

const initialStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

// Viewport settings
const initialViewState = {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 11.5,
  pitch: 0,
  bearing: 0,
};

const BLUE = [30, 150, 190];
const FLOW_COLOR = [72, 43, 189];

// const multiply = 100;
// const maxTime = 10;
// const step = 2;
// variables

const userId = localStorage.getItem("userId");

const Content = (props) => {
  const {
    selectedLocation,
    selectedMap,
    editValueInfra,
    infrasEdited,
    setInfraestructures,
    iterationId,
  } = props;
  const { locationId, simulationId } = useParams();
  const subscriptionIS$ = interactiveSimulationsEventsService.getSubject();
  const [viewState, setViewState] = useState(initialViewState);
  // const [time, setTime] = useState(1);
  const [linksLayer, setLinkLayer] = useState([]);
  const [infrasLayer, setInfrasLayer] = useState([]);
  // const [animateLayer, setAnimateLayer] = useState([]);
  const [infras, setInfras] = useState(null);
  const [links, setLinks] = useState(null);
  // const [linksMinify, setLinksMinify] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  // const [infraInfo, setInfraInfo] = useState(null);
  const [selectedInfra, setSelectedInfra] = useState(null);
  const [popupCoordinates, setPopupCoordinates] = useState([]);
  const [animation] = useState({});
  const dispatch = useDispatch();
  const { value: context, setValue: setContext } = useContext(
    TourInteractiveSimulationContext
  );

  // let dataToGraph = null;
  // const maxTimestamp = useRef(null);

  // useImperativeHandle(
  //   ref,
  //   () => {
  //     return {
  //       setInfrasMap(data) {
  //         setInfras(data);
  //       },
  //       getInfras() {
  //         return infras;
  //       },
  //     };
  //   },
  //   []
  // );

  useEffect(() => {
    return () => window.cancelAnimationFrame(animation.id);
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      fetchData();
      loadMapInfo();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedMap.latitude && selectedMap.longitude) {
      setViewState({
        longitude: selectedMap.longitude,
        latitude: selectedMap.latitude,
        zoom: selectedMap.zoomLevel,
        pitch: 0,
        bearing: 0,
      });
    }
  }, [selectedMap]);

  useEffect(() => {
    if (infras) {
      drawInfras();
    }
  }, [infras]);

  useEffect(() => {
    if (infras && infras.length > 0 && iterationId) {
      subscriptionIS$.subscribe((event) => {
        if (event.type == "dataSocketRecived") {
          event.data.forEach((res) => {
            let infrasCopy = [...infras];
            const index = infrasCopy.findIndex((infra) => {
              return (
                infra.mainMeasurementTarget.measurementTargetId == res.paramId
              );
            });
            if (index >= 0) {
              infrasCopy[index].mainMeasurementTarget.currentValue = res.value;
              infrasCopy[index].mainMeasurementTarget.usage =
                (res.value /
                  infrasCopy[index].mainMeasurementTarget.criticalHhigh) *
                100;

              setInfras(infrasCopy);
            }
          });
        }
      });
    }
  }, [iterationId]);

  const getEditableInfras = useCallback(() => {
    return infras
      ? infras.filter((infra) => infra.editableAttributes == true)
      : [];
  }, [infras]);

  const loadMapInfo = async () => {
    await dispatch(getMapsAction(locationId));
    if (selectedMap.latitude && selectedMap.longitude) {
      setViewState({
        longitude: selectedMap.longitude,
        latitude: selectedMap.latitude,
        zoom: selectedMap.zoomLevel,
        pitch: 0,
        bearing: 0,
        // transitionDuration: 2000,
        // transitionInterpolator: new FlyToInterpolator(),
      });
    }
  };

  // const animate = async () => {
  //   /setTimeout(() => {
  //   setTime((t) => (t + step) % (maxTime * multiply));
  //   /setTime((t) => {
  //        return (t + step) % maxTimestamp.current;
  //    });
  //   animation.id = window.requestAnimationFrame(animate); // draw next frame
  //    }, 10);
  // };

  const fetchData = async () => {
    const infrasResult = await getInfraestructuresByLocation(locationId);
    if (infrasResult) {
      setInfras(infrasResult);
      setInfraestructures(infrasResult);
    }
    const linksResultMinify = await getLinksMinify(locationId);

    if (linksResultMinify) {
      setLinks(linksResultMinify);
    }
  };

  const drawInfras = () => {
    if (!infras) return;
    const data = infras.map((infra) => {
      return {
        ...infra,
        position: [parseFloat(infra.longitude), parseFloat(infra.latitude)],
        icon: getImageUsageValue(
          infra.mainMeasurementTarget.usage,
          infra.editableAttributes,
          infra.measurementeInfraestructureId
        ),
      };
    });
    setInfrasLayer([
      new IconLayer({
        id: "icon-layer",
        data,
        pickable: true,
        getPosition: (d) => d.position,
        getIcon: (d) => ({
          url: d.icon,
          width: 200,
          height: 200,
        }),
        sizeScale: 1,
        getSize: 40,
        onHover: ({ object }) => {},
      }),
    ]);
  };

  const drawLinks = () => {
    if (!links) return;
    const ConduitsS = links.filter((item) => item.size === "S");
    const ConduitsM = links.filter((item) => item.size === "M");
    const ConduitsB = links.filter((item) => {
      return item.size === "B";
    });
    setLinkLayer([
      new PathLayer({
        id: "conduitsS",
        data: ConduitsS,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 1,
        rounded: true,
      }),
      new PathLayer({
        id: "conduitsM",
        data: ConduitsM,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 3,
        rounded: true,
      }),
      new PathLayer({
        id: "conduitsB",
        data: ConduitsB,
        getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
        getColor: (d) => BLUE,
        opacity: 1,
        widthMinPixels: 5,
        rounded: true,
      }),
    ]);
  };

  // const drawTripsLinks = () => {
  //   if (!links) return;
  //   const trailLength = 60 * 1000;
  //   setAnimateLayer([
  //     new TripsLayer({
  //       id: "trips",
  //       data: linksMinify,
  //       getPath: (d) => d.coordinates.map((l) => [l.longitude, l.latitude]),
  //       getTimestamps: (d) => d.timestamps,
  //       getColor: (d) => RED,
  //       fadeTrail: true,
  //       opacity: 0.5,
  //       widthMinPixels: 8,
  //       rounded: true,
  //       trailLength: 300,
  //       currentTime: time,
  //     }),
  //   ]);
  // };

  function handleClickMap(event) {
    if (event.picked) {
      if (
        event.object?.measurementeInfraestructureId &&
        event.object?.editableAttributes
      ) {
        showPopUpDevice(event.object);
        setContext({ ...context, activeStep: 4 });
      }
    } else {
      setShowPopup(false);
      setSelectedInfra(null);
    }
  }

  function tooltipToshow(infra) {
    const element = (
      <>
        <div style={{ borderBottom: "solid 4px rgb(7,140,179)" }}>
          <span style={{ textAlign: "center" }}>
            {infra.measurementInfraestructureName}
          </span>
        </div>
        <div>
          {`${infra.mainMeasurementTarget?.currentValue} ${infra.mainMeasurementTarget?.unit}`}
        </div>
      </>
    );
    return renderToString(element);
  }

  function showPopUpDevice(infra) {
    const coordinates = infra.position.slice();
    setPopupCoordinates(coordinates);
    setSelectedInfra(infra);
    setShowPopup(true);
  }

  function getImageUsageValue(
    usage,
    editableAttributes,
    measurementeInfraestructureId
  ) {
    if (editableAttributes) {
      if (usage <= 0) {
        return `/img/map/new-devices-edited.png`;
      }
      if (usage <= 25) {
        return "/img/map/new-devices-edited-25.png";
      }
      if (usage <= 50) {
        return "/img/map/new-devices-edited-50.png";
      }
      if (usage <= 75) {
        return "/img/map/new-devices-edited-75.png";
      }
      if (usage <= 100) {
        return "/img/map/new-devices-edited-100.png";
      }
      if (usage <= 125) {
        return "/img/map/new-devices-edited-125.png";
      }
      if (usage <= 150) {
        return "/img/map/new-devices-edited-150.png";
      }
      if (usage <= 175) {
        return "/img/map/new-devices-edited-175.png";
      }
      if (usage <= 200) {
        return "/img/map/new-devices-edited-200.png";
      }

      return `/img/map/new-devices-edited.png`;
    } else {
      if (usage <= 0) {
        return `/img/map/new-devices.png`;
      }
      if (usage <= 25) {
        return "/img/map/new-devices-25.png";
      }
      if (usage <= 50) {
        return "/img/map/new-devices-50.png";
      }
      if (usage <= 75) {
        return "/img/map/new-devices-75.png";
      }
      if (usage <= 100) {
        return "/img/map/new-devices-100.png";
      }
      if (usage <= 125) {
        return "/img/map/new-devices-125.png";
      }
      if (usage <= 150) {
        return "/img/map/new-devices-150.png";
      }
      if (usage <= 175) {
        return "/img/map/new-devices-175.png";
      }
      if (usage <= 200) {
        return "/img/map/new-devices-200.png";
      }

      return `/img/map/new-devices.png`;
    }
  }

  // function changeAnimate(val) {
  //   if (val.target.checked) {
  //     console.log("Animate");
  //     drawTripsLinks();
  //     animation.id = window.requestAnimationFrame(animate);
  //   } else {
  //     setAnimateLayer([]);
  //     console.log("Cancelar");
  //     window.cancelAnimationFrame(animation.id);
  //     animation.id = 0;
  //   }
  // }

  useEffect(() => {
    drawLinks();
  }, [links]);

  return (
    <div
      className="MapContainer"
      style={{ height: "100%", width: "100%", position: "absolute" }}
    >
      <DeckGL
        controller
        ContextProvider={MapContext.Provider}
        viewState={viewState}
        layers={[...linksLayer, ...infrasLayer]}
        style={initialStyle}
        onViewStateChange={(nextViewState) => {
          setViewState(nextViewState.viewState);
        }}
        onClick={handleClickMap}
        getTooltip={({ object }) =>
          object && {
            html: tooltipToshow(object),
            style: {
              backgroundColor: "#fff",
            },
          }
        }
      >
        <>
          <StaticMap
            mapboxApiAccessToken={mapboxApiAccessToken}
            mapStyle={mapStyle}
          ></StaticMap>
          {showPopup && (
            <Popup
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              anchor="bottom"
              style={{ width: "300px" }}
              onClose={() => setShowPopup(false)}
            >
              <div
                className="flex column items-center"
                style={{ width: "300px" }}
              >
                <span
                  className="mt-2"
                  style={{ color: "#023E65", fontWeight: "600" }}
                >
                  {selectedInfra.measurementInfraestructureName}
                </span>
                <div
                  className="my-2"
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#E6E6E6",
                  }}
                />
                <span
                  style={{ color: "#74788D" }}
                >{`${selectedInfra.mainMeasurementTarget.currentValue} ${selectedInfra.mainMeasurementTarget.unit} (${selectedInfra.mainMeasurementTarget.usage}% Usage)`}</span>
                <Popover
                  placement="left"
                  color="#023E65"
                  open={context.showTour && context.activeStep == 4}
                  content={
                    <div className="flex column">
                      <div className="flex column text-white">
                        <span>
                        Now click on edit values
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <Button
                          type="text"
                          style={{ color: "white" }}
                          onClick={() => {
                            setContext({ ...context, activeStep: 3 });
                          }}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <button
                    className="m-2 px-1"
                    style={{
                      backgroundColor: "#023E65",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      editValueInfra(selectedInfra);
                      setContext({ ...context, activeStep: 5})
                    }}
                  >
                    Edit settings
                  </button>
                </Popover>
              </div>
            </Popup>
          )}
          {context.showTour &&
            context.activeStep == 3 &&
            getEditableInfras().length > 0 && (
              <Popup
                longitude={parseFloat(getEditableInfras()[0].longitude)}
                latitude={parseFloat(getEditableInfras()[0].latitude)}
                anchor="bottom"
                style={{ width: "300px", backgroundColor: "#023E65" }}
                onClose={() => setShowPopup(false)}
              >
                <div
                  className="flex column"
                  style={{
                    backgroundColor: "#023E65",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <div className="flex column text-white">
                    <span>
                      Then look for the infrastructure to edit and click on it.
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <Button
                      type="text"
                      style={{ color: "white" }}
                      onClick={() => {
                        setContext({ ...context, activeStep: 2 });
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Popup>
            )}
          {/* <Toggle
            onClick={changeAnimate}
            style={{
              position: "absolute",
              right: "20px",
              bottom: "27px",
            }}
            checkedChildren="Hide animation"
            unCheckedChildren="Add animation"
          /> */}
        </>
      </DeckGL>
    </div>
  );
};

function mapState(state) {
  return {
    selectedLocation: state.locations.selectedLocation,
    selectedMap: state.maps.array,
  };
}

export default connect(mapState)(Content);
