import React, { useLayoutEffect } from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Input,
  Select,
  Switch,
  Modal,
  TreeDataNode,
  Tooltip,
} from "antd";
import Icon, {
  ArrowLeftOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import LocationService from "../../domain/Location/LocationService";
import LocationsContext from "./context";
import { Tree } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import Map, { Marker, NavigationControl } from "react-map-gl";
import CreateLocationModal from "../../components/Locations/CreateLocationModal";
import Pin from "../../components/Locations/CreateLocationModal/pin";
import "./locationDetail.scss";

const locationService = new LocationService();

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
// Viewport settings
const initialViewState = {
  longitude: -86.250275,
  latitude: 41.676388,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LocationsDetail: React.FC = () => {
  let { locationId } = useParams();
  let query = useQuery();
  let locations: Location[] = [];
  const navigate = useNavigate();
  const locationsCtx = useContext(LocationsContext);
  // const [locations, setLocations] = useState<Location[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [locationSelect, setLocationSelect] = useState<ItemDataType | null>(
    null
  );
  const [isEdit, setIsEdit] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [viewport, setViewport] = useState(initialViewState);
  const [marker, setMarker] = useState<any>(null);
  const [queryFilter, setQuery] = useState("");
  const [tempQueryFilter, setTempQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [existData, setExistData] = useState(false);

  function treeNodeFromLocation(location: Location): any {
    return {
      id: location.id,
      label: location.name,
      value: JSON.stringify(location),
      visible: location.visible,
      ...(location.childrens?.length > 0
        ? {
            children: location.childrens.map((loc) =>
              treeNodeFromLocation(loc)
            ),
          }
        : {}),
    };
  }

  function locationsFromTreeNode(treeNode: ItemDataType, level: number): any {
    if (!treeNode.value) return;
    const node = treeNode.value;
    const location = JSON.parse(node.toString());
    return {
      ...(location.id ? { id: location.id } : {}),
      name: location.name,
      latitude: location.latitude,
      longitude: location.longitude,
      visible: location.visible,
      zoom: location.zoom,
      level: level,
      networkType: location.networkType,
      ...(treeNode.children && treeNode.children.length > 0
        ? {
            childLocations: treeNode.children.map(
              (loc: ItemDataType<string | number>) => {
                const childLocation = locationsFromTreeNode(loc, level + 1);
                console.log("Child Location:", childLocation);
                return childLocation;
              }
            ),
          }
        : {}),
    };
  }

  function searchAndAdd(
    array: any[],
    key: string | number,
    value: any,
    newValue: any
  ): any[] {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        if (array[i]["children"]) {
          array[i]["children"].push(newValue);
        } else {
          array[i]["children"] = [newValue];
        }
        return array;
      } else if (array[i].children) {
        searchAndAdd(array[i].children, key, value, newValue);
      }
    }
    return array;
  }

  function searchAndReplace(array: any[], value: any, newValue: any): any[] {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === value) {
        array[i] = treeNodeFromLocation(newValue);
        return array;
      } else if (array[i].children) {
        searchAndReplace(array[i].children, value, newValue);
      }
    }
    return array;
  }

  function existInArray(array: any[], value: any): boolean {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === value) {
        return true;
      } else if (array[i].children) {
        existInArray(array[i].children, value);
      }
    }
    return false;
  }

  function countChildren(object: ItemDataType<string | number>): number {
    let count = 0;
    if (object["children"]) {
      for (let i = 0; i < object["children"].length; i++) {
        count++;
        count += countChildren(object["children"][i]);
      }
    }
    return count;
  }

  function filterArray(
    array: any[],
    label: string | null = null,
    status: boolean | null = null
  ): any[] {
    let filteredArray: any[] = [];
    for (let i = 0; i < array.length; i++) {
      if (
        (label === null || array[i]["label"].indexOf(label) > -1) &&
        (status === null || array[i]["status"] === status)
      ) {
        if (!existInArray(filteredArray, array[i].id)) {
          filteredArray.push(array[i]);
        }
        // filteredArray.push(array[i]);
      }
      if (array[i]["children"]) {
        let childrensFiltered = filterArray(
          array[i]["children"],
          label,
          status
        );
        if (
          childrensFiltered.length > 0 &&
          !existInArray(filteredArray, array[i].id)
        ) {
          filteredArray.push(array[i]);
        }
        // filteredArray = filteredArray.concat(
        //   childrensFiltered.filter(
        //     (obj) => !existInArray(filteredArray, obj.id)
        //   )
        // );
      }
    }
    return filteredArray;
  }

  async function getLocations() {
    setShowTree(false);
    if (!locationId) return;
    const security = localStorage.getItem("security");
    if (!security) return;
    const organizationId = JSON.parse(security).organizationId;
    const res = await locationService.getLocationsByCountryOrganization(
      locationId,
      organizationId,
      queryFilter != "" ? queryFilter : undefined,
      statusFilter != "" ? statusFilter : undefined
    );
    locations = res;
    const data = locations.map((loc) => treeNodeFromLocation(loc));
    if (queryFilter == "" && statusFilter == "" && data.length > 0) {
      setExistData(true);
    }
    setTreeData(data);
    if (data && data.length > 0) {
      const row = data[0];
      console.log("🚀 ~ getLocations ~ row:", row);
      if (row.latitude && row.longitude) {
        setMarker({
          latitude: row.latitude,
          longitude: row.longitude,
        });
        setViewport({
          ...viewport,
          longitude: parseFloat(row.longitude),
          latitude: parseFloat(row.latitude),
          zoom: parseFloat(row.zoom),
        });
      }
    }
    setShowTree(true);
  }

  async function handleUpdateLocations(newTreeData: ItemDataType) {
    console.log("newTreeData", newTreeData);
    if (!locationId) return;
    const security = localStorage.getItem("security");
    if (!security) return;
    const organizationId = JSON.parse(security).organizationId;
    const newLocations = newTreeData.map(
      (node: ItemDataType<string | number>) => {
        return locationsFromTreeNode(node, 1);
      }
    );
    const res = await locationService.postLocationsByCountryOrganization(
      locationId,
      organizationId,
      newLocations
    );
    if (res) {
      setShowCreationModal(false);
      getLocations();
    }
  }

  async function handleChangeVisibleLocation(
    location: ItemDataType<string | number>
  ) {
    if (!location || !location.value) return;
    const security = localStorage.getItem("security");
    if (!security) return;
    const organizationId = JSON.parse(security).organizationId;
    const res = await locationService.changeEnableLocation(
      organizationId,
      location.id,
      JSON.parse(location?.value?.toString()).visible
    );
    if (res) {
      getLocations();
    }
  }

  useEffect(() => {
    if (!locationId) return navigate(`/locations`);
    getLocations();
  }, [statusFilter, queryFilter]);

  useEffect(() => {
    console.log("🚀 ~ file: locationDetail.tsx:257 ~ treeData", treeData);
    if (showTree) {
      // handleUpdateLocations();
    }
  }, [treeData, showTree]);

  return (
    <div style={{ padding: "0px 3rem" }}>
      <div>
        <Button
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(`/locations`);
          }}
        >
          <ArrowLeftOutlined /> Back to regions
        </Button>
      </div>
      {existData ? (
        <div
          className="grid"
          style={{
            gridTemplateColumns: "1fr minmax(300px, 0.7fr)",
            columnGap: "2rem",
          }}
        >
          <div className="pt-3">
            <span className="text-title-page">
              {query.get("name")} locations
            </span>
            <div className="flex items-center mt-4">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ color: "#078CB3" }} />}
                style={{ width: "160px", height: "32px" }}
                className="mr-2"
                onBlur={(event) => {
                  setQuery(event.target.value);
                  setTempQuery(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setQuery(tempQueryFilter);
                  }
                }}
                onChange={(e) => {
                  setTempQuery(e.target.value);
                }}
              />
              <Select
                placeholder="Select"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
                allowClear
                options={[
                  { value: "true", label: "Active" },
                  { value: "false", label: "Inactive" },
                ]}
                style={{ width: "120px", height: "32px" }}
              />
              <Button
                type="primary"
                shape="round"
                icon={<PlusCircleOutlined />}
                className="flex items-center ml-auto"
                style={{
                  backgroundColor: "#023E65",
                  color: "white",
                  width: "130px",
                  height: "40px",
                }}
                onClick={() => {
                  setIsEdit(false);
                  setLocationSelect(null);
                  setShowCreationModal(true);
                }}
              >
                Add location
              </Button>
            </div>
            <div className="mt-3">
              <Card title="Locations">
                {showTree && (
                  <Tree
                    data={treeData}
                    draggable
                    defaultExpandAll={true}
                    onDrop={({ createUpdateDataFunction }, event) =>
                      setTreeData(createUpdateDataFunction(treeData))
                    }
                    renderTreeNode={(item) => (
                      <div
                        className="flex w-full justify-between"
                        onClick={() => {
                          const row = JSON.parse(
                            item?.value?.toString() || "{}"
                          );
                          if (row.latitude && row.longitude) {
                            setMarker({
                              latitude: row.latitude,
                              longitude: row.longitude,
                            });
                            setViewport({
                              ...viewport,
                              longitude: parseFloat(row.longitude),
                              latitude: -89.90,
                              zoom: parseFloat(row.zoom),
                            });
                          }
                        }}
                      >
                        <span>{item.label}</span>
                        <div className="flex">
                          <Switch
                            defaultChecked={
                              JSON.parse(item.value?.toString() || "{}").visible
                            }
                            onChange={(checked) => {
                              handleChangeVisibleLocation(item);
                            }}
                            className="mr-8"
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#023E65",
                              textDecoration: "underline",
                            }}
                            className="mr-8"
                            onClick={() => {
                              setIsEdit(false);
                              setLocationSelect(item);
                              setShowCreationModal(true);
                            }}
                          >
                            Add location
                          </span>
                          <Tooltip title="Delete">
                            <DeleteOutlined
                              className="mr-1"
                              style={{ fontSize: "1.5rem" }}
                              onClick={() => {
                                setLocationSelect(item);
                                setShowDeleteModal(true);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Edit">
                            <EditOutlined
                              className="mr-1"
                              style={{ fontSize: "1.5rem" }}
                              onClick={() => {
                                setIsEdit(true);
                                setLocationSelect(item);
                                setShowCreationModal(true);
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                    style={{ height: "calc(100vh - 380px)" }}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="map-container flex items-center">
            <div style={{ width: "100%", height: "80%" }}>
              <Map
                {...viewport}
                mapboxApiAccessToken={mapboxApiAccessToken}
                mapStyle={mapStyle}
                style={initialStyle}
                width="100%"
                height="100%"
                onViewportChange={setViewport}
              >
                {marker && (
                  <Marker
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    offsetLeft={-20}
                    offsetTop={-10}
                  >
                    <Pin size={30} />
                  </Marker>
                )}
                <NavigationControl />
              </Map>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="pt-3 flex column"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <span className="text-title-page">{query.get("name")} locations</span>
          <div className="flex column self-center mb-auto mt-auto">
            <span
              style={{ color: "#232B36", width: "180px", textAlign: "center" }}
              className="mb-2"
            >
              Now you only need to click on “Add location” button to continue!
            </span>
            <Button
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined />}
              className="flex items-center self-center"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "130px",
                height: "40px",
              }}
              onClick={() => {
                setIsEdit(false);
                setLocationSelect(null);
                setShowCreationModal(true);
              }}
            >
              Add location
            </Button>
          </div>
        </div>
      )}
      <CreateLocationModal
        isEdit={isEdit}
        isModalOpen={showCreationModal}
        selectLocation={locationSelect}
        onAcept={(location: any, selectLocation: any) => {
          if (isEdit) {
            const newArray = searchAndReplace(
              treeData,
              selectLocation.id,
              location
            );
            handleUpdateLocations(newArray);
          } else {
            if (selectLocation) {
              const copy = [...treeData];
              const newArray: ItemDataType[] = searchAndAdd(
                copy,
                "value",
                selectLocation.value,
                {
                  label: location.name,
                  value: JSON.stringify(location),
                  visible: location.visible,
                }
              );
              handleUpdateLocations(newArray);
            } else {
              const newArray = [
                ...treeData,
                {
                  label: location.name,
                  value: JSON.stringify(location),
                  visible: location.visible,
                },
              ];
              handleUpdateLocations(newArray);
              // setTreeData((currentValue) => [
              //   ...currentValue,
              //   {
              //     label: location.name,
              //     value: JSON.stringify(location),
              //     visible: location.visible,
              //   },
              // ]);
              // handleUpdateLocations();
            }
          }
        }}
        onCancel={() => setShowCreationModal(false)}
      />
      <Modal
        open={showDeleteModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
      >
        <div className="flex column items-center">
          <span
            className="mb-2"
            style={{ color: "#232B36", fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Delete Location?
          </span>
          <span
            className="mb-3"
            style={{ color: "#74788D", fontSize: "1rem", fontWeight: "600" }}
          >
            Are you sure you want to delete {locationSelect?.label}
          </span>
          {locationSelect !== null && countChildren(locationSelect) > 0 ? (
            <div
              className="flex column mb-2"
              style={{
                background: "rgba(255, 211, 211, 0.5)",
                borderRadius: "1rem",
                padding: "1rem",
                color: "#F46A6A",
              }}
            >
              <b>Warning</b>
              <span>
                By deleting this location {countChildren(locationSelect)} other
                locations will also be deleted
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="separator mb-2" />
          <div className="flex">
            <Button
              className="mr-3"
              style={{ width: "85px", height: "40px" }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={async () => {
                if (locationSelect && locationSelect.value) {
                  await locationService.deleteLocation(
                    JSON.parse(locationSelect?.value?.toString()).id
                  );
                  setShowDeleteModal(false);
                  getLocations();
                }
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LocationsDetail;
