import { Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useInfrastructure from "../../hooks/useInfrastructure";

const SelectInfrastructure = forwardRef(
  (
    {
      location = null,
      organizationId = null,
      onSelect,
      labelInValue = false,
      mode,
    }: {
      location?: number | null;
      organizationId?: number | null;
      name?: string | null;
      onSelect: Function;
      labelInValue?: boolean;
      mode: "ByLocation" | "ByOrganization";
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const { infrastructures, setParams } = useInfrastructure({
      mode,
      location,
      organizationId,
      size: 50,
    });

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
    }));

    useEffect(() => {
      setParams((old: any) => {
        return { ...old, location };
      });
    }, [location]);

    return (
      <Select
        value={selectedValue}
        showSearch
        labelInValue
        defaultActiveFirstOption={false}
        onSearch={(newValue: string) => {
          setParams((params: any) => {
            return { ...params, name: newValue };
          });
        }}
        id="parameter"
        onChange={(val: object) => {
          setSelectedValue(val);
          onSelect(val);
        }}
        style={{ width: "100%" }}
        options={(infrastructures || []).map((i) => ({
          value: i.id,
          label: i.name,
        }))}
      />
    );
  }
);

export default SelectInfrastructure;
