import { api } from "../../services/api";
import { apiNl } from "../../services/apiNl";

export default class DigitalTwinRepository {
  async postDigitalTwin(
    data: any,
    infrastructureId: any,
    parameterId: any
  ): Promise<boolean> {
    const bulkIot = await api.post(
      `digitaltwins/neuralnetworks/infrastructures/${infrastructureId}/parameters/${parameterId}/train`,
      data
    );
    return bulkIot.data;
  }

  async getDigitalTwins(
    infrastructureId: number | string,
    paramId: number | string,
    loading: boolean = true
  ) {
    const axiosInstance = loading ? api : apiNl;
    const response = await axiosInstance.get(
      `digitaltwins/neuralnetworks/infrastructures/${infrastructureId}/params/${paramId}`
    );
    return response.data;
  }

  async deleteDigitalTwin(neuralNetworkId: any): Promise<boolean> {
    const response = await api.delete(
      `digitaltwins/neuralnetworks/${neuralNetworkId}`
    );
    return response.data;
  }

  async getTestModel(neuralNetworkId: number | string): Promise<boolean> {
    const response = await api.get(
      `/digitaltwins/neuralnetworks/${neuralNetworkId}/testModel`
    );
    return response.data;
  }

  async setFavoriteModel(neuralNetworkId: number | string): Promise<boolean> {
    const response = await api.patch(
      `digitaltwins/neuralnetworks/${neuralNetworkId}/fav`
    );
    return response.data;
  }

  async getDefaultConfiguration() {
    const response = await api.get(
      `digitaltwins/neuralnetworks/advancedConfiguration`
    );
    return response.data;
  }

  async postConfiguration({
    parameterId,
    data,
  }: {
    parameterId: Number | String;
    data: {
      inpId: Number;
      inpObjectId: Number;
      inpObjectType: Number;
    };
  }) {
    const response = await api.post(
      `measurements/parameters/${parameterId}/neuralNetwork/configuration`,
      data
    );
    return response.data;
  }

  async getConfiguration({ paramId, type }: { paramId: Number; type: String }) {
    const response = await api.get(`modelConfiguration/${paramId}/${type}`);
    return response.data;
  }
}
