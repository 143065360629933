import { Button, Placeholder, Modal as RModal } from "rsuite";
import { ReactComponent as SuccessSvg } from "../../assets/icons/success.svg";
import { ReactComponent as ErrorSvg } from "../../assets/icons/error.svg";
import { WarningOutlined } from "@ant-design/icons";
import "./ModalAlert.scss";
import { useMemo } from "react";

const titles = {
  success: "Success",
  error: "Error",
  warning: "Warning",
};

const icons = {
  success: <SuccessSvg />,
  error: <ErrorSvg />,
  warning: <WarningOutlined style={{ fontSize: '32px', color: 'orange' }}/>,
};

const colors = {
  success: "green",
  error: "red",
  warning: "orange",
};

function ModalAlert({ show, title, message, onClose, type = "success" }) {
  const titleText = useMemo(() => title || titles[type], [title, type]);
  const iconToShow = useMemo(() => icons[type], [type]);
  return (
    <RModal className="modal-alert" open={show} onClose={onClose}>
      <RModal.Header closeButton={false}>
        {iconToShow}
        <RModal.Title>{titleText}</RModal.Title>
      </RModal.Header>
      <RModal.Body>
        <span>{message || ""}</span>
      </RModal.Body>
      <RModal.Footer>
        <Button
          appearance="primary"
          color={colors[type]}
          onClick={() => onClose()}
        >
          Okay
        </Button>
      </RModal.Footer>
    </RModal>
  );
}

export default ModalAlert;
