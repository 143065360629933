import {
  Button,
  Card,
  Form,
  Input,
  message,
  notification,
  Select,
  Switch,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import VirtualSources from "../VirtualSources";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { getModelFiles } from "../../../../../services/model";
import InpService from "../../../../../domain/ModelFile/InpService";
import SelectInpFile from "../../../../../basicComponents/Selects/SelectInpFile";
import SelectMetereological from "../../../../../basicComponents/Selects/SelectMetereological";
import { infraTypes } from "../../../../../statics/constants/infraTypes";
import InfraestructureContext from "../../../../../views/Infrastructures/Parameters/context";
export interface IParameterSource extends ParameterSource {
  isEdit: boolean;
}

type VirtualSourceHandle = React.ElementRef<typeof VirtualSources>;

type Props = {
  source: IParameterSource;
  sources: ParameterSource[];
  infrastructure: Infrastructure;
  updateSource: Function;
  handleSaveSources: Function;
  deleteSourceById: Function;
  updateAndSaveSource: Function;
  handleLoadSources: Function;
};

const inpService = new InpService();

export default function SourceCard({
  source,
  sources,
  infrastructure,
  updateSource,
  handleSaveSources,
  deleteSourceById,
  updateAndSaveSource,
  handleLoadSources,
}: Props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { value: context, setValue: setContext } = useContext(
    InfraestructureContext
  );
  const virtualSourcesRef = React.useRef<VirtualSourceHandle>(null);
  const security = localStorage.getItem("security");
  const organizationId = JSON.parse(security || "{}").organizationId;
  const [inpId, setInpId] = useState<number>();
  const [modelFiles, setModelFiles] = useState<any>([]);
  const [inpObjectId, setInpObjectId] = useState<any>(null);
  const [inpObjectType, setInpObjectType] = useState<any>(null);
  const [virtualType, setVirtualType] = useState("CODE");
  const [modelInfraIdList, setModelInfraIdList] = useState<any[]>([]);
  const [form] = Form.useForm();

  function replaceObjectById(array: any[], id: number, newObject: object) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i] = newObject;
        break;
      }
    }
    return array;
  }

  async function handleSaveActionButton() {
    const sourceCopy = { ...source };
    sourceCopy.isEdit = false;
    if (!sourceCopy.name || sourceCopy.name == "") {
      notification.error({
        message: "Error",
        description: `You must enter an Alert Name`,
      });
      return;
    }
    if (!sourceCopy.sourceSerialNumber || sourceCopy.sourceSerialNumber == "") {
      notification.error({
        message: "Error",
        description: `You must enter an Alert Serial Number`,
      });
      return;
    }
    if (infrastructure.infraTypeId == 8 && !sourceCopy.historicalMetService) {
      notification.error({
        message: "Error",
        description: `You must select an meteostat service`,
      });
      return;
    }
    if (
      infrastructure.infraTypeId == infraTypes.PLUVIOMETER &&
      sources.length > 0
    ) {
      sourceCopy.forecastMetService =
        context.selectedParam?.sources[0].forecastMetService;
    }
    if (source.isVirtual) {
      sourceCopy.virtualType = virtualType;
      if (virtualType === "CODE") {
        const virtual = virtualSourcesRef.current?.getVirtualSourceData();
        sourceCopy.statement = virtual.code;
        sourceCopy.variablesDTOS = virtual.variables;
        sourceCopy.virtualCurrentTs = virtualSourcesRef.current?.getDateStamp();
        sourceCopy.inpId = null;
        sourceCopy.objectTypeId = null;
        sourceCopy.objectModelId = null;
      } else {
        sourceCopy.inpId = form.getFieldValue("inpId");
        sourceCopy.objectTypeId = form.getFieldValue("inpObjectType");
        sourceCopy.objectModelId = form.getFieldValue("inpObjectId");
        sourceCopy.statement = null;
        sourceCopy.variablesDTOS = null;
        sourceCopy.virtualCurrentTs = null;
      }
    } else {
      sourceCopy.virtualType = "";
      sourceCopy.statement = null;
      sourceCopy.variablesDTOS = null;
      sourceCopy.virtualCurrentTs = null;
      sourceCopy.inpId = null;
      sourceCopy.objectTypeId = null;
      sourceCopy.objectModelId = null;
    }
    await updateAndSaveSource(sourceCopy);
  }

  const handleGetModelFiles = async () => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getModelFiles(organizationId, {
      page: 1,
      size: 100,
      name: null,
      type: null,
      locationId: infrastructure.locationId,
    })
      .then((response) => {
        setModelFiles(response.getAllResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGetModelInfraId = async () => {
    const response = await inpService.getModelInfrastructureId({
      inpId: form.getFieldValue("inpId"),
      typeId: form.getFieldValue("inpObjectType"),
    });
    setModelInfraIdList(response.data);
  };

  useEffect(() => {
    handleGetModelFiles();
  }, []);

  useEffect(() => {
    if (source.id >= 0 && source.isVirtual && source.virtualType) {
      console.log(
        "🚀 ~ file: SourceCard.tsx:106 ~ useEffect ~ source:",
        source
      );
      setVirtualType(source.virtualType);
      if (source.virtualType === "MODEL") {
        form.setFieldValue("inpId", source.inpId);
        form.setFieldValue("inpObjectType", source.objectTypeId);
        form.setFieldValue("inpObjectId", source.objectModelId);
        setInpId(source.inpId as number);
        setInpObjectId(source.objectModelId);
        setInpObjectType(source.objectTypeId);
      }
    }
  }, [source]);

  // useEffect(() => {
  //   if (form.getFieldValue("inpId") && form.getFieldValue("inpObjectType")) {
  //     handleGetModelInfraId();
  //   }
  // }, [form.getFieldValue("inpId"), form.getFieldValue("inpObjectType")]);

  return source.isEdit ? (
    <Card className="card-source-container">
      <div
        className="flex justify-between items-center"
        style={{ height: "50px" }}
      >
        <span style={{ color: "#232B36", fontWeight: "700" }}>
          <Input
            style={{ width: "275px" }}
            maxLength={30}
            placeholder="Type the source name"
            defaultValue={source.name}
            onBlur={(e) => {
              const sourceCopy = { ...source };
              sourceCopy.name = e.target.value;
              updateSource(sourceCopy);
            }}
          />
        </span>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            className="flex items-center justify-center"
            style={{
              backgroundColor: "#023E65",
              color: "white",
              width: "42px",
              height: "16px",
              fontWeight: "700",
              cursor: "pointer",
              marginRight: "10px",
            }}
            onClick={() => {
              if (source.isVirtual && virtualType === "MODEL") {
                form
                  .validateFields()
                  .then(() => {
                    handleSaveActionButton();
                  })
                  .catch((error) => {
                    // console.log(error)
                  });
              } else {
                handleSaveActionButton();
              }
            }}
          >
            Save
          </Button>
          {/* <DeleteOutlined
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              color: "#74788D",
            }}
            onClick={() => {}}
            rev={undefined}
          /> */}
        </div>
      </div>

      <div className="separator" />
      <div
        className="grid"
        style={{
          gridTemplateColumns: "0.5fr 0.25fr 0.25fr",
          minHeight: "70px",
          columnGap: "5px",
        }}
      >
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-1"
          >
            Serial Number
          </span>
          <span className="source-value">
            <Input
              maxLength={30}
              placeholder="00:00"
              defaultValue={source.sourceSerialNumber}
              onBlur={(e) => {
                setTimeout(() => {
                  const sourceCopy = { ...source };
                  sourceCopy.sourceSerialNumber = e.target.value;
                  updateSource(sourceCopy);
                }, 100);
              }}
            />
          </span>
        </div>
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-2"
          >
            Device ID
          </span>
          <span className="source-value">
            {source.sourceSerialNumber?.split(":")[0] || "00"}
          </span>
        </div>
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-2"
          >
            Sensor ID
          </span>
          <p className="source-value">
            {source.sourceSerialNumber?.split(":")[1] || "00"}
          </p>
        </div>
      </div>
      <div className="separator" />
      <div
        className="flex justify-between items-center"
        style={{ height: "40px" }}
      >
        <div>
          <span className="mr-1">Enable</span>
          <Switch
            defaultChecked={source.isEnabled}
            onChange={(checked) => {
              const sourceCopy = { ...source };
              sourceCopy.isEnabled = checked;
              updateSource(sourceCopy);
            }}
          />
        </div>
        {infrastructure.infraTypeId != 8 ? (
          <div>
            <span className="mr-1">Virtual</span>
            <Switch
              defaultChecked={source.isVirtual}
              onChange={(checked) => {
                const sourceCopy = { ...source };
                sourceCopy.isVirtual = checked;
                updateSource(sourceCopy);
              }}
            />
          </div>
        ) : (
          <div className="flex items-center">
            <span className="mr-1">
              MeteoStat <span style={{ color: "red" }}>*</span>
            </span>
            <div style={{ width: "140px" }}>
              <SelectMetereological
                mode="historical"
                defaultValue={source.historicalMetService}
                onSelect={(value: number | null | undefined) => {
                  const sourceCopy = { ...source };
                  sourceCopy.historicalMetService = value;
                  updateSource(sourceCopy);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="separator mb-2" />
      {source.isVirtual && (
        <div className="flex column">
          <Tag
            icon={<InfoCircleOutlined style={{ fontWeight: 800 }} />}
            color="processing"
          >
            <span style={{ fontWeight: 700 }}>
              Use subindex to reference to past values
            </span>
          </Tag>
          <Tabs
            activeKey={virtualType}
            onChange={(val) => {
              setVirtualType(val);
            }}
            items={[
              {
                key: "CODE",
                label: "Python Code",
                children: (
                  <VirtualSources
                    ref={virtualSourcesRef}
                    source={source}
                    sources={sources}
                    infrastructure={infrastructure}
                  />
                ),
              },
              {
                key: "MODEL",
                label: "Model",
                children: (
                  <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    size="small"
                  >
                    <div className="flex column mt-2">
                      <Form.Item
                        label="Model File"
                        name="inpId"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option",
                          },
                          {
                            validator: (_, value) =>
                              value !== 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Please select an option")
                                  ),
                          },
                        ]}
                      >
                        {/* <Select
                          style={{ width: "100%" }}
                          defaultValue={inpId}
                          placeholder="Select an option"
                          onChange={() => {
                            form.setFieldValue("inpObjectId", null);
                            if (
                              form.getFieldValue("inpId") &&
                              form.getFieldValue("inpObjectType")
                            ) {
                              handleGetModelInfraId();
                            }
                          }}
                          options={modelFiles?.map((modeli: any) => {
                            return {
                              value: parseInt(modeli.inpId),
                              label: modeli.inpName,
                            };
                          })}
                        /> */}
                        <SelectInpFile
                          defaultValue={inpId}
                          emitObject
                          onSelect={(val: any) => {
                            form.setFieldValue("inpObjectId", null);
                            form.setFieldValue("inpId", val.inpId);
                            if (
                              form.getFieldValue("inpId") &&
                              form.getFieldValue("inpObjectType")
                            ) {
                              handleGetModelInfraId();
                            }
                          }}
                          organizationId={organizationId}
                          onlyCompleted={true}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Object Type"
                        name="inpObjectType"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Object Type",
                          },
                          {
                            validator: (_, value) =>
                              value !== 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Please select an option")
                                  ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          defaultValue={inpObjectType}
                          placeholder="Select an option"
                          onChange={() => {
                            form.setFieldValue("inpObjectId", null);
                            if (
                              form.getFieldValue("inpId") &&
                              form.getFieldValue("inpObjectType")
                            ) {
                              handleGetModelInfraId();
                            }
                          }}
                          options={[
                            { value: 1, label: "Nodes" },
                            { value: 2, label: "Links" },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Model Infrastructure ID"
                        name="inpObjectId"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a Model Infrastructure ID",
                          },
                          {
                            validator: (_, value) =>
                              value !== 0
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Please select an option")
                                  ),
                          },
                        ]}
                      >
                        <Select
                          defaultValue={inpObjectId}
                          className="mt-1"
                          placeholder="Select an option"
                          options={modelInfraIdList?.map((modelid: any) => {
                            return {
                              value: modelid,
                              label: modelid,
                            };
                          })}
                        />
                      </Form.Item>
                    </div>
                  </Form>
                ),
              },
            ]}
          />
        </div>
      )}
    </Card>
  ) : (
    <Card className="card-source-container">
      <div
        className="flex justify-between items-center"
        style={{ height: "50px" }}
      >
        <span style={{ color: "#232B36", fontWeight: "700" }}>
          {source.name}
        </span>
        <div className="flex">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined rev={undefined} />}
              style={{
                color: "#74788D",
                marginRight: "10px",
              }}
              onClick={() => {
                const sourceCopy = { ...source };
                sourceCopy.isEdit = true;
                updateSource(sourceCopy);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              icon={<DeleteOutlined rev={undefined} />}
              style={{
                color: "#74788D",
                marginRight: "10px",
              }}
              onClick={async () => {
                await deleteSourceById(source.id);
                // handleSaveSources();
              }}
            />
          </Tooltip>
        </div>
      </div>

      <div className="separator" />
      <div
        className="grid"
        style={{
          gridTemplateColumns: "0.5fr 0.25fr 0.25fr",
          minHeight: "70px",
          columnGap: "5px",
        }}
      >
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-2"
          >
            Serial Number
          </span>
          <span className="source-value">{source.sourceSerialNumber}</span>
        </div>
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-2"
          >
            Device ID
          </span>
          <span className="source-value">
            {source.sourceSerialNumber?.split(":")[0]}
          </span>
        </div>
        <div className="flex column">
          <span
            style={{
              color: "#74788D",
              fontWeight: "400",
            }}
            className="mb-2"
          >
            Sensor ID
          </span>
          <span className="source-value">
            {source.sourceSerialNumber?.split(":")[1]}
          </span>
        </div>
      </div>
      <div className="separator" />
      <div
        className="flex justify-between items-center"
        style={{ height: "40px" }}
      >
        <div>
          <span className="mr-1">Enable</span>
          <Switch defaultChecked={source.isEnabled} disabled />
        </div>
        {infrastructure.infraTypeId != 8 ? (
          <div>
            <span className="mr-1">Virtual</span>
            <Switch defaultChecked={source.isVirtual} disabled />
          </div>
        ) : (
          <div className="flex items-center">
            <span className="mr-1">
              MeteoStat <span style={{ color: "red" }}>*</span>
            </span>
            <div style={{ width: "140px" }}>
              <SelectMetereological
                mode="historical"
                defaultValue={source.historicalMetService}
                otherSettings={{ disabled: true }}
                onSelect={() => {}}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
