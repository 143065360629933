import {
  Button,
  Divider,
  Input,
  Modal,
  notification,
  Pagination,
  PaginationProps,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";

import Table, { ColumnsType } from "antd/es/table";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import dayjs from "dayjs";
import TrashSvg from "../../assets/svg/TrashSvg";
import PencilSvg from "../../assets/svg/PencilSvg";
import OrganizationService from "../../domain/Organization/OrganizationService";

interface DataType {
  id: string;
  name: string;
  createdAt: string;
  createdBy: string;
}

const organizationService = new OrganizationService();

export default function OrganizationView() {
  const [organizations, setOrganizations] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [queryName, setQueryName] = useState<any>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [addModalOrganization, setAddModalOrganization] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, record) => (
        <div className="flex column">
          <span> {`${dayjs(record.createdAt).format("d MMM YYYY")}`} </span>
          <span style={{ color: "#74788D", fontSize: "10px" }}>
            {`${record.createdBy}`}
          </span>
        </div>
      ),
      width: 200,
    },
    {
      title: "Actions",
      dataIndex: "s",
      key: "id",
      width: 150,
      fixed: "right",
      render: (_, record) => (
        <>
          <div>
            <Tooltip title="Delete" className="mr-2">
              <Button
                icon={<TrashSvg />}
                onClick={() => {
                  setSelectedOrganization(record);
                  setShowConfirmDelete(true);
                }}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                icon={<PencilSvg />}
                onClick={() => {
                  setSelectedOrganization(record);
                  setName(record.name);
                  setAddModalOrganization(true);
                }}
                style={{
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const onChangePagination: PaginationProps["onChange"] = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  async function handleGetOrganizations() {
    const res = await organizationService.getOrganizations({ name:queryName });
    setOrganizations(res);
  }

  useEffect(() => {
    handleGetOrganizations();
  }, [queryName]);

  return (
    <div>
      <div className="text-title-page">Organizations</div>
      <div className="flex justify-between" style={{ marginTop: "2rem" }}>
        <div>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <SearchOutlined style={{ color: "#078CB3" }} rev={undefined} />
            }
            onPressEnter={(e: any) => {
              if (e.target) {
                setQueryName(e.target.value);
              }
            }}
            onChange={(e) => {
              if (!e.target.value || e.target.value == "") {
                setQueryName(null);
              }
            }}
            allowClear
          />
        </div>

        <div>
          <Button
            type="primary"
            shape="round"
            className="flex items-center self-center"
            style={{
              backgroundColor: "#023E65",
              color: "white",
              width: "170px",
              height: "40px",
            }}
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              setSelectedOrganization(null);
              setName("");
              setAddModalOrganization(true);
              // setIsNewUser(true);
            }}
          >
            New Organization
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={organizations}
          pagination={false}
          style={{
            maxHeight: "calc(100vh - 250px)",
            overflowX: "auto",
          }}
          className="tableOrganization"
        />
        {/* <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          defaultCurrent={1}
          total={pagination.totalPages}
          showSizeChanger={false}
        /> */}
      </div>
      <Modal
        title={
          <div className="title-modal">
            {selectedOrganization ? "EDIT ORGANIZATION" : "NEW ORGANIZATION"}
          </div>
        }
        open={addModalOrganization}
        onOk={() => {}}
        onCancel={() => {
          setAddModalOrganization(false);
        }}
        footer={[]}
        closable={false}
        width={300}
        destroyOnClose={true}
        afterClose={() => {}}
      >
        <span>Organization name</span>
        <Input
          status={showError ? "error" : ""}
          value={name}
          onChange={(e) => {
            if (e.target.value.length >= 5) {
              setShowError(false);
            }
            setName(e.target.value);
          }}
          autoComplete="off"
        />
        <Divider style={{ border: "1px solid #E6E6E6" }} />
        <div className="flex justify-between">
          <Button
            className="mr-3"
            style={{ width: "85px", height: "40px" }}
            onClick={() => {
              setSelectedOrganization(null);
              setName("");
              setAddModalOrganization(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={async () => {
              if (name.length < 5) {
                notification.warning({
                  message: "The name needs at least 5 characters",
                  duration: 2,
                });
                setShowError(true);
                return;
              } else {
                setShowError(false);
              }
              if (selectedOrganization) {
                await organizationService.putOrganization(
                  selectedOrganization.id,
                  { name: name, modifiedBy: localStorage.getItem("userId") }
                );
                notification.success({
                  message: "Successful",
                  description: "Organization edited successfully",
                });
              } else {
                await organizationService.postOrganization({
                  name: name,
                  createdBy: localStorage.getItem("userId"),
                });
                notification.success({
                  message: "Successful",
                  description: "Organization created successfully",
                });
              }
              await handleGetOrganizations();
              setSelectedOrganization(null);
              setName("");
              setAddModalOrganization(false);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        title="Are you sure you want to delete this organization?"
        message="The organization will be deleted"
        showModal={showConfirmDelete}
        onAccept={async () => {
          if (selectedOrganization) {
            await organizationService.deleteOrganization(
              selectedOrganization.id
            );
            handleGetOrganizations();
            setSelectedOrganization(null);
            setShowConfirmDelete(false);
            notification.success({
              message: "Successful",
              description: "Organization deleted successfully",
            });
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
}
