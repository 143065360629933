import { Button, Input, Modal, Select, notification } from "antd";
import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import InfrastructureService from "../../../domain/Infrastructure/InfraestructureService";
import LocationService from "../../../domain/Location/LocationService";
import KpiService from "../../../domain/Kpi/KpiService";
import SelectLocations from "../../General/SelectLocations";

const infrastructureService = new InfrastructureService();
const kpiService = new KpiService();
export interface IProps {
  isModalOpen: boolean;
  onSuccess: Function;
  onCancel: Function;
  selectedKpi: any;
}

export default function CreateKpiModal({
  isModalOpen,
  selectedKpi,
  onSuccess,
  onCancel,
}: IProps) {
  const [nameKpi, setNameKpi] = useState("");
  const [infraestructures, setInfraestructures] = useState<any[]>([]);
  const [parameters, setParameters] = useState<any[]>([]);
  const [location, setLocation] = useState("");
  const [infrastructure, setInfrastructure] = useState(null);
  const [parameter, setParameter] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [detailsKpi, setdetailsKpi] = useState<any>(null);
  const selectLocationRef = useRef<any>(null);

  const handleClickSelectLocation = (value: any) => {
    setInfraestructures([]);
    setInfrastructure(null);
    setParameters([]);
    setParameter(null);
    setLocation(value);
  };

  async function handleSearchInfrastructures(search: any, locationId?: any) {
    if (location === "" && !locationId) return;
    const response = await infrastructureService.getInfraestructureInUse(
      locationId ? locationId : location,
      1,
      50,
      search,
      null,
      0
    );
    setInfraestructures(response.data);
  }

  function handleSetParameters(val: null) {
    const selectedParameter = infraestructures.find(
      (p) => p.infraestructureId === val
    );
    if (selectedParameter) {
      setParameters(selectedParameter.measurementParameters);
    }
  }

  async function handlePostKpi() {
    if (parameter != null) {
      const response = await kpiService.postKpi(
        nameKpi,
        true,
        parameter,
        selectedKpi
      );
      if (response) {
        if (selectedKpi) {
          notification.success({
            message: "Successful",
            description: "Kpi edited successfully",
          });
        } else {
          notification.success({
            message: "Successful",
            description: "Kpi created successfully",
          });
        }
        setNameKpi("");
        setLocation("");
        setInfrastructure(null);
        setParameter(null);
        onSuccess();
      }
    }
  }

  async function handleGetKpiDetails() {
    const response = await kpiService.getKpiDetails(selectedKpi);
    setdetailsKpi(response);
    // setNameKpi(response.name);
    setLocation(response.locationId);
    // setInfrastructure(response.infrastructureId);
    // setParameter(response.parameterId);
  }

  async function setKPIDetails() {
    kpiService.getKpiDetails(selectedKpi).then(async (response) => {
      console.log("🚀 ~ kpiService.getKpiDetails ~ response:", response);
      setNameKpi(response.name);
      setLocation(response.locationId);
      await handleSearchInfrastructures("", response.locationId);
      setInfrastructure(response.infrastructureId);
      await handleSetParameters(response.infrastructureId);
      setParameter(response.parameterId);
      setLoaded(true);
    });
  }

  useEffect(() => {
    if (selectedKpi) {
      setLoaded(false);
      setKPIDetails();
    }
  }, []);

  useEffect(() => {
    if (location && loaded) {
      console.log("🚀 ~ useEffect ~ location:", location);
      handleSearchInfrastructures("");
    }
  }, [location]);

  useEffect(() => {
    // if (infrastructure && loaded) {
    handleSetParameters(infrastructure);
    // }
  }, [infrastructure]);

  return (
    <Modal
      open={isModalOpen}
      footer={[]}
      closable={false}
      style={{ minWidth: "500px" }}
    >
      <div className="flex column pl-3 pr-3">
        <span
          className="text-title-page pt-2 pb-2"
          style={{ fontSize: "1rem" }}
        >
          {selectedKpi ? "EDIT KPI" : "CREATE KPI"}
        </span>
        <div className="separator mb-2" />
        <div className="mb-1">
          <label htmlFor="nameKpi" className="mr-2">
            Name KPI:
          </label>{" "}
          <br />
          <Input
            id="nameKpi"
            value={nameKpi}
            onChange={(val) => {
              setNameKpi(val.target.value);
            }}
            style={{ minWidth: "200px", width: "100%", borderRadius: "20px" }}
          />
        </div>
        <div className="mb-1" style={{ minWidth: "200px", width: "100%" }}>
          <label htmlFor="location" className="mr-2">
            Location:
          </label>{" "}
          <br />
          <SelectLocations
            defaultValue={selectedKpi ? location : null}
            aditionalStyle={{}}
            setSelected={handleClickSelectLocation}
            ref={selectLocationRef}
          />
        </div>

        <div className="mb-1">
          <label htmlFor="infrastructure" className="mr-2">
            Infrastructure:
          </label>{" "}
          <br />
          <Select
            id="infrastructure"
            value={infrastructure}
            notFoundContent={null}
            onSearch={(val) => {
              if (val) {
                handleSearchInfrastructures(val);
              }
            }}
            onChange={(val) => {
              setInfrastructure(val);
            }}
            style={{ minWidth: "200px", width: "100%" }}
            showSearch
            filterOption={(input, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={infraestructures.map((d) => ({
              value: d.infraestructureId,
              label: d.infraestructureName,
            }))}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="parameter" className="mr-2">
            Parameter:
          </label>{" "}
          <br />
          <Select
            id="parameter"
            value={parameter}
            onChange={(val) => {
              setParameter(val);
            }}
            style={{ minWidth: "200px", width: "100%" }}
            options={(parameters || []).map((p) => ({
              value: p.paremeterId,
              label: p.paremeterName,
            }))}
          />
        </div>
        <div className="separator" />
        <div className="flex pt-3 justify-between">
          <Button
            style={{ width: "85px", height: "40px" }}
            onClick={() => {
              setNameKpi("");
              setLocation("");
              setInfrastructure(null);
              setParameter(null);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={() => {
              handlePostKpi();
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
}
