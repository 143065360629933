import { api } from "../services/api";

// constants
let initialData = {
  fetching: false,
  array: [],
  totalRecords: 0,
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/measurements/location/";

let GET_DEVICES = "GET_MENU_DEVICES";
let GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
let GET_DEVICES_ERROR = "GET_DEVICES_ERROR";

export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_DEVICES:
      return { ...state, fetching: true };
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        array: action.payload,
        totalRecords: action.totalRecords,
        fetching: false,
      };
    case GET_DEVICES_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}

export let getDeviceAction =
  (id, page = 1, size = 100, inuse = false) =>
  (dispatch, getState) => {
    if (!id) return;
    dispatch({
      type: GET_DEVICES,
    });
    return api
      .get(`${URL}${id}${inuse ? "/inuse" : ""}`, {
        params: { page: page, size: size },
      })
      .then((res) => {
        dispatch({
          type: GET_DEVICES_SUCCESS,
          payload: res.data.data,
          totalRecords: res.data.totalRecords,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DEVICES_ERROR,
          payload: err.response.message,
        });
      });
  };
