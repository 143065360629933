import DevicesRepository from "./DevicesRespository";

export default class DevicesService {
  private devicesRepository: DevicesRepository = new DevicesRepository();

  async uploadIotBulk(data: Iot): Promise<any> {
    return await this.devicesRepository.uploadIotBulk(data);
  }

  async getIotByOrganization(
    organizationId: number | string,
    page = 1,
    size = 10,
    name: null | string = null
  ) {
    const response = await this.devicesRepository.getIotByOrganization(
      organizationId,
      page,
      size,
      name
    );
    return response;
  }

  async getIotInformation(deviceId: number | string, loading: boolean = true) {
    const info = await this.devicesRepository.getIotInformation(
      deviceId,
      loading
    );
    return info;
  }

  async getStatusOfUpload(
    iotUploadId: number | string,
    loading: boolean = true
  ) {
    const status = await this.devicesRepository.getStatusOfUpload(
      iotUploadId,
      loading
    );
    return status;
  }

  async postCheckedIot(iotUploadId: number | string) {
    return await this.devicesRepository.postCheckedIot(iotUploadId);
  }

  async deleteIot(iotUploadId: number | string) {
    return await this.devicesRepository.deleteIot(iotUploadId);
  }

  async getMessagesByIot(iotUploadId: number | string) {
    return await this.devicesRepository.getMessagesByIot(iotUploadId);
  }
}
