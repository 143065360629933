import React, { FC, useEffect, useState } from "react";
import { Button, Input, Table, Pagination, Tooltip, Select } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { PaginationProps } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import AlertService from "../../domain/Alerts/AlertService";

const alertService = new AlertService();

const AlertsView: FC = () => {
  const [alerts, setAlerts] = useState<Alert[]>();
  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [enabled, setEnabled] = useState<boolean | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });
  const navigate = useNavigate();

  const handleGetAlerts = async () => {
    try {
      const res = await alertService.getAlerts({
        name,
        enable: enabled,
        page: pagination.page,
        size: pagination.pageSize,
      });
      console.log("🚀 ~ handleGetAlerts ~ res:", res);
      setAlerts(res.alerts);
      setPagination({ ...pagination, totalRows: res.totalRecords });
    } catch (error) {
      setAlerts([]);
    }
  };

  const columns: ColumnsType<Alert> = [
    {
      title: "Alert name",
      key: "name",
      render: (_, { alertName }) => (
        <>
          <span className="table-data-gis">{alertName}</span>
        </>
      ),
    },
    {
      title: "Upload for",
      key: "createdBy",
      render: (_, { alertCreatedAt, alertCreatedBy }) => (
        <>
          <div className="flex column">
            <span
              style={{
                color: "#232B36",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {alertCreatedBy}
            </span>
            <span
              style={{
                color: "#74788D",
              }}
            >
              {dayjs(alertCreatedAt).format("YYYY/MM/DD [at] hh:mm a")}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      key: "id",
      render: (_, record) => (
        <>
          <div className="text-center">
            <Tooltip title="Edit">
              <EditOutlined
                className="mr-1"
                style={{ cursor: "pointer", fontSize: "1.5rem" }}
                onClick={() => {
                  navigate(`/alerts/form/${record.alertId}`);
                }}
              />
            </Tooltip>
            {/* <Tooltip title="Delete">
              <DeleteOutlined
                style={{ fontSize: "1.5rem" }}
                onClick={() => {
                  setSelectedDiagram(record);
                  setShowConfirmDelete(true);
                }}
              />
            </Tooltip> */}
          </div>
        </>
      ),
    },
  ];
  const onChangePagination: PaginationProps["onChange"] = (page) => {
    // console.log(page);
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    handleGetAlerts();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    if (prevPage == 1) {
      handleGetAlerts();
    } else {
      setPagination({ ...pagination, page: 1 });
    }
  }, [name, enabled]);

  return (
    <div className="px-5">
      <div className="text-title-page mb-2">Alerts</div>
      <div className="flex justify-between">
        <div className="flex">
          <Input
            className="mr-2"
            placeholder="Search"
            prefix={<SearchOutlined rev={undefined} />}
            style={{ width: "205px" }}
            onChange={(e) => {
              if (e.target.value == "") {
                setName(null);
              }
            }}
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                setName(element.value);
              }
            }}
            allowClear
          />
          {/* <Select
            style={{ marginLeft: 30, width: 140 }}
            placeholder="In use"
            optionFilterProp="children"
            options={[
              {
                value: true,
                label: "True",
              },
              {
                value: false,
                label: "False",
              },
            ]}
            onChange={(value) => {
              setEnabled(value === undefined ? null : value);
            }}
            allowClear
          /> */}
        </div>
        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`/alerts/form`);
            }}
          >
            Add Alert
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={alerts}
          pagination={false}
          size="small"
          bordered
          style={{
            maxHeight: "calc(100vh - 280px)",
            overflow: "auto",
          }}
          // scroll={{ x: 1100, y: 370 }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalRows}
          showSizeChanger={false}
        />
      </div>
      {/* <ConfirmationModal
        title="Delete Block Diagram?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={async () => {
          if (selectedDiagram) {
            deleteBlockDiagram(selectedDiagram?.id);
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      /> */}
    </div>
  );
};
export default AlertsView;
