import React from "react";

const PlayCircleSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14 8.27L9 5.38C8.69612 5.20455 8.35142 5.11214 8.00052 5.11205C7.64962 5.11196 7.30487 5.20419 7.00089 5.37949C6.69691 5.55478 6.44441 5.80697 6.26872 6.11072C6.09304 6.41447 6.00036 6.7591 6 7.11V12.89C6.00036 13.2407 6.09295 13.5852 6.26847 13.8888C6.44398 14.1925 6.69627 14.4446 7 14.62C7.30404 14.7955 7.64893 14.8879 8 14.8879C8.35108 14.8879 8.69597 14.7955 9 14.62L14 11.73C14.3031 11.5542 14.5547 11.3018 14.7295 10.9982C14.9044 10.6946 14.9965 10.3504 14.9965 10C14.9965 9.64962 14.9044 9.30539 14.7295 9.00177C14.5547 8.69816 14.3031 8.44581 14 8.27ZM13 10L8 12.89V7.11L13 10ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
        fill="#74788D"
      />
    </svg>
  );
};

export default PlayCircleSvg;
