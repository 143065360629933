import {
  Button,
  Collapse,
  InputNumber,
  Layout,
  notification,
  Popover,
} from "antd";
import Icon, { ExperimentOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PlusCircleSvg from "../../assets/svg/PlusCircleSvg";
import CancelCircleSvg from "../../assets/svg/CancelCircleSvg";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoaderText } from "../../redux/loaderDuck";
import IterationSimulationService from "../../domain/IterationSimulation/IterationSimulationService";
import InteractiveSimulationService from "../../domain/InteractiveSimulations/InteractiveSimulationService";
import ArrowIndicationSimulationSvg from "../../assets/svg/ArrowIndicationSimulationSvg";
import TourInteractiveSimulationContext from "../../views/Simulations/Context";
import "./LateralMenu.scss";

const iterationSimulationService = new IterationSimulationService();
const interactiveSimulationService = new InteractiveSimulationService();

const LateralMenu = forwardRef(
  (
    {
      isRunningIteration,
      iterations,
      currentIteration,
      currentTime,
      onCreateIteration,
      setEventsToSend,
      generateKeplerAnalysis,
      endedSimulation = false,
      onClickViewGuide,
    },
    ref
  ) => {
    const { locationId, simulationId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lateralMenuRef = useRef(null);
    const plusButtonRef = useRef(null);
    const [temporalEventsToSend, setTemporalEventsToSend] = useState([]);
    const { value: context, setValue: setContext } = useContext(
      TourInteractiveSimulationContext
    );

    useImperativeHandle(ref, () => ({
      getLateralMenuRef: () => {
        return lateralMenuRef.current;
      },
      getPlusButtonRef: () => {
        return plusButtonRef.current;
      },
    }));

    const handleAddIteration = async () => {
      if (!simulationId) return;
      if (iterations.length === 0) {
        dispatch(
          setLoaderText(
            "Building test environment. This can take a few minutes"
          )
        );
      }
      const res = await iterationSimulationService.postIterationSimulation({
        simulationId,
      });
      dispatch(setLoaderText("Loading..."));
      if (!res) return;
      
      notification.success({
        message: "Successful",
        description: "Iteration created successfully",
      });

      const iterationId = JSON.parse(
        res.data.replaceAll("\\", "") || "{}"
      ).iterationId;
      if (iterationId) {
        onCreateIteration(iterationId);
      }
    };

    const handleEndSimulation = async () => {
      if (!simulationId) return;
      const res =
        await interactiveSimulationService.postFinishInteractiveSimulation(
          simulationId
        );
      if (!res) return;
      notification.info({
        message: `Simlation Completed`,
        description: "Your simulation was completed successfully.",
        placement: "topRight",
      });
      navigate(`/simulations`);
    };

    return (
      <Layout
        className="site-layout"
        style={{ background: "white", overflowY: "auto" }}
      >
        <Popover
          placement="leftTop"
          color="#023E65"
          open={context.showTour && context.activeStep == 1}
          content={
            <div className="flex column">
              <div className="flex column text-white mb-2">
                <span style={{ fontSize: "1.25rem" }}>
                  Welcome to the simulator,
                </span>
                <span>to start follow the guide by clicking on continue</span>
              </div>
              <div className="flex justify-between">
                <Button
                  type="text"
                  style={{ color: "white" }}
                  onClick={() => {
                    setContext({ ...context, showTour: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  ghost
                  onClick={() => {
                    setContext({ ...context, activeStep: 2 });
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          }
        >
          <Sider
            style={{
              background: "white",
              position: "absolute",
              zIndex: 9,
              top: 75,
              right: 10,
              borderRadius: 5,
              height: "calc(100% - 90px)",
              overflowX: "auto",
            }}
            width={350}
            ref={lateralMenuRef}
            data-tour="step-1"
            className="tour-step-1"
          >
            <div className="flex column p-2 h-100">
              <div className="flex justify-between">
                <span
                  style={{
                    color: "#023E65",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Simulation Name
                </span>
                <div className="flex">
                  {!isRunningIteration && (
                    <Popover
                      placement="bottom"
                      color="#023E65"
                      open={context.showTour && context.activeStep == 2}
                      content={
                        <div className="flex column">
                          <div className="flex column text-white">
                            <span style={{ fontSize: "1.25rem" }}>
                              To start a iteration click on the plus button
                            </span>
                          </div>
                          <div className="flex justify-between">
                            <Button
                              type="text"
                              style={{ color: "white" }}
                              onClick={() => {
                                setContext({ ...context, activeStep: 1 });
                              }}
                            >
                              Back
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <Icon
                        component={PlusCircleSvg}
                        className="cursor-pointer"
                        onClick={() => {
                          handleAddIteration();
                          setContext({ ...context, activeStep: 3 });
                        }}
                        ref={plusButtonRef}
                      />
                    </Popover>
                  )}
                  {/* <Icon
                  component={CancelCircleSvg}
                  className="cursor-pointer ml-2"
                /> */}
                </div>
              </div>
              <div className="separator my-2"></div>
              {iterations.length > 0 ? (
                <div className="flex column lateral-menu">
                  <Collapse
                    activeKey={`I${currentIteration.iterationId}`}
                    onChange={(data) => {
                      console.log("change acoprderon", data);
                    }}
                    style={{ width: "100%" }}
                    accordion
                  >
                    {iterations.map((iteration, index) => {
                      return (
                        <Collapse.Panel
                          header={
                            <div className="flex justify-between">
                              <span>{`Iteration ${index + 1}`}</span>
                              {iteration.ended &&
                                !iteration.analysisGenerated && (
                                  <Popover
                                    placement="left"
                                    color="#023E65"
                                    open={
                                      context.showTour &&
                                      context.activeStep == 8
                                    }
                                    content={
                                      <div className="flex column">
                                        <div className="flex column text-white">
                                          <span>
                                            Finally you can generate the
                                            simulation analysis
                                          </span>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <ExperimentOutlined
                                      onClick={() => {
                                        generateKeplerAnalysis(iteration);
                                        setContext({ showTour: false });
                                      }}
                                    />
                                  </Popover>
                                )}
                            </div>
                          }
                          key={`I${iteration.iterationId}`}
                        >
                          <Collapse
                            defaultActiveKey={[`I${iteration.iterationId}S0`]}
                            onChange={() => {}}
                            style={{ width: "100%", padding: "0px" }}
                          >
                            {iteration?.markers
                              .find(
                                (marker) =>
                                  marker.time.valueOf() == currentTime.valueOf()
                              )
                              ?.sensors.map((sensor, index) => {
                                return (
                                  <Collapse.Panel
                                    header={
                                      sensor.measurementInfraestructureName
                                    }
                                    key={`I${iteration.iterationId}S${index}`}
                                  >
                                    <div className="flex column">
                                      <div
                                        className="grid items-center mt-2"
                                        style={{
                                          gridTemplateColumns: "1fr 1fr",
                                        }}
                                      >
                                        <div className="flex column">
                                          <span style={{ fontWeight: "600" }}>
                                            Setting
                                          </span>
                                          <span style={{ fontSize: "10px" }}>
                                            Time:{" "}
                                            {`${dayjs(
                                              currentTime.current
                                            ).format("DD/MM/YYYY HH:mm")}`}
                                          </span>
                                        </div>
                                        <Popover
                                          placement="bottom"
                                          color="#023E65"
                                          open={
                                            context.showTour &&
                                            context.activeStep == 5
                                          }
                                          content={
                                            <div className="flex column">
                                              <div className="flex column text-white">
                                                <span>
                                                  Edit the value of the
                                                  attribute
                                                </span>
                                              </div>
                                            </div>
                                          }
                                        >
                                          <InputNumber
                                            placeholder=""
                                            min={0}
                                            max={1}
                                            step={0.1}
                                            onChange={(value) => {
                                              setContext({
                                                ...context,
                                                activeStep: 6,
                                              });
                                              const eventsCopy = [
                                                ...temporalEventsToSend,
                                              ];
                                              const indexEvent =
                                                eventsCopy.findIndex(
                                                  (ev) =>
                                                    ev.infrastructureId ==
                                                    sensor.measurementeInfraestructureId
                                                );
                                              if (indexEvent >= 0) {
                                                eventsCopy[
                                                  indexEvent
                                                ].mod_value = value;
                                                setTemporalEventsToSend(
                                                  eventsCopy
                                                );
                                              } else {
                                                eventsCopy.push({
                                                  infrastructureId:
                                                    sensor.measurementeInfraestructureId,
                                                  mod_value: value,
                                                });
                                                setTemporalEventsToSend(
                                                  eventsCopy
                                                );
                                              }
                                            }}
                                          />
                                        </Popover>
                                      </div>
                                    </div>
                                  </Collapse.Panel>
                                );
                              })}
                          </Collapse>
                        </Collapse.Panel>
                      );
                    })}
                  </Collapse>
                </div>
              ) : (
                <div className="flex column text-center">
                  <div style={{ textAlignLast: "right", paddingRight: "5px" }}>
                    <ArrowIndicationSimulationSvg />
                  </div>
                  <span
                    className="self-center"
                    style={{ color: "#44B6DC", width: "150px" }}
                  >
                    It looks like you haven't create any iteration
                  </span>
                  <span
                    className="self-center cursor-pointer mt-2"
                    style={{
                      color: "#023E65",
                      textDecoration: "underline",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      setContext({
                        activeStep: 1,
                        showTour: true,
                      });
                    }}
                  >
                    View guide
                  </span>
                </div>
              )}

              <div className="flex justify-content-around mt-auto">
                {endedSimulation ? (
                  <Popover
                    placement="left"
                    color="#023E65"
                    open={context.showTour && context.activeStep == 8}
                    content={
                      <div className="flex column">
                        <div className="flex column text-white">
                          <span>Or end the simulation</span>
                        </div>
                      </div>
                    }
                  >
                    <Button
                      style={{
                        backgroundColor: "#00203C",
                        color: "white",
                        height: "40px",
                      }}
                      onClick={() => {
                        handleEndSimulation();
                      }}
                    >
                      Finish Simulation
                    </Button>
                  </Popover>
                ) : (
                  temporalEventsToSend.length > 0 && (
                    <Popover
                      placement="left"
                      color="#023E65"
                      open={context.showTour && context.activeStep == 6}
                      content={
                        <div className="flex column">
                          <div className="flex column text-white">
                            <span>Click send</span>
                          </div>
                          <div className="flex justify-between">
                            <Button
                              type="text"
                              style={{ color: "white" }}
                              onClick={() => {
                                setContext({
                                  ...context,
                                  activeStep: 5,
                                });
                              }}
                            >
                              Back
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <Button
                        style={{
                          backgroundColor: "#00203C",
                          color: "white",
                          height: "40px",
                        }}
                        onClick={() => {
                          setContext({
                            ...context,
                            activeStep: 7,
                          });
                          //Aca se envia el evento
                          setEventsToSend(temporalEventsToSend);
                          setTemporalEventsToSend([]);
                        }}
                      >
                        Send
                      </Button>
                    </Popover>
                  )
                )}
              </div>
            </div>
          </Sider>
        </Popover>
      </Layout>
    );
  }
);

export default LateralMenu;
