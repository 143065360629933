import React from "react";

const PlayCircleBlueSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M16.5 10.27L11.5 7.38C11.1961 7.20455 10.8514 7.11214 10.5005 7.11205C10.1496 7.11196 9.80487 7.20419 9.50089 7.37949C9.19691 7.55478 8.94441 7.80697 8.76872 8.11072C8.59304 8.41447 8.50036 8.7591 8.5 9.11V14.89C8.50036 15.2407 8.59295 15.5852 8.76847 15.8888C8.94398 16.1925 9.19627 16.4446 9.5 16.62C9.80404 16.7955 10.1489 16.8879 10.5 16.8879C10.8511 16.8879 11.196 16.7955 11.5 16.62L16.5 13.73C16.8031 13.5542 17.0547 13.3018 17.2295 12.9982C17.4044 12.6946 17.4965 12.3504 17.4965 12C17.4965 11.6496 17.4044 11.3054 17.2295 11.0018C17.0547 10.6982 16.8031 10.4458 16.5 10.27ZM15.5 12L10.5 14.89V9.11L15.5 12ZM12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01809 6.3459 3.26121 8.17317C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8079C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2363 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2ZM12.5 20C10.9178 20 9.37104 19.5308 8.05544 18.6518C6.73985 17.7727 5.71447 16.5233 5.10897 15.0615C4.50347 13.5997 4.34504 11.9911 4.65372 10.4393C4.9624 8.88743 5.72433 7.46197 6.84315 6.34315C7.96197 5.22433 9.38743 4.4624 10.9393 4.15372C12.4911 3.84504 14.0997 4.00346 15.5615 4.60896C17.0233 5.21447 18.2727 6.23984 19.1518 7.55544C20.0308 8.87103 20.5 10.4177 20.5 12C20.5 14.1217 19.6572 16.1566 18.1569 17.6569C16.6566 19.1571 14.6217 20 12.5 20Z"
        fill="#078CB3"
      />
    </svg>
  );
};

export default PlayCircleBlueSvg;
