import React from "react";

interface LocationsContextInterface {
  countriesByOrganization: CountryOrganization[];
}

const initialContext: LocationsContextInterface = {
  countriesByOrganization: [],
};

const LocationsContext = React.createContext(initialContext);

export default LocationsContext;
