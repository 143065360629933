import StateMachineRepository from "./StateMachineRespository";

export default class StateMachineService {
  private stateMachineRepository: StateMachineRepository =
    new StateMachineRepository();

  async getAllStateMachines({
    name = null,
    locationId = null,
    inpId = null,
    page = 1,
    size = 20,
  }: {
    name?: string | null;
    locationId?: string | number | null;
    inpId?: string | null;
    page?: number;
    size?: number;
  }) {
    return await this.stateMachineRepository.getAllStateMachines({
      name,
      locationId,
      inpId,
      page,
      size,
    });
  }

  async createStateMachine({
    name,
    locationId,
    inpId,
    enabled = true,
  }: {
    name: string | null;
    locationId: string | number | null;
    inpId: string | null;
    enabled: boolean;
  }) {
    return await this.stateMachineRepository.createStateMachine({
      name,
      locationId,
      inpId,
      enabled,
    });
  }

  async editStateMachineComplete(data: any) {
    return await this.stateMachineRepository.editStateMachineComplete(data);
  }

  async getStateMachineDetail(machineId: string | number) {
    return this.stateMachineRepository.getStateMachineDetail(machineId);
  }
}
