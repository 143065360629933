import React from "react";

type Props = {};

export default function ExpandArrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17.2666 1.18329C17.1821 0.979668 17.0203 0.817855 16.8166 0.733293C16.7164 0.690592 16.6089 0.667943 16.5 0.666626H11.5C11.2789 0.666626 11.067 0.754423 10.9107 0.910704C10.7544 1.06698 10.6666 1.27895 10.6666 1.49996C10.6666 1.72097 10.7544 1.93293 10.9107 2.08922C11.067 2.2455 11.2789 2.33329 11.5 2.33329H14.4916L2.33329 14.4916V11.5C2.33329 11.2789 2.2455 11.067 2.08922 10.9107C1.93293 10.7544 1.72097 10.6666 1.49996 10.6666C1.27895 10.6666 1.06698 10.7544 0.910704 10.9107C0.754423 11.067 0.666626 11.2789 0.666626 11.5V16.5C0.667943 16.6089 0.690592 16.7164 0.733293 16.8166C0.817855 17.0203 0.979668 17.1821 1.18329 17.2666C1.28348 17.3093 1.39106 17.332 1.49996 17.3333H6.49996C6.72097 17.3333 6.93293 17.2455 7.08922 17.0892C7.2455 16.9329 7.33329 16.721 7.33329 16.5C7.33329 16.2789 7.2455 16.067 7.08922 15.9107C6.93293 15.7544 6.72097 15.6666 6.49996 15.6666H3.50829L15.6666 3.50829V6.49996C15.6666 6.72097 15.7544 6.93293 15.9107 7.08922C16.067 7.2455 16.2789 7.33329 16.5 7.33329C16.721 7.33329 16.9329 7.2455 17.0892 7.08922C17.2455 6.93293 17.3333 6.72097 17.3333 6.49996V1.49996C17.332 1.39106 17.3093 1.28348 17.2666 1.18329Z"
        fill="black"
      />
    </svg>
  );
}


