import InpRepository from "./InpRepository";

export default class InpService {
  private inpRepository: InpRepository = new InpRepository();

  async getModelFilesByOrganization({
    organizationId,
    page = 1,
    size = 20,
    name,
    locationId,
    onlyCompleted,
  }: {
    organizationId: number;
    page?: number;
    size?: number;
    name?: string | null;
    locationId?: number | null;
    onlyCompleted?: boolean | null;
  }) {
    const response = await this.inpRepository.getModelFilesByOrganization({
      organizationId,
      page,
      size,
      name,
      locationId,
      onlyCompleted,
    });
    return {
      totalRecords: response.totalRecords,
      inpFiles: response.getAllResponseDTOList,
    } as InpPaginated;
  }

  async uploadInps(
    userId: string,
    organizationId: string,
    data: Inp
  ): Promise<boolean> {
    return this.inpRepository.uploadInps(userId, organizationId, data);
  }
  async changeStatusInp(inpId: any, status: boolean): Promise<boolean> {
    return this.inpRepository.changeStatusInp(inpId, status);
  }

  async getModelInfrastructureId({
    inpId,
    typeId,
    name = null,
  }: {
    inpId: any;
    typeId: any;
    name?: null | string;
  }): Promise<any> {
    return this.inpRepository.getModelInfrastructureId(inpId, typeId, name);
  }

  async enableInp(inpId: string | number, enabled: boolean): Promise<boolean> {
    return this.inpRepository.enableInp(inpId, enabled);
  }
  async disableInp(inpId: string | number, enabled: boolean): Promise<boolean> {
    return this.inpRepository.disableInp(inpId, enabled);
  }
}
