import VirtualSourceRepository from "./VirtualSourceRepository";

export default class VirtualSourceService {
  private virtualSourceRepository: VirtualSourceRepository =
    new VirtualSourceRepository();

  async getVirtualSource(source: ParameterSource): Promise<any> {
    const virtualSource = await this.virtualSourceRepository.getVirtualSource(
      source.id
    );
    const variables: SourceVariable[] =
      virtualSource.variables != "" && virtualSource.variables != "null"
        ? JSON.parse(virtualSource.variables)
        : [];
    return {
      sourceId: source.id,
      code: virtualSource.statement,
      variables: variables.map((variable: any) => {
        return {
          name: variable.name,
          parameterId: variable.parameterId,
          parameterName: variable.parameterString,
        };
      }),
    } as VirtualSource;
  }

  async saveVirtualSource(virtualSource: VirtualSource): Promise<boolean> {
    return this.virtualSourceRepository.saveVirutalSource(
      virtualSource.sourceId,
      virtualSource.variables,
      virtualSource.code
    );
  }
}
