import React from "react";

const PluviometerIconSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
    >
      <path
        d="M13 8.65833L8.91666 2.25833C8.35833 1.38333 6.96666 1.38333 6.40833 2.25833L2.32499 8.65833C1.74999 9.51666 1.40833 10.5167 1.40833 11.5917C1.40833 14.7167 4.20833 17.25 7.66666 17.25C11.125 17.25 13.925 14.7167 13.925 11.5917C13.925 10.5167 13.5833 9.50833 13.0083 8.65833H13Z"
        stroke="#023E65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PluviometerIconSvg;
