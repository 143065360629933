import React, { useState, useRef } from "react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MAPBOX_TOKEN = "TU_MAPBOX_ACCESS_TOKEN"; // Reemplaza con tu token de Mapbox

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "50%",
};
// Viewport settings
const initialViewState = {
  longitude: -74,
  latitude: 40,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

const initialNodes = [
  { id: "1", longitude: -74.5, latitude: 40, label: "Node 1" },
  { id: "2", longitude: -74.3, latitude: 40.1, label: "Node 2" },
  { id: "3", longitude: -74.8, latitude: 40.4, label: "Node 3" },
];

const initialLines = [
  {
    id: "1",
    sourceNodeId: "1",
    targetNodeId: "3",
    color: "#888",
    thickness: 2,
  },
  {
    id: "2",
    sourceNodeId: "2",
    targetNodeId: "3",
    color: "#888",
    thickness: 2,
  },
];

// Función para validar coordenadas
const isValidCoordinate = (lng, lat) => {
  return (
    typeof lng === "number" &&
    typeof lat === "number" &&
    lng >= -180 &&
    lng <= 180 &&
    lat >= -90 &&
    lat <= 90
  );
};

const MapWithMarkersAndLines = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [lines, setLines] = useState(initialLines);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState(initialViewState);

  const onMarkerDragEnd = (event, nodeId) => {
    const { lngLat } = event;
    if (!isValidCoordinate(lngLat[0], lngLat[1])) {
      console.error(`Invalid coordinates: [${lngLat[0]}, ${lngLat[1]}]`);
      return;
    }

    // Actualizar la posición del nodo
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, longitude: lngLat[0], latitude: lngLat[1] }
          : node
      )
    );

    // Actualizar las líneas relacionadas con este nodo
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.sourceNodeId === nodeId) {
          const targetNode = nodes.find(
            (node) => node.id === line.targetNodeId
          );
          return {
            ...line,
            coordinates: [
              [lngLat[0], lngLat[1]],
              [targetNode.longitude, targetNode.latitude],
            ],
          };
        } else if (line.targetNodeId === nodeId) {
          const sourceNode = nodes.find(
            (node) => node.id === line.sourceNodeId
          );
          return {
            ...line,
            coordinates: [
              [sourceNode.longitude, sourceNode.latitude],
              [lngLat[0], lngLat[1]],
            ],
          };
        }
        return line;
      })
    );
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Map
        ref={mapRef}
        {...viewport}
        mapboxApiAccessToken={mapboxApiAccessToken}
        mapStyle={mapStyle}
        style={initialStyle}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
      >
        {nodes.map((node) => {
          if (!isValidCoordinate(node.longitude, node.latitude)) {
            console.warn(
              `Invalid coordinates for node ${node.id}: [${node.longitude}, ${node.latitude}]`
            );
            return null;
          }

          return (
            <Marker
              key={node.id}
              longitude={node.longitude}
              latitude={node.latitude}
              draggable
              onDragEnd={(event) => onMarkerDragEnd(event, node.id)}
              anchor="center"
              offsetLeft={-10} // Ajustar el offset horizontal
              offsetTop={-10} // Ajustar el offset vertical
            >
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                {node.label}
              </div>
            </Marker>
          );
        })}

        <Source
          id="edges"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: lines.map((line) => {
              const sourceNode = nodes.find(
                (node) => node.id === line.sourceNodeId
              );
              const targetNode = nodes.find(
                (node) => node.id === line.targetNodeId
              );

              return {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [sourceNode.longitude, sourceNode.latitude],
                    [targetNode.longitude, targetNode.latitude],
                  ],
                },
                properties: {
                  color: line.color,
                  thickness: line.thickness,
                },
              };
            }),
          }}
        >
          <Layer
            id="lines"
            type="line"
            paint={{
              "line-color": ["get", "color"],
              "line-width": ["get", "thickness"],
            }}
          />
        </Source>
      </Map>
    </div>
  );
};

export default MapWithMarkersAndLines;
