import { api } from "../../services/api";
import { apiNl } from "../../services/apiNl";

export default class ParameterFileRepository {
  //Get Params with Sources
  async getParametersFiles({
    name = null,
    status = null,
    page = 1,
    size = 10,
    loading = true,
  }: {
    name?: null | string;
    status?: null | string;
    page: number;
    size: number;
    loading?: boolean;
  }): Promise<any> {
    const apiInstance = loading ? api : apiNl;
    const files = await apiInstance.get(`/bulks/parameterssources`, {
      params: { name, status, page, size },
    });
    return files.data;
  }

  async getStatusOfUpload(
    id: number | string,
    loading: boolean = true
  ) {
    let axiosMode = loading ? api : apiNl;
    const response = await axiosMode.get(`bulks/parameterssources/${id}`);
    return response.data;
  }

  async postParameterFiles(file: any): Promise<any> {
    const result = await api.post(`/bulks/parameterssources/`, {
      ...file,
      uploadedBy: "",
    });
    return result.data;
  }

  async deleteParameterFile(
    parameterFileId: string | number
  ): Promise<Parameter[]> {
    const result = await api.delete(
      `/bulks/parameterssources/${parameterFileId}`
    );
    return result.data;
  }
}
