import React, { useEffect, useState } from "react";
import SelectSearch from "../Library/SelectSearch";
import { Alert, Button, Divider, Select, Tooltip, notification } from "antd";
import {
  CheckCircleOutlined,
  FlagOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import Tree, { DataNode } from "antd/es/tree";
import LocationService from "../../domain/Location/LocationService";
import CountryService from "../../domain/Country/CountryService";
import UserService from "../../domain/Users/UserService";

const locationService = new LocationService();
const countryService = new CountryService();
const userService = new UserService();

interface IProps {
  setAddModalUser: any;
  isNewUser: any;
  selectedUser: any;
  setSelectNav: any;
}

export default function LocationsUser({
  setAddModalUser,
  setSelectNav,
  isNewUser,
  selectedUser,
}: IProps) {
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationsToList, setLocationsToList] = useState<DataNode[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const [locationDataUser, setLocationDataUser] = useState<any[]>([]);
  const [principalLocation, setPrincipalLocation] = useState("");
  const [visibleLocations, setVisibleLocations] = useState<any[]>([]);

  const [errors, setErrors] = useState<any[]>([]);

  async function handleGetLocations() {
    if (!selectedCountry) return;
    const security = localStorage.getItem("security");
    if (!security) return;
    const res = await locationService.getLocationsByCountryOrganization(
      selectedCountry,
      selectedUser.organizationId || selectedUser.organization
    );
    setLocations(res);
  }

  async function handleGetCountries() {
    const res = await countryService.getCountries();
    setCountries(res);
  }

  async function handleGetLocationsByUser() {
    const res = await userService.getLocationsIdsByUser(selectedUser.userId);
    setPrincipalLocation(res.defaultLocation == -1 ? "" : res.defaultLocation);
    setLocationDataUser(res.locations);
    setVisibleLocations(res.visible);
    return res.locations;
  }

  function findAncestorById(nodes: Location[], id: any) {
    function findAncestorRecursive(node: Location, targetId: any) {
      if (node.id === targetId) {
        return node;
      }
      for (const child of node.childrens) {
        const ancestor: any = findAncestorRecursive(child, targetId);
        if (ancestor) {
          return ancestor;
        }
      }
      return null;
    }

    for (const node of nodes) {
      const ancestor = findAncestorRecursive(node, id);
      if (ancestor) {
        return ancestor;
      }
    }

    return null;
  }

  function transformObjectToNode(node: any, nivel: number) {
    const { childrens } = node;
    const transformedNode = {
      title: JSON.stringify({ ...node, nivel, userCanView: true }),
      key: node.id,
      children: childrens.map((children: any) =>
        transformObjectToNode(children, nivel + 1)
      ),
    };
    return transformedNode;
  }

  function addLocationToList(nodeId: any) {
    const ancestor = findAncestorById(locations, nodeId);
    setLocationsToList((locationsListed) => {
      const finded = locationsListed.find((l) => l.key === ancestor.id);
      if (!finded) {
        return [...locationsListed, transformObjectToNode(ancestor, 1)];
      } else {
        return locationsListed;
      }
    });
  }

  function createAlert(key: any, message: string) {
    return (
      <Alert
        key={key}
        message={message}
        type="warning"
        showIcon
        style={{ width: "100%" }}
      />
    );
  }

  async function handleSaveLocations() {
    const errorMessages = {
      noLocations: "Select at least one location",
      noPrimary: "Primary location needs to be selected",
      noVisibles: "Mark at least one location as visibled",
    };

    const errorsToAdd: any[] = [];

    if (locationDataUser.length <= 0) {
      errorsToAdd.push({
        type: "noLocations",
        element: createAlert("noLocations", errorMessages.noLocations),
      });
    }

    if (!principalLocation) {
      errorsToAdd.push({
        type: "noPrimary",
        element: createAlert("noPrimary", errorMessages.noPrimary),
      });
    }

    if (visibleLocations.length <= 0) {
      errorsToAdd.push({
        type: "noVisibles",
        element: createAlert("noVisibles", errorMessages.noVisibles),
      });
    }

    setErrors((prevErrors) => {
      return prevErrors
        .filter(
          (err) => !errorsToAdd.some((newErr) => err.type === newErr.type)
        )
        .concat(errorsToAdd);
    });

    if (errorsToAdd.length > 0) return;

    const userId = selectedUser.userId;
    const res = await userService.postLocationsUser(userId, {
      defaultValue: principalLocation,
      locations: locationDataUser.join(),
      visible: visibleLocations.join(),
    });
    if (res) {
      notification.success({
        message: "Successful",
        description: "Location assignment saved successfully",
      });
      setAddModalUser(false);
      setLocations([]);
      setLocationsToList([]);
      setCountries([]);
      setSelectedCountry(null);
      setLocationDataUser([]);
      setPrincipalLocation("");
      setVisibleLocations([]);
      setSelectNav("UserInformation");
    }
  }

  async function handleInitCurrentUserData() {
    handleGetLocationsByUser().then((locationsUserResult) => {
      countries.forEach(async (country) => {
        const security = localStorage.getItem("security");
        if (!security) return;
        const organizationId = JSON.parse(security).organizationId;
        const res = await locationService.getLocationsByCountryOrganization(
          country.id,
          organizationId
        );
        locationsUserResult.forEach((location: any) => {
          const nodeExist = findAncestorById(res, location);
          if (nodeExist) {
            setLocationsToList((ltl) => {
              return [...ltl, transformObjectToNode(nodeExist, 1)];
            });
          }
        });
      });
    });
  }

  useEffect(() => {
    handleGetCountries();
  }, []);

  useEffect(() => {
    if (!isNewUser && countries.length > 0) {
      handleInitCurrentUserData();
    }
  }, [countries]);

  useEffect(() => {}, []);

  useEffect(() => {
    handleGetLocations();
  }, [selectedCountry]);

  return (
    <div className="flex column">
      <div className="flex" style={{ gap: "10px" }}>
        <div className="flex column" style={{ width: "50%" }}>
          <span>Regions</span>
          <SelectSearch
            styleDiv={{ width: "100%" }}
            placeholder="Search"
            options={countries.map((c) => {
              return { value: c.id, label: c.name };
            })}
            onSelect={(value: any) => {
              setSelectedCountry(value.value);
            }}
            fetching={false}
          />
        </div>
        <div className="flex column" style={{ width: "50%" }}>
          <span>Locations</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Search"
            options={locations.map((c) => {
              return { value: c.id, label: c.name };
            })}
            onSelect={(value: any) => {
              addLocationToList(value);
              console.log(value);
            }}
          />
        </div>
      </div>

      <div className="flex column mt-4">
        {errors.map((error) => {
          return error.element;
        })}
      </div>

      <div className="flex my-4">
        <FlagOutlined
          style={{ color: "#C4CCD5", height: "14px" }}
          className="mr-1"
        />
        <span>
          Select the primary location that will appear as primary on their main
          screen.
        </span>
      </div>
      <div>
        <Tree
          checkable
          selectable
          blockNode
          checkStrictly
          checkedKeys={locationDataUser}
          onSelect={() => {}}
          onCheck={(checkedKeys: any) => {
            if (checkedKeys.checked.length > 0) {
              setErrors((errors) => {
                return errors.filter((err) => err.type !== "noLocations");
              });
            }
            if (checkedKeys.checked.includes(principalLocation)) {
              setLocationDataUser(checkedKeys.checked);
            }
          }}
          treeData={locationsToList}
          titleRender={(nodeData) => {
            const item = JSON.parse(nodeData.title as string);
            return (
              <div className="flex itemTreeContainer">
                <span>{`${item.name}`}</span>
                <div
                  className="flex ml-auto"
                  style={{ flexDirection: "row-reverse" }}
                >
                  {item.nivel == 1 && (
                    <Tooltip title="Remove Location">
                      <CloseCircleOutlined
                        className="ml-1"
                        style={{
                          color: "#74788D",
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          setLocationsToList((locations) =>
                            locations.filter((loc) => {
                              return loc.key != item.id;
                            })
                          );
                        }}
                      />
                    </Tooltip>
                  )}
                  {visibleLocations.find((lid) => lid == item.id) ? (
                    <Tooltip title="Set as invisible">
                      <EyeOutlined
                        className="ml-1"
                        style={{
                          color: "#74788D",
                          fontSize: "24px",
                          cursor:
                            item.id == principalLocation
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => {
                          if (item.id == principalLocation) return;
                          setVisibleLocations((list) =>
                            list.filter((id) => id != item.id)
                          );
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Set as visible">
                      <EyeInvisibleOutlined
                        className="ml-1"
                        style={{
                          color: "#74788D",
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          setErrors((errors) => {
                            return errors.filter(
                              (err) => err.type !== "noVisibles"
                            );
                          });
                          setVisibleLocations((list) => [...list, item.id]);
                        }}
                      />
                    </Tooltip>
                  )}
                  {principalLocation != item.id ? (
                    <Tooltip title="Set as principal">
                      <FlagOutlined
                        style={{
                          color: "#74788D",
                          fontSize: "24px",
                          display: "none",
                        }}
                        className="ml-1 flagItemTree"
                        onClick={() => {
                          setErrors((errors) => {
                            return errors.filter(
                              (err) => err.type !== "noPrimary"
                            );
                          });
                          console.log("item", item);
                          setPrincipalLocation(item.id);
                          if (!locationDataUser.includes(item.id)) {
                            setLocationDataUser((prev) => {
                              return [...prev, item.id];
                            });
                          }
                          if (!visibleLocations.includes(item.id)) {
                            setVisibleLocations((list) => [...list, item.id]);
                          }
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <FlagOutlined
                      style={{
                        color: "#44B6DC",
                        fontSize: "24px",
                      }}
                      className="ml-1 flagItemTree"
                    />
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
      <Divider style={{ border: "1px solid #E6E6E6" }} />
      <div className="flex justify-between">
        <Button
          className="mr-3"
          style={{ width: "85px", height: "40px" }}
          onClick={() => setAddModalUser(false)}
        >
          Cancel
        </Button>
        <Button
          style={{
            width: "100px",
            height: "40px",
            backgroundColor: "#00203C",
            color: "white",
          }}
          onClick={() => {
            handleSaveLocations();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
