import { api } from "../../services/api";
import "./Types";
import { Pagination } from "antd";

export default class InfrastructureRepository {
  async getInfrastructureWithParams(
    infraestructureId: string
  ): Promise<Infrastructure> {
    const data = await api.get(
      `/measurements/infrastructures/${infraestructureId}/parameters/all/sources/all`
    );
    const infra = data.data;
    return {
      id: infra.id,
      name: infra.name,
      description: infra.description,
      inpId: infra.inpId,
      inpObjectId: infra.inpObjectId,
      inpObjectType: infra.inpObjectType,
      inUse: infra.inUse,
      latitude: infra.latitude,
      longitude: infra.longitude,
      locationId: infra.locationId,
      locationName: infra.locationName,
      infraTypeId: infra.infraTypeId,
      parameters: infra.parametersDTOList.map(
        (parameter: {
          inpId: number;
          inpObjectId: string;
          inpObjectType: number;
          parameterType: string;
          parameterTypeId: number;
          parameterUnit: string;
          parameterUnitId: number;
          id: number;
          name: string;
          expirationTime: number;
          forecastedRetentionTime: number;
          criticalHigh: number;
          criticalHhigh: number;
          criticalLow: number;
          criticalLlow: number;
          principal: boolean;
          retentionPolicy: number;
          sampleRate: number;
          historicalRetentionTime: number;
          txRate: number;
          visible: boolean;
          sourcesDTOList: any[];
        }) => {
          return {
            id: parameter.id,
            name: parameter.name,
            expirationTime: parameter.expirationTime,
            forecastedRetentionTime: parameter.forecastedRetentionTime,
            highCritical: parameter.criticalHigh,
            hHighCritical: parameter.criticalHhigh,
            lowCritical: parameter.criticalLow,
            lLowCritical: parameter.criticalLlow,
            historicalPolicy: parameter.historicalRetentionTime,
            principal: parameter.principal,
            isVisible: parameter.visible,
            retentionPolicy: parameter.retentionPolicy,
            sampleRate: parameter.sampleRate,
            transferRate: parameter.txRate,
            parameterType: parameter.parameterType,
            paramTypeId: parameter.parameterTypeId,
            parameterUnit: parameter.parameterUnit,
            paramUnitId: parameter.parameterUnitId,
            inpId: parameter.inpId,
            inpObjectId: parameter.inpObjectId,
            inpObjectType: parameter.inpObjectType,
            sources: parameter.sourcesDTOList.map((source) => {
              return {
                id: source.id,
                name: source.name,
                isEnabled: source.enabled,
                isVirtual: source.virtual,
                sourceSerialNumber: source.sourceSerialNumber,
                forecastMetService: source.forecastMetService,
                historicalMetService: source.historicalMetService,
                ...(source.virtual
                  ? source.virtualType === "M"
                    ? {
                        virtualType: "MODEL",
                        inpId: source.inpId,
                        objectModelId: source.inpObjectId,
                        objectTypeId: source.inpObjectType,
                      }
                    : {
                        virtualType: "CODE",
                        statement: source.statement,
                        variablesDTOS: JSON.parse(source.variablesDTOS || "[]"),
                        virtualCurrentTs: source.virtualCurrentTs,
                      }
                  : {}),
              } as ParameterSource;
            }),
          } as Parameter;
        }
      ),
    } as Infrastructure;
  }

  async getInfrastructureTypes(): Promise<Types[]> {
    const types = await api.get(`measurements/infrastructure/types`);
    return types.data.map(
      (type: { infrastructureTypeId: any; infrastructureTypeName: any }) => {
        return {
          value: type.infrastructureTypeId,
          label: type.infrastructureTypeName,
        };
      }
    );
  }

  async postAddParameter(
    infrastructureId: string,
    parameters: Parameter
  ): Promise<any> {
    const parameter = await api.post(
      `measurements/infrastructures/${infrastructureId}/parameters`,
      parameters
    );
    return parameter.data;
  }

  async getInfraestructureInUse(
    location: string | number,
    page: number,
    size: number,
    name: string | null,
    infraId: any | null,
    infraTypeId: number | string | null
  ): Promise<any> {
    let filter = "";

    if (!location) return [];

    if (infraId) {
      filter += `&infraId=${infraId}`;
    }

    if (name && name != "") {
      filter += `&name=${name}`;
    }

    if (infraTypeId && infraTypeId != 0) {
      filter += `&infraTypeIds=${infraTypeId}`;
    }

    const parameters = await api.get(
      `/measurements/location/${location}/inuse?page=${page}&size=${size}${filter}`
    );
    return parameters.data;
  }

  async getMeasurementTarget(infrastructureId: string): Promise<any> {
    const all = await api.get(
      `measurements/infraestructure/${infrastructureId}/measurementTarget/all`
    );
    return all.data;
  }

  async putInfraestructureDescription(
    infrastructureId: string | number,
    description: string
  ): Promise<any> {
    const response = await api.put(
      `measurements/infrastructures/${infrastructureId}/description`,
      {
        description,
      }
    );
    return response.data;
  }

  async getAllInfrastructuresByOrganization({
    organizationId,
    page,
    size,
  }: getAllInfrastructuresByOrganizationRequest) {
    const response = await api.get(
      `measurements/infrastructures/organizations/${organizationId}`,
      { params: { page, size } }
    );
    return response.data;
  }
}
