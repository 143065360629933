import React from "react";
import { Button, message, Upload } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


const LoadFile: React.FC<{url:string, text:string}> = ({url, text}) => {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>, value?: string) => {
        navigate(`/upload/${value}`)
      };



    return  <div className="contenedor-inicio">
              <div className="texto-inicio">Woops looks like you have nothing here.</div>
              <div  className="texto-inicio">Start by clicking here</div>
              <Button onClick={(e) => handleClick(e, url)} className="mt-3 boton-inicio" icon={<PlusCircleOutlined rev={undefined}  />}>{text}</Button>
            </div>
}

export default LoadFile;
