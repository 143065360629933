import React, { ReactElement, useState } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import type { MenuProps } from "antd";
import Icon, { UserOutlined, SettingOutlined } from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { MenuInfo } from "rc-menu/lib/interface";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ArrowDropdownSvg from "../../assets/svg/ArrowDropdownSvg";
import LogoutIconSvg from "../../assets/svg/LogoutIconSvg";
import OrganizationIconSvg from "../../assets/svg/OrganizationIconSvg";
import ModalExit from "../Modal";

const Header: React.FC = ({ security }: any) => {
  const name = `${security.firstname} ${security.lastname}`;
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex cursor-pointer"
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          <Icon component={LogoutIconSvg} className="mr-2" rev={undefined} />
          <span style={{ color: "#F46A6A" }}>Logout</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/organizations");
          }}
          style={{ display: security.role == "SuperAdmin" ? "flex" : "none" }}
        >
          <Icon
            component={OrganizationIconSvg}
            className="mr-2"
            rev={undefined}
          />
          <span style={{ color: "#00203C" }}>Organization Manager</span>
        </div>
      ),
    },
  ];
  return (
    <div
      style={{
        height: "72px",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <Dropdown
        menu={{
          items,
        }}
      >
        <div
          style={{
            maxWidth: "180px",
            padding: "0px 10px",
            height: "40px",
            borderRadius: "52px",
            border: "solid 1px #C4CCD5",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginRight: "15px",
            marginLeft: "15px",
          }}
        >
          <Avatar
            size={24}
            icon={<UserOutlined rev={undefined} />}
            className="mr-1"
          />
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "120px",
            }}
          >
            {name}
          </span>
          <ArrowDropdownSvg />
        </div>
      </Dropdown>
      {showLogoutModal && <ModalExit closeModal={setShowLogoutModal} />}
    </div>
  );
};

function mapState(state: any) {
  return {
    security: state.security.array,
  };
}

export default connect(mapState)(Header);
