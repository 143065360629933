import React, { useState, useEffect } from "react";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import { DatePicker, Space, Input, Select, Table, Tag, Pagination } from "antd";
import { apiController } from "../../services/api";
import { getSimulationHistory } from "../../services/simulations";
import type { ColumnsType } from "antd/es/table";
import type { PaginationProps } from "antd";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
interface DataType {
  key: string;
  id: string;
  inp: number;
  status: string;
  startedAt: string;
  finishedAt: string;
  timeExecution: string;
}

const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

const Simulations: React.FC = () => {
  const [simulationId, setSimulationId] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [dates, setDates] = useState<any>(null);
  const [status, setStatus] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });

  useEffect(() => {
    handleUpdateHistory();
    return () => {
      apiController.abort();
    };
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    setPagination({ ...pagination, page: 1 });
    if (prevPage == 1) {
      handleUpdateHistory();
    }
    return () => {
      apiController.abort();
    };
  }, [simulationId, startDate, endDate, status]);

  const handleUpdateHistory = async () => {
    let organizationId = [];
    const printItems = localStorage.getItem("security");
    if (printItems) {
      organizationId = JSON.parse(printItems).organizationId;
    }
    getSimulationHistory(organizationId, {
      page: pagination.page,
      size: pagination.pageSize,
      simulationId,
      startDate,
      endDate,
      status,
    })
      .then((response) => {
        setPagination({
          ...pagination,
          totalRows: response.totalSimulations,
        });
        setSimulations(response.simulationsResponseDTOList);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "years") >= 2;
    const tooEarly = dates[1] && dates[1].diff(current, "years") >= 2;
    return !!tooEarly || !!tooLate;
  };

  const { RangePicker } = DatePicker;

  const columns: ColumnsType<DataType> = [
    {
      title: "Simulation ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <a style={{ color: "#078CB3" }}>{text}</a>,
    },
    {
      title: "INP File",
      dataIndex: "inp",
      key: "inp",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const fontColor = "#232B36";
        let color = "#FFEECB";
        if (text === "COMPLETED") {
          color = "#F7F9FC";
        }
        if (text === "SUCCESS") {
          color = "#C0EFDE";
        }
        if (text === "IN PROGRESS") {
          color = "#FFEECB";
        }
        if (text === "FAILED") {
          color = "#FFD3D3";
        }
        return (
          <Tag color={color} style={{ color: fontColor }} key={text}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Started at",
      key: "startedAt",
      render: (_, record) => (
        <span>{dayjs(record.startedAt).format("YYYY/MM/DD [at] hh:mm a")}</span>
      ),
    },
    {
      title: "Finished at",
      key: "finishedAt",
      render: (_, record) => (
        <span>
          {dayjs(record.finishedAt).format("YYYY/MM/DD [at] hh:mm a")}
        </span>
      ),
    },
    {
      title: "Time execution (seconds)",
      dataIndex: "timeExecution",
      key: "timeExecution",
    },
  ];

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    // console.log(page);
    setPagination({ ...pagination, page: page });
  };

  return (
    <div>
      <div className="mt-5">
        <Input
          style={{ width: 200 }}
          placeholder="Search ID"
          prefix={
            <ClockCircleOutlined style={{ color: "#078CB3" }} rev={undefined} />
          }
          onPressEnter={(e) => {
            if (e.target) {
              setPagination({ ...pagination, page: 1 });
              const element = e.currentTarget as HTMLInputElement;
              setSimulationId(element.value);
            }
          }}
          onChange={(e) => {
            if (e.currentTarget.value == '') {
              setPagination({ ...pagination, page: 1 });
              setSimulationId(null);
            }
          }}
          allowClear
        />
        <RangePicker
          onChange={(dates: null | (Dayjs | null)[], dateStrings: string[]) => {
            if (dates) {
              setStartDate(dateStrings[0]);
              setEndDate(dateStrings[1]);
            } else {
              console.log("Clear");
            }
          }}
          disabledDate={disabledDate}
          presets={rangePresets}
          onCalendarChange={(val) => {
            setDates(val);
          }}
          // onChange={onRangeChange}
          format="YYYY-MM-DD"
          style={{ marginLeft: 30, width: 300 }}
        />
        <Select
          style={{ marginLeft: 30, width: 140 }}
          placeholder="Status"
          optionFilterProp="children"
          options={[
            {
              value: "COMPLETED",
              label: "COMPLETED",
            },
            {
              value: "IN PROGRESS",
              label: "IN PROGRESS",
            },
            {
              value: "FAILED",
              label: "FAILED",
            },
          ]}
          onChange={(value) => {
            setStatus(value);
            // handleUpdateHistory()
          }}
          allowClear
        />
      </div>
      <div></div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={simulations}
          pagination={false}
          style={{
            maxHeight: "calc(100vh - 420px)",
            overflowX: "auto",
          }}
          // scroll={{ x: 1100, y: 400 }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalRows}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default Simulations;
