import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";

interface propertyTypes {
  selectedTransition: any;
  source: any;
  target: any;
  onCancel: Function;
  saveChanges: Function;
}

export default function TransitionModal({
  selectedTransition,
  source,
  target,
  onCancel,
  saveChanges,
}: propertyTypes) {
  const [statement, setStatement] = useState("");

  useEffect(() => {
    if (selectedTransition) {
      setStatement(selectedTransition.condition.statement);
    }
  }, [selectedTransition]);

  return (
    <Modal
      title=""
      open={true}
      footer={[]}
      onCancel={() => {
        onCancel();
      }}
    >
      <div className="flex column">
        <span>Origin State: {source.data.label}</span>
        <span>Target State: {target.data.label}</span>
        <AceEditor
          className="mt-1"
          mode="python"
          theme="github"
          name="editor"
          onChange={(val) => {
            setStatement(val);
          }}
          value={statement}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          placeholder="Type your code here"
          style={{ maxHeight: "120px", width: "auto" }}
        />
        <div className="flex justify-center mt-2">
          <Button
            style={{
              backgroundColor: "#00203C",
              color: "white",
              height: "40px",
            }}
            onClick={() => {
              saveChanges({
                fromId: source.id,
                toId: target.id,
                condition: { statement: statement },
              });
              onCancel();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
