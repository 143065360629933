import React, { FC } from "react";

/**
 * @author
 * @function @WaveSvg
 **/

 const WaveSvg: FC = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1020_28579)">
                <path
                    d="M12.0476 21.0476C17.0445 21.0476 21.0952 16.9969 21.0952 12C21.0952 7.00313 17.0445 2.95238 12.0476 2.95238C7.05076 2.95238 3 7.00313 3 12C3 16.9969 7.05076 21.0476 12.0476 21.0476Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4 10.9803L5.85166 9.30958C6.30615 8.89681 6.94582 8.89681 7.40873 9.30958L8.61231 10.3907C9.06681 10.8034 9.70647 10.8034 10.1694 10.3907L11.314 9.35872C11.7685 8.94595 12.4082 8.94595 12.8711 9.35872L13.9232 10.3022C14.3777 10.715 15.0174 10.715 15.4803 10.3022L16.5744 9.31941C17.0289 8.90663 17.6686 8.90663 18.1315 9.31941L20 11"
                    fill="white"
                />
                <path
                    d="M4 10.9803L5.85166 9.30958C6.30615 8.89681 6.94582 8.89681 7.40873 9.30958L8.61231 10.3907C9.06681 10.8034 9.70647 10.8034 10.1694 10.3907L11.314 9.35872C11.7685 8.94595 12.4082 8.94595 12.8711 9.35872L13.9232 10.3022C14.3777 10.715 15.0174 10.715 15.4803 10.3022L16.5744 9.31941C17.0289 8.90663 17.6686 8.90663 18.1315 9.31941L20 11"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4 15.9803L5.85166 14.3096C6.30615 13.8968 6.94582 13.8968 7.40873 14.3096L8.61231 15.3907C9.06681 15.8034 9.70647 15.8034 10.1694 15.3907L11.314 14.3587C11.7685 13.9459 12.4082 13.9459 12.8711 14.3587L13.9232 15.3022C14.3777 15.715 15.0174 15.715 15.4803 15.3022L16.5744 14.3194C17.0289 13.9066 17.6686 13.9066 18.1315 14.3194L20 16"
                    fill="white"
                />
                <path
                    d="M4 15.9803L5.85166 14.3096C6.30615 13.8968 6.94582 13.8968 7.40873 14.3096L8.61231 15.3907C9.06681 15.8034 9.70647 15.8034 10.1694 15.3907L11.314 14.3587C11.7685 13.9459 12.4082 13.9459 12.8711 14.3587L13.9232 15.3022C14.3777 15.715 15.0174 15.715 15.4803 15.3022L16.5744 14.3194C17.0289 13.9066 17.6686 13.9066 18.1315 14.3194L20 16"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1020_28579">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WaveSvg