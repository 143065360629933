//----
import { Button, Col, Divider, Form, Input, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../domain/Users/UserService";
import { CheckCircleOutlined } from "@ant-design/icons";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const dataUTC = [
  { label: "(UTC-12:00) Baker Island, Howland Island", value: -12 },
  { label: "(UTC-11:00) Pago Pago", value: -11 },
  { label: "(UTC-10:00) Honolulu", value: -10 },
  { label: "(UTC-09:00) Anchorage", value: -9 },
  { label: "(UTC-08:00) Los Ángeles", value: -8 },
  { label: "(UTC-07:00) Denver", value: -7 },
  { label: "(UTC-06:00) Ciudad de México", value: -6 },
  { label: "(UTC-05:00) Bogotá, Lima, Quito, Rio Branco", value: -5 },
  { label: "(UTC-04:00) Caracas, La Paz", value: -4 },
  { label: "(UTC-03:00) Buenos Aires, Georgetown", value: -3 },
  { label: "(UTC-02:00) Stanley", value: -2 },
  { label: "(UTC-01:00) Azores, Cabo Verde Is.", value: -1 },
  { label: "(UTC+00:00) Casablanca, Monrovia, Reykjavik", value: +0 },
  { label: "(UTC+01:00) Londres, Dublin, Lisboa", value: +1 },
  { label: "(UTC+02:00) París, Berlín, Roma, Madrid, Varsovia", value: +2 },
  { label: "(UTC+03:00) Moscú, San Petersburgo, Volgogrado", value: +3 },
  { label: "(UTC+04:00) Dubai, Baku, Tbilisi", value: +4 },
  { label: "(UTC+05:00) Islamabad, Karachi, Tashkent", value: +5 },
  { label: "(UTC+06:00) Almaty, Novosibirsk", value: +6 },
  { label: "(UTC+07:00) Bangkok, Hanói, Yakarta", value: +7 },
  { label: "(UTC+08:00) Pekín, Hong Kong, Singapur, Perth", value: +8 },
  { label: "(UTC+09:00) Tokio, Seúl", value: +9 },
  { label: "(UTC+10:00) Sídney, Melbourne, Brisbane", value: +10 },
  { label: "(UTC+11:00) Honiara, Vanuatu", value: +11 },
  { label: "(UTC+12:00) Auckland, Wellington", value: +12 },
];
const organizationId = JSON.parse(
  localStorage.getItem("security") || "{}"
).organizationId;

interface IProps {
  setAddModalUser: any;
  roles: any;
  modules: any;
  organizations: any;
  handleGetUsers: any;
  isNewUser: any;
  selectedUser: any;
  setSelectedUser: any;
  setSelectNav: any;
}

type PersonalInformationRefType = {
  resetForm: Function;
};

const userService = new UserService();

const PersonalInformation = React.forwardRef<
  PersonalInformationRefType,
  IProps
>(
  (
    {
      setAddModalUser,
      setSelectNav,
      roles,
      modules,
      organizations,
      isNewUser,
      selectedUser,
      setSelectedUser,
      handleGetUsers,
    }: IProps,
    ref
  ) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
    const userRole = JSON.parse(localStorage.getItem("security") || "{}").role;

    React.useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
      },
    }));

    const onFinish = async (values: any) => {
      values.createdBy = localStorage.getItem("userId") || "";
      values.modules = values.modules.map((module: any) => {
        return {
          id: module.value,
          name: module.label,
        };
      });
      if (userRole == "Administrator") {
        values.organization = organizationId;
      }

      const resCreate: any = await userService.createUser(values);
      console.log("🚀 ~ onFinish ~ resCreate:", resCreate);
      if (resCreate.success) {
        if (isNewUser) {
          notification.success({
            message: "Successful",
            description: "User created successfully",
          });
        } else {
          notification.success({
            message: "Successful",
            description: "User edited successfully",
          });
        }
        if (!selectedUser) {
          const responseUser = JSON.parse(resCreate.data);
          setSelectedUser({
            userId: responseUser.userId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            timeZone: values.timeZone,
            roleId: values.roleId,
            organization: values.organization,
            modules: values.modules,
          });
        }
        // form.resetFields();
        // handleGetUsers();
        setSelectNav("AsignLocation");
      }
    };

    const validatePassword = (rule: any, value: any, callback: any) => {
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*])[A-Za-z\d@#$%^&*]{8,}$/;

      if (value && !passwordRegex.test(value)) {
        callback("The password does not fullfill the requirements");
      } else {
        callback();
      }
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };

    useEffect(() => {
      if (!isNewUser) {
        form.setFieldsValue({
          userId: selectedUser?.userId,
          firstName: selectedUser?.firstName,
          lastName: selectedUser?.lastName,
          email: selectedUser?.email,
          timeZone: parseInt(selectedUser?.timeZone),
          roleId: selectedUser?.roleId,
          organization: selectedUser?.organizationId,
          modules: selectedUser?.modules.map((module: any) => {
            return {
              value: module.id,
              label: module.name,
            };
          }),
        });
      } else {
        form.setFieldsValue({});
      }
    }, [selectedUser, isNewUser]);

    return (
      <Form
        layout={formLayout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item hidden={true} name="userId">
          <Input />
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label={<div style={{ width: 80 }}>First Name</div>}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label={<div style={{ width: 80 }}>Email</div>}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              label={<div style={{ width: 80 }}>Role</div>}
              name="roleId"
              rules={[
                {
                  required: true,
                  message: "Please select your role!",
                },
              ]}
            >
              <Select
                placeholder="Role"
                // defaultValue="lucy"
                // onChange={handleChange}
                options={roles}
              />
            </Form.Item>
            {userRole == "SuperAdmin" ? (
              <Form.Item
                label={<div style={{ width: 80 }}>Organization</div>}
                name={"organization"}
                rules={[
                  {
                    required: true,
                    message: "Please select your organization!",
                  },
                ]}
              >
                <Select
                  placeholder="Organization"
                  // defaultValue="lucy"
                  // onChange={handleChange}
                  options={organizations}
                />
              </Form.Item>
            ) : (
              ""
            )}
            {isNewUser ? (
              <div>
                <Form.Item
                  label={<div style={{ width: 80 }}>Password</div>}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    { validator: validatePassword },
                    // { min: 8, message: 'Username must be minimum 8 characters.' },
                  ]}
                  help={
                    <div className="flex column">
                      <div>
                        <CheckCircleOutlined className="mr-1" />
                        <span>Minimum 8 characters</span>
                      </div>
                      <div>
                        <CheckCircleOutlined className="mr-1" />
                        <span>Lowercase characters</span>
                      </div>
                      <div>
                        <CheckCircleOutlined className="mr-1" />
                        <span>Uppercase characters</span>
                      </div>
                    </div>
                  }
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label={<div style={{ width: 80 }}>Last Name</div>}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label={<div style={{ width: 80 }}>Time Zone</div>}
              name="timeZone"
              rules={[
                {
                  required: true,
                  message: "Please select your Time Zone!",
                },
              ]}
            >
              <Select
                placeholder="Time Zone"
                // defaultValue="lucy"
                // onChange={handleChange}
                options={dataUTC}
              />
            </Form.Item>
            <Form.Item
              label={<div style={{ width: 80 }}>Modules</div>}
              name="modules"
              rules={[
                {
                  required: true,
                  message: "Please select your modules!",
                },
              ]}
            >
              <Select
                labelInValue
                placeholder="Modules"
                mode="tags"
                // defaultValue="lucy"
                // onChange={handleChange}
                options={modules}
              />
            </Form.Item>
            {isNewUser && (
              <Form.Item
                label={<div style={{ width: 80 }}>Re-password</div>}
                name="rePassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    min: 8,
                    message: "Password must be minimum 8 characters.",
                  },
                  { validator: validatePassword },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
                hasFeedback

                // Accepted Symbols @#$%^&*-_!+=[]|\:',.?/`~();"
              >
                <Input.Password />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Divider style={{ border: "1px solid #E6E6E6" }} />
        <div className="flex justify-between">
          <Button
            className="mr-3"
            style={{ width: "85px", height: "40px" }}
            onClick={() => setAddModalUser(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "150px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            htmlType="submit"
          >
            Save and continue
          </Button>
        </div>
      </Form>
    );
  }
);

export default PersonalInformation;
