import React, { useEffect, useState } from "react";
import InfrastructureService from "../domain/Infrastructure/InfraestructureService";
import InpService from "../domain/ModelFile/InpService";
import MetereologicalService from "../domain/Metereological/MetereologicalService";

const metereologicalService = new MetereologicalService();

export default function useMetereological() {
  const [meterelogicals, setMeterelogicals] = useState<Metereological[]>([]);

  async function handleGetMetereological() {
    const response = await metereologicalService.getMeteorologicalInfo();
    setMeterelogicals(response);
  }

  useEffect(() => {
    handleGetMetereological();
  }, []);

  return { meterelogicals };
}
