import { api } from "../../services/api";

export default class OrganizationRepository {
  async getOrganizations({ name }: { name: null | string }) {
    const res = await api.get(`security/organizations`, { params: { name } });
    return res.data;
  }

  async postOrganization(data: any) {
    const res = await api.post("security/organizations", data);
    return res.data;
  }

  async putOrganization(organizationId: string, data: any) {
    const res = await api.put(`security/organizations/${organizationId}`, data);
    return res.data;
  }

  async deleteOrganization(organizationId: string) {
    const res = await api.delete(`security/organizations/${organizationId}`);
    return res.data;
  }
}
