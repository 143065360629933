import { api } from "../../services/api";

export default class HydraulicSimulatorRepository {
  async postInpAssets(file: string, name: string, others: any): Promise<any> {
    const response = await api.post(`/mms/upload`, { file, fileName: name, ...others });
    return response.data;
  }

  async getAssetsFromMmsid(mmsId: string) {
    const response = await api.get(`mms/${mmsId}`);

    return response.data;
  }

  async getAttributesFromAsset(mmsId: string, assetId: string, type: string) {
    const response = await api.get(
      `mms/${mmsId}/assets/${assetId}/type/${type}/details`
    );
    return response.data;
    // return {
    //   assetId: "TANK_003",
    //   assetType: "NODE",
    //   type: "STORAGE",
    //   coordinates: [[41.68043393728247, -86.25448354881955]],
    //   attributes: [
    //     {
    //       name: "depth",
    //       value: "30",
    //       editable: true,
    //       numeric: true,
    //       ranges: [4, null],
    //       options: [],
    //     },
    //     {
    //       name: "depth2",
    //       value: "30",
    //       editable: true,
    //       numeric: false,
    //       ranges: [],
    //       options: ["30", "40", "50"],
    //     },
    //   ],
    //   edited: false,
    //   invisible: false,
    // };
  }

  async postSaveChangesModelFile(mmsId: string, data: any) {
    const response = await api.post(`mms/${mmsId}/changes`, data);
    return response.data;
  }
}
