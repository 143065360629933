/* eslint-disable @typescript-eslint/ban-types */
import React, { FC, useEffect, useState } from "react";
import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";
import {
  Alert,
  Modal,
  Button,
  Input,
  Select,
  Table,
  Pagination,
  Tooltip,
  notification,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import GisService from "../../domain/Gis/GisService";
import dayjs from "dayjs";

const infrastructureService = new InfrastructureService();
const gisService = new GisService();

interface DataType {
  key: string;
  uploadId: string;
  uploadName: number;
  gisFileUploadedDTOS: {
    locationName: string;
    infraTypeName: string;
    createdBy: string;
  }[];
  uploadDate: string;
  uploadStatusMessage: string;
  uploadStatus: string;
}

const GisTable: FC<{
  handleUpdateGisFiles: any;
  files: Array<any>;
  onChangePagination: any;
  pagination: any;
  setInputSearch: Function;
  setTypeGis: Function;
}> = ({
  handleUpdateGisFiles,
  files,
  onChangePagination,
  pagination,
  setInputSearch,
  setTypeGis,
}) => {
  const navigate = useNavigate();
  const [types, setTypes] = useState<Types[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [gisSelect, setGisSelect] = useState<any | null>(null);

  const columns: ColumnsType<DataType> = [
    {
      title: "File Name",
      dataIndex: "uploadName",
      key: "uploadName",
      render: (_, { uploadName }) => (
        <>
          <span className="table-data-gis">{uploadName}</span>
        </>
      ),
    },
    {
      title: "Location",
      dataIndex: "gisFileUploadedDTOS",
      key: "gisFileUploadedDTOS",
      render: (_, { gisFileUploadedDTOS }) => (
        <>
          {gisFileUploadedDTOS.map((gis, index) => {
            return (
              <span className="table-data-gis" key={index}>
                {gis.locationName}
              </span>
            );
          })}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "gisFileUploadedDTOS",
      key: "gisFileUploadedDTOS",
      render: (_, { gisFileUploadedDTOS }) => (
        <>
          {gisFileUploadedDTOS.map((gis, index) => {
            return (
              <span className="table-data-gis" key={index}>
                {gis.infraTypeName.charAt(0).toUpperCase() +
                  gis.infraTypeName.slice(1).toLowerCase()}
              </span>
            );
          })}
        </>
      ),
    },
    {
      title: "Uploaded by",
      dataIndex: ["uploadDate", "gisFileUploadedDTOS"],
      key: "gisFileUploadedDTOS",
      render: (_, { uploadDate, gisFileUploadedDTOS }) => (
        <>
          {gisFileUploadedDTOS.map((gis, index) => {
            return (
              <div key={index}>
                <span className="table-data-gis">{gis.createdBy}</span>
                <br></br>
                <span className="date-gis">
                  {dayjs(uploadDate).format("YYYY/MM/DD [at] hh:mm a")}
                </span>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Upload Status",
      dataIndex: "uploadStatus",
      key: "uploadStatus",
      render: (_, { uploadStatus }) => {
        const fontColor = "#232B36";
        let color = "#FFEECB";
        if (uploadStatus === "COMPLETED") {
          color = "#C0EFDE";
        }
        if (uploadStatus === "IN PROGRESS") {
          color = "#FFEECB";
        }
        if (uploadStatus === "FAILED") {
          color = "#FFD3D3";
        }
        return (
          uploadStatus && (
            <Tag color={color} style={{ color: fontColor }}>
              {uploadStatus}
            </Tag>
          )
        );
      },
    },
    {
      title: "Upload Status Message",
      dataIndex: "uploadStatusMessage",
      key: "uploadStatusMessage",
      render: (_, { uploadStatusMessage }) => (
        <span className="table-data-gis">{uploadStatusMessage}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "uploadId",
      key: "uploadId",
      render: (_, { uploadName, uploadId }) => (
        <>
          <Tooltip title="Delete">
            <DeleteOutlined
              className="mr-1"
              style={{ fontSize: "1.5rem" }}
              onClick={() => {
                setGisSelect({
                  label: uploadName,
                  id: uploadId,
                });
                setShowDeleteModal(true);
              }}
              rev={undefined}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    const res = infrastructureService.getInfrastructureTypes();
    res.then((value) => {
      setTypes(value);
    });
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={
              <ClockCircleOutlined
                style={{ color: "#078CB3" }}
                rev={undefined}
              />
            }
            onChange={(e) => {
              if (e.target.value == "") {
                setInputSearch(null);
              }
            }}
            onPressEnter={(e) => {
              if (e.target) {
                const element = e.currentTarget as HTMLInputElement;
                setInputSearch(element.value);
              }
            }}
            allowClear
          />
          <Select
            placeholder={"Type"}
            style={{ marginLeft: 30, width: 140 }}
            optionFilterProp="children"
            options={types}
            onChange={(value) => {
              setTypeGis(value);
            }}
            allowClear
          />
        </div>

        <div>
          <Button
            style={{ fontWeight: 700, fontSize: 14 }}
            shape="round"
            className="mr-5 boton-inicio flex items-center"
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`/upload/gisfiles`);
            }}
          >
            Upload GIS Files
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <div style={{ maxHeight: "calc(100vh - 320px)", overflowX: "auto" }}>
          <Table
            columns={columns}
            dataSource={files}
            rowKey={(record) => {
              return record.uploadId;
            }}
            pagination={false}
            size="small"
            scroll={{ x: 1100, y: 370 }}
          />
        </div>
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          current={pagination.page}
          total={pagination.totalRows}
          showSizeChanger={false}
        />
      </div>
      <Modal
        open={showDeleteModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
      >
        <div className="flex column items-center">
          <span
            className="mb-2"
            style={{
              color: "#232B36",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            GIS File delete?
          </span>
          <span
            className="mb-3"
            style={{
              color: "#74788D",
              fontSize: "1rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure to delete {gisSelect?.label} ?
          </span>
          <span>
            <Alert
              style={{
                border: "none",
                background: "rgba(255, 211, 211, 0.5)",
              }}
              message={
                <div
                  style={{
                    color: "#F46A6A",
                    fontWeight: "bolder",
                  }}
                >
                  Warning
                </div>
              }
              description={
                <div style={{ color: "#F46A6A" }}>
                  By deleting this will be wiped off the map
                </div>
              }
              type="error"
            />
          </span>
          <div className="separator mb-2 mt-2" />
          <div className="flex">
            <Button
              className="mr-3"
              style={{ width: "85px", height: "40px" }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={async () => {
                console.log("gisSelect", gisSelect);
                if (gisSelect && gisSelect.id) {
                  await gisService.deleteGis(gisSelect?.id?.toString());
                  notification.success({
                    message: "Successful deletion",
                    description: "Gis file deleted successfully",
                    placement: "topRight",
                  });
                  setShowDeleteModal(false);
                  handleUpdateGisFiles();
                }
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GisTable;
