import PluviometerRepository from "./PluviometerRepository";

export default class PluviometerService {
  private pluviometerRepository: PluviometerRepository =
    new PluviometerRepository();

  async getPluviometersByLocation(locationId: number): Promise<Location[]> {
    return this.pluviometerRepository.getPluviometersByLocation(locationId);
  }
}
