import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Table,
  Tooltip,
  message,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ScheduleSelector from "react-schedule-selector";
import {
  PlusCircleOutlined,
  StarOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import SelectInfrastructure from "../../basicComponents/Selects/SelectInfrastructure";
import SelectParameter from "../../basicComponents/Selects/SelectParameter";
import Locations from "../../components/Locations/Locations/Locations";
import useLocation from "../../hooks/useLocation";
import { ColumnsType } from "antd/es/table";
import SelectUser from "../../basicComponents/Selects/SelectUser";
import AlertService from "../../domain/Alerts/AlertService";
import { useNavigate, useParams } from "react-router-dom";

const alertService = new AlertService();

export default function AlertForm() {
  const navigate = useNavigate();
  const security = localStorage.getItem("security") || "{}";
  const userId = localStorage.getItem("userId") || "";
  const organizationId = JSON.parse(security).organizationId;
  const { alertId } = useParams();

  const { locations } = useLocation({ mode: "ByUser", userId });
  const [sendAtFinal, setSendAtFinal] = useState({
    willSend: false,
    title: "",
    message: "",
  });
  const [selectedLocation, setSelectedLocation] = useState<number | null>(null);
  const [selectRecipient, setSelectRecipient] = useState<any>(null);
  const [newRule, setNewRule] = useState<any>({
    selectedInfrastructure: null,
    selectedParam: null,
    criticalLow: null,
    criticalHigh: null,
  });
  const [selectedDates, setselectedDates] = useState<any>([]);
  const [rules, setRules] = useState<any[]>([]);
  const [recipients, setRecipients] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const selectParameter = useRef<any>(null);
  const selectUser = useRef<any>(null);
  const selectInfrastructureRef = useRef<any>(null);

  const columnsRules: ColumnsType<any> = [
    {
      title: "Infrastructure",
      key: "Infrastructure",
      render: (_, { selectedInfrastructure, infrastructureName }) => (
        <>
          {selectedInfrastructure
            ? selectedInfrastructure.label
            : infrastructureName}
        </>
      ),
    },
    {
      title: "Parameter",
      key: "parameter",
      render: (_, { selectedParam, parameterName }) => (
        <>{selectedParam ? selectedParam.name : parameterName}</>
      ),
    },
    {
      title: "Critical Values",
      key: "criticalValues",
      render: (_, { criticalLow, criticalHigh }) => (
        <>
          {criticalLow} - {criticalHigh}
        </>
      ),
    },
    {
      title: "Principal",
      key: "principal",
      render: (_, { mainParameter, selectedParam }) => (
        <StarOutlined
          style={{
            color: mainParameter ? "#fffe26" : "#C4CCD5",
            cursor: "pointer",
          }}
          onClick={() => {
            handleUpdateMainRule(selectedParam.id);
            // handleUpdateMainRule(id);
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, { selectedParam }) => (
        <Tooltip title="Delete">
          <DeleteOutlined
            style={{ fontSize: "1.5rem" }}
            onClick={() => {
              setRules((rules) => {
                return rules.filter(
                  (rp) => rp.selectedParam.id != selectedParam.id
                );
              });
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const columnsUsers: ColumnsType<any> = [
    {
      title: "Username",
      key: "username",
      render: (_, { name, email }) => (
        <>
          {name} - ({email})
        </>
      ),
    },
    {
      title: "Destination",
      key: "parameter",
      render: (_, { userId, enableSendEmail, enableSendSms }) => (
        <div className="flex">
          <Checkbox
            checked={enableSendEmail}
            className="mr-2"
            onChange={(checked) => {
              let copy = [...recipients];
              let finded = copy.findIndex((recipient: any) => {
                return recipient.userId == userId;
              });
              copy[finded].enableSendEmail = checked.target.checked;
              setRecipients(copy);
            }}
          >
            Email
          </Checkbox>
          <Checkbox
            checked={enableSendSms}
            onChange={(checked) => {
              let copy = [...recipients];
              let finded = copy.findIndex((recipient: any) => {
                return recipient.userId == userId;
              });
              copy[finded].enableSendSms = checked.target.checked;
              setRecipients(copy);
            }}
          >
            Sms
          </Checkbox>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, { userId }) => (
        <Tooltip title="Delete">
          <DeleteOutlined
            style={{ fontSize: "1.5rem" }}
            onClick={() => {
              setRecipients(() => {
                return recipients.filter((rp) => rp.userId != userId);
              });
            }}
          />
        </Tooltip>
      ),
    },
  ];

  function completarHoras(fechas: any[]) {
    // Esta función asume que las fechas son instancias de Date de JavaScript
    const fechasCompletas: Date[] = [];
    const fechasPorDia: any = {};

    // Agrupar las fechas por día
    fechas.forEach((fecha: any) => {
      const diaClave = `${new Date(fecha).getDay()}`; // 'YYYY-MM-DD'
      if (!fechasPorDia[diaClave]) {
        fechasPorDia[diaClave] = [];
      }
      fechasPorDia[diaClave].push(new Date(fecha));
    });

    // Encontrar la mayor fecha para cada día y completar las horas faltantes
    Object.keys(fechasPorDia).forEach((dia) => {
      const fechasDelDia = fechasPorDia[dia];
      const fechaFinDelDia = new Date(Math.max.apply(null, fechasDelDia));
      const fechaInicioDelDia = new Date(Math.min.apply(null, fechasDelDia));

      while (fechaInicioDelDia <= fechaFinDelDia) {
        fechasCompletas.push(new Date(fechaInicioDelDia));
        fechaInicioDelDia.setHours(fechaInicioDelDia.getHours() + 1);
      }
    });

    return fechasCompletas;
  }

  function getParsedHours(dates: any) {
    const fechasPorDia: any = {};
    const datesParsed: any = [];

    // Agrupar las fechas por día
    dates.forEach((fecha: any) => {
      const diaClave = `${new Date(fecha).getDay()}`; // 'YYYY-MM-DD'
      if (!fechasPorDia[diaClave]) {
        fechasPorDia[diaClave] = [];
      }
      fechasPorDia[diaClave].push(new Date(fecha));
    });
    Object.keys(fechasPorDia).forEach((dia) => {
      const fechasDelDia = fechasPorDia[dia];
      const minValue = new Date(Math.min.apply(null, fechasDelDia));
      const maxValue = new Date(Math.max.apply(null, fechasDelDia));

      datesParsed.push({
        day: minValue.getDay() === 0 ? 7 : minValue.getDay(),
        startTime: minValue.getHours(),
        endTime: maxValue.getHours(),
      });
    });
    return datesParsed;
  }

  function getOriginalDates(datesParsed: any) {
    // Reconstruir las fechas originales a partir de las horas parseadas
    const dates: Date[] = [];
    const baseDate = new Date("2020-09-14T00:00:00Z"); // Fecha de inicio de la semana

    datesParsed.forEach(
      (parsedDate: { day: number; startTime: number; endTime: number }) => {
        // Crear una nueva fecha para el inicio y el final del día
        const startDate = new Date(baseDate);
        startDate.setDate(baseDate.getDate() + (parsedDate.day - 1));
        startDate.setHours(parsedDate.startTime, 0, 0, 0);

        const endDate = new Date(baseDate);
        endDate.setDate(baseDate.getDate() + (parsedDate.day - 1));
        endDate.setHours(parsedDate.endTime, 0, 0, 0);

        // Añadir las fechas al arreglo
        dates.push(startDate, endDate);
      }
    );
    return dates;
  }

  async function handleUpdateMainRule(parameterId: string | number) {
    setRules((rules: any) => {
      return rules.map((rule: any) => {
        return { ...rule, mainParameter: rule.selectedParam.id == parameterId };
      });
    });
  }

  async function handleGetAlertById(alertId: number) {
    const res = await alertService.getAlertById(alertId);
    return res;
  }

  const handleChange = (val: any) => {
    setselectedDates(completarHoras(val));
  };

  async function onFinish(values: any) {
    let errors = 0;
    if (rules.length <= 0) {
      message.error("You must add at least one rule");
      errors++;
    } else {
      if (rules.findIndex((rl) => rl.mainParameter) < 0) {
        message.error("You must select the main rule");
        errors++;
      }
    }

    if (recipients.length <= 0) {
      message.error("You must add at least one recipient");
      errors++;
    }
    if (selectedDates.length <= 0) {
      message.error("You must select at least one date for alert");
      errors++;
    }

    if (errors > 0) return;
    const organizationId = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;

    const newAlert: Alert = {
      organizationId,
      alertName: values.alertName,
      alertDescription: values.alertDescription,
      alertSendStart: values.sendAtStart,
      alertStartTitle: values.sendAtStarTitle || "",
      alertStartMessage: values.sendAtStartMessage || "",
      alertSendFinal: values.sendAtFinal,
      alertFinalTitle: values.sendAtFinalMessage || "",
      alertFinalMessage: values.sendAtFinalMessage || "",
      alertEnable: true,
      alertWhenFiring: getParsedHours(selectedDates),
      rules: rules.map((rule) => {
        return {
          parameterId: rule.selectedParam.id,
          criticalLow: rule.criticalLow,
          criticalHigh: rule.criticalHigh,
          mainParameter: rule.mainParameter,
        };
      }),
      recipients: recipients.map((recipient) => {
        return {
          ...(alertId && recipient.recipientId
            ? { recipientId: recipient.recipientId }
            : {}),
          userId: recipient.userId || recipient.id,
          enableSendEmail: recipient.enableSendEmail,
          enableSendSms: recipient.enableSendSms,
        };
      }),
    };
    if (!alertId) {
      const res = await alertService.createAlert(newAlert);
      if (res.success) {
        notification.success({
          message: "Successful",
          description: "Alert created successfully",
        });
        navigate(`/alerts`);
      }
    } else {
      const res = await alertService.updateAlert(parseInt(alertId), newAlert);
      if (res.success) {
        notification.success({
          message: "Successful",
          description: "Alert updated successfully",
        });
        navigate(`/alerts`);
      }
    }
  }

  function onFinishFailed() {
    if (rules.length <= 0) {
      message.error("You must add at least one rule");
    }
    if (recipients.length <= 0) {
      message.error("You must add at least one recipient");
    }
    message.error("Verify all fields");
  }

  useEffect(() => {
    if (alertId) {
      handleGetAlertById(parseInt(alertId)).then((alert: Alert) => {
        form.setFieldValue("alertName", alert.alertName);
        form.setFieldValue("alertDescription", alert.alertDescription);
        form.setFieldValue("sendAtStart", alert.alertSendStart);
        form.setFieldValue("sendAtStarTitle", alert.alertStartTitle);
        form.setFieldValue("sendAtStartMessage", alert.alertStartMessage);
        form.setFieldValue("sendAtFinal", alert.alertSendFinal);
        form.setFieldValue("sendAtFinalTitle", alert.alertFinalTitle);
        form.setFieldValue("sendAtFinalMessage", alert.alertFinalMessage);
        setRules(
          alert.rules.map((rule) => {
            return {
              ...rule,
              selectedParam: { name: rule.parameterName, id: rule.parameterId },
            };
          })
        );
        setselectedDates(
          completarHoras(getOriginalDates(alert.alertWhenFiring))
        );
        setRecipients(alert.recipients);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    !isLoading && (
      <div
        className="flex flex-column px-4"
        style={{ overflow: "auto", height: "calc(100vh - 65px)" }}
      >
        <Form
          form={form}
          layout="inline"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="small"
          style={{ justifyContent: "center" }}
        >
          <div className="flex flex-column">
            <span>
              Alert Name <span style={{ color: "red" }}>*</span>
            </span>
            <Form.Item
              name="alertName"
              rules={[{ required: true, message: <>This field is required</> }]}
            >
              <Input
                // value={alertName}
                className="text-title-page"
                placeholder="Enter an Alert Name"
                // onChange={(e) => {
                //   setAlertName(e.currentTarget.value);
                // }}
              />
            </Form.Item>
            <span className="mt-2">
              Alert Description <span style={{ color: "red" }}>*</span>
            </span>
            <Form.Item
              name="alertDescription"
              rules={[{ required: true, message: <>This field is required</> }]}
            >
              <Input.TextArea
                // value={alertDescription}
                placeholder="Enter a description"
                rows={4}
                // onChange={(e) => {
                //   setAlertDescription(e.currentTarget.value);
                // }}
              />
            </Form.Item>
            <div className="mt-4 flex w-100 items-start">
              <div style={{ minWidth: "210px" }}>
                <Form.Item
                  valuePropName="checked"
                  name="sendAtStart"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          !form.getFieldValue("sendAtStart") &&
                          !form.getFieldValue("sendAtFinal")
                        ) {
                          return Promise.reject(
                            new Error("You must select an alert trigger")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox
                    defaultChecked={form.getFieldValue("sendAtStart")}
                    onChange={(val) => {
                      form.validateFields();
                    }}
                  >
                    Send at start
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="ml-4 flex items-center">
                <Form.Item
                  label="Title at start"
                  name="sendAtStarTitle"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (form.getFieldValue("sendAtStart") && !value) {
                          return Promise.reject(new Error("Required field"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="ml-4 flex items-center">
                <Form.Item label="Message at start" name="sendAtStartMessage">
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="mt-2 flex w-100 items-start">
              <div style={{ minWidth: "210px" }}>
                <Form.Item
                  valuePropName="checked"
                  name="sendAtFinal"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          !form.getFieldValue("sendAtStart") &&
                          !form.getFieldValue("sendAtFinal")
                        ) {
                          return Promise.reject(
                            new Error("You must select an alert trigger")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox
                    defaultChecked={form.getFieldValue("sendAtFinal")}
                    onChange={(val) => {
                      form.validateFields();
                    }}
                  >
                    Send at final
                  </Checkbox>
                </Form.Item>
              </div>

              <div className="ml-4 flex items-center">
                <Form.Item label="Title at final" name="sendAtFinalTitle">
                  <Input />
                </Form.Item>
              </div>
              <div className="ml-4 flex items-center">
                <Form.Item label="Message at final" name={"sendAtFinalMessage"}>
                  <Input />
                </Form.Item>
              </div>
            </div>
            <span className="mt-4 f-18" style={{ fontWeight: "bold" }}>
              Rules
            </span>
            {rules.length <= 0 && (
              <Alert message="You need add at least one rule" type="error" />
            )}
            <div
              className="mt-2 items-center"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "20px",
              }}
            >
              <div>
                <span style={{ width: "150px" }}>
                  Location <span style={{ color: "red" }}>*</span>
                </span>
                <Locations
                  handleClickSelect={(selected: any) => {
                    setSelectedLocation(selected);
                    setNewRule({
                      ...newRule,
                      selectInfrastructure: null,
                      selectParam: null,
                    });
                    selectInfrastructureRef.current.setValue(null);
                    selectParameter.current.cleanValue();
                  }}
                  locations={locations}
                />
              </div>
              <div>
                <span style={{ width: "150px" }}>
                  Infrastructure <span style={{ color: "red" }}>*</span>
                </span>
                <SelectInfrastructure
                  ref={selectInfrastructureRef}
                  mode="ByLocation"
                  location={selectedLocation}
                  labelInValue
                  onSelect={(val: object) => {
                    setNewRule((rl: any) => {
                      return {
                        ...rl,
                        selectedInfrastructure: val,
                        selectedParam: null,
                      };
                    });
                    selectParameter.current.cleanValue();
                  }}
                />
                {/* <SelectInfrastructure location={1} onSelect={() => {}} /> */}
              </div>
              <div>
                <span style={{ width: "150px" }}>
                  Parameter <span style={{ color: "red" }}>*</span>
                </span>
                <SelectParameter
                  ref={selectParameter}
                  infrastructureId={newRule.selectedInfrastructure?.value}
                  emitObject
                  filteredOptions={rules.map((rl) => {
                    return rl.selectedParam
                      ? rl.selectedParam.id
                      : rl.parameterId;
                  })}
                  onSelect={(val: object) => {
                    setNewRule((rl: any) => {
                      return { ...rl, selectedParam: val };
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="mt-2"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridColumnGap: "20px",
              }}
            >
              <div>
                <span style={{ width: "150px" }}>
                  Critical Low <span style={{ color: "red" }}>*</span>
                </span>
                <br />
                <InputNumber
                  value={newRule.criticalLow}
                  onChange={(val) => {
                    setNewRule((rl: any) => {
                      return { ...rl, criticalLow: val };
                    });
                  }}
                  min={1}
                />
              </div>
              <div>
                <span style={{ width: "150px" }}>
                  Critical High <span style={{ color: "red" }}>*</span>
                </span>
                <br />
                <InputNumber
                  value={newRule.criticalHigh}
                  onChange={(val) => {
                    setNewRule((rl: any) => {
                      return { ...rl, criticalHigh: val };
                    });
                  }}
                  min={1}
                />
              </div>
              <div style={{ placeSelf: "center" }}>
                <Button
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  className="flex items-center self-center"
                  style={{
                    backgroundColor: "#023E65",
                    color: "white",
                    width: "auto",
                    height: "40px",
                  }}
                  disabled={
                    !newRule.selectedParam ||
                    !newRule.criticalLow ||
                    !newRule.criticalHigh
                  }
                  onClick={() => {
                    if (
                      !newRule.selectedParam ||
                      !newRule.criticalLow ||
                      !newRule.criticalHigh
                    ) {
                      message.error("Complete all fields");
                      return;
                    } else if (
                      rules.findIndex(
                        (rule) =>
                          rule.selectedParam.id == newRule.selectedParam.id
                      ) >= 0
                    ) {
                      message.error(
                        "You already have a rule for this parameter"
                      );
                      return;
                    } else if (newRule.criticalLow >= newRule.criticalHigh) {
                      message.error(
                        "Critital High must be greater than Critical Low"
                      );
                      return;
                    }
                    setRules((rules) => {
                      return [
                        ...rules,
                        {
                          ...newRule,
                          mainParameter: !(rules.length > 0),
                        },
                      ];
                    });
                    setNewRule({
                      ...newRule,
                      selectedParam: null,
                      criticalLow: null,
                      criticalHigh: null,
                      mainParameter: false,
                    });
                    selectParameter?.current?.cleanValue();
                    selectInfrastructureRef.current.setValue(null);
                  }}
                >
                  Add rule
                </Button>
              </div>
            </div>
            <div className="mt-2">
              <Table
                columns={columnsRules}
                dataSource={rules}
                pagination={false}
                size="small"
                bordered
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  overflow: "auto",
                }}
              />
            </div>
            <span className="mt-4 f-18" style={{ fontWeight: "bold" }}>
              Firing Schedule
            </span>
            {selectedDates.length <= 0 && (
              <Alert
                className="mb-2"
                message="You need select the dates"
                type="error"
              />
            )}
            <div className="">
              <ScheduleSelector
                selection={selectedDates}
                selectionScheme="linear"
                numDays={7}
                minTime={0}
                maxTime={24}
                // hourlyChunks={2}
                timeFormat={"hh:mm A"}
                dateFormat={"ddd"}
                startDate={new Date("9-14-20")}
                onChange={handleChange}
                // renderDateCell={renderCustomDateCell}
              />
            </div>
            <span className="mt-4 f-18" style={{ fontWeight: "bold" }}>
              Recipients
            </span>
            {recipients.length <= 0 && (
              <Alert
                className="mb-2"
                message="You need add at least one recipient"
                type="error"
              />
            )}
            <div className="flex">
              <SelectUser
                ref={selectUser}
                organizationId={organizationId}
                onSelect={(selected: any) => {
                  setSelectRecipient(selected);
                }}
                emitObject
              />
              <Button
                shape="round"
                icon={<PlusCircleOutlined />}
                className="flex items-center ml-2"
                style={{
                  backgroundColor: "#023E65",
                  color: "white",
                  width: "auto",
                }}
                disabled={!selectRecipient}
                onClick={() => {
                  if (selectRecipient.userId) {
                    const newObj = {
                      userId: selectRecipient.userId,
                      name: `${selectRecipient.firstName} ${selectRecipient.lastName}`,
                      email: selectRecipient.email,
                      enableSendEmail: true,
                      enableSendSms: true,
                    };
                    if (!recipients.find((rp) => rp.userId == newObj.userId)) {
                      setRecipients((prev: any) => {
                        return [...prev, newObj];
                      });
                      setSelectRecipient(null);
                      selectUser.current.cleanValue();
                    }
                  }
                }}
              >
                Add recipient
              </Button>
            </div>

            <div className="mt-2">
              <Table
                columns={columnsUsers}
                dataSource={recipients}
                pagination={false}
                size="small"
                bordered
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  overflow: "auto",
                }}
              />
            </div>
            <Button
              className="my-2"
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
                alignSelf: "center",
              }}
              htmlType="submit"
            >
              Accept
            </Button>
          </div>
        </Form>
      </div>
    )
  );
}
