import { api } from "../../services/api";

export default class PluviometerRepository {
  async getPluviometersByLocation(locationId: number): Promise<Location[]> {
    const locations = await api.get(
      `/measurements/infrastructures/pluviometers/locations/${locationId}`
    );
    return locations.data;
  }
}
