import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import menuReducer from "./menuDuck";
import activitiesReducer from "./activitiesDuck";
import kpisReducer from "./kpisDuck";
import sensorReducer, { getSensorAction } from "./sensorDuck";
import locationReducer, { getLocationsAction } from "./locationsDuck";
import mapReducer, { getMapsAction } from "./mapDuck";
import deviceReducer, { getDeviceAction } from "./deviceDuck";
import infrastructureReducer from "./infrastructure/infrastructureDuck";
import infrastructureParameterReducer from "./infrastructure/infrastructureParameterDuck";
import userReducer from "./usersDuck";
import roleReducer from "./roleDuck";
import moduleReducer from "./modulesDuck";
import countryReducer from "./countryDuck";
import forecastReducer from "./forecastDuck";
import typesReducer from "./measurementDuck";
import unitsReducer from "./unitsDuck";
import securityReducer from "./securityDuck";
import organizationsReducer from "./organizationsDuck";
import profileReducer from "./profileDuck";
import gisReducer from "./gisDuck";
import inpReducer from "./inpDuck";
import monitorReducer from "./monitorDuck";
import loaderReducer from "./loaderDuck";
import pluviometerReducer from "./pluviometerDuck";
import modalAlertReducer from "./modalAlertDuck";
import interactiveSimulationReducer from "./interactiveSimulationDuck";
import digitalTwinReducer from "./digitalTwinDuck";
import readingsResumesReducer from "./readingsResumesDuck";
import modelFileReducer from "./modelFileDuck";
import thunk from "redux-thunk";

let rootReducer = combineReducers({
  menu: menuReducer,
  activities: activitiesReducer,
  kpi: kpisReducer,
  sensor: sensorReducer,
  locations: locationReducer,
  maps: mapReducer,
  device: deviceReducer,
  infrastructure: infrastructureReducer,
  infrastructureParameter: infrastructureParameterReducer,
  users: userReducer,
  role: roleReducer,
  modules: moduleReducer,
  countries: countryReducer,
  forecast: forecastReducer,
  types: typesReducer,
  units: unitsReducer,
  security: securityReducer,
  organizations: organizationsReducer,
  gis: gisReducer,
  profile: profileReducer,
  inp: inpReducer,
  monitor: monitorReducer,
  loader: loaderReducer,
  pluviometer: pluviometerReducer,
  modalAlert: modalAlertReducer,
  interactiveSimulation: interactiveSimulationReducer,
  digitalTwin: digitalTwinReducer,
  readingsResumes: readingsResumesReducer,
  modelFiles: modelFileReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  let store = createStore(
    rootReducer,
    // composeEnhancers(...enhancers)
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}