import React, { useContext, useEffect, useRef, useState } from "react";
import {
  EnvironmentOutlined,
  ArrowLeftOutlined,
  LineChartOutlined,
  StarOutlined,
  StarFilled,
  DeleteOutlined,
  PlusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Form,
  notification,
  Select,
  Tag,
  Tooltip,
  Modal as ModalAnt,
} from "antd";

import InfraestructureContext from "../Parameters/context";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { Modal } from "rsuite";
import InfrastructureService from "../../../domain/Infrastructure/InfraestructureService";
import { connect } from "react-redux";
import DigitalTwinService from "../../../domain/DigitalTwin/DigitalTwinService";
import dayjs, { Dayjs } from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Step1 from "./Modal/Step1";
import Step2 from "./Modal/Step2";
import SelectInpFile from "../../../basicComponents/Selects/SelectInpFile";
import InpService from "../../../domain/ModelFile/InpService";

const infrastructureService = new InfrastructureService();
const digitalTwinService = new DigitalTwinService();
const inpService = new InpService();

function ParamSelfCalibrationView({ digitalTwinData }: any) {
  const security = localStorage.getItem("security");
  const organizationId = JSON.parse(security || "{}").organizationId;
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const [calibrations, setCalibrations] = useState<any[]>([]);
  const [showNewNeuralNetwork, setShowNewNeuralNetwork] = useState(false);
  const [showNeuralGraph, setShowNeuralGraph] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [dataGraph, setDataGraph] = useState({});
  const [graphOptions, setGraphOptions] = useState({});
  const [newDigitalTwinData, setNewDigitalTwinData] = useState<any>(null);
  const [form] = Form.useForm();
  const [modelInfraIdList, setModelInfraIdList] = useState<any[]>([]);
  const selectInp = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canAddNeurals, setCanAddNeurals] = useState(false);
  const [imgUrl, setImgUrl] = useState<any>(null);
  const [showGraphModal, setShowGraphModal] = useState<boolean>(false);
  const timeoutRef = useRef<any>(null);

  async function handleGetDigitalTwins(loading = true) {
    const response = await digitalTwinService.getDigitalTwins(
      digitalTwinData.infrastructureId,
      digitalTwinData.parameterId,
      loading
    );
    setCalibrations(response);
  }

  async function handleDeleteDigitalTwin(id: any) {
    const response = await digitalTwinService.deleteDigitalTwin(id);
    handleGetDigitalTwins();
  }

  async function handleGetGraph(id: string | number) {
    const response = await digitalTwinService.getTestModel(id);
    console.log(
      "🚀 ~ file: index.tsx:113 ~ handleGetGraph ~ response:",
      response
    );
    setDataGraph(response);
    const seriesData = [
      {
        name: "Neural Network Value",
        data: Object.keys(response.neuralNetworkValues)
          .map((item) => [
            parseFloat(`${item}000`),
            response.neuralNetworkValues[item],
          ])
          .sort((a, b) => {
            return a[0] - b[0];
          }),
      },
      {
        name: "Historical value",
        data: Object.keys(response.testValues)
          .map((item) => [parseFloat(`${item}000`), response.testValues[item]])
          .sort((a, b) => {
            return a[0] - b[0];
          }),
      },
    ];
    console.log(
      "🚀 ~ file: index.tsx:135 ~ handleGetGraph ~ seriesData:",
      seriesData
    );
    setGraphOptions({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: 500,
        plotBorderWidth: 1,
      },
      xAxis: {
        type: "datetime",
        ordinal: false,
      },
      // yAxis: yAxis,
      plotOptions: { line: { marker: { enabled: false } } },
      tooltip: {
        valueDecimals: 2,
        split: false,
        shared: true,
        xDateFormat: "%Y-%m-%d %H:%M:%S",
      },
      navigator: {
        enabled: true,
      },
      legend: {
        enabled: true,
      },
      series: seriesData,
    });
    setShowNeuralGraph(true);
  }

  async function handleSetFavorite(id: string | number) {
    const response = await digitalTwinService.setFavoriteModel(id);
    handleGetDigitalTwins();
  }

  const handleGetModelInfraId = async () => {
    if (form.getFieldValue("inpId") && form.getFieldValue("inpObjectType")) {
      const response = await inpService.getModelInfrastructureId({
        inpId: form.getFieldValue("inpId"),
        typeId: form.getFieldValue("inpObjectType"),
      });
      setModelInfraIdList(response.data);
    }
  };

  const handleGetConfiguration = async () => {
    const res = await digitalTwinService.getConfiguration({
      paramId: digitalTwinData.parameterId,
      type: "NN",
    });
    if (res) {
      form.setFieldsValue({
        inpId: `${res.inpId}`,
        inpObjectType: res.inpObjectType,
        inpObjectId: res.inpObjectId,
      });
      if (selectInp.current) {
        selectInp.current.setValue({ inpId: res.inpId, label: "asdas" });
      }
      setCanAddNeurals(true);
    }
  };

  const onFinish = async (values: any) => {
    const res = await digitalTwinService.postConfiguration({
      parameterId: digitalTwinData.parameterId,
      data: {
        inpId: parseInt(values.inpId),
        inpObjectType: values.inpObjectType,
        inpObjectId: values.inpObjectId,
      },
    });
    if (res) {
      notification.success({
        message: "Successful",
        description: "Configuration updated successfully",
      });
      setCanAddNeurals(true);
    }
  };

  async function handleGetInitialData() {
    try {
      await handleGetDigitalTwins();
      await handleGetConfiguration();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleGetInitialData();
  }, []);

  useEffect(() => {
    if (calibrations.findIndex((c) => c.status == "IN_PROGRESS") >= 0) {
      timeoutRef.current = setTimeout(() => {
        handleGetDigitalTwins(false);
      }, 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [calibrations]);

  return !isLoading ? (
    <>
      <div style={{ padding: "0px 3rem" }}>
        <Button
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftOutlined rev={undefined} /> Back to Parameter
        </Button>
        <div className="flex column pt-2">
          <span className="text-title-page">
            {digitalTwinData.parameterName || "Param name"}
          </span>
          <div style={{ color: "#023E65" }} className="pt-2 mb-2">
            <EnvironmentOutlined
              style={{ fontSize: "1.3rem" }}
              rev={undefined}
            />
            <span style={{ fontSize: "1.1rem" }} className="ml-1">
              {digitalTwinData.locationName || "Location"}
            </span>
          </div>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            size="small"
            onFinish={onFinish}
            onFinishFailed={() => {}}
          >
            <div className="form-configuration-container">
              <Form.Item
                label="Model File"
                name="inpId"
                rules={[
                  { required: true, message: "Please select an option" },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <SelectInpFile
                  ref={selectInp}
                  defaultValue={form.getFieldValue("inpId")}
                  emitObject
                  onSelect={(val: any) => {
                    form.setFieldValue("inpObjectId", null);
                    form.setFieldValue("inpId", val.inpId);
                    if (
                      form.getFieldValue("inpId") &&
                      form.getFieldValue("inpObjectType")
                    ) {
                      handleGetModelInfraId();
                    }
                  }}
                  organizationId={organizationId}
                />
              </Form.Item>

              <Form.Item
                label="Object Type"
                name="inpObjectType"
                rules={[
                  { required: true, message: "Please select a Object Type" },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  // defaultValue={inpObjectType}
                  placeholder="Select an option"
                  onChange={() => {
                    form.setFieldValue("inpObjectId", null);
                    if (
                      form.getFieldValue("inpId") &&
                      form.getFieldValue("inpObjectType")
                    ) {
                      handleGetModelInfraId();
                    }
                  }}
                  options={[
                    { value: 1, label: "Nodes" },
                    { value: 2, label: "Links" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Model Infrastructure ID"
                name="inpObjectId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Model Infrastructure ID",
                  },
                  {
                    validator: (_, value) =>
                      value !== 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please select an option")),
                  },
                ]}
              >
                <Select
                  // defaultValue={inpObjectId}
                  placeholder="Select an option"
                  options={modelInfraIdList?.map((modelid: any) => {
                    return {
                      value: modelid,
                      label: modelid,
                    };
                  })}
                />
              </Form.Item>
            </div>
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              htmlType="submit"
            >
              Save
            </Button>
          </Form>
          <div className="flex justify-end mb-3">
            <Button
              disabled={!canAddNeurals}
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined rev={undefined} />}
              className="flex items-center ml-auto"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "170px",
                height: "40px",
              }}
              onClick={() => {
                setShowNewNeuralNetwork(true);
              }}
            >
              Add HydroTwin
            </Button>
          </div>
          <div style={{ height: "calc(100vh - 410px)", overflow: "auto" }}>
            <div className="w-full card-calibration-display__container">
              {calibrations.map((item: DigitalTwin) => (
                <Card
                  key={item.id}
                  cover={
                    <div
                      className="flex items-center justify-between"
                      style={{
                        padding: "1.5rem",
                        borderBottom: "1px solid #C4CCD5",
                      }}
                    >
                      <div className="flex column">
                        <div>
                          <span
                            className="mr-2"
                            style={{ fontSize: "24px", fontWeight: "bold" }}
                          >
                            {item.name}
                          </span>
                          <Tooltip
                            title={
                              item.status == "IN_PROGRESS"
                                ? "In Progress"
                                : item.status == "COMPLETED"
                                ? "Completed"
                                : "Failed"
                            }
                          >
                            <Badge
                              color={
                                item.status == "COMPLETED"
                                  ? "green"
                                  : item.status == "FAILED"
                                  ? "red"
                                  : "orange"
                              }
                            />
                          </Tooltip>
                        </div>
                        {item.progress != null ? (
                          <span>Executing {item.progress} %</span>
                        ) : (
                          <span></span>
                        )}
                        <div className="flex">
                          {item.loss != null && (
                            <div
                              className="flex mr-2"
                              style={{ alignItems: "center" }}
                            >
                              <span className="mr-1">loss: {item.loss}</span>
                              {item.lossGraph != null && (
                                <Tooltip title="See Graph">
                                  <EyeOutlined
                                    className="ml-1"
                                    style={{
                                      fontSize: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setImgUrl(item.lossGraph);
                                      setShowGraphModal(true);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          )}
                          {item.accuracy != null && (
                            <div
                              className="flex"
                              style={{ alignItems: "center" }}
                            >
                              <span className="mr-1">acc: {item.accuracy}</span>
                              {item.accuracyGraph != null && (
                                <Tooltip title="See Graph">
                                  <EyeOutlined
                                    className="ml-1"
                                    style={{
                                      fontSize: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setImgUrl(item.accuracyGraph);
                                      setShowGraphModal(true);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ alignSelf: "start" }}>
                        {item.status == "COMPLETED" && (
                          <LineChartOutlined
                            className="mr-1 cursor-pointer"
                            onClick={() => {
                              handleGetGraph(item.id);
                            }}
                            rev={undefined}
                          />
                        )}
                        {item.principal ? (
                          <StarFilled
                            className="mr-1 cursor-pointer"
                            rev={undefined}
                          />
                        ) : (
                          <StarOutlined
                            className="mr-1 cursor-pointer"
                            onClick={() => {
                              handleSetFavorite(item.id);
                            }}
                            rev={undefined}
                          />
                        )}
                        <DeleteOutlined
                          className="mr-1 cursor-pointer"
                          onClick={() => {
                            handleDeleteDigitalTwin(item.id);
                          }}
                          rev={undefined}
                        />
                      </div>
                    </div>
                  }
                  style={{ maxWidth: "400px" }}
                >
                  <div className="card-calibration-content__container">
                    <div>Start Date</div>
                    <div>{`${dayjs(item.startDate).format("DD/MM/YYYY")}`}</div>
                    <div>End Date</div>
                    <div>{`${dayjs(item.endDate).format("DD/MM/YYYY")}`}</div>
                    <div>Input Params</div>
                    <div>{item.inputParams}</div>
                    <div>Output Params</div>
                    <div>{item.outputParam}</div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-selfcalibration-create"
        open={showNewNeuralNetwork}
      >
        {currentStep === 1 ? (
          <Step1
            digitalTwinData={digitalTwinData}
            onSaved={(data: any) => {
              setCurrentStep(2);
              setNewDigitalTwinData(data);
            }}
            onBack={() => {
              setShowNewNeuralNetwork(false);
            }}
            onCancel={() => {
              setShowNewNeuralNetwork(false);
            }}
          />
        ) : (
          <Step2
            digitalTwinData={digitalTwinData}
            newDigitalTwinData={newDigitalTwinData}
            onSaved={() => {
              handleGetDigitalTwins();
              setShowNewNeuralNetwork(false);
              setCurrentStep(1);
            }}
            onBack={() => {
              setCurrentStep(1);
            }}
            onCancel={() => {
              setShowNewNeuralNetwork(false);
            }}
          />
        )}
      </Modal>
      <Modal open={showNeuralGraph}>
        <ArrowLeftOutlined
          onClick={() => {
            setShowNeuralGraph(false);
          }}
          rev={undefined}
        />
        <div className="flex">
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={graphOptions}
            className={"chart-container"}
          />
        </div>
      </Modal>
      <ModalAnt
        title=""
        open={showGraphModal}
        footer={[]}
        onCancel={() => {
          setShowGraphModal(false);
        }}
        className="modalImg"
      >
        <div className="" style={{ width: "100%", height: "100%" }}>
          <img src={imgUrl} alt="" style={{ width: "100%", height: "100%" }} />
        </div>
      </ModalAnt>
    </>
  ) : (
    <>
      <div></div>
    </>
  );
}

function mapState(state: { digitalTwin: { data: any } }) {
  return {
    digitalTwinData: state.digitalTwin.data,
  };
}

export default connect(mapState)(ParamSelfCalibrationView);
