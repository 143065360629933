import React, { useEffect, useState } from "react";
import { TreePicker } from "rsuite";
import { setSelectedLocation } from "../../../redux/locationsDuck";
import { useDispatch } from "react-redux";
import "./locations.scss";

export default function Locations({
  locations,
  handleClickSelect,
  defaultValue = false,
  aditionalStyle = {},
  cleanable = false
}) {
  const dispatch = useDispatch();
  let datas = [];
  let dataSelect = 0;
  let itemDisable = [];

  function childChild(childrenLocation) {
    let childrenArray = [];
    childrenLocation.map((child) => {
      if (!child.canShowLocation) {
        itemDisable.push(child.locationId);
      }
      if (child.defaultSelected) {
        dataSelect = child.locationId;
        localStorage.setItem("Location", dataSelect);
        dispatch(setSelectedLocation(dataSelect));
        if (defaultValue) handleClickSelect(dataSelect);
      }
      childrenArray.push({
        label: child.locationName,
        value: child.locationId,
        disabled: !child.canShowLocation,
        ...(child.hasChild
          ? { children: childChild(child.childLocations) }
          : {}),
      });
    });

    return childrenArray;
  }

  if (locations && locations.length > 0) {
    datas = locations.map((location) => {
      if (!location.canShowLocation) {
        itemDisable.push(location.locationId);
      }
      return {
        label: location.locationName,
        value: location.locationId,
        disabled: !location.canShowLocation,
        ...(location.hasChild
          ? { children: childChild(location.childLocations) }
          : {}),
      };
    });
    const index = locations.findIndex((location) => location.defaultSelected);

    if (index >= 0) {
      dataSelect = locations[index].locationId;
    }
  } else {
    datas = [];
  }

  useEffect(() => {
    if (locations && locations.length > 0) {
      locations.forEach((location) => {
        if (location.defaultSelected) {
          localStorage.setItem("Location", location.locationId);
          dispatch(setSelectedLocation(location.locationId));
          if (defaultValue) handleClickSelect(dataSelect);
        }
        if (location.childLocations != null){
          location.childLocations.forEach((l) => {
            if (l.defaultSelected) {
              localStorage.setItem("Location", l.locationId);
              dispatch(setSelectedLocation(l.locationId));
              if (defaultValue) handleClickSelect(dataSelect);
            }
          });
        }
      });
    }
  }, [locations]);

  return (
    <>
      {datas.length > 0 ? (
        <TreePicker
          key={datas.value}
          data={datas}
          showIndentLine
          onChange={handleClickSelect}
          disabledItemValues={itemDisable}
          defaultValue={defaultValue ? dataSelect : 0}
          cleanable={cleanable}
          className="location-picker"
          style={{ ...aditionalStyle }}
        />
      ) : (
        ""
      )}
    </>
  );
}
