import React, { useContext, useEffect, useState } from "react";
import BlockService from "../../../domain/BlockDiagram/BlockService";
import { Pagination, Table } from "antd";
import { useNavigate } from "react-router-dom";
import DiagramBlockContext from "../../../views/DiagramBlock/context";
import DashboardContext from "../../../layout/DashboardLayout/context";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];

const blockService = new BlockService();
type Props = {};
export default function BlockDiagramCard({ onClickRow }: any) {
  const navigate = useNavigate();
  const { value: contextDashboard, setValue: setContextDashboard } =
    useContext(DashboardContext);
  const { value: context, setValue: setContext } =
    useContext(DiagramBlockContext);
  const [blockList, setBlockList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRecords: 0,
  });

  async function handleGetBlockLis() {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const res = await blockService.getAllBlockRecordsByOrganization(
      oui,
      contextDashboard.selectedLocation,
      pagination.page,
      pagination.pageSize,
      null
    );
    setBlockList(res.allBlocksResponseDTOList);
    setPagination({ ...pagination, totalRecords: res.totalRecords });
  }

  const onChangePagination = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    handleGetBlockLis();
  }, [contextDashboard.selectedLocation]);

  useEffect(() => {
    handleGetBlockLis();
  }, [pagination.page]);

  return (
    <>
      <div className="flex column justify-center items-center">
        <Table
          columns={columns}
          dataSource={blockList}
          pagination={false}
          onRow={(record: any) => {
            return {
              onClick: (event) => {
                onClickRow();
                setContext({
                  ...context,
                  locationId: contextDashboard.selectedLocation,
                });
                navigate(
                  `/diagram-block/${contextDashboard.selectedLocation}/${record.id}`
                );
                // handleShowBlockDiagram(record.id);
              }, // click row
            };
          }}
        />
        <Pagination
          style={{ marginTop: "2rem" }}
          onChange={onChangePagination}
          defaultCurrent={1}
          total={pagination.totalRecords}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
