import React, { useEffect, useState } from "react";
import InfrastructureService from "../domain/Infrastructure/InfraestructureService";
import InpService from "../domain/ModelFile/InpService";

const inpService = new InpService();

export default function useInpFiles({
  organizationId,
  page = 1,
  size = 20,
  name = null,
  locationId = null,
  requireLocation = false,
  onlyCompleted = null,
}: {
  organizationId: number;
  page?: number;
  size?: number;
  name?: string | null;
  locationId?: number | null;
  requireLocation?: boolean;
  onlyCompleted?: boolean | null;
}) {
  const [inpFiles, setInpFiles] = useState<InpFile[]>([]);
  const [pagination, setPagination] = useState({ page, size, totalRows: 0 });
  const [params, setParams] = useState<any>({
    name,
    locationId,
    requireLocation,
    organizationId,
    onlyCompleted,
  });

  async function handleGetInpFiles() {
    if (requireLocation && !locationId) return;
    const response = await inpService.getModelFilesByOrganization({
      organizationId,
      page,
      size,
      name: params.name,
      locationId: params.locationId,
      onlyCompleted,
    });
    setInpFiles(response.inpFiles);
    setPagination({ ...pagination, totalRows: response.totalRecords });
  }

  useEffect(() => {
    handleGetInpFiles();
  }, [pagination.page, pagination.size, params.name, params.locationId]);

  return { inpFiles, pagination, params, setPagination, setParams };
}
