import React, { useCallback, useEffect, useRef, useState } from "react";
import InteractiveSimulationMap from "./InteractiveSimulationMap";
import { notification, Popover, Button } from "antd";

import "./InteractiveSimulation.style.scss";
import dayjs, { utc } from "dayjs";
import TimePlayer from "../../components/General/TimePlayer";
import { useLocation, useParams } from "react-router-dom";
import IterationSimulationService from "../../domain/IterationSimulation/IterationSimulationService";
import { connect } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import KeplerService from "../../domain/Kepler/KeplerService";
import { interactiveSimulationsEventsService } from "../../events/interactiveSimulations";
import IterationCompletedModal from "../../components/InteractiveSimulation/IterationCompletedModal";
import GenerateKeplerAnalysisModal from "../../components/InteractiveSimulation/GenerateKeplerAnalysisModal";
import LateralMenu from "../../components/InteractiveSimulation/LateralMenu";
import useWebSocket from "../../hooks/useWebSocket";
// import { TourProvider, useTour } from "@reactour/tour";
// import Tour from "reactour";
// import Joyride from "react-joyride";
import TourInteractiveSimulationContext from "./Context";
import NoDataTableSvg from "../../assets/svg/NoDataTableSvg";

var timezone = require("dayjs/plugin/timezone");

Highcharts.setOptions({
  lang: {
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
});

const keplerService = new KeplerService();

function InteractiveSimulation({ interactiveSimulationData }) {
  const route = useLocation();
  const isEdit = route.pathname.includes("interactive-simulation-edit");

  //Constants
  const { simulationId } = useParams();
  const clientSocket = useWebSocket(process.env.REACT_APP_SOCKET_URL, isEdit);
  const [simulationTime, setSimulationTime] = useState([]);
  const [stepTime, setStepTime] = useState(0);
  const [isReplaying, setIsReplaying] = useState(false);
  const [contextValue, setContextValue] = useState({
    activeStep: 1,
    showTour: false,
  });

  //Serie de tiempo
  const chartRef = useRef(null);
  const [graphOptions, setGraphOptions] = useState(null);
  const [highchartVmRef, setHighchartVmRef] = useState(null);

  //Iteration Data
  const [iterationList, setIterationList] = useState([]);
  const [currentIteration, setCurrentIteration] = useState(null);

  //TimePlayer/Socket/Simulation
  const [isRunningIteration, setIsRunningIteration] = useState(false);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [eventsToSend, setEventsToSend] = useState([]);
  const currentTime = useRef(dayjs(simulationTime[0]));
  const [playing, setPlaying] = useState(false);
  const [markers, setMarkers] = useState([]);

  //Replay Data
  const [replayData, setReplayData] = useState([]);

  //Modals
  const [showIterationCompletedModal, setShowIterationCompletedModal] =
    useState(false);
  const [showGenerateKeplerModal, setShowGenerateKeplerModal] = useState(false);

  //Infrastructures
  const [infrasEdited, setInfrasEdited] = useState([]);
  const [infraestructures, setInfraestructures] = useState([]);
  const [selectedIteration, setSelectedIteration] = useState(null);

  //Referencias
  const lateralMenuRef = useRef(null);

  //Functions
  const playPause = () => {
    const iterationIndex = iterationList.findIndex((it) => {
      return it.iterationId == currentIteration?.iterationId;
    });

    if (iterationList[iterationIndex].ended) {
      setPlaying(() => {
        return !playing;
      });
    } else {
      setPlaying(() => {
        if (playing) {
          if (clientSocket && isRunningIteration) {
            const userId = localStorage.getItem("userId");
            const nextTime = currentTime.current.add(
              stepTime * 60 * 1000,
              "ms"
            );
            currentTime.current = nextTime;
            // Logica para cuando se pause la simulacion
            const body = {
              timeEvent: dayjs(nextTime).format("YYYY-MM-DD HH:mm:ss"),
              status: "PAUSE",
              events: [],
            };
            console.log("Send pause", body);

            clientSocket.publish({
              destination: `/users/${userId}/simulations/${simulationId}/iterations/${currentIteration?.iterationId}/events`,
              body: JSON.stringify(body),
            });
          }
        }
        return !playing;
      });
    }
  };

  const restart = () => {
    initGraphOptions();
    currentTime.current = dayjs(simulationTime[0]);
    setIsReplaying(true);
    // setPlaying(false);
    const interval = setInterval(() => {
      const step = stepTime;
      const endTime = dayjs(simulationTime[1]);
      if (playing) {
        const nextTime = dayjs(currentTime.current).add(step * 60 * 1000, "ms");
        if (nextTime >= endTime) {
          interactiveSimulationsEventsService.setSubject({
            type: "setCurrentTime",
            data: endTime,
          });
          currentTime.current = endTime;
          setIsReplaying(false);
          clearInterval(interval);
        } else {
          interactiveSimulationsEventsService.setSubject({
            type: "setCurrentTime",
            data: nextTime,
          });
          currentTime.current = nextTime;
        }
        const finded = replayData.find(
          (item) => item.time.valueOf() >= nextTime.valueOf()
        );
        if (finded) {
          const dataToAddToGraph = finded.data;
          interactiveSimulationsEventsService.setSubject({
            type: "dataSocketRecived",
            data: dataToAddToGraph,
          });
          let series = highchartVmRef.series;
          series.forEach((serie) => {
            const findedIndex = dataToAddToGraph.findIndex((item) => {
              return serie.userOptions.paramId == item.paramId;
            });
            if (findedIndex >= 0) {
              let x = dayjs(currentTime.current).valueOf(), // current time
                y = dataToAddToGraph[findedIndex].value;
              serie.addPoint([x, y], true, false, true);
            } else {
              let x = dayjs(currentTime.current).valueOf(), // current time
                y = serie.yData[serie.yData.lenght - 2];
              serie.addPoint([x, y], true, false, true);
            }
          });
          if (highchartVmRef && highchartVmRef.xAxis) {
            highchartVmRef.xAxis[0] &&
              highchartVmRef.xAxis[0].setExtremes(null, null);
            highchartVmRef.xAxis[1] &&
              highchartVmRef.xAxis[1].setExtremes(null, null);
          }
        }
      }
    }, 1000);
  };

  const addMarker = () => {
    const newMarker = {
      time: dayjs(currentTime.current),
      iterations: [],
    };
    setMarkers((prevMarkers) => {
      const updatedMarkers = [...prevMarkers, newMarker];
      updatedMarkers.sort((a, b) => a.time - b.time);
      return updatedMarkers;
    });
  };

  const initGraphOptions = useCallback(() => {
    setGraphOptions({});
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

    const yAxisUnits = new Set();
    let yAxis = [];
    let seriesData = [];

    const from = dayjs(simulationTime[0]).subtract(localOffset, "ms").valueOf();
    const to = dayjs(simulationTime[1]).subtract(localOffset, "ms").valueOf();

    infraestructures.forEach((data) => {
      if (!data) return;

      const { unit } = data.mainMeasurementTarget;
      if (!yAxisUnits.has(unit)) {
        yAxisUnits.add(unit);
        yAxis.push({
          valueUnit: unit,
          labels: {
            format: `{value}${unit}`,
          },
        });
      }

      seriesData.push({
        paramId: data.mainMeasurementTarget.measurementTargetId,
        measurementeInfraestructureId: data.measurementeInfraestructureId,
        name: data.measurementInfraestructureName,
        sourceName: data.mainMeasurementTarget.measurementTargetName,
        valueUnit: unit,
        yAxis: Array.from(yAxisUnits).indexOf(unit),
        data: [
          [from, null],
          [to, null],
        ],
      });
    });

    setGraphOptions({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: null,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
        events: {
          load: function () {
            const vm = this;
            setHighchartVmRef(this);
          },
          render() {
            setHighchartVmRef(this);
          },
        },
      },
      legend: {
        backgroundColor: "#FFFFFF",
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: new Date(),
            to: Date.UTC(2025, 1, 1),
            color: "#EFFFFF",
          },
        ],
        // min: dayjs(simulationTime[0])
        //   // .subtract(new Date().getTimezoneOffset(), "minute")
        //   .valueOf(),
        // max: dayjs(simulationTime[1])
        //   .subtract(new Date().getTimezoneOffset(), "minute")
        //   .valueOf(),
        ordinal: false,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: true,
        margin: 5,
        height: 30,
      },
      tooltip: {
        formatter: function () {
          const vm = this;
          let text = `<span>${dayjs(vm.points[0].x).format(
            "YYYY-MM-DD hh:mm:ss A"
          )}</span><br/><br/>`;
          vm.points.forEach((point) => {
            const series = point.series;
            const symbol = "●";
            text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Infrastructure: <b>${series.userOptions.name}</b></span><br/>`;
          });
          return [text];
        },
        valueDecimals: 2,
        shared: true,
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: false,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    });
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }, [infraestructures, simulationTime]);

  const handleGeneratekeplerAnalysis = async () => {
    const response = await keplerService.postGenerateKeplerAnimation(
      simulationId,
      selectedIteration.iterationId
    );
    if (response.success == 1) {
      let iterationsCopy = [...iterationList];
      const iterationIndex = iterationsCopy.findIndex((it) => {
        return it.iterationId == selectedIteration.iterationId;
      });
      iterationsCopy[iterationIndex].analysisGenerated = true;
      setShowGenerateKeplerModal(false);
      openNotification();
    }
  };

  const openNotification = () => {
    notification.info({
      message: `Request sent`,
      description:
        "Your analysis is being performed, you will be able to get it in about 10 minutes",
      placement: "topRight",
    });
  };

  function resetGraph() {
    if (highchartVmRef && highchartVmRef.series) {
      let series = highchartVmRef.series;
      series.forEach((serie) => {
        serie.setData(
          [
            [dayjs(simulationTime[0]).valueOf(), null],
            [dayjs(simulationTime[1]).valueOf(), null],
          ],
          true,
          false,
          true
        );
      });
    }
  }

  useEffect(() => {
    console.log("Is Runnign Iteration", isRunningIteration);
  }, [isRunningIteration]);

  useEffect(() => {
    if (isWaitingResponse) {
      console.log("waiting reponse");
    }
  }, [isWaitingResponse]);

  useEffect(() => {
    interactiveSimulationsEventsService.setSubject({
      type: "setCurrentTime",
      data: currentTime.current,
    });
  }, [currentTime.current]);

  useEffect(() => {
    if (infraestructures.length > 0) {
      initGraphOptions();
    }
  }, [infraestructures]);

  useEffect(() => {
    if (currentIteration && isEdit) {
      // createClient();
      setIsRunningIteration(true);
      chartRef.current = null;
      setShowIterationCompletedModal(false);
      currentTime.current = dayjs(simulationTime[0]);
      setPlaying(false);
    }
  }, [currentIteration]);

  useEffect(() => {
    if (clientSocket && currentIteration && isEdit && isRunningIteration) {
      console.log("Entro aquii");
      const userId = localStorage.getItem("userId");
      const subscription = clientSocket.subscribe(
        `/topic/reply/simulations/${simulationId}/iterations/${currentIteration?.iterationId}/events/users/${userId}`,
        (message) => {
          const response = JSON.parse(message.body);
          console.log("Data recibida por el socket", response);
          const dataToGraph = response.map((res) => {
            return {
              paramId: res.data.paramId,
              value: res.data.value,
              last: res.data.last,
            };
          });
          setReplayData((rd) => {
            return [...rd, { data: dataToGraph, time: currentTime.current }];
          });
          // Envento publicado
          interactiveSimulationsEventsService.setSubject({
            type: "dataSocketRecived",
            data: dataToGraph,
          });
          if (response.length > 0 && response[0].data.last) {
            const endTime = dayjs(simulationTime[1]);
            currentTime.current = endTime;
            setShowIterationCompletedModal(true);
            console.log(
              "🚀 ~ file: InteractiveSimulation.view.jsx:427 ~ iterationIndex ~ currentIteration:",
              currentIteration
            );
            setIterationList((prev) => {
              let iterationsCopy = [...prev];
              const iterationIndex = iterationsCopy.findIndex((it) => {
                return it.iterationId == currentIteration?.iterationId;
              });
              iterationsCopy[iterationIndex].ended = true;
              return iterationsCopy;
            });
            setIsRunningIteration(false);
            subscription.unsubscribe();
          }
          let series = highchartVmRef.series;
          series.forEach((serie) => {
            const findedIndex = dataToGraph.findIndex((item) => {
              return serie.userOptions.paramId == item.paramId;
            });
            if (findedIndex >= 0) {
              let x = dayjs(currentTime.current).valueOf(), // current time
                y = dataToGraph[findedIndex].value;
              serie.addPoint([x, y], true, false, true);
            } else {
              let x = dayjs(currentTime.current).valueOf(), // current time
                y = serie.yData[serie.yData.lenght - 2];
              serie.addPoint([x, y], true, false, true);
            }
          });
          if (highchartVmRef && highchartVmRef.xAxis) {
            highchartVmRef.xAxis[0] &&
              highchartVmRef.xAxis[0].setExtremes(null, null);
            highchartVmRef.xAxis[1] &&
              highchartVmRef.xAxis[1].setExtremes(null, null);
          }
          setIsWaitingResponse(false);
        }
      );
    }
  }, [clientSocket, currentIteration, highchartVmRef, isRunningIteration]);

  useEffect(() => {
    const endTime = dayjs(simulationTime[1]);
    const step = stepTime;
    const nextTime = currentTime.current.add(step * 60 * 1000, "ms");

    if (playing && isEdit) {
      const ended = iterationList.find((it) => {
        return it.iterationId == currentIteration?.iterationId;
      })?.ended;
      if (!isWaitingResponse && clientSocket && currentIteration && !ended) {
        const userId = localStorage.getItem("userId");
        const body = {
          timeEvent: dayjs(currentTime.current).format("YYYY-MM-DD HH:mm:ss"),
          status: "RUNNING",
          events: eventsToSend,
        };
        console.log("publish", JSON.stringify(body));
        clientSocket.publish({
          destination: `/users/${userId}/simulations/${simulationId}/iterations/${currentIteration?.iterationId}/events`,
          body: JSON.stringify(body),
        });
        setIsWaitingResponse(true);
        if (eventsToSend.length > 0) {
          addMarker();
          setInfrasEdited((val) => [
            ...val,
            ...eventsToSend.map((ev) => {
              return ev.infrastructureId;
            }),
          ]);
        }
        setEventsToSend([]);
        setTimeout(() => {
          if (nextTime > endTime) {
            //Se termino la simulacion
            currentTime.current = endTime;
            setShowIterationCompletedModal(true);
            let iterationsCopy = [...iterationList];
            const iterationIndex = iterationsCopy.findIndex((it) => {
              return it.iterationId == currentIteration?.iterationId;
            });
            iterationsCopy[iterationIndex].ended = true;
            setIterationList(iterationsCopy);
            setIsRunningIteration(false);
          } else {
            currentTime.current = nextTime;
          }
        }, 1000);
      }
    }
  }, [playing, isWaitingResponse, currentTime.current, eventsToSend]);

  return (
    <TourInteractiveSimulationContext.Provider
      value={{
        value: contextValue,
        setValue: setContextValue,
      }}
    >
      <InteractiveSimulationMap
        infrasEdited={infrasEdited}
        setInfraestructures={setInfraestructures}
        editValueInfra={(selectedInfra) => {
          setIterationList(() => {
            let iterationsCopy = [...iterationList];
            const iterationIndex = iterationsCopy.findIndex((it) => {
              return it.iterationId == currentIteration?.iterationId;
            });
            let iterationCopy = iterationsCopy[iterationIndex];
            let temporalMarkers = iterationCopy.markers;
            const findedIndex = temporalMarkers.findIndex((marker) => {
              return marker.time == currentTime.current;
            });
            if (findedIndex >= 0) {
              temporalMarkers[findedIndex].sensors.push(selectedInfra);
            } else {
              temporalMarkers.push({
                time: currentTime.current,
                sensors: [selectedInfra],
              });
            }
            iterationCopy.markers = temporalMarkers;
            iterationsCopy[iterationIndex] = iterationCopy;
            return iterationsCopy;
          });
        }}
        iterationId={currentIteration?.iterationId}
      />
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "5px",
          width: "100vw",
          height: "300px",
          flexDirection: "column",
        }}
        className="items-center justify-center pa-4"
      >
        <Popover
          placement="right"
          color="#023E65"
          overlayStyle={{ maxWidth: "190px" }}
          open={contextValue.showTour && contextValue.activeStep == 7}
          content={
            <div className="flex column">
              <div className="flex column text-white mb-2">
                <span>Here you can visualize your simulation</span>
              </div>
              <div className="flex justify-between">
                <Button
                  ghost
                  onClick={() => {
                    setContextValue({ ...contextValue, activeStep: 8 });
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          }
        >
          {/* <div
            className="GraphContainer"
            style={{
              width: "600px",
              height: "190px",
              position: "absolute",
              bottom: "75px",
              left: "5px",
              display: currentIteration ? "flex" : "hidden",
            }}
          ></div> */}
          <div
            style={{
              display: "flex",
              position: "absolute",
              width: "600px",
              height: "190px",
              bottom: "75px",
              left: "5px",
              backgroundColor: "white",
              // paddingLeft: currentIteration ? "0px" : "10px",
            }}
          >
            {currentIteration ? (
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={graphOptions}
              />
            ) : (
              <NoDataTableSvg />
            )}
          </div>
        </Popover>
        <div
          style={{
            width: "600px",
            height: "65px",
            backgroundColor: "white",
            position: "absolute",
            bottom: "0px",
            left: "5px",
          }}
        >
          <TimePlayer
            startDate={simulationTime[0]}
            endDate={simulationTime[1]}
            stepTime={stepTime}
            currentTimeProp={currentTime.current}
            markers={markers}
            playPause={playPause}
            restart={restart}
            endedSimulation={
              iterationList.find((it) => {
                return it.iterationId == currentIteration?.iterationId;
              })?.ended
            }
            playing={playing}
            iterationNumber={iterationList.length}
            isReplaying={isReplaying}
            isWaitingResponse={isWaitingResponse}
          />
        </div>
      </div>

      <IterationCompletedModal
        show={showIterationCompletedModal}
        onAccept={() => {
          setShowIterationCompletedModal(false);
        }}
      />

      <GenerateKeplerAnalysisModal
        show={showGenerateKeplerModal}
        onAccept={() => {
          handleGeneratekeplerAnalysis();
          // setShowGenerateKeplerModal(false);
        }}
        onCancel={() => {
          setShowGenerateKeplerModal(false);
        }}
      />

      <LateralMenu
        ref={lateralMenuRef}
        isRunningIteration={isRunningIteration}
        iterations={iterationList}
        currentIteration={currentIteration}
        currentTime={currentTime.current}
        generateKeplerAnalysis={(iterataion) => {
          setSelectedIteration(iterataion);
          setShowGenerateKeplerModal(true);
        }}
        endedSimulation={
          iterationList.find((it) => {
            return it.iterationId == currentIteration?.iterationId;
          })?.ended
        }
        setEventsToSend={(events) => {
          setEventsToSend(events);
          playPause();
        }}
        onCreateIteration={(iterationId) => {
          // setCurrentIteration(null);
          // initGraphOptions();
          setSimulationTime(
            JSON.parse(localStorage.getItem("simulationTime") || "[]")
          );
          setStepTime(parseInt(localStorage.getItem("stepTime")));
          resetGraph();
          setCurrentIteration({
            iterationId,
            markers: [],
          });
          setIterationList((previus) => {
            return [
              ...previus,
              {
                iterationId,
                ended: false,
                analysisGenerated: false,
                markers: [],
              },
            ];
          });
        }}
        onClickViewGuide={() => {
          console.log("ver");
          // setShowTour(true);
          setContextValue((prev) => {
            return { ...prev, showTour: true };
          });
        }}
      />

      {/* <Tour
        steps={steps}
        isOpen={showTour}
        maskSpace={0}
        disableDotsNavigation={true}
        rounded={5}
        className={'helpersssss'}
        maskClassName={'maskkk'}
        closeWithMask={false}
        prevButton={<Navigation/>}
        onRequestClose={() => {
          setShowTour(false);
        }}
      /> */}
      {/* <Joyride steps={steps} run={showTour} continuous scrollToFirstStep /> */}
      {/* <Tour steps={steps}/> */}
    </TourInteractiveSimulationContext.Provider>
  );
}

function mapState(state) {
  return {
    interactiveSimulationData: state.interactiveSimulation.data,
  };
}

export default connect(mapState)(InteractiveSimulation);
