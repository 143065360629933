import axios from "axios";
import { store } from "../index";
// import { useDispatch } from "react-redux";
import { setShowLoader } from "../redux/loaderDuck";
import { showErrorModal, showWarningModal } from "../redux/modalAlertDuck";

let apiController = new AbortController();
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let access_token = localStorage.getItem("accessToken");
if (access_token) {
  api.defaults.headers.common = {
    Authorization: `${access_token}`,
    'Content-Encoding': 'gzip',
  };
}

api.interceptors.request.use((config) => {
  //Start the request
  store.dispatch(setShowLoader(true));
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      if (response.data.success === 0) {
        console.log("Show warning");
        store.dispatch(showWarningModal("", response.data.message));
      }
    }
    store.dispatch(setShowLoader(false));
    //End the response
    return response;
  },
  (error) => {
    store.dispatch(setShowLoader(false));
    if (error.response && error.response.status === 429) {
      store.dispatch(
        showErrorModal(
          "You have reached the limit of requests to the server. Retry in a few minutes",
          error.response?.data?.error
        )
      );
    } else if (error.code == "ERR_CANCELED") {
    } else if (error.response && error.response.status === 500) {
      store.dispatch(
        showErrorModal(error.message, error.response?.data?.error)
      );
    } else {
      store.dispatch(
        showWarningModal(
          error.response.data.type,
          error.response?.data?.message
        )
      );
    }
    //Error managment
    return Promise.reject(error);
  }
);

const restartApiCotroller = () => {
  apiController = new AbortController();
};

export { api, apiController, restartApiCotroller };