import React from "react";

const PlusCircleSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 1.66667C8.35185 1.66667 6.74068 2.15541 5.37027 3.07109C3.99986 3.98677 2.93176 5.28826 2.30103 6.81098C1.6703 8.33369 1.50527 10.0092 1.82681 11.6258C2.14836 13.2423 2.94203 14.7271 4.10747 15.8926C5.27291 17.058 6.75776 17.8517 8.37427 18.1732C9.99078 18.4948 11.6663 18.3297 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.9289 14.6298C17.8446 13.2593 18.3334 11.6482 18.3334 10C18.3334 8.90566 18.1178 7.82202 17.699 6.81098C17.2802 5.79993 16.6664 4.88127 15.8926 4.10745C15.1188 3.33363 14.2001 2.7198 13.1891 2.30101C12.178 1.88222 11.0944 1.66667 10 1.66667ZM10 16.6667C8.68148 16.6667 7.39255 16.2757 6.29622 15.5431C5.19989 14.8106 4.34541 13.7694 3.84083 12.5512C3.33624 11.3331 3.20422 9.99261 3.46146 8.6994C3.71869 7.4062 4.35363 6.21831 5.28598 5.28596C6.21833 4.35361 7.40622 3.71867 8.69942 3.46144C9.99263 3.2042 11.3331 3.33622 12.5512 3.84081C13.7694 4.34539 14.8106 5.19988 15.5432 6.2962C16.2757 7.39253 16.6667 8.68146 16.6667 10C16.6667 11.7681 15.9643 13.4638 14.7141 14.7141C13.4638 15.9643 11.7681 16.6667 10 16.6667ZM13.3334 9.16667H10.8334V6.66667C10.8334 6.44566 10.7456 6.2337 10.5893 6.07742C10.433 5.92114 10.221 5.83334 10 5.83334C9.77901 5.83334 9.56705 5.92114 9.41077 6.07742C9.25449 6.2337 9.16669 6.44566 9.16669 6.66667V9.16667H6.66669C6.44568 9.16667 6.23372 9.25447 6.07744 9.41075C5.92115 9.56703 5.83336 9.77899 5.83336 10C5.83336 10.221 5.92115 10.433 6.07744 10.5893C6.23372 10.7455 6.44568 10.8333 6.66669 10.8333H9.16669V13.3333C9.16669 13.5544 9.25449 13.7663 9.41077 13.9226C9.56705 14.0789 9.77901 14.1667 10 14.1667C10.221 14.1667 10.433 14.0789 10.5893 13.9226C10.7456 13.7663 10.8334 13.5544 10.8334 13.3333V10.8333H13.3334C13.5544 10.8333 13.7663 10.7455 13.9226 10.5893C14.0789 10.433 14.1667 10.221 14.1667 10C14.1667 9.77899 14.0789 9.56703 13.9226 9.41075C13.7663 9.25447 13.5544 9.16667 13.3334 9.16667Z"
        fill="#22A2CA"
      />
    </svg>
  );
};

export default PlusCircleSvg;
