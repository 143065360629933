/* eslint-disable @typescript-eslint/ban-types */
import { useState } from "react";
import * as React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Select, notification } from "antd";
import { List } from "rsuite";
import "./sources.scss";
import ParameterSourceService from "../../../../domain/ParameterSource/ParameterSourceService";
import { getUnitsAction } from "../../../../redux/unitsDuck";
import { getTypesAction } from "../../../../redux/measurementDuck";
import { connect } from "react-redux";
import SourceCard from "./Sources/SourceCard";

export interface ISourcesDetailProps {
  sources: ParameterSource[];
  parameter: Parameter | null;
  infrastructure: Infrastructure | null;
  types?: any[];
  units?: any[];
  loadUnits?: Function;
  handleLoadSources: Function;
}
export interface IParameterSource extends ParameterSource {
  isEdit: boolean;
}

const parameterSourceService = new ParameterSourceService();

function SourcesDetail({
  sources,
  parameter,
  infrastructure,
  types,
  units,
  loadUnits,
  handleLoadSources,
}: ISourcesDetailProps) {
  const newIndex = React.useRef(-1);

  const [sourceList, setSourceList] = useState<IParameterSource[]>(
    sources.map((source) => {
      return { ...source, isEdit: false };
    })
  );

  const handleSortEnd = async ({ oldIndex, newIndex }: any) => {
    await setSourceList((prvData: any[]) => {
      const moveData = prvData.splice(oldIndex, 1);
      const newData = [...prvData];
      newData.splice(newIndex, 0, moveData[0]);
      handleSaveCustomSources(newData);
      return newData;
    });
  };

  function replaceObjectById(array: any[], id: number, newObject: object) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i] = newObject;
        break;
      }
    }
    return array;
  }

  async function deleteSourceById(id: any) {
    await setSourceList((previusValue) => {
      const newSources = previusValue.filter((obj) => obj.id !== id);
      parameterSourceService.deleteSource(
        sourceList.find((s) => s.id === id) as ParameterSource
      );
      notification.success({
        message: "Successful",
        description: "Source deleted successfully",
      });
      return newSources;
    });
  }

  const handleSaveSources = async (sourceListArray?: any) => {
    if (infrastructure && parameter) {
      return await parameterSourceService.saveSourcesByInfrastructure(
        infrastructure,
        parameter,
        sourceListArray ? sourceListArray : sourceList
      );
    }
  };

  const handleSaveCustomSources = async (newSources: IParameterSource[]) => {
    if (infrastructure && parameter) {
      const res = await parameterSourceService.saveSourcesByInfrastructure(
        infrastructure,
        parameter,
        newSources
      );
      if (res) {
        setSourceList((previusValue) => {
          return previusValue.map((obj) => {
            return { ...obj, isEdit: false };
          });
        });
      }
    }
  };

  const updateSource = (source: ParameterSource) => {
    const copySources = [...sourceList];
    setSourceList([]);
    setSourceList(() => {
      return replaceObjectById(copySources, source.id, source);
    });
  };

  const updateAndSaveSource = async (source: ParameterSource) => {
    const copySources = [...sourceList];
    setSourceList([]);
    await setSourceList((prevState) => {
      const updatedSources = replaceObjectById(copySources, source.id, source);
      handleSaveSources(updatedSources)
        .then(() => {
          notification.success({
            message: "Successful",
            description: "Sources saved successfully",
          });
          handleLoadSources();
        })
        .catch((error) => {
          handleLoadSources();
        });
      return updatedSources;
    });
  };

  React.useEffect(() => {
    loadUnits && loadUnits();
  }, []);

  React.useEffect(() => {
    setSourceList(
      sources.map((source) => {
        return { ...source, isEdit: false };
      })
    );
  }, [sources]);

  return (
    <>
      {infrastructure && (
        <div className="flex column  mt-2">
          <div
            className="grid mt-2"
            style={{ gridTemplateColumns: "1fr 1fr", padding: "0px 20px" }}
          >
            <div className="flex column">
              <span style={{ fontWeight: 800, color: "#00203C" }}>
                Source priority
              </span>
              <span>Drag card to reorder the priority of sources</span>
            </div>
            <div>
              <Button
                shape="round"
                icon={<PlusCircleOutlined rev={undefined} />}
                className="flex items-center ml-auto"
                style={{
                  borderColor: "#023E65",
                  color: "#023E65",
                  width: "150px",
                  height: "40px",
                }}
                onClick={() => {
                  setSourceList((oldvalue) => {
                    return [
                      ...oldvalue,
                      {
                        id: newIndex.current,
                        isEnabled: true,
                        isVirtual: false,
                        name: "",
                        sourceSerialNumber: "",
                        isEdit: true,
                      } as IParameterSource,
                    ];
                  });
                  newIndex.current = newIndex.current - 1;
                }}
              >
                Add new source
              </Button>
            </div>
          </div>

          {sourceList.length > 0 ? (
            <List
              className="mt-3"
              sortable={
                sourceList.length > 1 &&
                sourceList.findIndex((s) => s.isEdit) < 0
              }
              onSort={handleSortEnd}
            >
              {sourceList.map((source, index: number) => {
                return (
                  <List.Item
                    index={index}
                    style={{ display: "flex" }}
                    key={
                      source.id +
                      source.name +
                      source.isEdit +
                      source.sourceSerialNumber
                    }
                  >
                    <div
                      className="flex items-center justify-center position-circle"
                      style={{ width: "20%" }}
                    >
                      <div className="position-circle-border">
                        <div className="position-circle-content">
                          {index + 1}
                        </div>
                        <div className="arrow"></div>
                      </div>
                    </div>
                    <div style={{ width: "80%" }}>
                      <SourceCard
                        key={index}
                        source={source}
                        sources={sources}
                        infrastructure={infrastructure}
                        updateSource={updateSource}
                        handleSaveSources={handleSaveSources}
                        deleteSourceById={deleteSourceById}
                        updateAndSaveSource={updateAndSaveSource}
                        handleLoadSources={handleLoadSources}
                      />
                    </div>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "200px" }}
            >
              <span style={{ fontSize: "16px" }}>
                Looks like you have nothing - yet!
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function mapState(state: { types: { array: any }; units: { array: any } }) {
  return {
    types: state.types.array,
    units: state.units.array,
  };
}

const mapDispatch = (dispatch: any) => {
  return {
    loadUnits: () => {
      dispatch(getUnitsAction());
      dispatch(getTypesAction());
    },
  };
};

export default connect(mapState, mapDispatch)(SourcesDetail);
