import React, { useContext } from "react";
import { Select, Tag } from "antd";
import Sider from "antd/es/layout/Sider";
import Icon from "@ant-design/icons";
import OverloadSvg from "../../assets/svg/OverloadSvg";
import DashboardContext from "./context";
import { connect, useDispatch } from "react-redux";
import { getActivitiesAction } from "../../redux/activitiesDuck";
import { AnyAction } from "redux";
import dayjs from "dayjs";

type Props = {
  activities: any[];
};

export default function Notifications({ activities }: Props) {
  const { value: context, setValue: setContext } = useContext(DashboardContext);

  function getTimeAgo(time: string) {
    const hours = dayjs().diff(dayjs(time), "hour");
    if (hours > 24) {
      return `${dayjs().diff(dayjs(time), "day")} days ago`;
    } else {
      return `${hours} hours ago`;
    }
  }

  return (
    <Sider
      style={{
        backgroundColor: "white",
      }}
      width={245}
    >
      <div className="flex column" style={{ padding: "20px" }}>
        <span
          className="cursor-pointer"
          style={{
            position: "absolute",
            right: "20px",
            top: "20px",
            fontWeight: "bolder",
          }}
          onClick={() => {
            setContext({
              ...context,
              showNotifications: false,
            });
          }}
        >
          X
        </span>
        <span className="mb-2" style={{ color: "#023E65", fontSize: "16px" }}>
          Notifications
        </span>
        <Select options={[]} placeholder={"Status"} className="mb-2" />
        <span className="mb-1">Today</span>
        <div className="flex column items-center">
          {activities
            .map((activitie, index) => {
              return (
                <div
                  className="flex items-center mb-2"
                  style={{
                    border: "1px solid #74788D",
                    borderRadius: "8px",
                    width: "205px",
                    padding: "10px",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      backgroundColor: "#F7F9FC",
                      borderRadius: "50%",
                    }}
                    className="flex mr-2 items-center justify-center"
                  >
                    <Icon
                      component={OverloadSvg}
                      style={{
                        width: "40px",
                        height: "40px",
                      }} rev={undefined}                    />
                  </div>
                  <div className="flex column">
                    <Tag
                      color="red"
                      style={{
                        width: "fit-content",
                        borderRadius: "10px",
                        fontSize: "8px",
                      }}
                    >
                      {activitie.status}
                    </Tag>
                    <span style={{ color: "#023E65" }}>
                      {activitie.issueType}
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#232B36",
                      }}
                    >
                      {activitie.locationName}
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#74788D",
                      }}
                    >
                      {getTimeAgo(activitie.datetime)}
                    </span>
                  </div>
                </div>
              );
            })
            .slice(0, 4)}
          {activities.length > 4 && (
            <span
              style={{
                color: "#023E65",
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              View all notifications
            </span>
          )}
        </div>
      </div>
    </Sider>
  );
}
