import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Alert,
  Table,
  Tag,
  ConfigProvider,
  Select,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import Icon, {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import Map, { Marker, NavigationControl } from "react-map-gl";

import Locations from "../../../components/Locations/Locations/Locations";
import InfrastructureService from "../../../domain/Infrastructure/InfraestructureService";
import "./index.scss";
import GisService from "../../../domain/Gis/GisService";
import LocationService from "../../../domain/Location/LocationService";
import mapboxgl from "mapbox-gl";

const infrastructureService = new InfrastructureService();
const gisService = new GisService();
const locationService = new LocationService();

const { Dragger } = Upload;

const mapStyle = "mapbox://styles/mapbox/light-v9";
const mapboxApiAccessToken =
  "pk.eyJ1IjoiY2RnYXJjaWEiLCJhIjoiY2w4bjdsejY0MTNuNzNvcDRsZWd2Z3ZwMCJ9.3rToUu3sd9pc5Nc8nrISFg";
const initialStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
// Viewport settings
const initialViewState = {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

interface DataType {
  key: string;
  id: string;
  name: string;
  size: string;
  type: number;
  typeInfrastructure: number;
  location: number;
}

const GisFiles: React.FC = () => {
  const [customize, setCustomize] = useState(true);
  const navigate = useNavigate();
  const [viewport, setViewport] = useState(initialViewState);
  const [gisFile, setGisFile] = useState<any[]>([]);
  const [types, setTypes] = useState<Types[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [showSuccesModal, setShowSuccessModal] = useState(false);
  const [lastFile, setLastFile] = useState("");

  const mapContainer = useRef<any>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const fileSize = (size: any) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${(size / Math.pow(k, i)).toFixed(3)} ${sizes[i]}`;
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId") || "";
    const resInfrastructureTypes =
      infrastructureService.getInfrastructureTypes();
    const resLocationByUser = locationService.getLocationsByUser(userId);
    resInfrastructureTypes.then((value) => {
      setTypes(value);
    });
    resLocationByUser.then((value) => {
      setLocations(value);
    });

    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [0, 0],
      zoom: 1.5,
      accessToken: mapboxApiAccessToken,
    });
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (_, { size }) => (
        <>
          <span className="table-data-gis">{fileSize(size)}</span>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <>
          {
            <Select
              style={{ marginLeft: 30, width: 140 }}
              placeholder="Type"
              optionFilterProp="children"
              options={types}
              onChange={(value) => {
                record.typeInfrastructure = value;
              }}
              allowClear
            />
          }
        </>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, record) => {
        const handleClickSelect = (value: any) => {
          record.location = value;
        };
        return (
          <Locations
            locations={locations}
            handleClickSelect={handleClickSelect}
            defaultValue={false}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "location",
      key: "location",
      render: (_, record) => (
        <>
          <a
            className="table-data-gis"
            style={{
              color: "#22A2CA",
              textDecoration: "underline",
            }}
            onClick={() => viewMap(record)}
          >
            View
          </a>
        </>
      ),
    },
  ];

  const viewMap = (file: any) => {
    // console.log('file', file)

    if (lastFile) {
      map.current?.setLayoutProperty(lastFile, "visibility", "none");
    }

    if (map.current?.getLayer(file.name)) {
      map.current?.removeLayer(file.name);
    }

    if (map.current?.getSource("gis-" + file.lastModified)) {
      map.current?.removeSource("gis-" + file.lastModified);
    }

    fetch(file.preview, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log("myJson", myJson);
        // map.current?.on("load", () => {
        map.current?.addSource("gis-" + file.lastModified, {
          type: "geojson",
          data: file.preview,
        });

        const typeOfLayer = myJson.features[0].geometry.type;

        if (typeOfLayer == "LineString") {
          map.current?.addLayer({
            id: file.name,
            type: "line",
            source: "gis-" + file.lastModified,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#6ed2ef",
              "line-width": 5,
            },
          });
        } else {
          map.current?.addLayer({
            id: file.name,
            type: "circle",
            source: "gis-" + file.lastModified,
            paint: {
              "circle-color": "#4264fb",
              "circle-radius": 8,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#ffffff",
            },
          });
        }
        map.current?.flyTo({
          center:
            typeOfLayer == "LineString"
              ? myJson.features[0].geometry.coordinates[0]
              : myJson.features[0].geometry.coordinates,
          zoom: 10,
          duration: 5000,
        });
        console.log("map.current", map.current);
        // })
      });
    setLastFile(file.name);
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".geojson, .json",
    showUploadList: false,
    beforeUpload: (file: any) => {
      const extension = file.name.split(".")[1];
      if (extension === "geojson" || extension === "json") {
        setCustomize(false);
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        setGisFile((previousState) => [...previousState, file]);
      }
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center", height: "54vh", margin: -17 }}>
      <Dragger {...props}>
        {/* <p className="ant-upload-text">Click or drag file to this area to upload</p> */}
        <p className="ant-upload-hint drag-drop">
          Drag and drop your file here
        </p>
        <p className="ant-upload-drag-icon">
          <Button
            icon={
              <PlusCircleOutlined
                style={{ color: "#023E65", fontSize: "20px" }}
                rev={undefined}
              />
            }
            shape="round"
            className="add-files"
          >
            Add Files
          </Button>
        </p>
      </Dragger>
    </div>
  );

  const blobToBase64 = async (blob: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result as string;
          resolve(result.split(",")[1]);
        } else {
          resolve("");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFiles = async () => {
    // console.log("gisFile", gisFile);
    if (gisFile.length > 0) {
      const oui = JSON.parse(
        localStorage.getItem("security") || ""
      ).organizationId;

      const data = {
        userId: localStorage.getItem("userId") || "",
        organizationId: oui,

        files: [],
      } as Gis;

      const resp = await gisFile.map(async (files, index) => {
        data.files.push({
          name: files.name,
          url: oui + "/model/json",
          data: await blobToBase64(files),
          physicalStructureTypeId: files.typeInfrastructure,
          locationId: files.location,
        });

        if (gisFile.length == data.files.length) {
          const gisValid = await gisService.uploadGis(data);
          console.log("gisValid", gisValid);
          if (gisValid) {
            timerControl();
          }
        }
      });
    } else {
      openNotification("You need to select a GIS file.");
    }
  };

  const timerControl = () => {
    setShowSuccessModal(true);
    const timer = setTimeout(() => {
      setShowSuccessModal(false);
      navigate("/upload");
    }, 1000);
    return () => clearTimeout(timer);
  };

  const openNotification = (message: any) => {
    notification.open({
      message: "Error",
      description: <div dangerouslySetInnerHTML={{ __html: message }} />,
      type: "error",
    });
  };

  return (
    <div style={{ padding: "0px 3rem" }}>
      <div>
        <Button
          icon={<ArrowLeftOutlined rev={undefined} />}
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(`/upload`);
          }}
        >
          Back to Gis Files
        </Button>
      </div>
      <div className="text-title-page">Uploads GIS Files</div>
      <div
        className="mt-2 mb-2 flex items-center"
        style={{
          background: "rgba(255, 238, 203, 0.5)",
          height: "40px",
          borderLeft: "solid 8px #FBC261",
        }}
      >
        <span className="ml-2" style={{ color: "#023E65" }}>
          Only .json files accepted
        </span>
      </div>

      <ConfigProvider
        renderEmpty={customize ? customizeRenderEmpty : undefined}
      >
        <div className="row" style={{ height: "calc(100vh - 400px)" }}>
          <div className="col" style={{ height: "calc(100vh - 400px)" }}>
            <Table
              style={{ height: "calc(100vh - 400px)", overflowY: "scroll" }}
              dataSource={gisFile}
              columns={columns}
              pagination={false}
            />
            {gisFile.length > 0 ? (
              <Upload {...props}>
                <Button
                  icon={
                    <PlusCircleOutlined
                      style={{ fontSize: "1.4rem" }}
                      rev={undefined}
                    />
                  }
                  shape="round"
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#023E65",
                    border: "2px solid #023E65",
                    bottom: "10px",
                    right: "15px",
                  }}
                  className="flex items-center justify-center absolute"
                />
              </Upload>
            ) : null}
          </div>
          <div
            className="col"
            style={{ height: "calc(100vh - 400px)", marginLeft: -25 }}
          >
            <div className="map-container-gis">
              <div style={{ width: "100%", height: "100%" }}>
                <div className="map-container-gis" ref={mapContainer} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex pt-3 justify-between">
          <Button
            style={{ width: "85px", height: "40px" }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "85px",
              height: "40px",
              backgroundColor: "#00203C",
              color: "white",
            }}
            onClick={() => {
              let validator = false;
              let nameGisFile = "";
              // console.log("gisFile", gisFile);
              // console.log("validator inicio", validator);
              if (gisFile.length > 0) {
                for (let i = 0; i < gisFile.length; i++) {
                  if (gisFile[i].location) {
                    validator = true;
                  } else {
                    validator = false;

                    nameGisFile = gisFile[i].name;
                    openNotification(
                      `You need to select a <b><i>location</i></b> for <b>${nameGisFile}</b>`
                    );
                    break;
                  }
                  if (gisFile[i].typeInfrastructure) {
                    validator = true;
                  } else {
                    validator = false;

                    nameGisFile = gisFile[i].name;
                    openNotification(
                      `You need to select a <b><i>type</i></b> for <b>${nameGisFile}</b>`
                    );
                    break;
                  }
                }

                if (validator) {
                  uploadFiles();
                }
              }
            }}
          >
            Accept
          </Button>
        </div>
      </ConfigProvider>

      <Modal
        open={showSuccesModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "180px" }}
      >
        <div className="flex column items-center">
          <span
            className="mt-2"
            style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: 15,
              lineHeight: "14px",
              display: "flex",
              color: "#34C38F",
            }}
          >
            <CheckCircleOutlined
              className="mr-1"
              style={{ fontSize: 25 }}
              rev={undefined}
            />
            All files have <br></br> been saved
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default GisFiles;
