import LocationRepository from "./LocationRepository";

export default class LocationService {
  private locationRepository: LocationRepository = new LocationRepository();

  async getLocationsByCountryOrganization(
    countryId: string | number,
    organizationId: string,
    name?: string,
    status?: string,
  ): Promise<Location[]> {
    return this.locationRepository.getLocationsByCountryOrganization(
      countryId,
      organizationId,
      name,
      status
    );
  }

  async getLocationsByUser(userId: string): Promise<Location[]> {
    return this.locationRepository.getLocationsByUser(userId);
  }

  async postLocationsByCountryOrganization(
    countryId: string,
    organizationId: string,
    newLocations: Location[]
  ): Promise<boolean> {
    return this.locationRepository.postLocationsByCountryOrganization(
      countryId,
      organizationId,
      newLocations
    );
  }

  async deleteLocation(locationId: number): Promise<boolean> {
    return this.locationRepository.deleteLocation(locationId);
  }

  async changeEnableLocation(
    organizationId: string,
    locationId: number,
    visible: boolean
  ): Promise<boolean> {
    return this.locationRepository.changeEnableLocation(
      organizationId,
      locationId,
      visible
    );
  }
}
