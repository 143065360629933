import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./index.scss";
import { Layout, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import HeadderCmp from "../../components/Layouts/Header";

// import { ReactComponent as HydrodigitalLogo } from "../assets/img/hydrodigital.svg";
interface Props {
  children: React.ReactNode;
}

const GeneralLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const HydrodigitalLogo = require("../../assets/img/hydrodigital.svg").default;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible={false}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          background: "#f7f9fc",
          padding: "15px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div className="flex column" style={{ height: "inherit" }}>
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            <img
              src={HydrodigitalLogo}
              alt="logo"
              style={{ width: "143px", marginTop: "10px", cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          {children}
        </div>
      </Sider>
      <Layout
        className="site-layout"
        style={{ background: "white", overflowY: "auto" }}
      >
        <Header style={{ padding: 0, background: "white" }}>
          <HeadderCmp />
        </Header>
        <Content style={{ margin: "0 16px", background: "white" }}>
          <Outlet />
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          Ant Design ©2023 Created by Ant UED
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default GeneralLayout;
