import React, { FC } from "react";

/**
 * @author
 * @function @
 **/

const PinSvg: FC = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 20L9.09091 14.9091M13.4545 4L20 10.5455M14.9091 5.45455L10.5455 9.81818C10.5455 9.81818 7.63636 9.09091 5.45455 11.2727L12.7273 18.5455C14.9091 16.3636 14.1818 13.4545 14.1818 13.4545L18.5455 9.09091L14.9091 5.45455Z"
                stroke="black"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PinSvg;
