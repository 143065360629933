import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Switch,
  Badge,
  Input,
  Collapse,
  Select,
  Table,
  Tag,
  Pagination,
  Checkbox,
  Button,
  Divider,
} from "antd";
import { getParameters } from "../../services/parameters";
import { apiController } from "../../services/api";

import ClockCircleOutlined from "@ant-design/icons/SearchOutlined";

import { PlusCircleOutlined } from "@ant-design/icons";

import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";

import type { PaginationProps } from "antd";
import Icon from "@ant-design/icons";

import "./index.scss";
import DashboardContext from "../../layout/DashboardLayout/context";
import ViewGraphicSvg from "../../assets/svg/ViewGraphic";
import HideViewGraphicSvg from "../../assets/svg/HideViewGraphic";
import { useNavigate } from "react-router-dom";
// import { addReadingToList } from "../../store/reducers/readingsReducer";
import { addResumeToList } from "../../store/reducers/resumeReducer";
import dayjs from "dayjs";
import {
  addReadingToList,
  addResumeAndReaginToList,
  deleteResumeAndReadingToList,
  restartResumeAndReadingList,
} from "../../redux/readingsResumesDuck";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import SelectTypeInfrastructure from "../../basicComponents/Selects/SelectTypeInfrastructure";
const { Panel } = Collapse;
const { Option } = Select;

const infrastructureService = new InfrastructureService();

/**
 * @author
 * @function @InfrastructureDashboardView
 **/

type ExpandIconPosition = "start" | "end";

const InfrastructureDashboardView: FC = () => {
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const [name, setName] = useState<any>("");
  const [inUse, setInUse] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [parameters, setParameters] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRecords: 0,
  });
  const navigate = useNavigate();
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("start");
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<any[]>([]);
  const [temporalName, setTemporalName] = useState("");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string[]) => {
    setDefaultExpandedKeys(key);
  };

  const genExtra = () => (
    <PlusCircleOutlined
      style={{ color: "#04628C" }}
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
      rev={undefined}
    />
  );

  const handleUpdateParameters = async (
    name: string | null,
    page: number,
    size: number,
    infraTypeId: number | null
  ) => {
    console.log("HAciewndo la peticino");
    const locationId = context.selectedLocation;
    if (locationId) {
      const infra: any = await infrastructureService.getInfraestructureInUse(
        locationId,
        page,
        size,
        name,
        null,
        infraTypeId
      );
      if (context.infraToShow) {
        setPagination({
          ...pagination,
          totalRecords: infra.totalRecords,
          page: infra.currentPage,
        });
      } else {
        setPagination({
          ...pagination,
          totalRecords: infra.totalRecords,
          page: infra.currentPage,
        });
      }
      setParameters(
        infra.data.map((infra: any) => {
          return {
            ...infra,
            measurementParameters: infra.measurementParameters.map(
              (measurement: any) => {
                return {
                  ...measurement,
                  checked: !!context.selectedSensors.find(
                    (sensor: any) =>
                      sensor.measurementTargetId == measurement.paremeterId
                  ),
                };
              }
            ),
          };
        })
      );
    }
  };

  const onChangePagination: PaginationProps["onChange"] = (page) => {
    setPagination({ ...pagination, page: page });
    handleUpdateParameters(name, page, pagination.pageSize, selectedInfraType); 
  };

  const selectTypeInfrastructureRef = useRef<any>(null);
  const [selectedInfraType, setselectedInfraType] = useState<any>(null);
  useEffect(() => {
    if (mounted) {
      setParameters([]);
      setName("");
      setPagination({
        page: 1,
        pageSize: 10,
        totalRecords: 0,
      });
      dispatch(restartResumeAndReadingList() as unknown as any);
      handleUpdateParameters(null, 1, 10, selectedInfraType);
    } else {
      setMounted(true);
    }
  }, [context.selectedLocation, mounted]);

  useEffect(() => {
    if (context.infraToShow.name) {
      setPagination({ ...pagination, page: 1 });
      setName(context.infraToShow.name);
      setTemporalName(context.infraToShow.name);
      handleUpdateParameters(
        context.infraToShow.name,
        1,
        10,
        selectedInfraType
      );
    }
  }, [context.infraToShow.name]);

  return (
    <div style={{ minHeight: 540 }}>
      <div className="flex justify-between mr-2 pb-1">
        <Input
          value={temporalName}
          onChange={(e) => {
            setTemporalName(e.target.value);
            if (e.target.value == "") {
              setContext({...context, infraToShow: {...context.infraToShow, name: null}})
              setName("");
              setPagination({ ...pagination, page: 1 });
              handleUpdateParameters(
                null,
                1,
                pagination.pageSize,
                selectedInfraType
              );
            }
          }}
          className="ml-2"
          style={{ width: 240 }}
          placeholder="Search Name"
          prefix={
            <ClockCircleOutlined style={{ color: "#078CB3" }} rev={undefined} />
          }
          onPressEnter={(e) => {
            setName(e.currentTarget.value);
            setPagination({ ...pagination, page: 1 });
            handleUpdateParameters(
              e.currentTarget.value,
              1,
              pagination.pageSize,
              selectedInfraType
            );
          }}
          allowClear
        />
      </div>
      <div className="flex justify-between ml-2 mr-2">
        <div style={{ width: 160 }}>
          <SelectTypeInfrastructure
            ref={selectTypeInfrastructureRef}
            onSelect={(value: any) => {
              if (value) {
                setselectedInfraType(value);
                setPagination({...pagination, page: 1})
                handleUpdateParameters(
                  name,
                  1,
                  pagination.pageSize,
                  value
                );
              } else {
                setselectedInfraType(null);
                setPagination({...pagination, page: 1})
                handleUpdateParameters(
                  name,
                  1,
                  pagination.pageSize,
                  null
                );
              }
            }}
          />
        </div>
        <Icon
          component={
            context.showParamsCard ? ViewGraphicSvg : HideViewGraphicSvg
          }
          className="cursor-pointer"
          onClick={() => {
            context.showParamsCard
              ? setContext({ ...context, showParamsCard: false })
              : setContext({ ...context, showParamsCard: true });
          }}
          rev={undefined}
        />
      </div>
      <div className="mt-3 ml-2 mr-2">
        <Collapse
          defaultActiveKey={[context.infraToShow?.id]}
          onChange={(keys: any) => {
            setDefaultExpandedKeys(keys);
          }}
          expandIconPosition={expandIconPosition}
        >
          {parameters.map((parameter: any) => (
            <Panel
              header={
                <div className="flex column">
                  <span>{parameter?.infraestructureName}</span>
                  <span style={{ color: "#74788D", fontSize: "10px" }}>
                    {`${parameter.infrastructureTypeName}`}
                  </span>
                </div>
              }
              key={parameter?.infraestructureId}
              extra={genExtra()}
            >
              <div className="flex column columnapequena">
                {parameter?.measurementParameters?.map((target: any) => (
                  <Checkbox
                    checked={context.selectedSensors.find(
                      (sensor: any) =>
                        sensor.measurementTargetId == target.paremeterId
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const [dateFrom, dataTo] = context.selectedDate;
                        dispatch(
                          addResumeAndReaginToList(
                            parameter?.infraestructureId,
                            target.paremeterId,
                            target.serialNumberSource,
                            dateFrom,
                            dataTo
                          ) as unknown as AnyAction
                        );
                        setContext({
                          ...context,
                          selectedSensors: [
                            ...context.selectedSensors,
                            {
                              measurementTargetId: target.paremeterId,
                              measurementTargetName: target.paremeterName,
                              serialNumberMeasurementTool: null,
                              infrastructureId: parameter?.infraestructureId,
                              sourceId: null,
                            },
                          ],
                        });
                      } else {
                        dispatch(
                          deleteResumeAndReadingToList(
                            target.paremeterId,
                            null
                          ) as unknown as AnyAction
                        );
                        setContext({
                          ...context,
                          selectedSensors: context.selectedSensors.filter(
                            (sensor) => {
                              return (
                                sensor.measurementTargetId !==
                                target.paremeterId
                              );
                            }
                          ),
                        });
                      }
                    }}
                  >
                    <span className="parameter-name">
                      {target.paremeterName}
                    </span>
                  </Checkbox>
                ))}
              </div>
            </Panel>
          ))}
        </Collapse>
        {parameters && (
          <Pagination
            style={{ marginTop: "2rem" }}
            onChange={onChangePagination}
            current={pagination.page}
            total={pagination.totalRecords}
            showSizeChanger={false}
          />
        )}
      </div>
      <div>
        <Button
          className="mt-3 ml-2"
          onClick={() => {
            navigate(`/infrastructures`);
          }}
        >
          Add Infrastructure
        </Button>
      </div>
    </div>
  );
};

export default InfrastructureDashboardView;
