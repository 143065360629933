import { Select } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { infraTypesById } from "../../statics/constants/infraTypes";

const SelectTypeInfrastructure = forwardRef(
  (
    {
      onSelect,
      emitObject,
      defaultValue = null,
      otherSettings = {},
    }: {
      onSelect: Function;
      emitObject?: boolean;
      defaultValue?: any;
      otherSettings?: any;
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue);

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
    }));

    return (
      <Select
        {...otherSettings}
        value={selectedValue}
        placeholder="Type Infrastructure"
        defaultValue={defaultValue}
        defaultActiveFirstOption={false}
        onChange={(val: string) => {
          if (val) {
            setSelectedValue(val);
            onSelect(emitObject ? JSON.parse(val || "{}") : val);
          }
        }}
        style={{ width: "140px" }}
        options={Object.entries(infraTypesById).map(([key, value]) => ({
          value: parseInt(key),
          label: value,
        }))}
        allowClear
        onClear={() => {
          onSelect(null);
          setSelectedValue(null);
        }}
      />
    );
  }
);

export default SelectTypeInfrastructure;
