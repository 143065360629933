import {
  Modal,
  Button,
  Divider,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tabs,
} from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { Rule } from "antd/lib/form";
import UserService from "../../domain/Users/UserService";
import Locations from "../../components/Locations/Locations/Locations";
import LocationService from "../../domain/Location/LocationService";
import Tree, { DataNode } from "antd/es/tree";
import SelectSearch from "../../components/Library/SelectSearch";
import CountryService from "../../domain/Country/CountryService";
import "./ModalUser.scss";
import PersonalInformation from "../../components/Users/PersonalInformation";
import LocationsUser from "../../components/Users/LocationsUser";

const locationService = new LocationService();
const countryService = new CountryService();
const userService = new UserService();

interface IProps {
  addModalUser: any;
  handleOk: any;
  handleCancel: any;
  setAddModalUser: any;
  roles: any;
  modules: any;
  organizations: any;
  handleGetUsers: any;
  isNewUser: any;
  selectedUser: any;
  setSelectedUser: any;
}

type PersonalInformationHandle = React.ElementRef<typeof PersonalInformation>;

const treeData: DataNode[] = [
  {
    title:
      '{"value":1,"label":"asdas","nivel":1,"visible":true,"principal":false}',
    key: "0-0",
    children: [
      {
        title:
          '{"value":1,"label":"asdas","nivel":2,"visible":true,"principal":false}',
        key: "0-0-0",
        disabled: true,
        children: [
          {
            title:
              '{"value":1,"label":"asdas","nivel":3,"visible":true,"principal":false}',
            key: "0-0-0-0",
            disableCheckbox: true,
          },
          {
            title:
              '{"value":1,"label":"asdas","nivel":3,"visible":true,"principal":true}',
            key: "0-0-0-1",
          },
        ],
      },
      {
        title:
          '{"value":1,"label":"asdas","nivel":2,"visible":true,"principal":false}',
        key: "0-0-1",
        children: [
          {
            title:
              '{"value":1,"label":"asdas","nivel":3,"visible":true,"principal":false}',
            key: "0-0-1-0",
          },
        ],
      },
    ],
  },
  {
    title:
      '{"value":1,"label":"asdas","nivel":1,"visible":true,"principal":false}',
    key: "0-1",
    children: [
      {
        title:
          '{"value":1,"label":"asdas","nivel":2,"visible":true,"principal":false}',
        key: "0-0-0",
        disabled: true,
      },
    ],
  },
];

const ModalUser: FC<IProps> = ({
  addModalUser,
  handleOk,
  handleCancel,
  setAddModalUser,
  roles,
  modules,
  organizations,
  handleGetUsers,
  isNewUser,
  selectedUser,
  setSelectedUser,
}) => {
  const personalInformationRef = React.useRef<PersonalInformationHandle>(null);
  const organizationId = JSON.parse(
    localStorage.getItem("security") || "{}"
  ).organizationId;
  const [selectNav, setSelectNav] = useState("UserInformation");

  return (
    <div>
      <Modal
        title={
          <div className="title-modal">
            {" "}
            {!isNewUser ? "EDIT USER" : "NEW USER"}
          </div>
        }
        open={addModalUser}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        closable={false}
        width={700}
        destroyOnClose={true}
        afterClose={() => personalInformationRef.current?.resetForm()}
      >
        <Tabs
          onTabClick={(key) => {
            if (key == "AsignLocation" && !selectedUser) return;
            setSelectNav(key);
          }}
          activeKey={selectNav}
          items={[
            {
              label: "User information",
              key: "UserInformation",
              children: (
                <PersonalInformation
                  ref={personalInformationRef}
                  handleGetUsers={handleGetUsers}
                  isNewUser={isNewUser}
                  modules={modules}
                  organizations={organizations}
                  roles={roles}
                  selectedUser={selectedUser}
                  setAddModalUser={setAddModalUser}
                  setSelectNav={setSelectNav}
                  setSelectedUser={setSelectedUser}
                />
              ),
            },
            {
              label: "Assign location",
              key: "AsignLocation",
              children: (
                <LocationsUser
                  setAddModalUser={setAddModalUser}
                  isNewUser={isNewUser}
                  selectedUser={selectedUser}
                  setSelectNav={setSelectNav}
                />
              ),
              disabled: false,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default ModalUser;
