import { api, apiController, restartApiCotroller } from "./api";

const getLinksByLocation = async (locationId) => {
  try {
    restartApiCotroller();
    const response = await api.get(`links/locations/${locationId}`, {
      signal: apiController.signal,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getLinksMinify = async (locationId) => {
  try {
    restartApiCotroller();
    const response = await api.get(`links/locations/${locationId}/minify`, {
      signal: apiController.signal,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getLinksByLocation, getLinksMinify };
