import { Button, Input, Modal, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import StateMachineContext from "../context";
import AceEditor from "react-ace";

interface propertyTypes {
  selectedNode: any;
  onCancel: Function;
}

export default function ActionModal({
  selectedNode,
  onCancel,
}: propertyTypes): React.JSX.Element {
  const { value: context, setValue: setContext } =
    useContext(StateMachineContext);
  const [action, setAction] = useState<any>({
    name: null,
    type: null,
    input: null,
    output: null,
    constant: null,
    value: null,
    statement: null,
  });

  const filterOptions = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    setAction(selectedNode.data.value);
  }, [selectedNode]);

  return (
    <Modal
      title="Link Action"
      open={true}
      footer={[]}
      onCancel={() => {
        onCancel();
      }}
    >
      <div className="flex column">
        <Input
          type="text"
          placeholder="Action name"
          onChange={(event) => {
            setAction({ ...action, name: event.target.value });
          }}
          className="mb-1"
          value={action.name}
        />
        <Select
          style={{ width: "100%" }}
          placeholder="Select an option"
          onChange={(val) => {
            setAction({ ...action, type: val });
          }}
          options={[
            { value: "FUNCTION", label: "Function" },
            { value: "CONSTANT", label: "Assignment" },
          ]}
          value={action.type}
          className="mb-1"
        />
        {action.type == "FUNCTION" && (
          <>
            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Input</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, input: val });
                }}
                onSearch={() => {}}
                filterOption={filterOptions}
                options={context.inputsList.map((input: any) => {
                  return {
                    label: input.label,
                    value: input.label,
                  };
                })}
                value={action.input}
              />
            </div>
            <div className="flex mb-1">
              <span style={{ width: "11%" }}>Output</span>
              <Select
                style={{ width: "89%" }}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, output: val });
                }}
                onSearch={() => {}}
                filterOption={filterOptions}
                options={context.outputsList.map((output: any) => {
                  return {
                    label: output.label,
                    value: output.label,
                  };
                })}
                value={action.output}
              />
            </div>
            <div className="flex">
              <AceEditor
                className="mt-1"
                mode="python"
                theme="github"
                name="editor"
                onChange={(val) => {
                  setAction({ ...action, statement: val });
                }}
                value={action.statement}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                // value={virtualSource.code}
                setOptions={{
                  useWorker: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                placeholder="Type your code here"
                style={{ maxHeight: "120px" }}
              />
            </div>
          </>
        )}
        {action.type == "CONSTANT" && (
          <>
            <div className="flex">
              <Select
                value={action.constant}
                showSearch
                placeholder="Search"
                optionFilterProp="children"
                onChange={(val) => {
                  setAction({ ...action, constant: val });
                  if (action.value && val) {
                    setAction({
                      ...action,
                      statement: `${val}=${action.value}`,
                    });
                  }
                }}
                onSearch={() => {}}
                filterOption={filterOptions}
                options={context.outputsList.map((output) => {
                  return {
                    label: output.label,
                    value: output.label,
                  };
                })}
              />
              <span className="mx-2">=</span>
              <Input
                value={action.value}
                placeholder="Value"
                onChange={(event) => {
                  setAction({ ...action, value: event.target.value });
                  if (action.constant && event.target.value) {
                    setAction({
                      ...action,
                      statement: `${action.constant}=${event.target.value}`,
                      value: event.target.value,
                    });
                  }
                }}
              />
            </div>
          </>
        )}
        <div className="flex justify-center">
          <Button
            style={{
              backgroundColor: "#00203C",
              color: "white",
              height: "40px",
            }}
            onClick={() => {
              selectedNode.data.onChange(action, selectedNode.id);
              onCancel();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
