import React from "react";

interface InfraestructureValueInterface {
  selectedParam: Parameter | null;
  selectedVirtualSource: VirtualSource | null;
  showAddVariableVirtualParam: boolean;
  handleAddVariableVirtualParam: Function | null;
  locationName: string;
}

interface InfraestructureContextInterface {
  value: InfraestructureValueInterface;
  setValue: React.Dispatch<React.SetStateAction<InfraestructureValueInterface>>;
}

const initialContext: InfraestructureContextInterface = {
  value: {
    selectedParam: null,
    selectedVirtualSource: null,
    showAddVariableVirtualParam: false,
    handleAddVariableVirtualParam: null,
    locationName: '',
  },
  setValue: () => {},
};

const InfraestructureContext = React.createContext(initialContext);

export default InfraestructureContext;
