import { api } from "../../services/api";
import { apiNl } from "../../services/apiNl";
export default class DevicesRepository {
  async uploadIotBulk(data: Iot): Promise<boolean> {
    const bulkIot = await api.post(`/devices/iot`, data);
    return bulkIot.data;
  }

  async getIotByOrganization(
    organizationId: number | string,
    page = 1,
    size = 10,
    name: null | string = null
  ) {
    const response = await api.get(
      `/devices/iot/organizations/${organizationId}`,
      {
        params: { page, size,name },
      }
    );
    return response.data;
  }

  async getIotInformation(deviceId: number | string, loading: boolean = true) {
    let axiosMode = loading ? api : apiNl;
    const response = await axiosMode.get(`/devices/${deviceId}/info`);
    return response.data;
  }

  async getStatusOfUpload(
    iotUploadId: number | string,
    loading: boolean = true
  ) {
    let axiosMode = loading ? api : apiNl;
    const response = await axiosMode.get(`/devices/iot/upload/${iotUploadId}`);
    return response.data;
  }

  async postCheckedIot(iotUploadId: number | string) {
    const response = await api.post(
      `/devices/iot/uploads/${iotUploadId}/checked`
    );
    return response.data;
  }

  async deleteIot(iotUploadId: number | string) {
    const response = await api.delete(`/devices/iot/uploads/${iotUploadId}`);
    return response.data;
  }

  async getMessagesByIot(iotUploadId: number | string) {
    const response = await api.get(
      `devices/${iotUploadId}/messages?page=1&size=500`
    );
    return response.data;
  }
}
