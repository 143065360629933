import ParameterFileRepository from "./ParameterFilesRepository";

export default class ParameterFileService {
  private parameterFileRepository: ParameterFileRepository =
    new ParameterFileRepository();

  async getParametersFiles({
    name = null,
    status = null,
    page = 1,
    size = 10,
    loading = true,
  }: {
    name?: null | string;
    status?: null | string;
    page: number;
    size: number;
    loading?: boolean;
  }): Promise<any> {
    return await this.parameterFileRepository.getParametersFiles({
      name,
      status,
      page,
      size,
      loading,
    });
  }

  async getStatusOfUpload(id: number | string, loading: boolean = true) {
    return await this.parameterFileRepository.getStatusOfUpload(id, loading);
  }

  async postParameterFiles(file: any): Promise<any> {
    return await this.parameterFileRepository.postParameterFiles(file);
  }

  async deleteParameterFile(
    parameterFileId: string | number
  ): Promise<Parameter[]> {
    return await this.parameterFileRepository.deleteParameterFile(
      parameterFileId
    );
  }
}
