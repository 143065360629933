import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  notification,
  Pagination,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import DevicesService from "../../domain/Devices/DevicesService";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { iotEventsService } from "../../events/iot-events.subject";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useSearchParams } from "react-router-dom";

const devicesService = new DevicesService();

export default function BulkDevicesView() {
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  });
  const [query, setQuery] = useState<any>(null);
  const [messageError, setMessageError] = useState("");
  const [showErrorInfoModal, setShowErrorInfoModal] = useState(false);
  const [selectedIot, setSelectedIot] = useState<BulkDevice | null>(null);
  const [showIotDetails, setShowIotDetails] = useState(false);
  const subscriptionIot$ = iotEventsService.getSubject();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const selectedDevice = useRef<any>(null);
  const navigate = useNavigate();

  const columns: ColumnsType<BulkDevice> = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Serial number",
      key: "serialNumber",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          {record.status === "COMPLETED" ? (
            <span
              style={{
                color: "#22A2CA",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedIot(record);
                setShowIotDetails(true);
              }}
            >
              View devices
            </span>
          ) : (
            <span>--</span>
          )}
        </div>
      ),
    },
    {
      title: "Upload by",
      key: "uploadFor",
      width: 200,
      render: (_, record) => (
        <div className="flex column">
          <span
            style={{
              color: "#232B36",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {record.createdBy}
          </span>
          <span
            style={{
              color: "#74788D",
            }}
          >
            {dayjs(record.createdAt).format("YYYY/MM/DD [at] hh:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      render: (_, record) => (
        <span>
          <Tag
            color={
              record.status == "COMPLETED"
                ? "green"
                : record.status == "FAILED"
                ? "red"
                : "orange"
            }
            style={{ borderRadius: "20px" }}
          >
            {record.status}
          </Tag>
        </span>
      ),
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="flex">
          {record.status === "FAILED" && (
            <WarningOutlined
              className="mr-1 cursor-pointer"
              style={{ fontSize: "1.5rem", color: "red" }}
              onClick={() => {
                setSelectedIot(record);
                setMessageError("Error");
                setShowErrorInfoModal(true);
              }}
              rev={undefined}
            />
          )}
          {record.status !== "IN PROGRESS" && (
            <Tooltip title="Delete">
              <DeleteOutlined
                className="mr-1"
                style={{ fontSize: "1.5rem" }}
                onClick={async () => {
                  selectedDevice.current = record;
                  setShowConfirmDelete(true);
                }}
                rev={undefined}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const columnsDetail: ColumnsType<any> = [
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      width: 300,
    },
    {
      title: "Sensor",
      dataIndex: "sensor",
      key: "sensor",
      width: 300,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
      width: 300,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
      width: 300,
    },
  ];

  const getDevices = async () => {
    const oui = JSON.parse(
      localStorage.getItem("security") || "{}"
    ).organizationId;
    const response = await devicesService.getIotByOrganization(
      oui,
      pagination.page,
      pagination.pageSize,
      query
    );
    console.log("🚀 ~ file: index.tsx:126 ~ getDevices ~ response:", response);
    setBulkList(response.data);
    setPagination({
      ...pagination,
      totalPages: response.totalPages,
      totalRecords: response.totalRecords,
    });
  };

  const handleDeleteIot = async (iotId: string | number) => {
    setShowConfirmDelete(false);
    const response = await devicesService.deleteIot(iotId);
    if (response) {
      await getDevices();
      notification.success({
        message: "Successful",
        description: "Device deleted successfully",
      });
    }
  };

  const onChangePagination = (page: any) => {
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    subscriptionIot$.subscribe((event) => {
      if (event.type == "iotUploaded") {
        console.log(event);
        setBulkList((prev) =>
          prev.map((item) =>
            item.id === event.data.id
              ? { ...item, status: event.data.status }
              : item
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    getDevices();
  }, [pagination.page]);

  useEffect(() => {
    const prevPage = pagination.page;
    if (prevPage == 1) {
      getDevices();
    } else {
      setPagination({ ...pagination, page: 1 });
    }
  }, [query]);

  return (
    <div className="flex column">
      <span className="text-title-page mb-4">Bulk Device</span>
      {bulkList.length > 0 ? (
        <>
          <div className="flex justify-between mb-3">
            <div className="flex">
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                prefix={
                  <SearchOutlined
                    style={{ color: "#078CB3" }}
                    rev={undefined}
                  />
                }
                onPressEnter={(e: any) => {
                  if (e.target) {
                    setQuery(e.target.value);
                  }
                }}
                onChange={(e) => {
                  if (!e.target.value || e.target.value == "") {
                    setQuery(null);
                  }
                }}
                allowClear
                className="mr-2"
              />
              {/* <div style={{ width: "200px" }}>
                <Select
                  id="userId"
                  value={selectedUserId}
                  notFoundContent={null}
                  onChange={(val) => {
                    setSelectedUserId(val);
                  }}
                  style={{ minWidth: "200px" }}
                  showSearch
                  options={(users || []).map((u) => ({
                    value: u.userId,
                    label: `${u.firstName} ${u.lastName}`,
                  }))}
                />
              </div> */}
            </div>
            <Button
              type="primary"
              shape="round"
              className="flex items-center self-center"
              style={{
                backgroundColor: "#023E65",
                color: "white",
                width: "150px",
                height: "40px",
              }}
              icon={<PlusCircleOutlined rev={undefined} />}
              onClick={() => {
                navigate(`/bulk/upload`);
              }}
            >
              Upload IOT Files
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={bulkList}
            pagination={false}
            style={{
              maxHeight: "calc(100vh - 320px)",
              overflowX: "auto",
            }}
            bordered
          />
          <Pagination
            style={{ marginTop: "2rem" }}
            onChange={onChangePagination}
            total={pagination.totalRecords}
            current={pagination.page}
            showSizeChanger={false}
          />
        </>
      ) : (
        <div
          className="flex column items-center justify-center"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <span style={{ fontSize: "16px" }} className="text-center">
            Woops looks like you have nothing here. <br />
            Start by clicking here
          </span>
          <Button
            type="primary"
            shape="round"
            className="flex items-center self-center mt-3"
            style={{
              backgroundColor: "#023E65",
              color: "white",
              width: "150px",
              height: "40px",
            }}
            icon={<PlusCircleOutlined rev={undefined} />}
            onClick={() => {
              navigate(`/bulk/upload`);
            }}
          >
            Upload IOT Files
          </Button>
        </div>
      )}
      <Modal
        open={showErrorInfoModal}
        footer={[]}
        closable={false}
        style={{ maxWidth: "300px" }}
      >
        <div className="flex column items-center">
          <span
            className="mb-2"
            style={{ color: "#F46A6A", fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Error
          </span>
          <div
            className="flex items-center justify-center mb-3"
            style={{
              width: "150px",
              height: "56px",
              backgroundColor: "#FFD3D380",
              borderRadius: "5px",
            }}
          >
            <span
              className=""
              style={{
                color: "#F46A6A",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              {messageError}
            </span>
          </div>

          <div className="separator mb-2" />
          <div className="flex justify-center">
            <Button
              style={{
                width: "85px",
                height: "40px",
                backgroundColor: "#F46A6A",
                color: "white",
              }}
              onClick={async () => {
                if (selectedIot) {
                  const response = await devicesService.postCheckedIot(
                    selectedIot.iotUploadId
                  );
                  if (response.success === 1) {
                    navigate(`/bulk/upload`);
                  }
                }
              }}
            >
              Try again
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={showIotDetails} footer={[]} closable={false} width={800}>
        <div className="flex column">
          <div className="flex justify-between">
            <span
              className="mb-2"
              style={{
                color: "#023E65",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {`${selectedIot?.name} DEVICES`}
            </span>
            <CloseOutlined
              className="mb-auto"
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setShowIotDetails(false);
              }}
              rev={undefined}
            />
          </div>
          <div className="separator mb-2" />
          <Table
            columns={columnsDetail}
            dataSource={selectedIot?.serialNumbers.split("\n").map((dev) => {
              const props = dev.split(",");
              return {
                device: props[0],
                sensor: props[1],
                longitude: props[2],
                latitude: props[3],
              };
            })}
            pagination={false}
            style={{
              maxHeight: "calc(100vh - 300px)",
              overflowX: "auto",
            }}
            bordered
          />
        </div>
      </Modal>
      <ConfirmationModal
        title="Delete Device?"
        message="Are you sure to delete it?"
        showModal={showConfirmDelete}
        onAccept={() => {
          if (selectedDevice) {
            handleDeleteIot(selectedDevice.current.iotUploadId);
          }
        }}
        oncancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
}
