import React, { useState } from "react";

interface DiagramBlockValueInterface {
  locationId: string;
  infrastructures: any[];
}

interface DiagramBlockContextInterface {
  value: DiagramBlockValueInterface;
  setValue: React.Dispatch<React.SetStateAction<DiagramBlockValueInterface>>;
}

const initialContext: DiagramBlockContextInterface = {
  value: {
    locationId: "",
    infrastructures: [],
  },
  setValue: () => {},
};

const DiagramBlockContext = React.createContext(initialContext);

export default DiagramBlockContext;
