import CountryRepository from "./CountryRepository";

export default class CountryService {
  private countryRepository: CountryRepository = new CountryRepository();

  async getCountriesByOrganization(
    organizationId: string
  ): Promise<CountryOrganization[]> {
    return this.countryRepository.getCountriesByOrganization(organizationId);
  }

  async getCountries(): Promise<Country[]> {
    return this.countryRepository.getCountries();
  }
}
