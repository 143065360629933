import React, { FC } from "react";

/**
 * @author
 * @function @HomeSvg
 **/

const HomeSvg: FC = () => {
    return (

        <svg fill="none" height="22" viewBox="0 0 51 42" width="48" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m41.7083 23.794-17.6504-17.70831-17.76385 17.70591c-.39116.3899-.3922 1.0231-.00231 1.4143.38989.3911 1.02305.3922 1.41421.0023l2.29405-2.2866v18.0782c0 .5523.4477 1 1 1h9c.5523 0 1-.4477 1-1v-8.7408c0-.5522.4477-1 1-1h4c.5523 0 1 .4478 1 1v8.7408c0 .5523.4477 1 1 1h9c.5523 0 1-.4477 1-1v-18.0932l2.2917 2.2993c.3899.3911 1.0231.3922 1.4142.0023.3912-.3899.3922-1.0231.0024-1.4142zm-5.7083-2.894-11.9467-11.98588-12.0533 12.01398v19.0717h7v-7.7408c0-1.6568 1.3431-3 3-3h4c1.6569 0 3 1.3432 3 3v7.7408h7z" fill="#333" fill-rule="evenodd" /></svg>
    );
};

export default HomeSvg;
