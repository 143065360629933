import { api } from "../services/api";

// constants
let initialData = {
  fetching: false,
  array: [],
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_INP = "GET_INP";
let GET_INP_SUCCESS = "GET_INP_SUCCESS";
let GET_INP_ERROR = "GET_INP_ERROR";

// reducer
export default function inpReducer(state = initialData, action) {
  switch (action.type) {
    case GET_INP:
      return { ...state, fetching: true };
    case GET_INP_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_INP_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export const getInpAction = () => (dispatch, getState) => {
  let oui = JSON.parse(localStorage.getItem("security") || "{}").organizationId;
  return api
    .get(`${URL}/inps/uploads/organizations/${oui}`)
    .then((res) => {
      dispatch({
        type: GET_INP_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_INP_ERROR,
        payload: err.response.message,
      });
    });
};
