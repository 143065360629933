import { api } from "../services/api";
// constants
let initialData = {
    fetching: false,
    array: [],
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/users/modules/";

let GET_MENU = "GET_MENU";
let GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
let GET_MENU_ERROR = "GET_MENU_ERROR";

// reducer
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case GET_MENU:
            return { ...state, fetching: true };
        case GET_MENU_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_MENU_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let getMenusAction = () => (dispatch, getState) => {
    dispatch({
        type: GET_MENU,
    });
    return api
        .get(URL)
        .then((res) => {
            dispatch({
                type: GET_MENU_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_MENU_ERROR,
                payload: err.response.message,
            });
        });
};
