import HistoricalRepository from "./HistoricalReposiory";

export default class HistoricalService {
  private historicalRepository: HistoricalRepository =
    new HistoricalRepository();

  async postHistoricalFiles(file: any): Promise<boolean> {
    return await this.historicalRepository.postHistoricalFiles(file);
  }

  async getHistoricalFiles({
    name = null,
    status = null,
    page = 1,
    size = 10,
    loading = true,
  }: {
    name?: null | string;
    status?: null | string;
    page: number;
    size: number;
    loading?: boolean;
  }): Promise<any> {
    return await this.historicalRepository.getHistoricalFiles({
      name,
      status,
      page,
      size,
      loading,
    });
  }
  async deleteHistoricalFile(
    historicalFileId: string | number
  ): Promise<Parameter[]> {
    return await this.historicalRepository.deleteHistoricalFile(
      historicalFileId
    );
  }
}
