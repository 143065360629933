let SHOW_LOADING = "SHOW_LOADING";

const initialState = {
  options: { show: false, title: null, message: null, type: "success" },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING:
      return action.payload;
    default:
      return state;
  }
}

export const showSucessModal =
  (title = null, message = null) =>
  (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
      payload: { options: { show: true, type: "success", title, message } },
    });
  };

export const showErrorModal =
  (title = null, message = null) =>
  (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
      payload: { options: { show: true, type: "error", title, message } },
    });
  };

export const showWarningModal =
  (title = null, message = null) =>
  (dispatch) => {
    console.log("Show warning2");
    dispatch({
      type: SHOW_LOADING,
      payload: { options: { show: true, type: "warning", title, message } },
    });
  };

export const hideModal = () => (dispatch, getState) => {
  dispatch({
    type: SHOW_LOADING,
    payload: {
      options: { ...getState().modalAlert.options, show: false },
    },
  });
  setTimeout(() => {
    dispatch({
      type: SHOW_LOADING,
      payload: initialState,
    });
  }, 1000);
};
