import { api } from "../services/api";

// constants
let initialData = {
  fetching: false,
  array: [],
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_NOTIFICATION = "GET_NOTIFICATION";
let GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
let GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

// reducer
export default function activitiesReducer(state = initialData, action) {
  switch (action.type) {
    case GET_NOTIFICATION:
      return { ...state, fetching: true };
    case GET_NOTIFICATION_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_NOTIFICATION_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export const getActivitiesAction = (id) => (dispatch) => {
  if (!id) return;
  return api
    .get(`${URL}/notifications/issues/recent/${id}`)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_NOTIFICATION_ERROR,
        payload: err.response.message,
      });
    });
};
