import { Select, TreeSelect } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useLocation from "../../hooks/useLocation";

const SelectLocation = forwardRef(
  (
    {
      userId,
      onSelect,
      mode = "ByUser",
    }: {
      userId: string;
      onSelect: Function;
      mode?: "ByUser" | "ByCountry";
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const { locations, setParams } = useLocation({
      mode,
      userId,
    });

    function parseLocations(locations: any[]): any {
      return locations.map((location) => ({
        value: location.locationId,
        title: location.locationName,
        children: location.childLocations
          ? parseLocations(location.childLocations)
          : [],
        disabled: !location.canShowLocation,
      }));
    }

    useImperativeHandle(ref, () => ({
      setValue: (value: any) => {
        setSelectedValue(value);
      },
    }));

    return (
      <TreeSelect
        value={selectedValue}
        showSearch
        dropdownStyle={{ maxHeight: 400, overflow: "auto", minWidth: 300 }}
        placeholder="Select Location"
        allowClear
        treeDefaultExpandAll
        treeData={parseLocations(locations)}
        onSelect={(val) => {
          onSelect(val);
          setSelectedValue(val);
        }}
        onClear={() => {
          onSelect(null);
          setSelectedValue(null);
        }}
      />
    );
  }
);

export default SelectLocation;
