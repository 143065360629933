import { api, apiController } from "../services/api";

// constants
let initialData = {
    fetching: false,
    array: {},
    current: {},
};

let URL = process.env.REACT_APP_BASE_URL;

let GET_PROFILE = "GET_PROFILE";
let GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
let GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

// reducer
export default function profileReducer(state = initialData, action) {
    switch (action.type) {
        case GET_PROFILE:
            return { ...state, fetching: true };
        case GET_PROFILE_SUCCESS:
            return { ...state, array: action.payload, fetching: false };
        case GET_PROFILE_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
// actions (tunks)

export let profileAction = (userId) => (dispatch, getState) => {
    dispatch({
        type: GET_PROFILE,
    });
    return api
        .get(`${URL}/users/${userId}`)
        .then((res) => {
            localStorage.setItem("security", JSON.stringify(res.data));
            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: GET_PROFILE_ERROR,
                payload: err.response.message,
            });
        });
};
